/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import {
    MdMap,
    MdAccountCircle,
    MdPoll,
    // MdBrightness7,
    MdNotificationsOff,
    MdNotifications,
    MdWifi,
    MdWifiOff,
} from 'react-icons/md';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import { useQueryState } from 'use-location-state';

import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';

import Forbidden from '~/components/Forbidden';
import Header from '~/components/Header';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Outside';

import menuApps from '~/config/Apps';
import useIsMounted from '~/hooks/useIsMounted';
import RC3Footer from '~/pages/RC3/Components/RC3Footer';
import api from '~/services/pluginbot-api';
import {
    changeAppRequest,
    changeAppSettings,
} from '~/store/modules/application/actions';
import { signOut } from '~/store/modules/auth/actions';
import { loadPluginspaceSettingsRequest } from '~/store/modules/settings/actions';

import {
    AppContainer,
    Container,
    Body,
    RC3ToastContainer,
    DarkDialog,
} from './styles';

const footer_menu = [
    {
        key: 'rc3.menu.dashboard',
        icon: MdPoll,
        value: 'dashboard',
    },
    {
        key: 'rc3.menu.map',
        icon: MdMap,
        value: 'map',
    },
    {
        key: 'rc3.menu.robots',
        icon: MdAccountCircle,
        value: 'robots',
    },
    // {
    //     condition: 'disinfection',
    //     key: 'rc3.menu.disinfections',
    //     icon: MdBrightness7,
    //     value: 'disinfections',
    // },
];

export default function RC3({ children, expired, screen }) {
    const { isSmallScreen, isMobile, windowSize, minHeight } = screen;
    const isMounted = useIsMounted();
    const dispatch = useDispatch();

    const ps_settings = useSelector(state => state.settings);
    const appSettings = useSelector(state => state.application);

    const current_settings =
        appSettings && appSettings.settings ? appSettings.settings : {};

    const main_app = menuApps.getMainApp();
    const [isLoading, setIsLoading] = useState(true);
    const [RC3Settings, setRC3Settings] = useState({});
    const [forbidden, setForbidden] = useState(false);
    const [navCollapsed, setNavCollapsed] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [queryTab, setQueryTab] = useQueryState('tab', '');
    const [currTab, setCurrTab] = useState(queryTab || footer_menu[0].value);
    const [connectionFailed, setConnectionFailed] = useState(false);
    const [connectionDialogOpen, setConnectionDialogOpen] = useState(true);

    function updateAppSettings(key, value) {
        const new_settings = {
            ...current_settings,
            [key]: value,
        };

        dispatch(changeAppSettings(new_settings));
    }

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function verifySettings() {
        await api
            .get(`rc3/auth/pluginspace`)
            .then(response => {
                const { data } = response;
                setRC3Settings(data);

                if (!data.active) {
                    setForbidden(true);
                }
                setIsLoading(false);
            })
            .catch(error => requestError(error));

        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        if (isMounted.current) {
            verifySettings();
            const { pluginspace } = ps_settings;
            if (pluginspace) {
                dispatch(loadPluginspaceSettingsRequest(pluginspace.id));
            }
        }
    }, []);

    useEffect(() => {
        if (forbidden) {
            setTimeout(() => {
                dispatch(changeAppRequest(main_app));
            }, 5000);
        }
    }, [forbidden]);

    useEffect(() => {
        setDialogOpen(expired);
    }, [expired]);

    useEffect(() => {
        setConnectionFailed(false);
        if (current_settings.status === 'connected') {
            setConnectionDialogOpen(false);
            setTimeout(() => {
                setConnectionDialogOpen(false);
            }, 1000);
        } else {
            setConnectionDialogOpen(true);
            setTimeout(() => {
                setConnectionFailed(true);
            }, 15000);
        }
    }, [current_settings.status]);

    const handleChangeTab = (event, newValue) => {
        setCurrTab(newValue);
        if (queryTab !== newValue) {
            setQueryTab(newValue);
        }
    };

    const drawerStyle = isSmallScreen ? 'mini-drawer' : 'collapsible-drawer';

    function onToggleCollapsedNav() {
        setNavCollapsed(!navCollapsed);
    }

    function handleDialogClose() {
        setDialogOpen(false);
        dispatch(signOut());
        return true;
    }

    function buildLogoutDialog() {
        return (
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        <LocaleMessage msg="message.session.expired" />
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <LocaleMessage msg="message.session.inactivity.description" />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleDialogClose}
                            color="primary"
                            autoFocus
                        >
                            <LocaleMessage msg="button.close" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    function buildConnectionDialog() {
        return (
            <Dialog
                open={
                    (current_settings &&
                        current_settings.status === 'disconnected') ||
                    connectionDialogOpen
                }
                onClose={() => {
                    setConnectionDialogOpen(false);
                }}
                maxWidth="sm"
                fullWidth
            >
                <DarkDialog>
                    <DialogTitle>
                        {current_settings &&
                        current_settings.status === 'connected' ? (
                            <LocaleMessage msg="message.syncing" />
                        ) : (
                            <LocaleMessage
                                msg={
                                    connectionFailed
                                        ? 'message.connection_failed'
                                        : 'message.connecting'
                                }
                            />
                        )}
                    </DialogTitle>
                    <DialogContent>
                        {!connectionFailed ? (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <ReactLoading
                                    type="bubbles"
                                    color="#fff"
                                    height={80}
                                    width={80}
                                />
                            </div>
                        ) : null}
                    </DialogContent>
                    {connectionFailed ? (
                        <DialogActions>
                            <Button
                                onClick={() => setConnectionDialogOpen(false)}
                                color="secondary"
                                autoFocus
                            >
                                <LocaleMessage msg="button.close" />
                            </Button>
                            <Button
                                onClick={() => window.location.reload()}
                                color="primary"
                                autoFocus
                            >
                                <LocaleMessage msg="button.retry" />
                            </Button>
                        </DialogActions>
                    ) : null}
                </DarkDialog>
            </Dialog>
        );
    }

    const extras = RC3Settings && RC3Settings.extras ? RC3Settings.extras : [];
    const apps =
        RC3Settings && RC3Settings.applications ? RC3Settings.applications : {};

    const menuItems = footer_menu.filter(i => {
        const { condition } = i;
        return !condition || apps[condition] || extras.includes(condition);
    });

    function getExtraInfo(settings = {}) {
        return [
            {
                key: 'user_status',
                type: 'text',
                icon: settings.status === 'connected' ? MdWifi : MdWifiOff,
                text:
                    settings.status === 'connected'
                        ? 'message.connected'
                        : 'message.connecting',
            },
            {
                key: 'sound',
                type: 'icon',
                onClick: () => {
                    updateAppSettings('sound', !settings.sound);
                },
                icon: settings.sound ? MdNotifications : MdNotificationsOff,
                text: settings.sound
                    ? 'rc3.menu.sound.on'
                    : 'rc3.menu.sound.off',
                tooltip: settings.sound
                    ? 'rc3.menu.sound.on'
                    : 'rc3.menu.sound.off',
            },
        ];
    }

    return (
        <>
            {dialogOpen ? buildLogoutDialog() : null}
            {connectionDialogOpen ? buildConnectionDialog() : null}
            <RC3ToastContainer
                toastClassName="toast-container"
                autoClose={false}
                closeButton={false}
            />
            <AppContainer>
                <Header
                    smallScreen={isMobile || windowSize.height < minHeight}
                    extraInfo={getExtraInfo(current_settings || {})}
                    selectGroup
                    drawer={drawerStyle}
                    toggleCollapsedNav={() => onToggleCollapsedNav()}
                />
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {forbidden ? (
                            <Forbidden
                                showHome={false}
                                body={
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <h3 className="text-center fw-regular title bounceIn animation-delay-10 animated mb-5">
                                            <span>
                                                <LocaleMessage msg="message.redirect.platform" />
                                            </span>
                                        </h3>
                                        <ReactLoading
                                            type="bars"
                                            color="#c8c8c8"
                                            height={80}
                                            width={80}
                                        />
                                    </div>
                                }
                            />
                        ) : (
                            <>
                                <Container className="app-main-container">
                                    <Body>
                                        {{
                                            ...children,
                                            props: {
                                                ...children.props,
                                                tab: currTab,
                                                handleChangeTab: (
                                                    event,
                                                    tab
                                                ) => {
                                                    handleChangeTab(event, tab);
                                                },
                                                blockUser: () =>
                                                    setForbidden(true),
                                                smallScreen: isSmallScreen,
                                            },
                                        }}
                                    </Body>
                                </Container>
                                <RC3Footer
                                    smallScreen={isSmallScreen}
                                    items={menuItems}
                                    handleChangeTab={(event, val) =>
                                        handleChangeTab(event, val)
                                    }
                                />
                            </>
                        )}
                    </>
                )}
            </AppContainer>
        </>
    );
}

RC3.propTypes = {
    expired: PropTypes.bool,
    children: PropTypes.element.isRequired,
    screen: PropTypes.object,
};

RC3.defaultProps = {
    expired: false,
    screen: {},
};
