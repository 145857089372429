import styled from 'styled-components';

export const SearchInput = styled.div`
    .MuiInputAdornment-root {
        :hover {
            cursor: pointer;
        }
    }
`;

export const AppConfigArea = styled.div`
    padding: 20px 10px;
    border: 1px solid #ddd;

    .row {
        margin: 10px 0px;
    }
`;

export const IconArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;

    :hover {
        cursor: pointer;
    }
`;

export const Settings = styled.div`
    h3 {
        color: ${props => {
            return props.theme.dark_mode ? '#fff' : '#000';
        }} !important;
    }
`;

export const RC3Settings = styled.div`
    h3 {
        color: ${props => {
            return props.theme.dark_mode ? '#fff' : '#000';
        }} !important;
    }
`;
