/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import AWSMqttClient from 'aws-mqtt';
import AWS from 'aws-sdk/global';
import PropTypes from 'prop-types';
import { useQueryState } from 'use-location-state';

import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';
import { changeAppSettings } from '~/store/modules/application/actions';

import Alerts from './Alerts';
import Dashboard from './Dashboard';
import Disinfections from './Disinfections';
import Locations from './Locations';
import RobotPage from './RobotPage';
import { AppContainer } from './styles';
// import Telepresence from './Telepresence';

const error_time = 2000;

export default function RC3({ tab, blockUser, handleChangeTab, smallScreen }) {
    const dispatch = useDispatch();
    const mountedRef = React.useRef(true);
    const userSettings = useSelector(state => state.user);
    const appSettings = useSelector(state => state.application);
    const settings = useSelector(state => state.settings || null);
    const current_app = appSettings.app_type;

    const groups =
        userSettings && userSettings.groups ? userSettings.groups : null;
    const user =
        userSettings && userSettings.profile ? userSettings.profile : null;
    const current_settings =
        appSettings && appSettings.settings ? appSettings.settings : {};

    const [applications, setApplications] = useState([]);
    const [robotTypes, setRobotTypes] = useState({});
    const [robots, setRobots] = useState([]);
    const [locations, setLocations] = useState([]);
    const [robotLocations, setRobotLocations] = useState({});
    const [zones, setZones] = useState([]);
    const [RC3Client, setRC3Client] = useState(null);
    const [RC3UserSettings, setRC3UserSettings] = useState(null);
    const [userStatus, setUserStatus] = useState('disconnected');
    const [robotStatus, setRobotStatus] = useState({});
    const [lastMessage, setLastMessage] = useState({});
    const [lastEvent, setLastEvent] = useState({});
    const [lastNotification, setLastNotification] = useState({});
    const [lastAlert, setLastAlert] = useState({});

    const [, setCurrRobotId] = useQueryState('robot', '');

    function changePage(options) {
        if (options.tab) {
            handleChangeTab({}, options.tab);
        }
        if (options.robot) {
            setCurrRobotId(options.robot);
        }
    }

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message, {
                autoClose: error_time,
            });
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />, {
                autoClose: error_time,
            });
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />, {
                autoClose: error_time,
            });
        }
    }

    function updateAppSettings(key, value) {
        const new_settings = {
            ...current_settings,
            [key]: value,
        };

        dispatch(changeAppSettings(new_settings));
    }

    async function updateRobotInfo(r_id) {
        const idx = robots.findIndex(r => {
            return r.id === r_id;
        });
        await api
            .get(`rc3/robots/${r_id}/state`)
            .then(response => {
                const { data } = response;
                if (idx !== -1) {
                    const oldState = robots[idx];
                    const newState = {
                        ...oldState,
                        settings: data.settings,
                        state: data.state,
                    };
                    setRobots([
                        ...robots.slice(0, idx),
                        newState,
                        ...robots.slice(idx + 1),
                    ]);
                }
            })
            .catch(error => requestError(error));
    }

    function filterRobotLocations() {
        const r_locations = {};
        if (locations && robots) {
            locations.forEach(l => {
                r_locations[l.id] = {
                    ...l,
                    type: 'address',
                    robots: [],
                };
            });
            r_locations['no-location'] = {
                id: '0',
                name: '---',
                robots: [],
            };

            robots.forEach(r => {
                const r_loc = r && r.location ? r.location : {};
                const r_status =
                    robotStatus && robotStatus[r.id]
                        ? robotStatus[r.id]
                        : { status: 'disconnected' };

                const use_uptated =
                    robotStatus && r_status.status !== 'disconnected';
                const r_geo =
                    use_uptated && r_status.geolocation
                        ? r_status.geolocation
                        : null;
                const updated_loc =
                    use_uptated && r_status.location
                        ? r_status.location.id
                        : r_loc.id;
                const updated_zone =
                    use_uptated && r_status.location && r_status.zone
                        ? r_status.zone.id
                        : r_loc.id;

                const r_l = updated_loc || 'no-location';
                const r_z = updated_loc ? updated_zone : null;

                let r_zone = null;
                if (r_z && zones) {
                    r_zone = zones.find(z => {
                        return z.id === r_z;
                    });
                }

                if (r_geo) {
                    r_locations[r.id] = {
                        id: r.id,
                        name: r.code,
                        geolocation: r_geo,
                        type: 'geolocation',
                        robots: [
                            {
                                id: r.id,
                                name: r.name,
                                type: r.type.id,
                                status: r_status,
                                zone: r_zone ? r_zone.name : '---',
                            },
                        ],
                    };
                    return;
                }

                if (r_l) {
                    const r_list = r_locations[r_l];
                    if (r_list) {
                        r_locations[r_l].robots = [
                            ...r_list.robots,
                            {
                                id: r.id,
                                name: r.name,
                                type: r.type.id,
                                status: r_status,
                                zone: r_zone ? r_zone.name : '---',
                            },
                        ];
                    }
                }
            });
            setRobotLocations(r_locations);
        }
    }

    async function loadApplications() {
        await api
            .get(`rc3/applications`)
            .then(response => {
                const { data } = response;
                setApplications(data);
            })
            .catch(error => requestError(error));
    }

    async function loadLocations() {
        await api
            .get(`rc3/locations`)
            .then(response => {
                const data = response.data.map(l => ({
                    ...l,
                    formatted_address: l.address ? l.address.formatted : '',
                }));
                setLocations(data);
            })
            .catch(error => requestError(error));
    }

    async function loadZones() {
        await api
            .get(`rc3/zones`)
            .then(response => {
                const { data } = response;
                setZones(data);
            })
            .catch(error => requestError(error));
    }

    async function loadRobots() {
        await api
            .get(`rc3/robots`)
            .then(response => {
                const r_status = {};
                const robot_list = [];
                response.data.forEach(r => {
                    robot_list.push(r);
                    r_status[r.id] = {
                        status:
                            r.state && r.state.status
                                ? r.state.status
                                : 'disconnected',
                    };
                });
                setRobots(robot_list);
                setRobotStatus(r_status);
            })
            .catch(error => requestError(error));
    }

    async function loadRobotTypes() {
        await api
            .get(`/rc3/robots/types`)
            .then(response => {
                const r_types = {};
                response.data.forEach(r => {
                    const r_settings = r.settings || {};
                    r_types[r.id] = {
                        id: r.id,
                        name: r.name,
                        code: r.slug,
                        rc3: r.allow_rc3,
                        file: r.file,
                        settings: r_settings,
                        images: r_settings.rc3_images,
                    };
                });
                setRobotTypes(r_types);
            })
            .catch(error => requestError(error));
    }

    async function loadAll() {
        loadRobotTypes();
        loadApplications();
        loadZones();
        loadLocations();
        loadRobots();
    }

    async function authUser() {
        await api
            .post(`rc3/auth/user`)
            .then(response => {
                const { data } = response;

                setRC3UserSettings({
                    region: data.region,
                    endpoint: data.endpoint,
                    client_id: data.client_id,
                    auth: data.auth,
                    topics: data.topics,
                });
            })
            .catch(error => {
                blockUser();
                requestError(error);
            });
    }

    function sendAction(action = {}) {
        if (userStatus !== 'connected') return false;

        const publishTopics =
            RC3UserSettings &&
            RC3UserSettings.topics &&
            RC3UserSettings.topics.publish
                ? RC3UserSettings.topics.publish
                : [];

        const { action: action_type, robot: r_action, body } = action;

        if (!r_action) {
            return false;
        }

        const { id: r_id, group: r_group } = r_action;

        if (!r_group) {
            return false;
        }

        const { id: g_id } = r_group;

        const actionTopic = publishTopics.find(t => {
            return (
                t.indexOf(`/${g_id}/`) !== -1 && t.indexOf(`/actions`) !== -1
            );
        });

        if (!actionTopic) {
            return false;
        }

        const robotTopic = actionTopic.replace(`/+/`, `/${r_id}/`);

        if (!robotTopic) {
            return false;
        }

        const action_body = {
            message_type: 'action',
            action_type,
            data: body || {},
            sender: 'user',
            ...(user && {
                user: {
                    id: user.id,
                    name: user.name,
                },
            }),
        };

        if (
            !RC3Client ||
            !RC3Client.connected ||
            !RC3Client.publish ||
            RC3Client.disconnecting ||
            RC3Client.reconnecting
        ) {
            return false;
        }

        RC3Client.publish(robotTopic, JSON.stringify(action_body));
        return true;
    }

    function sendNotification(notification = {}) {
        const publishTopics =
            RC3UserSettings.topics && RC3UserSettings.topics.publish
                ? RC3UserSettings.topics.publish
                : [];

        const {
            type: notification_type,
            robot: r_notification,
            data: n_data,
        } = notification;

        if (!r_notification) {
            return false;
        }

        const { id: r_id, group: r_group } = r_notification;

        if (!r_group) {
            return false;
        }

        const { id: g_id } = r_group;

        const topic = publishTopics.find(t => {
            return (
                t.indexOf(`/${g_id}/`) !== -1 &&
                t.indexOf(`/notifications`) !== -1
            );
        });

        if (!topic) {
            return false;
        }

        const notificationTopic = topic.replace(`/+/`, `/${r_id}/`);
        const notification_body = {
            robot_id: r_id,
            sender: 'user',
            type: notification_type,
            data: n_data,
        };

        if (!RC3Client || !RC3Client.publish) return false;
        return RC3Client.publish(
            notificationTopic,
            JSON.stringify(notification_body)
        );
    }

    function sendAll(type, main_body) {
        groups.forEach(g => {
            const broadcast_body = {
                robot: {
                    id: '*',
                    group: {
                        id: g.id,
                    },
                },
            };

            const message_body = {
                ...broadcast_body,
                ...main_body,
            };

            switch (type) {
                case 'action':
                    sendAction(message_body);
                    break;
                case 'notification':
                    sendNotification(message_body);
                    break;
                default:
                    break;
            }
        });
    }

    function connectRC3() {
        const { region, endpoint, client_id, auth, topics } = RC3UserSettings;
        try {
            AWS.config.region = region;
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityId: auth.identity_id,
                Logins: auth.logins,
            });
            const ts = Date.now();
            const client = `${client_id}_${ts}`;

            const u_client = new AWSMqttClient({
                keepalive: 15,
                region: AWS.config.region,
                credentials: AWS.config.credentials,
                endpoint,
                expires: 600,
                clientId: client,
            });

            u_client.on('connect', () => {
                const sub = topics.subscribe || [];
                sub.forEach(t => {
                    u_client.subscribe(t);
                });
                setUserStatus('connected');
            });

            setRC3Client(u_client);
        } catch (err) {
            setRC3Client(null);
        }
    }

    function handleEventsUpdate(message) {
        setLastEvent(message);
        const { robot_id, event_type } = message;

        if (robot_id) {
            if (
                event_type === 'disconnected' ||
                event_type === 'disconnected-LWT'
            ) {
                setRobotStatus({
                    ...robotStatus,
                    [robot_id]: {
                        status: 'disconnected',
                    },
                });
            }
        }
    }

    function handleStatusUpdate(message, updated) {
        const { robot_id } = message;
        const r_state =
            message.operation && message.operation.action
                ? 'occupied'
                : message.status || 'disconnected';

        setRobotStatus({
            ...robotStatus,
            [robot_id]: {
                ...message,
                status: r_state,
                updated,
            },
        });
    }

    function handleAlertMessage(message) {
        const data = message.data || {};
        const { destination } = data;

        if (destination) {
            const list = destination.list || '*';
            if (list === '*') {
                return setLastAlert(message);
            }
            if (user && Array.isArray(list) && list.includes(user.id)) {
                return setLastAlert(message);
            }
        }
        return false;
    }

    function handleNotificationMessage(message) {
        setLastNotification(message);
        const { sender, robot_id, type } = message;

        if (sender === 'robot') {
            if (robot_id) {
                switch (type) {
                    case 'update_info':
                        return updateRobotInfo(robot_id);
                    default:
                        return false;
                }
            }
        }
        switch (type) {
            case 'update_all':
                return loadAll();
            case 'update_certificates':
                setUserStatus('disconnected');
                authUser();
                loadAll();
                return false;
            default:
                return false;
        }
    }

    function handleRC3Message() {
        const { topic, message, updated } = lastMessage;
        if (message) {
            if (topic) {
                if (topic.includes('/status')) {
                    return handleStatusUpdate(message, updated);
                }
                if (topic.includes('/events')) {
                    return handleEventsUpdate(message);
                }
                if (topic.includes('/notifications')) {
                    return handleNotificationMessage(message);
                }
                if (topic.includes('/alerts')) {
                    return handleAlertMessage(message);
                }
            }
        }
        return null;
    }

    function handleRC3Client(client) {
        if (client) {
            if (current_app !== 'rc3') {
                client.end();
                setRC3Client(null);
                setUserStatus('disconnected');
                return;
            }

            client.on('close', () => {
                client.end();
                setRC3Client(null);
                setUserStatus('disconnected');
            });

            client.on('offline', () => {
                setUserStatus('disconnected');
            });

            client.on('message', (topic_ui8, message_ui8) => {
                try {
                    const topic = JSON.stringify(topic_ui8);
                    const message = JSON.parse(JSON.stringify(message_ui8));
                    const buffer = Buffer.from(message.data).toString('utf8');
                    const message_data = JSON.parse(buffer);

                    setLastMessage({
                        topic,
                        message: message_data,
                        updated: new Date(),
                    });
                } catch (err) {
                    console.error(err);
                }
            });
        }
    }

    useEffect(() => {
        authUser();
        loadAll();
        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        handleRC3Client(RC3Client);
    }, [RC3Client, current_app]);

    useEffect(() => {
        handleRC3Message();
    }, [lastMessage]);

    useEffect(() => {
        if (RC3UserSettings && userStatus !== 'connected') {
            connectRC3();
        }
        updateAppSettings('status', userStatus);
    }, [RC3UserSettings, userStatus]);

    useEffect(() => {
        if (RC3Client && RC3Client.connected && userStatus === 'connected') {
            sendAll('notification', {
                type: 'request_info',
            });
        }
    }, [RC3Client, userStatus]);

    useEffect(() => {
        filterRobotLocations();
    }, [robots, locations, robotStatus]);

    function renderDashboard() {
        return (
            <Dashboard
                smallScreen={smallScreen}
                userStatus={userStatus}
                settings={settings}
                robots={robots}
                robotStatus={robotStatus}
                robotLocations={robotLocations}
                handleChangeTab={(event, t) => handleChangeTab(event, t)}
                requestError={error => requestError(error)}
            />
        );
    }

    function renderLocations() {
        return (
            <Locations
                smallScreen={smallScreen}
                locations={locations}
                robotLocations={robotLocations}
                handleChangeTab={(event, t) => handleChangeTab(event, t)}
            />
        );
    }

    function renderRobotPage() {
        return (
            <RobotPage
                user={{
                    id: user.id,
                    name: user.name,
                }}
                settings={settings}
                robots={robots}
                robotTypes={robotTypes}
                robotStatus={robotStatus}
                applications={applications}
                groups={groups}
                locations={locations}
                zones={zones}
                rc3_client={RC3Client}
                lastMessage={lastEvent}
                lastNotification={lastNotification}
                smallScreen={smallScreen}
                requestError={error => requestError(error)}
                sendAction={action => sendAction(action)}
                sendNotification={n => sendNotification(n)}
            />
        );
    }

    function renderDisinfectionsPage() {
        return (
            <Disinfections
                settings={settings}
                robots={robots}
                robotStatus={robotStatus}
                applications={applications}
                locations={locations}
                zones={zones}
                rc3_client={RC3Client}
                lastMessage={lastEvent}
                requestError={error => requestError(error)}
            />
        );
    }

    function renderTelepresencePage() {
        return (
            <Disinfections
                settings={settings}
                robots={robots}
                robotStatus={robotStatus}
                applications={applications}
                locations={locations}
                zones={zones}
                rc3_client={RC3Client}
                lastMessage={lastEvent}
                requestError={error => requestError(error)}
            />
        );
    }

    const pages = {
        dashboard: renderDashboard,
        map: renderLocations,
        robots: renderRobotPage,
        disinfections: renderDisinfectionsPage,
        telepresence: renderTelepresencePage,
    };

    function renderCentral() {
        const page = pages[tab];

        return page ? (
            page()
        ) : (
            <div
                style={{
                    backgroundColor: '#434f5a',
                    width: '100%',
                    heigh: '100%',
                }}
            />
        );
    }

    return (
        <AppContainer>
            <Alerts
                robots={robots}
                robotTypes={robotTypes}
                lastAlert={lastAlert}
                lastNotification={lastNotification}
                sendNotification={n => sendNotification(n)}
                requestError={error => requestError(error)}
                changePage={p => changePage(p)}
            />
            {renderCentral()}
        </AppContainer>
    );
}

RC3.propTypes = {
    smallScreen: PropTypes.bool,
    tab: PropTypes.string,
    blockUser: PropTypes.func,
    handleChangeTab: PropTypes.func,
};

RC3.defaultProps = {
    smallScreen: false,
    tab: '',
    blockUser: () => {},
    handleChangeTab: () => {},
};
