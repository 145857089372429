export default {
    plugin_availability_list: [
        {
            value: 'robot_steps',
            label: 'Robot Steps',
            label_code: 'label.settings.plugin.robot_steps',
        },
        {
            value: 'conversation_apps',
            label: 'Conversation Apps',
            label_code: 'label.settings.plugin.conversation_apps',
        },
        {
            value: 'invite_manager',
            label: 'Invite Manager Apps',
            label_code: 'label.settings.plugin.invite_manager',
        },
    ],
    survey_evaluation_list: [
        {
            value: 'has_languages',
            label_code: 'page.survey_steps.list.conditions.has_languages',
        },
        {
            value: 'start_step',
            label_code: 'page.survey_steps.list.conditions.start_step',
        },
        {
            value: 'end_steps',
            label_code: 'page.survey_steps.list.conditions.end_steps',
        },
        {
            value: 'exit_conditions',
            label_code: 'page.survey_steps.list.conditions.exit_conditions',
        },
    ],
    rc3_status_list: {
        disconnected: {
            value: 'status-disconnected',
            label_code: 'rc3.robot.status.disconnected',
            color: '#999',
        },
        connected: {
            value: 'status-connected',
            label_code: 'rc3.robot.status.connected',
            color: '#0f0',
        },
        occupied: {
            value: 'status-occupied',
            label_code: 'rc3.robot.status.occupied',
            color: '#ff0',
        },
        alert: {
            value: 'status-alert',
            label_code: 'rc3.robot.status.alert',
            color: '#f00',
        },
    },
    rc3_wifi_sign: {
        0: {
            value: 'signal-bad',
            label_code: 'rc3.robot.status.wifi.signal.bad',
        },
        1: {
            value: 'signal-regular',
            label_code: 'rc3.robot.status.wifi.signal.regular',
        },
        2: {
            value: 'signal-good',
            label_code: 'rc3.robot.status.wifi.signal.good',
        },
        3: {
            value: 'signal-excelent',
            label_code: 'rc3.robot.status.wifi.signal.excelent',
        },
    },
    transfer_status: [
        {
            value: 'open',
            label_code: 'list.transfers.status.open',
        },
        {
            value: 'waiting',
            label_code: 'list.transfers.status.waiting',
        },
        {
            value: 'running',
            label_code: 'list.transfers.status.running',
        },
        {
            value: 'finished',
            label_code: 'list.transfers.status.finished',
        },
        {
            value: 'canceled',
            label_code: 'list.transfers.status.canceled',
        },
        {
            value: 'claimed',
            label_code: 'list.transfers.status.claimed',
        },
    ],
    disinfection_modes: [
        {
            value: 'uvc',
            label_code: 'list.operations.mode.uvc',
        },
        {
            value: 'o3',
            label_code: 'list.operations.mode.o3',
        },
        {
            value: 'uvc_o3',
            label_code: 'list.operations.mode.uvc_o3',
        },
        {
            value: 'air',
            label_code: 'list.operations.mode.air',
        },
        {
            value: 'floor',
            label_code: 'list.operations.mode.floor',
        },
    ],
    interaction_keys: {
        conversation: 'list.operations.type.conversation',
        presentation: 'list.operations.type.presentation_mode',
        script: 'list.operations.type.script_mode',
        disinfection: 'list.operations.type.disinfection',
        telepresence: 'list.operations.type.telepresence',
        indoor_delivery: 'list.operations.type.indoor_delivery',
        mission: 'list.operations.type.mission_mode',
        // internal_delivery: 'list.operations.type.delivery',
    },
    default_colors: [
        '#D0021B',
        '#F5A623',
        '#F8E71C',
        '#8B572A',
        '#7ED321',
        '#417505',
        '#BD10E0',
        '#9013FE',
        '#4A90E2',
        '#50E3C2',
        '#B8E986',
        '#000000',
        '#4A4A4A',
        '#9B9B9B',
    ],
    script_actions: {
        no_action: {
            value: 'no_action',
            label_code:
                'page.tools.scripts.step.form.action.type.no_action.title',
        },
        speak: {
            value: 'speak',
            label_code: 'page.tools.scripts.step.form.action.type.speak.title',
        },
        play_audio: {
            value: 'play_audio',
            label_code:
                'page.tools.scripts.step.form.action.type.play_audio.title',
        },
        navigate: {
            value: 'navigate',
            label_code:
                'page.tools.scripts.step.form.action.type.navigate.title',
        },
    },
    qna_languages: [
        {
            key: 'en',
            value: 'en_GB',
            label: 'list.languages.en',
        },
        {
            key: 'pt',
            value: 'pt_BR',
            label: 'list.languages.pt',
        },
    ],
};
