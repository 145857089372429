function descVal(a, b) {
    if (b < a) {
        return -1;
    }
    if (b > a) {
        return 1;
    }
    return 0;
}

function descObj(a, b, _orderBy) {
    return descVal(a[_orderBy], b[_orderBy]);
}

function getObjSorting(_order, _orderBy) {
    return _order === 'desc'
        ? (a, b) => descObj(a, b, _orderBy)
        : (a, b) => -descObj(a, b, _orderBy);
}

function getValueSorting(_order, _orderBy) {
    return _order === 'desc'
        ? (a, b) => descObj(a, b, _orderBy)
        : (a, b) => -descObj(a, b, _orderBy);
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const _order = cmp(a[0], b[0]);
        if (_order !== 0) return _order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

export default function getOrderedList(list, isObj, property, order) {
    if (!property) return list;
    if (!isObj) return list;
    return isObj
        ? stableSort(list, getObjSorting(order, property))
        : stableSort(list, getValueSorting(order));
}
