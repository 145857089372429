import styled from 'styled-components';

export const Container = styled.div`
    .card {
        height: 100%;
        color: #999;

        a {
            height: 100%;
            .card-body {
                border: 0.5vh solid
                    ${props => {
                        const color = props.theme.palette.primary.main;
                        return color;
                    }};
                height: 100%;
                display: flex;
                flex-direction: column;
                background: ${props => {
                    return props.theme.dark_mode ? '#424242' : '#fff';
                }};
                color: ${props => {
                    const color = props.theme.palette.primary.main;
                    return props.theme.dark_mode ? '#fff' : color;
                }};
            }
        }

        .group-warning {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            color: #fff;
            background: ${props => {
                const color = props.theme.palette.primary.main;
                return color;
            }};
        }

        .disabled {
            pointer-events: none;
            .card,
            .card-body {
                border: none;
                color: #999;
                background: ${props => {
                    return props.theme.dark_mode ? '#424242' : '#ddd';
                }};
            }
        }
    }
`;
