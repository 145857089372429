/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import Forbidden from '~/components/Forbidden';

import generateTheme from '~/styles/themes/customTheme';
import defaultTheme from '~/styles/themes/defaultTheme';

import useWindowDimensions from '~/hooks/useWindowDimensions';
import getMaterialLocale from '~/util/GetMaterialLocale';

import AuthLayout from '../layouts/auth';
import DefaultLayout from '../layouts/default';
import FullscreenLayout from '../layouts/fullscreen';
import LocalLayout from '../layouts/local';
import RC3Layout from '../layouts/rc3';
import SUViewLayout from '../layouts/suview';
import checkPermission from '../util/CheckPermission';

const MIN_WIDTH = 600;
const MIN_HEIGHT = 800;

export default function RouteWrapper({
    component: Component,
    isPrivate,
    hasGroup,
    layout,
    adminOnly,
    dark,
    ...rest
}) {
    const pluginspaceSettings = useSelector(state => state.settings);
    const userSettings = useSelector(state => state.user);
    const authSettings = useSelector(state => state.auth);
    const appSettings = useSelector(state => state.application);
    const windowSize = useWindowDimensions();

    const { dark_mode } = pluginspaceSettings;
    const date_loc = getMaterialLocale(pluginspaceSettings);

    const [isSmallScreen, setIsSmallScreen] = useState(
        isMobile &&
            (windowSize.width < MIN_WIDTH || windowSize.height < MIN_HEIGHT)
    );

    function checkScreen() {
        setIsSmallScreen(
            isMobile &&
                (windowSize.width < MIN_WIDTH || windowSize.height < MIN_HEIGHT)
        );
    }

    useEffect(() => {
        checkScreen();
    }, []);

    const { pluginspace, theme, active } = pluginspaceSettings;
    const { app_home } = appSettings;

    const { signed, expired } = authSettings;

    if (!signed && isPrivate) {
        return <Redirect to="/login" />;
    }

    if (layout === 'default') {
        if (signed && !isPrivate) {
            return <Redirect to={app_home} />;
        }
    }

    const forbidden =
        adminOnly && pluginspace && pluginspace.subdomain !== 'admin';

    const { profile } = userSettings;

    let selectGroup = false;
    const permitted = checkPermission(profile);

    if (!permitted && hasGroup) {
        if (!active) {
            selectGroup = true;
        } else if (!active.id) {
            selectGroup = true;
        }
    }

    let Layout = AuthLayout;

    switch (layout) {
        case 'suview':
            Layout = SUViewLayout;
            break;
        case 'local':
            Layout = LocalLayout;
            break;
        case 'rc3':
            Layout = RC3Layout;
            break;
        case 'fullscreen':
            Layout = FullscreenLayout;
            break;
        case 'default':
        default:
            Layout = signed ? DefaultLayout : AuthLayout;
            break;
    }

    const uiTheme =
        theme && theme.primary ? generateTheme(theme, dark_mode) : defaultTheme;

    const applyTheme = createTheme(uiTheme, date_loc);

    document.body.classList.remove('rtl');

    return (
        <ThemeProvider theme={uiTheme}>
            <MuiThemeProvider theme={applyTheme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Route
                        {...rest}
                        render={props => (
                            <Layout
                                selectGroup={selectGroup}
                                {...props}
                                expired={expired}
                                dark={dark}
                                screen={{
                                    isSmallScreen,
                                    isMobile,
                                    windowSize,
                                    minWidth: MIN_WIDTH,
                                    minHeight: MIN_HEIGHT,
                                }}
                            >
                                {forbidden ? (
                                    <Forbidden />
                                ) : (
                                    <Component {...props} />
                                )}
                            </Layout>
                        )}
                    />
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </ThemeProvider>
    );
}

RouteWrapper.propTypes = {
    adminOnly: PropTypes.bool,
    isPrivate: PropTypes.bool,
    hasGroup: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
    layout: PropTypes.string,
    dark: PropTypes.bool,
};

RouteWrapper.defaultProps = {
    isPrivate: false,
    hasGroup: false,
    layout: 'default',
    adminOnly: false,
    dark: false,
};
