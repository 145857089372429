/* eslint-disable consistent-return */
import produce from 'immer';

const INITIAL_STATE = {
    loading: true,
    pluginspace: {
        id: null,
        subdomain: null,
    },
    dark_mode: false,
    theme: {},
    logo: {},
    admin_groups: [],
    app_list: [],
    active: { id: null, name: '' },
    locale: {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us',
        code: 'en_US',
        format: 'en-US',
    },
};

export default function settings(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case '@settings/SWITCH_THEME': {
                draft.dark_mode = action.payload.dark_mode;
                break;
            }
            case '@settings/SWITCH_LANGUAGE': {
                draft.locale = action.payload.locale;
                break;
            }
            case '@settings/CHANGE_PLUGINSPACE_REQUEST': {
                draft.pluginspace = INITIAL_STATE.pluginspace;
                draft.theme = INITIAL_STATE.theme;
                draft.logo = INITIAL_STATE.logo;
                draft.admin_groups = INITIAL_STATE.admin_groups;
                draft.active = INITIAL_STATE.active;
                draft.loading = true;
                break;
            }
            case '@settings/CHANGE_PLUGINSPACE_SUCCESS': {
                draft.pluginspace = action.payload.pluginspace;
                draft.theme = action.payload.theme;
                draft.logo = action.payload.logo;
                draft.loading = false;
                draft.admin_groups = INITIAL_STATE.admin_groups;
                draft.app_list = INITIAL_STATE.app_list;
                draft.active = INITIAL_STATE.active;
                draft.locale = action.payload.locale;
                break;
            }
            case '@settings/CHANGE_PLUGINSPACE_FAILURE': {
                draft.pluginspace = {
                    id: null,
                    subdomain: 'new',
                };
                draft.admin_groups = INITIAL_STATE.admin_groups;
                draft.active = INITIAL_STATE.active;
                draft.logo = INITIAL_STATE.logo;
                draft.app_list = INITIAL_STATE.app_list;
                draft.loading = false;
                break;
            }
            case '@settings/LOAD_PLUGINSPACE_SETTINGS_REQUEST': {
                draft.loading = true;
                break;
            }
            case '@settings/LOAD_PLUGINSPACE_SETTINGS_SUCCESS': {
                draft.theme = action.payload.theme;
                draft.logo = action.payload.logo;
                draft.app_list = action.payload.apps;
                draft.loading = false;
                break;
            }
            case '@settings/LOAD_PLUGINSPACE_SETTINGS_FAILURE': {
                draft.loading = false;
                break;
            }
            case '@auth/SIGN_IN_REQUEST': {
                draft.loading = true;
                break;
            }
            case '@auth/SIGN_IN_SUCCESS': {
                draft.loading = false;
                break;
            }
            case '@auth/SIGN_FAILURE': {
                draft.loading = false;
                break;
            }
            case '@auth/SIGN_OUT': {
                draft = INITIAL_STATE;
                break;
            }
            case '@settings/CHANGE_GROUP_REQUEST': {
                draft.loading = true;
                break;
            }
            case '@settings/CHANGE_GROUP_SUCCESS': {
                draft.loading = false;
                draft.active = action.payload.active_group;
                break;
            }
            case '@settings/CHANGE_GROUP_FAILURE': {
                draft.loading = false;
                break;
            }
            case '@user/LOAD_GROUPS_REQUEST': {
                draft.loading = true;
                break;
            }
            case '@user/LOAD_GROUPS_SUCCESS': {
                draft.loading = false;
                draft.active = action.payload.active;
                draft.admin_groups = action.payload.admin;
                break;
            }
            case '@user/LOAD_GROUPS_FAILURE': {
                draft.loading = false;
                break;
            }
            case '@application/CHANGE_APP_REQUEST': {
                // draft.loading = true;
                break;
            }
            case '@application/CHANGE_APP_SUCCESS': {
                draft.loading = false;
                break;
            }
            case '@application/CHANGE_APP_FAILURE': {
                draft.loading = false;
                break;
            }
            default:
                return state;
        }
    });
}
