/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import { DialogContentText, Menu, MenuItem } from '@material-ui/core';

import CardItem from '~/components/CardItem';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

export default function VisualTemplates() {
    const dispatch = useDispatch();

    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);

    const { active } = settings;

    const [isLoading, setIsLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currDialog, setCurrDialog] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const open = Boolean(anchorEl);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`templates`)
            .then(response => {
                const data = response.data.map(t => ({
                    ...t,
                    updated: formatDistance(new Date(t.updated), new Date(), {
                        addSuffix: true,
                        locale: date_loc,
                    }),
                }));

                setTemplates(data);

                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadItems();
    }, [active, date_loc]);

    function handleClick(event, _id) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setCurrDialog(_id);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function goToSettings(event, _id) {
        event.preventDefault();
        setAnchorEl(null);
        history.push(`visual_templates/${_id}`);
    }

    function handleClickOpen(event, _id) {
        setCurrDialog(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDialogOpen(true);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    async function deleteDialog() {
        setIsLoading(true);
        setDialogOpen(false);
        await api
            .delete(`templates/${currDialog}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.visual_template.list.delete_success" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function templCard(templ) {
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={templ.id}>
                <Link to={`/visual_templates/${templ.id}`}>
                    <CardItem
                        title={templ.name}
                        optionsClick={event => handleClick(event, templ.id)}
                        updated={templ.updated}
                        colorBackground
                        img={{
                            url:
                                templ.file && templ.file.url
                                    ? templ.file.url
                                    : null,
                            name: templ.name,
                        }}
                    >
                        <>
                            <div className="card-subtitle">
                                <small>
                                    <LocaleMessage
                                        msg={`page.visual_template.type.${templ.type}`}
                                    />
                                </small>
                                <br />
                                <small>{templ.slug}</small>
                            </div>
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                <div
                    className="row"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    {templates.map(templ => templCard(templ))}
                </div>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={e => goToSettings(e, currDialog)}>
                        <LocaleMessage msg="button.settings" />
                    </MenuItem>
                    <MenuItem onClick={e => handleClickOpen(e, currDialog)}>
                        <LocaleMessage msg="button.delete" />
                    </MenuItem>
                </Menu>
            </>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.visual_template.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteDialog(currDialog),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.visual_template.list.title" />}
            currentPage={
                <LocaleMessage msg="page.visual_template.list.title" />
            }
            breadcrumbs={[]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                <div className="body-top-controls">
                    <>
                        <NewItemButton
                            link="/visual_templates/new"
                            text={
                                <LocaleMessage msg="page.visual_template.list.add" />
                            }
                        />
                    </>
                </div>
                {buildGridView()}
            </>
        </PageContent>
    );
}
