import styled from 'styled-components';

export const Item = styled.li`
    padding: 10px 10px !important;

    & {
        border-bottom: 1px solid #dee2e6;
    }

    :hover {
        background: rgba(0, 0, 0, 0.05);
        cursor: pointer;
    }
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px !important;
    font-weight: bold;
    color: ${props => {
        return props.theme.dark_mode ? '#fff' : '#252525';
    }} !important;

    .badge {
        position: relative !important;
        top: 0px !important;
        left: 0px !important;
        margin-bottom: 0px !important;
        width: 40px !important;
    }
`;

export const Selected = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Current = styled.span`
    padding: 10px 20px;
    minwidth: 200px;
    maxwidth: 250px;
`;

export const List = styled.div`
    h4 {
        color: ${props => {
            return props.theme.dark_mode ? '#fff' : '#252525';
        }} !important;
    }
`;
