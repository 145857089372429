/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdFileDownload } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { subDays, subMonths } from 'date-fns';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import ReportHeader from '~/components/Reports/ReportHeader';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';
import GetFileName from '~/util/GetFileName';

export default function Logs(props) {
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);
    const date_format = settings.locale;
    const date_opt = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    const time_opt = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hourCycle: 'h23',
    };

    const { dialogSettings, handleError } = props;
    const { id, name, provider } = dialogSettings;

    const [messageList, setMessageList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const minDate = new Date(subMonths(new Date(), 1));
    const [fromDate, setFromDate] = React.useState(
        new Date(subDays(new Date(), 7))
    );
    const [untilDate, setUntilDate] = React.useState(new Date());

    async function loadData() {
        const f_date = new Date(fromDate).toLocaleDateString('en-US', date_opt);
        const u_date = new Date(untilDate).toLocaleDateString(
            'en-US',
            date_opt
        );

        setIsLoading(true);
        await api
            .get(`dialogs/${id}/chatlog?from=${f_date}&until=${u_date}`)
            .then(response => {
                const { data } = response;

                const messages = data.messages.map(m => {
                    const ent = [];
                    const val = [];
                    let subjects = '';

                    m.subjects.forEach(s => {
                        subjects += `${s}, `;
                        const _s = s.split(':');
                        ent.push(_s[0]);
                        val.push(_s[1]);
                    });

                    const datetime = new Date(m.created);
                    const date = datetime.toLocaleDateString(
                        date_format.format,
                        date_opt
                    );
                    const time = datetime.toLocaleTimeString([], time_opt);
                    const channel = m.channel ? m.channel : 'robot';

                    return {
                        ...m,
                        channel_label: (
                            <LocaleMessage
                                msg={`page.reports.messages.label.channel.${channel}`}
                            />
                        ),
                        language: m.language.toUpperCase(),
                        robot: m.robot.code,
                        type: m.robot.type,
                        subjects,
                        entities: ent,
                        values: val,
                        source: m.source,
                        source_label: (
                            <LocaleMessage
                                msg={`list.dialogs.answer_sources.${m.source}`}
                            />
                        ),
                        created_date: date,
                        created_time: time,
                        created: `${date} - ${time}`,
                        created_timestamp: datetime.toISOString(),
                    };
                });
                setMessageList(messages);
                setIsLoading(false);
            })
            .catch(error => handleError(error));
    }

    useEffect(() => {
        loadData();
    }, [fromDate, untilDate, date_loc]);

    const handleFromDateChange = date => {
        setFromDate(date);
    };

    const handleUntilDateChange = date => {
        setUntilDate(date);
    };

    const headers = [
        {
            id: 'created',
            label: <LocaleMessage msg="table.headers.date" />,
            order_by: 'created_timestamp',
        },
        {
            id: 'channel_label',
            label: <LocaleMessage msg="table.headers.channel" />,
        },
        {
            id: 'language',
            label: <LocaleMessage msg="table.headers.language" />,
        },
        {
            id: 'message',
            label: <LocaleMessage msg="table.headers.message" />,
            width: '25%',
        },
        {
            id: 'answer',
            label: <LocaleMessage msg="table.headers.answer" />,
            width: '30%',
        },
        {
            id: 'intent',
            label: <LocaleMessage msg="table.headers.intent" />,
        },
        {
            id: 'subjects',
            label: <LocaleMessage msg="table.headers.subjects" />,
        },
        ...(provider === 'PluginbotQnA'
            ? [
                  {
                      id: 'source_label',
                      label: (
                          <LocaleMessage msg="page.dialogs.info.logs.answer_source" />
                      ),
                  },
              ]
            : []),
    ];

    const headersFile = [
        {
            key: 'created_date',
            label: 'Date',
        },
        {
            key: 'created_time',
            label: 'Time',
        },
        {
            key: 'channel',
            label: 'Channel',
        },
        {
            key: 'language',
            label: 'Language',
        },
        { key: 'robot', label: 'Robot' },
        { key: 'type', label: 'Robot Type' },
        {
            key: 'message',
            label: 'Message',
        },
        { key: 'answer', label: 'Answer' },
        { key: 'intent', label: 'Intent' },
        {
            key: 'subjects',
            label: 'Subjects',
        },
        {
            key: 'entities',
            label: 'Entities',
        },
        {
            key: 'values',
            label: 'Values',
        },
    ];

    function buildListView() {
        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headers}
                    data={messageList}
                    orderDirection="desc"
                    orderColumn="created_timestamp"
                    handleTableRowClick={() => {}}
                    hasActions={false}
                    sortable
                />
            </div>
        );
    }

    function CSVButton() {
        return (
            <CSVLink
                data={messageList}
                headers={headersFile}
                filename={GetFileName(`MESSAGES-${name}`, 'csv')}
                onClick={() => {
                    toast.info(<LocaleMessage msg="message.generating_file" />);
                }}
            >
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<MdFileDownload style={{ color: '#fff' }} />}
                >
                    <span style={{ color: '#fff' }}>
                        <LocaleMessage msg="page.dialogs.info.logs.label.export" />
                    </span>
                </Button>
            </CSVLink>
        );
    }

    return (
        <div className="col-12 mt-3 mb-3">
            <div
                className="sidecard-header"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                }}
            >
                <h2>
                    <LocaleMessage msg="page.dialogs.info.logs" />
                </h2>
                <p />
                <LocaleMessage msg="page.dialogs.info.logs.label.max_period.1_month" />
            </div>
            <ReportHeader
                date_props={{
                    locale: date_loc,
                }}
                period={{ minDate, fromDate, untilDate }}
                handleFromDateChange={handleFromDateChange}
                handleUntilDateChange={handleUntilDateChange}
                reloadData={() => loadData()}
                allowExport
                exportButton={CSVButton()}
            />
            <div className="sidecard-body">
                {isLoading ? <Splash /> : <>{buildListView()}</>}
            </div>
        </div>
    );
}

Logs.propTypes = {
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
