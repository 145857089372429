/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete, MdAdd } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CustomDialogTitle from '~/components/CustomDialogTitle';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

export default function SysAdmin() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [currItem, setCurrItem] = useState(null);

    const [editOpen, setEditOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [body, setBody] = useState({});

    const tableHeaders = [
        { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
        { id: 'email', label: <LocaleMessage msg="table.headers.email" /> },
        {
            id: 'permission',
            label: <LocaleMessage msg="table.headers.pluginspace_permission" />,
        },
    ];

    const permissions = [
        {
            label: <LocaleMessage msg="user.permission.master" />,
            value: 'master',
        },
        {
            label: <LocaleMessage msg="user.permission.super_admin" />,
            value: 'super_admin',
        },
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`users/sysadmin`)
            .then(response => {
                const users = response.data;

                const user_list = users.map(u => {
                    return {
                        ...u,
                        permission: u.permission ? (
                            <LocaleMessage
                                msg={`user.permission.${u.permission}`}
                            />
                        ) : (
                            '---'
                        ),
                    };
                });
                setItems(user_list);

                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    async function submitItem() {
        setIsLoading(true);
        setEditOpen(false);
        await api
            .post(`users/sysadmin`, body)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.sysadmin.list.add_success" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function deleteItem() {
        setIsLoading(true);
        setDeleteOpen(false);
        await api
            .delete(`users/sysadmin/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.sysadmin.list.delete_success" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadItems();
    }, []);

    function handleEditOpen() {
        setBody({
            permission: 'super_admin',
        });
        setEditOpen(true);
    }

    function handleRevokeOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDeleteOpen(true);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDeleteOpen(false);
        setEditOpen(false);
    }

    function buildListView() {
        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="buttons.deactivate" />,
                icon: <MdDelete />,
                action: handleRevokeOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={tableHeaders}
                    data={items}
                    orderColumn="name"
                    setcurrPluginspace={_id => setCurrItem(_id)}
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteOpen}
                onClose={handleDialogClose}
                title={<LocaleMessage msg="page.sysadmin.list.delete_title" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteItem(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildEditDialog() {
        return (
            <div>
                <Dialog
                    open={editOpen}
                    onClose={handleDialogClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <CustomDialogTitle
                        title={<LocaleMessage msg="page.sysadmin.list.add" />}
                    />
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-6 col-12 mb-5">
                                <TextField
                                    label={
                                        <LocaleMessage msg="page.sysadmin.list.add.email" />
                                    }
                                    fullWidth
                                    value={body.email || ''}
                                    onChange={event =>
                                        setBody({
                                            ...body,
                                            email: event.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div className="col-md-6 col-12 mb-5">
                                <InputLabel>
                                    <LocaleMessage msg="page.sysadmin.list.add.permission" />
                                </InputLabel>
                                <Select
                                    value={body.permission || ''}
                                    onChange={event =>
                                        setBody({
                                            ...body,
                                            permission: event.target.value,
                                        })
                                    }
                                    fullWidth
                                >
                                    {permissions.map(p => {
                                        return (
                                            <MenuItem
                                                value={p.value}
                                                key={p.value}
                                            >
                                                {p.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setEditOpen(false)}
                            color="primary"
                        >
                            <LocaleMessage msg="button.cancel" />
                        </Button>
                        <Button
                            onClick={() => submitItem()}
                            color="primary"
                            autoFocus
                        >
                            <LocaleMessage msg="button.edit" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.sysadmin.list.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {editOpen ? buildEditDialog() : null}
                {deleteOpen ? buildDeleteDialog() : null}
                <>
                    <div className="body-top-controls">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MdAdd />}
                            style={{
                                whiteSpace: 'nowrap',
                                padding: '5px 20px',
                            }}
                            onClick={() => handleEditOpen()}
                        >
                            <LocaleMessage msg="page.sysadmin.list.add" />
                        </Button>
                    </div>
                    {buildListView()}
                </>
            </>
        </PageContent>
    );
}
