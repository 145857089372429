/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdQueue, MdViewModule, MdCheck, MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';

import BigButton from '~/components/BigButton';
import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
// import FileInput from '~/components/FileInput';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import { ZoneTools } from './styles';
import ZoneContact from './ZoneContact';

const iconSize = 20;

export default function ZoneForm({
    location_id,
    zone_id,
    linkRoot,
    allowEdit,
    breadcrumbs,
}) {
    const { pluginspace } = useSelector(state => state.settings);
    const pluginspace_id = pluginspace.id;

    const dispatch = useDispatch();

    const [operation, setOperation] = useState('create');

    const [body, setBody] = useState({
        name: '',
        location_id,
    });
    // const [file, setFile] = useState({
    //     id: null,
    //     url: null,
    // });

    const [sections, setSections] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [availableTools, setAvailableTools] = useState({});
    const [contactList, setContactList] = useState({});

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadZoneSections(_id) {
        if (_id !== 'new') {
            await api
                .get(`/sections?zone=${_id}`)
                .then(response => {
                    const s_list = response.data;
                    setSections(s_list);
                })
                .catch(error => requestError(error));
        }
    }

    async function loadZone(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`/zones/${_id}`)
                .then(response => {
                    const l = response.data;
                    setBody({
                        name: l.name,
                        description: l.description,
                    });
                    // setFile(l.file);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadTools() {
        if (pluginspace_id) {
            await api
                .get(`pluginspaces/tools`)
                .then(response => {
                    const { data } = response;
                    const tools = {};
                    data.forEach(t => {
                        tools[t.slug] = true;
                    });
                    setAvailableTools(tools);
                    return true;
                })
                .catch(() => {});
        }
        return false;
    }

    useEffect(() => {
        loadTools();
    }, []);

    useEffect(() => {
        loadZone(zone_id);
        loadZoneSections(zone_id);
    }, [zone_id]);

    async function updateZone(data) {
        await api
            .put(`/zones/${zone_id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.locations.zone.form.update_success" />
                );
                setIsLoading(false);
                loadZone(zone_id);
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const data = { ...body };
        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(`/zones`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.locations.zone.form.create_success" />
                    );
                    history.push(`${linkRoot}/locations/${location_id}`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            updateZone(data);
        }
    }

    // const onFileUpload = async e => {
    //     // setIsLoading(true);
    //     // const data = new FormData();
    //     // data.append('file', e.target.files[0]);
    //     // await api
    //     //     .post(`zones/${zone_id}/media`, data)
    //     //     .then(response => {
    //     //         const updated = { ...body, file_id: response.data.id };
    //     //         setBody(updated);
    //     //         updateZone(updated);
    //     //     })
    //     //     .catch(error => requestError(error));
    // };

    // function fileInput() {
    //     return (
    //         <div
    //             className="col-12 mb-5"
    //             style={{ height: '200px', justifyContent: 'center' }}
    //         >
    //             <FileInput
    //                 defaultValue={file}
    //                 onFileUpload={onFileUpload}
    //                 multiple={false}
    //                 disabled={!allowEdit}
    //                 text={
    //                     <LocaleMessage msg="label.form.change_image" />
    //                 }
    //             />
    //         </div>
    //     );
    // }

    function renderSectionList() {
        const zone_contact = contactList.list;
        const { contacts } = contactList;
        const show_contact = availableTools.contact_list && zone_contact;

        const section_contacts = {};
        if (contacts) {
            contacts.forEach(c => {
                if (c.section_id) {
                    section_contacts[c.section_id] = c.id;
                }
            });
        }

        const sections_data = sections.map(s => {
            return {
                id: s.id,
                name: s.name,
                hasContact: section_contacts[s.id] ? (
                    <MdCheck size={iconSize} />
                ) : (
                    <MdClose size={iconSize} />
                ),
            };
        });

        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            ...(show_contact
                ? [
                      {
                          id: 'hasContact',
                          label: (
                              <LocaleMessage msg="table.headers.has_contact" />
                          ),
                      },
                  ]
                : []),
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={sections_data}
                    orderColumn="name"
                    handleTableRowClick={() => {}}
                    rowActions={[]}
                />
            </div>
        );
    }

    function renderZoneContact() {
        return (
            <>
                <h4 className="mb-3">
                    <LocaleMessage msg="titles.tools.contact_lists" />
                </h4>
                <ZoneContact
                    allowEdit={allowEdit}
                    zone={{ ...body, id: zone_id }}
                    sections={sections}
                    setContactList={c => setContactList(c)}
                    requestError={e => requestError(e)}
                />
            </>
        );
    }

    function renderZoneTools() {
        const tools = [];
        if (availableTools.contact_list) {
            tools.push({ key: 'contact_list', element: renderZoneContact });
        }

        return tools.length > 0 ? (
            <div className="col-12 mt-5">
                <ZoneTools>
                    {tools.map(t => {
                        return (
                            <div
                                className="mb-3"
                                style={{
                                    border: '1px solid #999',
                                    borderRadius: '3px',
                                    padding: '15px',
                                }}
                                key={t.key}
                            >
                                {t.element()}
                            </div>
                        );
                    })}
                </ZoneTools>
            </div>
        ) : null;
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.locations.zone.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.locations.zone.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                ...breadcrumbs,
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                <div className="row full-body">
                    <div
                        className={`${
                            operation === 'update' ? 'col-md-6' : 'col-md-8'
                        } col-12 mb-5`}
                    >
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.locations.zone.form.title" />
                            }
                            Icon={MdQueue}
                            className="mb-5"
                        >
                            <>
                                <div
                                    className="row"
                                    style={{ alignItems: 'center' }}
                                >
                                    <div className="col-12">
                                        <TextField
                                            className="mb-5"
                                            label={
                                                <LocaleMessage msg="page.locations.zone.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-12">
                                        <TextField
                                            className="mb-5"
                                            label={
                                                <LocaleMessage msg="page.locations.zone.form.label.description" />
                                            }
                                            fullWidth
                                            value={body.description || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    description:
                                                        event.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    {/* {operation === 'update' ? fileInput() : null} */}
                                    <div className="col-12">
                                        <BigButton
                                            disabled={!allowEdit}
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            title={
                                                <LocaleMessage msg="button.save" />
                                            }
                                        />
                                    </div>

                                    {operation === 'update'
                                        ? renderZoneTools()
                                        : null}
                                </div>
                            </>
                        </CardSideBordered>
                    </div>
                    {operation === 'update' ? (
                        <>
                            <div className="col-md-6 col-12 mb-5">
                                <CardSideBordered
                                    title={
                                        <LocaleMessage msg="page.locations.zone.form.sections.list" />
                                    }
                                    Icon={MdViewModule}
                                    hide
                                >
                                    <>{renderSectionList()}</>
                                </CardSideBordered>
                            </div>
                        </>
                    ) : null}
                </div>
            </>
        </PageContent>
    );
}

ZoneForm.defaultProps = {
    breadcrumbs: [],
    allowEdit: false,
};

ZoneForm.propTypes = {
    zone_id: PropTypes.string.isRequired,
    location_id: PropTypes.string.isRequired,
    linkRoot: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array,
    allowEdit: PropTypes.bool,
};
