/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { InfoCard } from '../../../RobotPage/RobotData/styles';

const h100 = { height: '100%' };

export default function ActiveCard({ width, margin, options, data, isMobile }) {
    const { occupation } = options;

    const start_dt = data && data.start ? data.start : {};
    const time_obj = data && data.time ? data.time : {};
    const op_status = data && data.status ? data.status : '';

    return (
        <div className={`${width} ${margin}`} key="card_active">
            <Box style={isMobile ? {} : h100}>
                <InfoCard
                    direction={isMobile ? 'row' : 'column'}
                    className={isMobile ? 'mb-3' : ''}
                >
                    <div className={`desc-full ${isMobile ? 'col-6' : ''}`}>
                        <span className="jr-fs-md title">
                            {op_status === 'disconnected' ? (
                                <LocaleMessage msg="rc3.robot.data.operations.last_active" />
                            ) : (
                                <LocaleMessage msg="rc3.robot.data.operations.active_since" />
                            )}
                        </span>
                        <div className="info">
                            <span className="big-value">
                                {start_dt.time ? start_dt.time : '---'}
                            </span>
                            <span className="jr-fs-md title">
                                {start_dt.date ? start_dt.date : '---'}
                            </span>
                        </div>
                    </div>

                    <div className={`desc-full ${isMobile ? 'col-6' : ''}`}>
                        <span className="jr-fs-md title">
                            <LocaleMessage msg="rc3.dashboard.active_time" />
                        </span>
                        <div className="info">
                            <span className="big-value">
                                {time_obj.active && time_obj.active.text
                                    ? time_obj.active.text
                                    : '0:00:00'}
                            </span>
                        </div>
                    </div>

                    {occupation ? (
                        <div className="desc-full">
                            <span className="jr-fs-md title">
                                <LocaleMessage msg="rc3.dashboard.occupation_rate" />
                            </span>
                            <div className="info">
                                <span className="big-value">
                                    {op_status === 'connected' && time_obj
                                        ? time_obj.rate
                                        : '---'}
                                </span>
                            </div>
                        </div>
                    ) : null}
                </InfoCard>
            </Box>
        </div>
    );
}

ActiveCard.propTypes = {
    isMobile: PropTypes.bool,
    width: PropTypes.string,
    margin: PropTypes.string,
    options: PropTypes.object,
    data: PropTypes.object,
};

ActiveCard.defaultProps = {
    isMobile: false,
    width: 'col-12',
    margin: 'mb-3',
    options: { key: 'total', style: 'bar', size: 'col-12' },
    data: {},
};
