/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDoneAll } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Button, Typography } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
// import lng_labels from '~/util/LangMessages';

import { ParameterArea } from '../styles';

export default function QnAError({
    dialogSettings,
    qnaSettings,
    reloadQnASettings,
    handleError,
}) {
    const { id } = dialogSettings;

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {}, []);

    async function handleSubmit(value) {
        setIsLoading(true);
        await api
            .put(`dialogs/${id}/qna/setup/${value}`, {})
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
            })
            .catch(error => {
                handleError(error);
            });
        reloadQnASettings();
    }

    function renderSetting(setting) {
        return (
            <div
                key={`rule_${setting.name}`}
                className="mb-3"
                style={{
                    border: '1px solid #ddd',
                    borderRadius: '3px',
                    padding: '15px',
                }}
            >
                <Typography variant="body1" display="block" className="mb-3">
                    {setting.name || '---'}
                </Typography>

                <div
                    className="row text-center"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {setting.is_set ? (
                        <Typography
                            className="col-12"
                            variant="body1"
                            display="block"
                            gutterBottom
                        >
                            {setting.value || '---'}
                        </Typography>
                    ) : (
                        <div className="col-4">
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={setting.setupAction}
                                fullWidth
                            >
                                {/* <LocaleMessage msg="page.rc3_settings.events.destination.setup" /> */}
                                SET UP
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    function renderContent() {
        return (
            <div
                className="col-12 text-center"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="col-md-8 col-12">
                    <ParameterArea className="pt-4 pb-4 mb-3">
                        {renderSetting({
                            name: 'GENERATIVE AGENT',
                            is_set: !!qnaSettings.generative,
                            value: <MdDoneAll size={24} />,
                            setupAction: () => {
                                handleSubmit('generative_agent');
                            },
                        })}
                        {renderSetting({
                            name: 'TRAINING AGENT',
                            is_set: !!qnaSettings.training,
                            value: <MdDoneAll size={24} />,
                            setupAction: () => {
                                handleSubmit('training_agent');
                            },
                        })}
                        {qnaSettings.training ? (
                            <>
                                {renderSetting({
                                    name: 'CONTENTS',
                                    is_set: qnaSettings.contents !== 0,
                                    value: qnaSettings.contents,
                                    setupAction: () => {
                                        handleSubmit('contents');
                                    },
                                })}
                                {qnaSettings.contents !== 0
                                    ? renderSetting({
                                          name: 'NODES',
                                          is_set: qnaSettings.nodes !== 0,
                                          value: qnaSettings.nodes,
                                          setupAction: () => {
                                              handleSubmit('nodes');
                                          },
                                      })
                                    : null}
                            </>
                        ) : null}
                    </ParameterArea>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="sidecard-header">
                <h2>QnA ERRORS</h2>
            </div>

            <div className="sidecard-body">
                {isLoading ? <Splash /> : <>{renderContent()}</>}
            </div>
        </>
    );
}

QnAError.defaultProps = {
    // settings: {},
    qnaSettings: {},
};

QnAError.propTypes = {
    // settings: PropTypes.object,
    reloadQnASettings: PropTypes.func.isRequired,
    qnaSettings: PropTypes.object,
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
