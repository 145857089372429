import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    width: 100%;

    .card {
        height: 100%;
        width: 100%;

        background: ${props => {
            return props.theme.dark_mode ? '#424242' : '#fff';
        }};
    }

    label {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .label {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #000000dd;
            color: #fff;
        }

        span {
            margin-top: 10px;
        }

        input {
            display: none;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            cursor: pointer;
        }
    }

    .card-body {
        background: rgba(0, 0, 0, 0.03);
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #999;
        cursor: pointer;

        :hover {
            opacity: 0.7;
        }
    }

    :hover {
        cursor: pointer;
    }
`;
