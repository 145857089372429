import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h2,
    h6 {
        font-weight: bold;
        color: ${props => {
            return props.theme.dark_mode ? '#fff' : '#252525';
        }};
    }

    .not-full {
        display: flex;
        justify-content: space-around;
    }
`;
