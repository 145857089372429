/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';
import ParseDashboardData from '~/util/ParseDashboardData';

import DataCard from '../DataCard';

export default function AutonomousActionsCard({
    dataValues,
    requestError,
    dateSettings,
    userSettings,
    getFilterInfo,
}) {
    const { active, colors } = userSettings;

    const availableFilters = [
        { label: 'page.dashboard.applications', value: 'application_id' },
        { label: 'page.dashboard.robots', value: 'robot_id' },
    ];
    if (active && active.id === '*') {
        availableFilters.unshift({
            label: 'page.dashboard.groups',
            value: 'group_id',
        });
    }

    const [isLoading, setIsLoading] = useState(true);

    const [rawData, setRawData] = useState({ list: [] });
    const [parsedData, setParsedData] = useState({});
    const [groupedData, setGroupedData] = useState([]);

    const [activeFilter, setActiveFilter] = useState(availableFilters[0].value);

    async function loadData() {
        setIsLoading(true);
        await api
            .get(`dashboards/autonomous_actions/period`)
            .then(response => {
                const { data } = response;
                setRawData(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function filterData(filter) {
        const list = rawData && rawData.list ? rawData.list : [];

        let infoData = {};
        switch (filter) {
            case 'group_id':
                infoData = getFilterInfo(dataValues.groups);
                break;
            case 'robot_id':
                infoData = getFilterInfo(dataValues.robots);
                break;
            case 'application_id':
                infoData = getFilterInfo(dataValues.applications);
                break;
            default:
                break;
        }

        const f_data = {};

        list.forEach(m => {
            const idx = m[filter];
            f_data[idx] = f_data[idx] ? f_data[idx] + 1 : 1;
        });

        if (infoData) {
            const grouped = Object.keys(f_data).map(f => {
                const val = f_data[f];
                const name = infoData[f] || '---';
                return {
                    name,
                    value: val,
                };
            });

            setGroupedData(grouped);
        }
    }

    function handleDateFilterChange(value) {
        setActiveFilter(value);
    }

    useEffect(() => {
        filterData(activeFilter);
    }, [dataValues, activeFilter, rawData]);

    useEffect(() => {
        setParsedData(ParseDashboardData(rawData, dateSettings));
    }, [rawData, dateSettings]);

    useEffect(() => {
        loadData();
    }, [active]);

    const card_title = {
        title: (
            <LocaleMessage msg="page.dashboard.title.autonomous_action_logs" />
        ),
        subtitle: <LocaleMessage msg="page.dashboard.actions_period_30" />,
        value: `${parsedData.total || '0'}`,
        color: colors.dashboard_graphs,
        link: '/reports/autonomous_actions',
    };

    const card_data = {
        data: parsedData.list || [],
        labels: parsedData.labels || [],
        key: 'value',
    };

    const card_filter = {
        available: availableFilters,
        current: activeFilter || 'application_id',
        grouped: groupedData,
    };

    return (
        <DataCard
            loading={isLoading}
            card={card_title}
            data={card_data}
            filter={card_filter}
            handleDateFilterChange={value => handleDateFilterChange(value)}
        />
    );
}

AutonomousActionsCard.defaultProps = {
    requestError: () => {},
    getFilterInfo: () => {},
    dateSettings: {},
    userSettings: {},
    dataValues: {},
};

AutonomousActionsCard.propTypes = {
    requestError: PropTypes.func,
    getFilterInfo: PropTypes.func,
    dateSettings: PropTypes.object,
    userSettings: PropTypes.object,
    dataValues: PropTypes.object,
};
