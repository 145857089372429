import styled from 'styled-components';

const logoSize = 4.5;

export const FlowArea = styled.div`
    width: 100%;
    position: inherit;

    .flow-buttons {
        text-align: left;
        left: 0;
        bottom: 0;
        position: absolute;
        display: flex;
        flex-direction: column;
    }
`;

export const FlowTree = styled.div`
    width: 100%;
    height: 70vh;
    /* background-color: #ffffff; */

    display: flex;
    flex-direction: column;

    .flow-footer {
        visibility: hidden;
        text-align: right;
        margin-top: -${logoSize}vh;
        height: ${logoSize}vh;
        padding: 0.5vh;

        .pluginbot-logo {
            height: 100%;
        }
    }
`;

export const ParameterArea = styled.div`
    padding: 10px;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
`;
