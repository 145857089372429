/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdFormatShapes } from 'react-icons/md';
import ReactJson from 'react-json-view';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import CustomDialogTitle from '~/components/CustomDialogTitle';
import InfoFormatterSettings from '~/components/InfoFormatterSettings';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import Splash from '~/components/Splash/Inside';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import checkAdmin from '~/util/CheckAdmin';
import checkPermission from '~/util/CheckPermission';

export default function PrivateInfoFormatterForm({ match }) {
    const dispatch = useDispatch();
    const formatter_id = match.params.id;

    const user_settings = useSelector(state => state.settings || null);
    const { profile } = useSelector(state => state.user || null);
    const { active } = user_settings;

    const super_user = checkAdmin(user_settings, profile);
    const admin_user = checkPermission(profile);

    const [operation, setOperation] = useState('create');
    const [allowEdit, setAllowEdit] = useState(false);

    const [body, setBody] = useState({
        name: '',
        options: {},
        group_id: active ? active.id : '*',
        group_name: active ? active.name : '',
    });

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    const [testLoading, setTestLoading] = useState(false);
    const [testResult, setTestResult] = useState(null);
    const [testOpen, setTestOpen] = useState(false);
    const [testObj, setTestObj] = useState({});

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadFormatter(_id) {
        if (_id === 'new') {
            setOperation('create');
            setAllowEdit(true);
        } else {
            setOperation('update');
            await api
                .get(`/info_formatters/${_id}`)
                .then(response => {
                    const t = response.data;
                    const g = t.group;
                    const g_id = t.group ? t.group.id : '*';
                    setBody({
                        name: t.name,
                        extractor_id: t.extractor_id,
                        options: t.options,
                        group_id: g_id,
                        group_name:
                            g && g.name ? (
                                g.name
                            ) : (
                                <LocaleMessage msg="message.all_groups.short" />
                            ),
                    });

                    const group_edit =
                        t.private &&
                        (admin_user || (g_id === active.id && false));

                    setAllowEdit(super_user || group_edit);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadAll(f_id) {
        loadFormatter(f_id);
    }

    useEffect(() => {
        loadAll(formatter_id);
    }, [formatter_id]);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body };

        if (operation === 'create') {
            await api
                .post(`/info_formatters`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.tools.info_formatters.custom.form.create_success" />
                    );
                    setIsLoading(false);
                    history.push(`/tools/info_formatters`);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`/info_formatters/${formatter_id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.tools.info_formatters.custom.form.update_success" />
                    );
                    loadFormatter(formatter_id);
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    function handleTestOpen(event) {
        setTestObj({ text: '' });
        setTestResult(null);
        event.preventDefault();
        setTestOpen(true);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setTestOpen(false);
    }

    // Formatter Tester
    async function submitFormatterTest(event) {
        event.preventDefault();
        setTestLoading(true);
        await api
            .post(`info_formatters/test/${formatter_id}`, testObj)
            .then(response => {
                setTestResult({ ...response.data });
            })
            .catch(error => requestError(error));

        setTestLoading(false);
    }

    function buildTestDialog() {
        return (
            <div>
                <Dialog
                    open={testOpen}
                    onClose={event => handleDialogClose(event, 'test')}
                    maxWidth="sm"
                    fullWidth
                >
                    <CustomDialogTitle
                        title={
                            <LocaleMessage msg="page.tools.info_formatters.custom.form.test" />
                        }
                    />
                    {!testLoading ? (
                        <>
                            <DialogContent>
                                <form
                                    onSubmit={event =>
                                        submitFormatterTest(event)
                                    }
                                >
                                    <TextField
                                        className="mb-3"
                                        label={
                                            <LocaleMessage msg="page.tools.info_formatters.custom.form.test.text" />
                                        }
                                        fullWidth
                                        value={testObj.text ? testObj.text : ''}
                                        onChange={event =>
                                            setTestObj({
                                                ...testObj,
                                                text: event.target.value,
                                            })
                                        }
                                    />
                                </form>

                                {testResult ? (
                                    <>
                                        <ReactJson
                                            src={testResult}
                                            name={false}
                                            enableClipboard={false}
                                            displayObjectSize={false}
                                            displayDataTypes={false}
                                        />
                                    </>
                                ) : null}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={event =>
                                        handleDialogClose(event, 'test')
                                    }
                                    color="primary"
                                >
                                    <LocaleMessage msg="button.cancel" />
                                </Button>
                                <Button
                                    onClick={event =>
                                        submitFormatterTest(event)
                                    }
                                    color="primary"
                                >
                                    <LocaleMessage msg="button.try_it" />
                                </Button>
                            </DialogActions>
                        </>
                    ) : (
                        <Splash />
                    )}
                </Dialog>
            </div>
        );
    }

    // Page Component
    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.tools.info_formatters.custom.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.tools.info_formatters.custom.form.edit.title" />
                )
            }
            currentPage={
                operation === 'create' ? (
                    <LocaleMessage msg="page.tools.info_formatters.custom.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.tools.info_formatters.custom.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/info_formatters',
                    title: <LocaleMessage msg="breadcrumbs.info_formatters" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {testOpen ? buildTestDialog() : null}
                <div className="row full-body">
                    <div className="col-md-8 col-12 mb-5">
                        <form noValidate autoComplete="off">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.tools.info_formatters.custom.form.title" />
                                }
                                Icon={MdFormatShapes}
                            >
                                <InfoFormatterSettings
                                    data={body}
                                    operation={operation}
                                    onError={error => requestError(error)}
                                    onDataChange={data => setBody(data)}
                                    allowEdition={allowEdit}
                                />
                            </CardSideBordered>
                            {allowEdit ? (
                                <Button
                                    className="p-3 mb-3"
                                    variant="contained"
                                    color="primary"
                                    onClick={event => handleSubmit(event)}
                                    fullWidth
                                    size="large"
                                >
                                    <LocaleMessage msg="button.save" />
                                </Button>
                            ) : null}
                            {operation === 'update' ? (
                                <Button
                                    className="p-3 mb-3"
                                    variant="outlined"
                                    color="primary"
                                    onClick={event => handleTestOpen(event)}
                                    fullWidth
                                    size="large"
                                >
                                    <LocaleMessage msg="button.try_it" />
                                </Button>
                            ) : null}
                        </form>
                    </div>
                </div>
            </>
        </PageContent>
    );
}

PrivateInfoFormatterForm.propTypes = {
    match: PropTypes.object.isRequired,
};
