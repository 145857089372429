/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete, MdAdd } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
} from '@material-ui/core';

import CustomDialogTitle from '~/components/CustomDialogTitle';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import { BorderedArea, Column } from '../styles';

export default function Settings({
    requestError,
    appSettings,
    updateObject,
    formatters,
    loadFormatters,
}) {
    const { id: app_id, settings } = appSettings;
    const settings_obj = settings.settings || {};

    const [isLoading, setIsLoading] = useState(true);
    const [actionList, setActionList] = useState([]);

    const [deleteOpen, setDeleteOpen] = useState(false);

    const [currItem, setCurrItem] = useState(null);

    const [formatterOpen, setFormatterOpen] = useState(false);
    const [availableFormatters, setAvailableFormatters] = useState([]);
    const [formatterOperation, setFormatterOperation] = useState('create');
    const [currFormatterItem, setCurrFormatterItem] = useState(null);
    const [currFormatter, setCurrFormatter] = useState(null);
    const [currFormatterContent, setCurrFormatterContent] = useState(null);

    const [pluginconfigList, setPluginconfigList] = useState({});
    const [settingsBody] = useState(settings_obj);
    const [integrations, setIntegrations] = useState(
        settings_obj.integrations || {}
    );

    const headCells = [
        {
            id: 'formatter',
            label: <LocaleMessage msg="table.headers.identification_type" />,
        },
        {
            id: 'description',
            label: <LocaleMessage msg="table.headers.description" />,
        },
        {
            id: 'updated',
            order_by: 'updated_timestamp',
            label: <LocaleMessage msg="table.headers.updated" />,
        },
    ];

    async function loadCommunicationConfigs() {
        await api
            .get(`pluginconfigs?plugin=communication`)
            .then(response => {
                const list = response.data;
                const configs = {};
                list.forEach(conf => {
                    const { action } = conf;
                    if (!configs[action.id]) {
                        configs[action.id] = [];
                    }
                    configs[action.id].push({ id: conf.id, name: conf.name });
                });
                setPluginconfigList(configs);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadCommunicationActions() {
        await api
            .get(`pluginspaces/actions?plugin=communication`)
            .then(response => {
                const r = response.data;
                const list = r.filter(a => {
                    return a.status;
                });

                setActionList(list);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadAvailableFormatters() {
        await api
            .get(`info_formatters`)
            .then(response => {
                const l = response.data;
                setAvailableFormatters(l);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function loadFormatter(_id) {
        if (!_id) {
            setCurrFormatter(null);
            setCurrFormatterContent(null);
            return;
        }
        await api
            .get(`applications/formatters/${app_id}/${_id}`)
            .then(response => {
                const f = response.data;
                const fObj = {
                    formatter_id: f.formatter ? f.formatter.id : null,
                    description: f.description || '',
                };
                setCurrFormatter(fObj);
                setCurrFormatterContent(f.content);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadAvailableFormatters();
        loadCommunicationActions();
        loadCommunicationConfigs();
    }, []);

    useEffect(() => {
        loadFormatter(currFormatterItem);
    }, [currFormatterItem]);

    async function handleSubmit() {
        updateObject({ settings: { ...settingsBody, integrations } });
    }

    async function handleFormatterSubmit(event) {
        event.preventDefault();

        setFormatterOpen(false);
        setIsLoading(true);

        const data = { ...currFormatter, content: currFormatterContent };

        if (formatterOperation === 'create') {
            await api
                .post(`applications/formatters/${app_id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.applications.invite_manager.settings.identification.created" />
                    );
                })
                .catch(error => requestError(error));
        } else if (formatterOperation === 'update') {
            await api
                .put(
                    `applications/formatters/${app_id}/${currFormatterItem}`,
                    data
                )
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.applications.invite_manager.settings.identification.updated" />
                    );
                })
                .catch(error => requestError(error));
        }
        loadFormatters();
        setIsLoading(false);
    }

    async function handleDeleteFormatter() {
        setFormatterOpen(false);
        setDeleteOpen(false);
        setIsLoading(true);

        await api
            .delete(`applications/formatters/${app_id}/${currItem}`)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.applications.invite_manager.settings.identification.deleted" />
                );
            })
            .catch(error => requestError(error));

        loadFormatters();
        setIsLoading(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setFormatterOpen(false);
        setDeleteOpen(false);
    }

    function handleDeleteItem(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDeleteOpen(true);
    }

    function handleSwitchChange(event, key) {
        const val = event.target.checked;
        return setIntegrations({
            ...integrations,
            [key]: val
                ? { ...integrations[key], active: true }
                : { active: false },
        });
    }

    function handlePluginconfigChange(event, key) {
        setIntegrations({
            ...integrations,
            [key]: {
                ...integrations[key],
                pluginconfig_id: event.target.value,
            },
        });
    }

    async function handleFormatterRowClick(event, _id) {
        event.stopPropagation();
        setFormatterOperation('update');
        setCurrFormatterItem(_id);
        setFormatterOpen(true);
    }

    function buildActionRow(a) {
        const f_name = a.function_name;
        const a_integration = integrations[f_name] || {};
        const a_list = pluginconfigList[a.id] || [];
        return (
            <FormGroup
                row
                key={a.id}
                className="mb-3 row"
                style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    justifyContent: 'space-between',
                }}
            >
                <FormControlLabel
                    className="col-md-4"
                    control={
                        <Switch
                            color="primary"
                            checked={
                                a_integration.active
                                    ? a_integration.active
                                    : false
                            }
                            value={f_name}
                            onChange={event =>
                                handleSwitchChange(event, f_name)
                            }
                        />
                    }
                    label={<LocaleMessage msg={a.name} />}
                />

                {a_integration.active ? (
                    <>
                        {a.require_config ? (
                            <>
                                <FormControl
                                    className="col-md-6"
                                    label="Integration"
                                >
                                    <InputLabel>
                                        <LocaleMessage msg="page.applications.invite_manager.settings.integration" />
                                    </InputLabel>
                                    <Select
                                        labelId={`label-${f_name}`}
                                        value={
                                            a_integration.pluginconfig_id
                                                ? a_integration.pluginconfig_id
                                                : ''
                                        }
                                        onChange={event =>
                                            handlePluginconfigChange(
                                                event,
                                                f_name
                                            )
                                        }
                                        required
                                    >
                                        {a_list.length > 0 ? (
                                            a_list.map(p => {
                                                return (
                                                    <MenuItem
                                                        value={p.id}
                                                        key={p.id}
                                                    >
                                                        {p.name}
                                                    </MenuItem>
                                                );
                                            })
                                        ) : (
                                            <MenuItem value={null}>
                                                <LocaleMessage msg="page.applications.invite_manager.settings.no_config" />
                                            </MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </>
                        ) : (
                            <p>
                                <LocaleMessage msg="page.applications.invite_manager.settings.no_require" />
                            </p>
                        )}
                    </>
                ) : null}
            </FormGroup>
        );
    }

    function buildIntegrationList() {
        const filteredActions = actionList
            ? actionList.filter(a => {
                  return a.status;
              })
            : [];

        return (
            <FormControl fullWidth component="fieldset">
                <FormLabel component="legend">
                    <LocaleMessage msg="page.applications.invite_manager.settings.use" />
                </FormLabel>
                {filteredActions.map(a => {
                    return buildActionRow(a);
                })}
            </FormControl>
        );
    }

    function renderPluginCommunicationSettings() {
        return (
            <div className="col-md-8 col-12 mb-5">
                <BorderedArea>
                    <div className="area-title">
                        <h4>Plugin Communication</h4>
                    </div>
                    <div className="config-area">
                        {buildIntegrationList(settingsBody.integrations || {})}
                    </div>
                </BorderedArea>
            </div>
        );
    }

    function handleNewFormatter() {
        setFormatterOperation('create');
        setCurrFormatterItem(null);
        setFormatterOpen(true);
    }

    function renderAppFormattersList() {
        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteItem,
            },
        ];

        return (
            <>
                <BorderedArea
                    className="mt-3"
                    style={{
                        minHeight: '150px',
                        width: '100%',
                        padding: '15px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <DataTable
                        data={formatters}
                        orderColumn="formatter"
                        headerColumns={headCells}
                        rowActions={rowActions}
                        handleTableRowClick={(event, _id) =>
                            handleFormatterRowClick(event, _id)
                        }
                        hasFilter={false}
                        hasHeader
                        header={
                            <div
                                className="sidecard-header row"
                                style={{
                                    width: '100%',
                                    padding: '0px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <h2 className="col-md-6 col-12 mb-3">
                                    <LocaleMessage msg="page.applications.invite_manager.settings.identification.list" />
                                </h2>
                                <div className="col-md-6 col-12 mb-3">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        onClick={() => handleNewFormatter()}
                                    >
                                        <MdAdd
                                            size={20}
                                            style={{
                                                color: '#fff',
                                            }}
                                        />

                                        <LocaleMessage msg="page.applications.invite_manager.settings.identification.add" />
                                    </Button>
                                </div>
                            </div>
                        }
                    />
                </BorderedArea>
            </>
        );
    }

    function renderFormatterDelete() {
        return (
            <SimpleDialog
                open={deleteOpen}
                onClose={event => handleDialogClose(event, 'delete')}
                title={
                    <LocaleMessage msg="page.applications.invite_manager.settings.identification.delete" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => handleDeleteFormatter(),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function renderFormatterDialog() {
        return (
            <Dialog
                open={formatterOpen}
                onClose={handleDialogClose}
                maxWidth="sm"
                fullWidth
            >
                <CustomDialogTitle
                    title={
                        <LocaleMessage msg="page.applications.invite_manager.settings.identification.form.formatter" />
                    }
                />
                <DialogContent>
                    <FormControl fullWidth className="mb-5">
                        <InputLabel>
                            <LocaleMessage msg="page.applications.invite_manager.settings.identification.form.formatter" />{' '}
                        </InputLabel>
                        <Select
                            value={
                                currFormatter && currFormatter.formatter_id
                                    ? currFormatter.formatter_id
                                    : ''
                            }
                            onChange={event => {
                                setCurrFormatter({
                                    ...currFormatter,
                                    formatter_id: event.target.value,
                                });
                            }}
                        >
                            {availableFormatters.map(f => (
                                <MenuItem value={f.id} key={f.id}>
                                    {f.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        className="mb-5"
                        label={
                            <LocaleMessage msg="page.applications.invite_manager.settings.identification.form.invite_text" />
                        }
                        fullWidth
                        value={
                            currFormatterContent && currFormatterContent.text
                                ? currFormatterContent.text
                                : ''
                        }
                        onChange={event =>
                            setCurrFormatterContent({
                                ...currFormatterContent,
                                text: event.target.value,
                            })
                        }
                    />
                    <TextField
                        className="mb-5"
                        label={
                            <LocaleMessage msg="page.applications.invite_manager.settings.identification.form.description" />
                        }
                        fullWidth
                        value={
                            currFormatter && currFormatter.description
                                ? currFormatter.description
                                : ''
                        }
                        onChange={event => {
                            setCurrFormatter({
                                ...currFormatter,
                                description: event.target.value,
                            });
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={event => handleFormatterSubmit(event)}
                        color="primary"
                        autoFocus
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <div className="col-12 mb-3">
            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {formatterOpen ? renderFormatterDialog() : null}
                        {deleteOpen ? renderFormatterDelete() : null}
                        <Column className="row">
                            <div className="col-md-8 col-12 mb-5">
                                {renderAppFormattersList()}
                            </div>
                        </Column>
                        <Column className="row">
                            {renderPluginCommunicationSettings()}
                        </Column>
                        <Column className="row">
                            <div className="col-md-8 col-12">
                                <Button
                                    className="p-3"
                                    variant="contained"
                                    color="primary"
                                    onClick={event => handleSubmit(event)}
                                    fullWidth
                                    size="large"
                                >
                                    <LocaleMessage msg="button.save" />
                                </Button>
                            </div>
                        </Column>
                    </>
                )}
            </div>
        </div>
    );
}

Settings.defaultProps = {
    requestError: () => {},
    formatters: [],
};

Settings.propTypes = {
    loadFormatters: PropTypes.func.isRequired,
    updateObject: PropTypes.func.isRequired,
    requestError: PropTypes.func,
    appSettings: PropTypes.object.isRequired,
    formatters: PropTypes.array,
};
