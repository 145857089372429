/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import { DialogContentText, Menu, MenuItem } from '@material-ui/core';

import CardItem from '~/components/CardItem';
import DataFilter from '~/components/DataFilter';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import NewItemCard from '~/components/NewItem/Card';
import PageContent from '~/components/PageContent';
import PreFilter from '~/components/PreFilter';
import SimpleDialog from '~/components/SimpleDialog';
import ViewSwitcher from '~/components/ViewSwitcher';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

const PAGE_KEY = 'galleries';
const PAGE_VIEW_DEF = 'grid';

export default function Galleries() {
    const dispatch = useDispatch();

    const settings = useSelector(state => state.settings || null);
    const page_settings = useSelector(state => state.pages || null);
    const curr_page_settings =
        page_settings[PAGE_KEY] && typeof page_settings[PAGE_KEY] === 'object'
            ? page_settings[PAGE_KEY]
            : {};

    const date_loc = getDateLocale(settings);

    const { active } = settings;

    const [view, setView] = useState(
        curr_page_settings && curr_page_settings.view
            ? curr_page_settings.view
            : PAGE_VIEW_DEF
    );
    const [isLoading, setIsLoading] = useState(true);
    const [rawData, setRawData] = useState([]);
    const [filteredData, setFilteredData] = useState(rawData);
    const [prefilter, setPrefilter] = useState(null);

    const [currItem, setCurrItem] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const open = Boolean(anchorEl);

    const album_types = [
        {
            value: 'images',
            label: <LocaleMessage msg="list.galleries.type.images" />,
        },
        {
            value: 'audios',
            label: <LocaleMessage msg="list.galleries.type.audios" />,
        },
        {
            value: 'documents',
            label: <LocaleMessage msg="list.galleries.type.documents" />,
        },
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`albums`)
            .then(response => {
                const data = response.data.map(a => {
                    const updated = new Date(a.updated);
                    return {
                        ...a,
                        group_id: a.group && a.group.id ? a.group.id : '*',
                        group_name:
                            a.group && a.group.name ? (
                                a.group.name
                            ) : (
                                <LocaleMessage msg="message.all_groups.short" />
                            ),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                        type: (
                            <LocaleMessage
                                msg={`list.galleries.type.${a.type}`}
                            />
                        ),
                        type_name: a.type,
                    };
                });
                setRawData(data);

                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadItems();
    }, [active, date_loc]);

    function handleClick(event, _id) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setCurrItem(_id);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/galleries/${id}`);
    }

    function goToSettings(event, _id) {
        event.preventDefault();
        setAnchorEl(null);
        history.push(`galleries/${_id}`);
    }

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDialogOpen(true);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    async function deleteItem() {
        setIsLoading(true);
        setDialogOpen(false);

        await api
            .delete(`albums/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.galleries.list.delete_success" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function itemCard(item) {
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={item.id}>
                <Link to={`/galleries/${item.id}`}>
                    <CardItem
                        title={item.name}
                        optionsClick={event => handleClick(event, item.id)}
                        group={
                            item.group_name || (
                                <LocaleMessage msg="message.all_groups.short" />
                            )
                        }
                        updated={item.updated}
                    >
                        <>
                            <p className="card-text">{item.type}</p>
                            <p
                                className="card-text"
                                style={{ minHeight: '40px' }}
                            >
                                {item.description}
                            </p>
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                <div
                    className="row"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    <NewItemCard
                        link="/galleries/new"
                        text={<LocaleMessage msg="page.galleries.list.add" />}
                    />
                    {filteredData.map(gallery => itemCard(gallery))}
                </div>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={e => goToSettings(e, currItem)}>
                        <LocaleMessage msg="button.settings" />
                    </MenuItem>
                    <MenuItem onClick={e => handleClickOpen(e, currItem)}>
                        <LocaleMessage msg="page.galleries.list.delete" />
                    </MenuItem>
                </Menu>
            </>
        );
    }

    function buildListView() {
        const headCells = [
            {
                id: 'group_name',
                label: <LocaleMessage msg="table.headers.group" />,
            },
            {
                id: 'type',
                label: <LocaleMessage msg="table.headers.type" />,
            },
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'description',
                label: <LocaleMessage msg="table.headers.description" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <>
                <div
                    style={{
                        minHeight: '150px',
                        width: '100%',
                        padding: '15px',
                    }}
                >
                    <DataTable
                        headerColumns={headCells}
                        data={filteredData}
                        pageKey={`${PAGE_KEY}_list`}
                        orderColumn="name"
                        orderDirection="asc"
                        setCurrDialog={_id => setCurrItem(_id)}
                        handleTableRowClick={(event, id) =>
                            handleTableRowClick(event, id)
                        }
                        rowActions={rowActions}
                        hasHeader
                        header={
                            <>
                                <div
                                    className="col-3"
                                    style={{ padding: '0px' }}
                                >
                                    <NewItemButton
                                        link="/galleries/new"
                                        text={
                                            <LocaleMessage msg="page.galleries.list.add" />
                                        }
                                    />
                                </div>
                            </>
                        }
                    />
                </div>
            </>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={<LocaleMessage msg="page.galleries.list.delete_title" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.recycler.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteItem(currItem),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="breadcrumbs.galleries" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                <PreFilter
                    title=""
                    filterKey="type_name"
                    options={album_types}
                    onFilterChange={f => setPrefilter(f)}
                />
                <div className="body-top-controls">
                    <div className="col-3 pl-0">
                        <DataFilter
                            rawData={rawData}
                            prefilter={prefilter}
                            headerColumns={[
                                'group_name',
                                'name',
                                'description',
                            ]}
                            returnFilteredData={f_data =>
                                setFilteredData(f_data)
                            }
                        />
                    </div>
                    <ViewSwitcher
                        size="col-3"
                        view={view}
                        setView={v => setView(v)}
                        pageKey={PAGE_KEY}
                    />
                </div>
                {view === 'grid' ? buildGridView() : buildListView()}
            </>
        </PageContent>
    );
}
