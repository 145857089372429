/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdAdd, MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    Badge,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    InputAdornment,
    TextField,
    Slider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import AppConfigRobotType from '~/components/AppConfigRobotType';
import CustomDialogTitle from '~/components/CustomDialogTitle';
// import DataTable from '~/components/DataTable';
import FormSelect from '~/components/Form/Select';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
// import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';
import UnavailablePlugin from '~/components/UnavailablePlugin';

import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';

// import MapPointIdentifier from './MapPointIdentifier';
import { SpeedArea, ParameterArea } from './styles';

const useStyles = makeStyles(() => {
    return {
        row: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});

const max_speeds = {
    cruzr: 1,
};

const nav_available = {
    cruzr: true,
    temi: true,
    nao: false,
};

const nav_features = {
    cruzr: {
        max_speed: true,
        reference_point_id: true,
        show_location: true,
        speed_selection: true,
    },
    temi: {},
};

export default function MapNavigation({
    // setToast,
    handleError,
    appSettings,
    updateObject,
    robotTypes,
    currType,
    setCurrType,
}) {
    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const { settings, group } = appSettings;
    const app_languages =
        settings && settings.language ? settings.language : {};
    const language_list = app_languages.list || ['pt_BR'];

    const group_id = group ? group.id : '*';
    // const { dialog_id } = application_content;

    const [mapNavigation, setMapNavigation] = useState(
        settings && settings.map_navigation
            ? settings.map_navigation
            : { active: false }
    );

    const [allowPlugin, setAllowPlugin] = useState(true);

    const [isLoading, setIsLoading] = useState(true);
    const [, setNeedSaving] = useState(false);
    const [maps, setMaps] = useState([]);
    const [points, setPoints] = useState([]);
    // const [identiferList, setIdentiferList] = useState([]);

    // const [currIdentifier, setCurrIdentifier] = useState('new');
    // const [deleteIdentifierOpen, setDeleteIdentifierOpen] = useState(false);
    // const [identifierOpen, setIdentifierOpen] = useState(false);

    const [currSpeed, setCurrSpeed] = useState('');
    const [tempSpeed, setTempSpeed] = useState({});
    const [speedOpen, setSpeedOpen] = useState(false);

    const r_key = currType.slug ? currType.slug : 'all';
    const robot_features = nav_features[r_key] || {};
    const max_speed = max_speeds[r_key] || 1;
    const r_type_settings = mapNavigation[r_key] || {};

    const classes = useStyles();

    function requestError(error) {
        handleError(error);
        setIsLoading(false);
    }

    async function loadRobotFunctionsPlugin() {
        await api
            .get(`pluginspaces/addons/robot_functions`)
            .then(async response => {
                const { data } = response;
                if (!data.id) {
                    setAllowPlugin(false);
                }
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadMapPoints(_id) {
        if (_id) {
            setIsLoading(true);
            await api
                .get(`map_points?map_layer=${_id}`)
                .then(response => {
                    const data = response.data.map(z => ({
                        id: z.id,
                        name: z.name,
                        reference: z.reference,
                    }));
                    setPoints(data);
                })
                .catch(error => requestError(error));
        } else {
            setPoints([]);
        }
        setIsLoading(false);
    }

    async function loadMaps(r_id) {
        setIsLoading(true);
        await api
            .get(`map_layers?robot=${r_id}`)
            .then(async response => {
                const filtered = response.data.filter(m => {
                    return m.group && m.group.id === group_id;
                });
                const data = filtered.map(m => {
                    const updated = new Date(m.updated);
                    return {
                        ...m,
                        name: m.map ? m.map.name : '',
                        label: `${m.map ? m.map.name : ''} [${m.reference}]`,
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setMaps(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadRobotFunctionsPlugin();
    }, []);

    useEffect(() => {
        loadMaps(currType.id);
    }, [currType]);

    useEffect(() => {
        loadMapPoints(r_type_settings.map_layer_id);
        // loadIdentifiers(currType.id, r_type_settings.map_layer_id);
    }, [r_type_settings.map_layer_id, currType]);

    function setCurrTypeSettings(key, value) {
        setNeedSaving(true);
        const updated = {
            ...mapNavigation,
            [r_key]: {
                ...r_type_settings,
                [key]: value,
            },
        };
        setMapNavigation(updated);
    }

    function addOption() {
        let { speeds } = r_type_settings;
        if (!speeds) {
            speeds = [];
        }
        if (speeds.length >= 3) return;
        const label = speeds.length + 1;
        speeds.push({
            value: 0.5,
            labels: {
                en_GB: label,
                en_US: label,
                es_ES: label,
                pt_BR: label,
            },
        });
        setCurrTypeSettings('speeds', speeds);
    }

    function removeOption(idx) {
        let { speeds } = r_type_settings;
        if (!speeds) {
            speeds = [];
        }
        if (!speeds[idx]) {
            return;
        }
        speeds.splice(idx, 1);
        setCurrTypeSettings('speeds', speeds);
    }

    function editOption(key, value) {
        setTempSpeed({
            ...tempSpeed,
            [key]: value,
        });
    }

    function saveOption(idx) {
        const speeds =
            r_type_settings && r_type_settings.speeds
                ? r_type_settings.speeds
                : [];
        const obj = speeds[idx];
        if (!obj) {
            return;
        }
        speeds[idx] = tempSpeed;

        setCurrTypeSettings('speeds', speeds);

        setTempSpeed(null);
        setCurrSpeed('');
        setSpeedOpen(false);
    }

    function optionClick(idx) {
        const speeds =
            r_type_settings && r_type_settings.speeds
                ? r_type_settings.speeds
                : [];
        const obj = speeds[idx];
        if (!obj) {
            return;
        }
        setTempSpeed(obj);
        setCurrSpeed(idx);
        setSpeedOpen(true);
    }

    async function handleSubmit() {
        const data = { map_navigation: mapNavigation };
        updateObject(data);
    }

    function handleSwitchChange(key, event) {
        setCurrTypeSettings(key, event.target.checked);
    }

    function renderSubmitButton() {
        return (
            <div className="col-12 mt-2">
                <Button
                    className="p-3"
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                    fullWidth
                    size="large"
                    // disabled={!needSaving}
                >
                    <LocaleMessage msg="button.save" />
                </Button>
            </div>
        );
    }

    function renderSpeedForm(idx) {
        const labels = tempSpeed && tempSpeed.labels ? tempSpeed.labels : {};
        return (
            <div>
                <Dialog
                    open={speedOpen}
                    onClose={() => {
                        setTempSpeed(null);
                        setCurrSpeed('');
                        setSpeedOpen(false);
                    }}
                    maxWidth="sm"
                    fullWidth
                >
                    <CustomDialogTitle
                        title={
                            <LocaleMessage msg="page.applications.conversation.map_navigation.speeds.settings" />
                        }
                    />
                    <DialogContent>
                        <div
                            className="col-12"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <h5 className="mb-3">
                                <LocaleMessage msg="page.applications.conversation.map_navigation.speeds.value" />
                            </h5>
                            <Slider
                                className="col-md-8 col-12"
                                value={
                                    tempSpeed && tempSpeed.value
                                        ? tempSpeed.value
                                        : 0.4
                                }
                                onChange={(event, value) => {
                                    editOption('value', value);
                                }}
                                step={0.2}
                                marks={[
                                    {
                                        value: 0.2,
                                        label: (
                                            <LocaleMessage msg="page.applications.conversation.map_navigation.max_speed.slow" />
                                        ),
                                    },
                                    {
                                        value: max_speed,
                                        label: (
                                            <LocaleMessage msg="page.applications.conversation.map_navigation.max_speed.fast" />
                                        ),
                                    },
                                ]}
                                min={0.2}
                                max={max_speed}
                                size="medium"
                            />
                        </div>
                        <div className="col-12">
                            <ParameterArea className="row mt-3 mb-5 p-4">
                                <div
                                    className="col-12 mb-1"
                                    style={{ textAlign: 'center' }}
                                >
                                    <h5>
                                        <LocaleMessage msg="page.applications.conversation.map_navigation.speeds.label" />
                                    </h5>
                                </div>
                                {language_list.map(l => {
                                    return (
                                        <div
                                            className="col-12 row mb-3"
                                            key={`speedlabel_${l}`}
                                        >
                                            <TextField
                                                label={
                                                    <LocaleMessage
                                                        msg={`list.languages.${l}`}
                                                    />
                                                }
                                                fullWidth
                                                value={
                                                    labels && labels[l]
                                                        ? labels[l]
                                                        : ''
                                                }
                                                onChange={event =>
                                                    editOption('labels', {
                                                        ...labels,
                                                        [l]: event.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </ParameterArea>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setTempSpeed(null);
                                setCurrSpeed('');
                                setSpeedOpen(false);
                            }}
                            color="primary"
                        >
                            <LocaleMessage msg="button.cancel" />
                        </Button>
                        <Button
                            onClick={() => saveOption(idx)}
                            color="primary"
                            autoFocus
                        >
                            <LocaleMessage msg="button.edit" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    function renderSpeeds() {
        const locale = _settings.locale || { code: 'pt_BR' };
        const speeds =
            r_type_settings && r_type_settings.speeds
                ? r_type_settings.speeds
                : [];
        const enable = speeds.length < 3;

        return (
            <>
                <Divider />
                {speedOpen ? renderSpeedForm(currSpeed) : null}
                <div
                    className="mt-3 mb-3 col-12"
                    style={{ textAlign: 'center' }}
                >
                    <LocaleMessage msg="page.applications.conversation.map_navigation.speeds" />
                </div>
                <SpeedArea className="mt-3 mb-3 col-12 row">
                    {speeds.map((s, i) => {
                        const labels = s.labels || {};
                        return (
                            <div className="col-md-3 col-12" key={`speed_${i}`}>
                                <Badge
                                    style={{
                                        width: '100%',
                                    }}
                                    overlap="rectangular"
                                    color="primary"
                                    badgeContent={
                                        <MdClose
                                            className="remove-option"
                                            size={14}
                                            onClick={() => {
                                                removeOption(i);
                                            }}
                                        />
                                    }
                                >
                                    <Chip
                                        style={{
                                            width: '100%',
                                        }}
                                        label={
                                            labels[locale.code] || labels.pt_BR
                                        }
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {
                                            optionClick(i);
                                        }}
                                    />
                                </Badge>
                            </div>
                        );
                    })}
                    <div className="col-md-3 col-12">
                        <Chip
                            style={{ width: '100%' }}
                            color={enable ? 'primary' : undefined}
                            label={<MdAdd size={18} />}
                            onClick={() => (enable ? addOption() : {})}
                        />
                    </div>
                </SpeedArea>
                <Divider />
            </>
        );
    }

    function renderNavSettings() {
        const is_available = nav_available[r_key] || false;

        return is_available ? (
            <>
                <div
                    className="col-12 row"
                    style={{ display: 'flex', alignItems: 'flex-start' }}
                >
                    <div className={`col-md-6 col-12 row mb-5 ${classes.row}`}>
                        {/* Activate Feature */}
                        <div className="col-12 mt-3">
                            <FormSwitch
                                value={
                                    r_type_settings.active
                                        ? r_type_settings.active
                                        : false
                                }
                                onChange={event =>
                                    handleSwitchChange('active', event)
                                }
                                label={
                                    <LocaleMessage msg="page.applications.conversation.map_navigation.use_map" />
                                }
                            />
                        </div>
                        {/* Setup Feature */}
                        {r_type_settings && r_type_settings.active ? (
                            <>
                                {/* Map and Reference Point */}
                                <div
                                    className="col-12 mt-1 row"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="col-12">
                                        <FormSelect
                                            classes="mb-5"
                                            options={maps}
                                            labelKey="label"
                                            label={
                                                <LocaleMessage msg="page.applications.conversation.map_navigation.current" />
                                            }
                                            value={
                                                r_type_settings.map_layer_id
                                                    ? r_type_settings.map_layer_id
                                                    : ''
                                            }
                                            onChange={value => {
                                                setCurrTypeSettings(
                                                    'map_layer_id',
                                                    value,
                                                    true
                                                );
                                            }}
                                        />
                                        {r_type_settings.map_layer_id ? (
                                            <>
                                                {robot_features.reference_point_id ? (
                                                    <FormSelect
                                                        classes="mb-5"
                                                        options={points}
                                                        labelKey="name"
                                                        label={
                                                            <LocaleMessage msg="page.applications.conversation.map_navigation.reference_point" />
                                                        }
                                                        value={
                                                            r_type_settings.reference_point_id
                                                                ? r_type_settings.reference_point_id
                                                                : ''
                                                        }
                                                        onChange={value => {
                                                            setCurrTypeSettings(
                                                                'reference_point_id',
                                                                value
                                                            );
                                                        }}
                                                        helper={
                                                            <LocaleMessage msg="page.applications.conversation.map_navigation.reference_point.helper" />
                                                        }
                                                    />
                                                ) : null}
                                            </>
                                        ) : null}
                                    </div>
                                </div>

                                {/* Navigation Speech */}
                                <div
                                    className="col-12 mt-1 row"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div className="col-12">
                                        <FormSwitch
                                            value={
                                                r_type_settings.navigation_speech
                                                    ? r_type_settings.navigation_speech
                                                    : false
                                            }
                                            onChange={event =>
                                                handleSwitchChange(
                                                    'navigation_speech',
                                                    event
                                                )
                                            }
                                            label={
                                                <LocaleMessage msg="page.applications.conversation.map_navigation.navigation_speech" />
                                            }
                                        />
                                    </div>

                                    {r_type_settings.navigation_speech ? (
                                        <div className="col-md-9 col-12">
                                            <TextField
                                                className="mb-5"
                                                fullWidth
                                                value={
                                                    r_type_settings.speech_timeout ||
                                                    60
                                                }
                                                onChange={event =>
                                                    setCurrTypeSettings(
                                                        'speech_timeout',
                                                        event.target.value
                                                    )
                                                }
                                                type="number"
                                                inputProps={{
                                                    min: '5',
                                                    step: '5',
                                                    style: {
                                                        textAlign: 'center',
                                                    },
                                                }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <LocaleMessage msg="label.time.timeout.each" />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <LocaleMessage msg="page.applications.conversation.map_navigation.navigation_speech.timeout.seconds" />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                </div>

                                {/* Show Location */}
                                {robot_features.show_location ? (
                                    <div className="col-12 mt-1">
                                        <FormSwitch
                                            value={
                                                r_type_settings.show_location
                                                    ? r_type_settings.show_location
                                                    : false
                                            }
                                            onChange={event =>
                                                handleSwitchChange(
                                                    'show_location',
                                                    event
                                                )
                                            }
                                            label={
                                                <LocaleMessage msg="page.applications.conversation.map_navigation.show_location" />
                                            }
                                        />
                                    </div>
                                ) : null}

                                {/* Speed Selection */}
                                {robot_features.speed_selection ? (
                                    <>
                                        <div className="col-12 mt-1 mb-3">
                                            <FormSwitch
                                                value={
                                                    r_type_settings.speed_selection
                                                        ? r_type_settings.speed_selection
                                                        : false
                                                }
                                                onChange={event =>
                                                    handleSwitchChange(
                                                        'speed_selection',
                                                        event
                                                    )
                                                }
                                                label={
                                                    <LocaleMessage msg="page.applications.conversation.map_navigation.speed_selection" />
                                                }
                                            />
                                        </div>

                                        {r_type_settings.speed_selection ? (
                                            <div className="col-12 mt-1 mb-5">
                                                {renderSpeeds()}
                                            </div>
                                        ) : null}
                                    </>
                                ) : null}
                            </>
                        ) : null}
                        {renderSubmitButton()}
                    </div>
                </div>
            </>
        ) : (
            <div
                className="col-12 mt-3 mb-5"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <LocaleMessage msg="label.form.not_available.robot_type" />
            </div>
        );
    }

    return (
        <div className="col-12 mb-3 row">
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.applications.conversation.map_navigation.title" />
                </h2>
            </div>
            <div
                className="col-12 mb-3"
                style={{
                    padding: '0px 25px',
                }}
            >
                <br />
                {/* <LocaleMessage msg="page.applications.conversation.map_navigation.label.tutorial_maps" /> */}
                <br />
                <LocaleMessage msg="page.applications.conversation.map_navigation.label.caution_maps" />
            </div>

            <div className="sidecard-body">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {!allowPlugin ? (
                            <UnavailablePlugin />
                        ) : (
                            <>
                                <div
                                    className="col-12"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <AppConfigRobotType
                                        className="col-12 mb-5"
                                        robotTypes={robotTypes}
                                        currType={currType}
                                        setCurrType={t => setCurrType(t)}
                                    >
                                        {renderNavSettings()}
                                    </AppConfigRobotType>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

MapNavigation.propTypes = {
    updateObject: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    appSettings: PropTypes.object.isRequired,
    robotTypes: PropTypes.array.isRequired,
    currType: PropTypes.object.isRequired,
    setCurrType: PropTypes.func.isRequired,
    // setToast: PropTypes.func.isRequired,
};
