import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    max-width: 800px;
    max-height: 60vh;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 800px;
    max-height: 800px;
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50px;
        margin: 0px;
        /* background-color: #fff; */
        padding: 20px;
    }

    .image-view {
        width: 100%;
        min-height: 500px;
        max-height: 500px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .card-title {
            font-size: 14px;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
                padding: 20px;
            }

            .MuiIconButton-root {
                margin: 20px;
                background: rgba(0, 0, 0, 0.42);

                svg {
                    color: #fff;
                }
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            overflow: hidden;
            display: block;
        }
    }

    .audio-view {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        audio {
            width: 100%;
        }
    }

    .document-view {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #fff;
    }

    .MuiMobileStepper-root {
        width: 100%;
    }
`;

export const DocumentView = styled.div`
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;

    a {
        color: #fff;
    }

    :hover {
        cursor: pointer;
    }
`;
