/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useTheme } from '@material-ui/core/styles';

import IconWithTextCard from '~/components/IconWithTextCard';
import InfoCard from '~/components/InfoCard';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import Splash from '~/components/Splash/Inside';

import locations_icon from '~/assets/images/dashboard/location-icon-white.png';
import robots_icon from '~/assets/images/dashboard/robot-icon-white.png';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import ActionsCard from './ActionsCard';
import AutonomousActionsCard from './AutonomousActionsCard';
import DeliveryCard from './DeliveryCard';
import DisinfectionsCard from './DisinfectionsCard';
import InvitesCard from './InvitesCard';
import MessagesCard from './MessagesCard';
import RC3OperationsCard from './RC3OperationsCard';
import { Container } from './styles';
import TelepresenceCard from './TelepresenceCard';

const cardIcons = {
    robots: robots_icon,
    locations: locations_icon,
};

const timeCards = {
    rc3_operations: RC3OperationsCard,
    conversation: MessagesCard,
    actions: ActionsCard,
    autonomous_actions: AutonomousActionsCard,
    disinfection: DisinfectionsCard,
    invites: InvitesCard,
    telepresence: TelepresenceCard,
    delivery: DeliveryCard,
};

const cardTypes = {
    color: IconWithTextCard,
    white: InfoCard,
};

export default function Dashboard() {
    const dispatch = useDispatch();
    const colors = GetPluginspaceTheme(useTheme());
    const settings = useSelector(state => state.settings || null);

    const { active, app_list } = settings;

    const rc3_app = app_list
        ? app_list.find(a => {
              return a.slug === 'rc3';
          })
        : false;
    const rc3_available = rc3_app ? rc3_app.active : false;

    const dateSettings = {
        locale: settings.locale,
        date: { year: 'numeric', month: '2-digit', day: '2-digit' },
        label: { month: '2-digit', day: '2-digit' },
    };

    const userSettings = {
        colors,
        active,
    };

    const [dataCount, setDataCount] = useState({});
    const [dataValues, setDataValues] = useState({});
    const [availableCards, setAvailableCards] = useState({});

    const [isLoading, setIsLoading] = useState(true);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
    }

    async function loadCounters() {
        setIsLoading(true);
        await api
            .get(`dashboards/counter`)
            .then(response => {
                const { data } = response;
                setDataCount({
                    robots: data.robots ? data.robots.count : 0,
                    locations: data.locations ? data.locations.count : 0,
                    applications: data.applications
                        ? data.applications.count
                        : 0,
                    tools: data.tools ? data.tools.count : 0,
                });
                setDataValues({
                    groups: data.groups ? data.groups.items : [],
                    robots: data.robots ? data.robots.items : [],
                    locations: data.locations ? data.locations.items : [],
                    dialogs: data.dialogs ? data.dialogs.items : [],
                    applications: data.applications
                        ? data.applications.items
                        : [],
                });

                setAvailableCards(data.app_types);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function getFilterInfo(values) {
        const infos = {};
        if (values) {
            values.forEach(m => {
                infos[m.id] = m.name;
            });
        }
        return infos;
    }

    useEffect(() => {
        loadCounters();
    }, [active]);

    function buildCard(card) {
        const { type } = card;
        const Card = cardTypes[type];

        return Card ? (
            <div className="col-md-3 col-12 dash-card" key={card.key}>
                <Link to={card.link}>
                    <Card
                        data={{
                            title: card.title,
                            subTitle: card.subtitle,
                        }}
                        color={card.color}
                        image={card.icon}
                        icon={card.icon}
                    />
                </Link>
            </div>
        ) : null;
    }

    function buildTimeCard(type) {
        const Card = timeCards[type];

        return Card ? (
            <Card
                dataValues={dataValues}
                dateSettings={dateSettings}
                userSettings={userSettings}
                requestError={error => requestError(error)}
                getFilterInfo={values => getFilterInfo(values)}
            />
        ) : null;
    }

    const active_group =
        active && active.id && active.id !== '*' ? active.id : null;

    const counterCards = [
        {
            key: 'card_robots',
            type: 'color',
            link: '/robots',
            title: dataCount.robots || '0',
            subtitle: <LocaleMessage msg="page.dashboard.robots" />,
            color: colors.dashboard_cards,
            icon: cardIcons.robots,
        },
        {
            key: 'card_applications',
            type: 'white',
            link: '/applications',
            title: dataCount.applications || '0',
            subtitle: <LocaleMessage msg="page.dashboard.applications" />,
            color: colors.dashboard_cards,
            icon: 'play-circle',
        },
        {
            key: 'card_locations',
            type: 'color',
            link: active_group
                ? `/groups/${active_group}/locations`
                : '/pluginspace/locations',
            title: dataCount.locations || '0',
            subtitle: <LocaleMessage msg="page.dashboard.locations" />,
            color: colors.dashboard_cards,
            icon: cardIcons.locations,
        },
        {
            key: 'card_tools',
            type: 'white',
            link: '/tools',
            title: dataCount.tools || '0',
            subtitle: <LocaleMessage msg="page.dashboard.tools" />,
            color: colors.dashboard_cards,
            icon: 'wrench',
        },
        // {
        //     key: 'card_sections',
        //     type: 'white',
        //     link: active_group ? `/groups/${active_group}/sections` : '/groups',
        //     title: dataCount.sections || '0',
        //     subtitle: <LocaleMessage msg="page.dashboard.sections" />,
        //     color: colors.dashboard_cards,
        //     icon: 'accounts',
        // },
    ];

    return (
        <>
            <PageContent
                title={<LocaleMessage msg="page.dashboard.title" />}
                currentPage={<LocaleMessage msg="page.dashboard.title" />}
                breadcrumbs={[]}
                loading={isLoading}
            >
                <Container>
                    {isLoading ? (
                        <Splash />
                    ) : (
                        <>
                            {active ? (
                                <h2>
                                    {active.id === '*' ? (
                                        <LocaleMessage msg="message.all_groups.title" />
                                    ) : (
                                        active.name.toUpperCase()
                                    )}
                                </h2>
                            ) : null}
                            <div
                                className="animated slideInUpTiny animation-duration-3 mt-5 mb-5"
                                style={{ width: '100%' }}
                            >
                                <div className="row card-row mb-5">
                                    {counterCards.map(c => {
                                        return buildCard(c);
                                    })}
                                </div>

                                {rc3_available
                                    ? buildTimeCard('rc3_operations')
                                    : null}

                                {availableCards ? (
                                    <>
                                        {availableCards.conversation ? (
                                            <>
                                                {buildTimeCard('conversation')}
                                                {buildTimeCard('actions')}
                                                {buildTimeCard(
                                                    'autonomous_actions'
                                                )}
                                            </>
                                        ) : null}

                                        {availableCards.invite_manager
                                            ? buildTimeCard('invites')
                                            : null}

                                        {availableCards.telepresence
                                            ? buildTimeCard('telepresence')
                                            : null}

                                        {availableCards.indoor_delivery
                                            ? buildTimeCard('delivery')
                                            : null}

                                        {availableCards.disinfection
                                            ? buildTimeCard('disinfection')
                                            : null}
                                    </>
                                ) : null}
                            </div>
                        </>
                    )}
                </Container>
            </PageContent>
        </>
    );
}

Dashboard.propTypes = {};
