/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportDateChart from '~/components/Reports/ReportDateChart';
import ReportHeader from '~/components/Reports/ReportHeader';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import ReportTimeChart from '~/components/Reports/ReportTimeChart';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetFileName from '~/util/GetFileName';
import GetPeriodDates from '~/util/GetPeriodDates';
import lng_labels from '~/util/LangMessages';

import { TotalContainer, InfoContainer, TableArea } from './style';

const warnings = ['warnings.version.cruzr_v3100', 'warnings.version.temi_v230'];

export default function AutonomousActionsReports({
    settings,
    headerSettings,
    requestError,
    counterData,
    language,
    options,
}) {
    const lng_all = lng_labels[language] || {};

    const {
        fromISO,
        untilISO,
        minDate,
        shortcuts,
        handleFromDateChange,
        handleUntilDateChange,
        handlePeriodShortcut,
    } = headerSettings;

    const { active, colors, format, locale, dateOptions, dark_mode } = settings;

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [rawActionLogData, setRawActionLogData] = useState({});
    const [actionLogList, setActionLogList] = useState([]);
    const [groupedData, setGroupedData] = useState({});

    const headers_file = [
        {
            key: 'created_date',
            label: 'Date',
        },
        {
            key: 'created_time',
            label: 'Time',
        },
        { key: 'group', label: 'Group' },
        { key: 'robot', label: 'Robot' },
        { key: 'robottype', label: 'Robot Type' },
        { key: 'action_label', label: 'Action' },
        { key: 'action_content', label: 'Content' },
    ];

    const headers_actions = [
        {
            id: 'created',
            order_by: 'timestamp',
            label: <LocaleMessage msg="table.headers.date" />,
        },
        { id: 'robot', label: <LocaleMessage msg="table.headers.robot" /> },
        {
            id: 'application',
            label: <LocaleMessage msg="table.headers.application" />,
        },
        {
            id: 'action_label',
            label: <LocaleMessage msg="table.headers.action" />,
            width: '15%',
        },
        {
            id: 'action_content',
            label: <LocaleMessage msg="table.headers.content" />,
            width: '40%',
        },
    ];

    function getActionContent(a) {
        const a_data = a.data || {};

        let a_content = '';

        switch (a.action_type) {
            case 'speak': {
                const lang_label = `${
                    lng_all[`list.languages.${a_data.language}`]
                }`;
                a_content = `${a_data.language ? `[${lang_label}]: ` : ''}${
                    a_data.text
                }`;
                break;
            }
            case 'speak_sequence': {
                const sequence = a_data.sequence || [];
                const seq_texts = sequence.map(s => {
                    const lang_label = `${
                        lng_all[`list.languages.${s.language}`]
                    }`;
                    return `${s.language ? `[${lang_label}]: ` : ''}${s.text}`;
                });
                a_content = seq_texts.join(' | ');
                break;
            }
            case 'run_script': {
                const lang_label = `${
                    lng_all[`list.languages.${a_data.language}`]
                }`;
                const script = a_data.script || {};
                a_content = `[${lang_label}] ${script.name}`;
                break;
            }
            case 'navigate': {
                const map = a_data.map || {};
                const point = a_data.point || {};
                a_content = `${map.name} - ${point.name}`;
                break;
            }
            case 'exit_base': {
                const map = a_data.map || {};
                const point = a_data.point || {};
                const battery_label = lng_all[`rc3.robot.data.card.battery`];
                a_content = `${battery_label}: ${a_data.battery || '--'}% - ${
                    map.name
                } - ${point.name}`;
                break;
            }
            case 'go_base': {
                const battery_label = lng_all[`rc3.robot.data.card.battery`];
                a_content = `${battery_label}: ${a_data.battery || '--'}%`;
                break;
            }
            default:
                break;
        }

        return a_content;
    }

    function filterRawActionData(data) {
        const { robot_types, robots, groups, applications } = counterData;
        const { period, action_logs } = data;

        const a_robots = [];
        const a_r_types = [];
        const a_groups = [];
        const a_applications = [];
        const a_action_types = [];
        const a_date = [];
        const a_time = [];

        const alist = action_logs.list.map(a => {
            const datetime = GetDateTimeLabel(new Date(a.created), { format });

            const group = groups[a.group_id];
            const robot = robots[a.robot_id];
            const r_type = robot_types[a.robottype_id];
            const application = applications[a.application_id];
            const action_name = a.action_type || '';
            const action_label =
                lng_all[
                    `page.tools.autonomous_actions.actions.${a.action_type}`
                ] || '';

            if (!robots[a.robot_id] || robots[a.robot_id].code === '---') {
                robots[a.robot_id] = { code: a.robot_code || '---', name: '' };
            }

            a_groups[a.group_id] = a_groups[a.group_id]
                ? a_groups[a.group_id] + 1
                : 1;

            a_robots[a.robot_id] = a_robots[a.robot_id]
                ? a_robots[a.robot_id] + 1
                : 1;

            a_r_types[a.robottype_id] = a_r_types[a.robottype_id]
                ? a_r_types[a.robottype_id] + 1
                : 1;

            a_applications[a.application_id] = a_applications[a.application_id]
                ? a_applications[a.application_id] + 1
                : 1;

            a_action_types[a.action_type] = a_action_types[a.action_type]
                ? {
                      ...a_action_types[a.action_type],
                      value: a_action_types[a.action_type].value + 1,
                  }
                : {
                      id: a.action_type,
                      action: action_label,
                      name: action_label,
                      value: 1,
                  };
            a_date[datetime.date] = a_date[datetime.date]
                ? a_date[datetime.date] + 1
                : 1;
            a_time[datetime.hour] = a_time[datetime.hour]
                ? a_time[datetime.hour] + 1
                : 1;

            const a_content = getActionContent(a);

            return {
                ...a,
                group: group ? group.name : '---',
                application: application ? application.name : '---',
                robot: robot
                    ? `[${robot.code}] ${robot.name}`
                    : `[${a.robot_code || '---'}]`,
                robottype: r_type ? r_type.name : '---',
                action: action_name || '---',
                action_label,
                action_content: a_content,
                created_date: datetime.date,
                created_time: datetime.time,
                created: datetime.string,
                timestamp: datetime.timestamp,
            };
        });

        const robot_grouped = Object.keys(a_robots).map(r => {
            const val = a_robots[r];
            const obj = robots[r];

            return {
                name: obj ? `[${obj.code}] ${obj.name || ''}` : `---`,
                value: val,
            };
        });

        const rtype_grouped = Object.keys(a_r_types).map(t => {
            const val = a_r_types[t];
            const obj = robot_types[t];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const application_grouped = Object.keys(a_applications).map(a => {
            const val = a_applications[a];
            const obj = applications[a];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const group_grouped = Object.keys(a_groups).map(g => {
            const val = a_groups[g];
            const obj = groups[g];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const action_grouped = Object.keys(a_action_types).map(a => {
            return a_action_types[a] || {};
        });

        const period_dates = GetPeriodDates(period);

        const d_labels = [];
        const date_grouped = [];
        period_dates.forEach(p => {
            const formatted_date = new Date(p).toLocaleDateString(
                format.format,
                dateOptions
            );
            const label_date = new Date(p).toLocaleDateString(format.format, {
                month: '2-digit',
                day: '2-digit',
            });
            d_labels.push(label_date);
            date_grouped.push({
                date: label_date,
                value: a_date[formatted_date] || 0,
            });
        });

        const t_labels = [];
        const time_grouped = [];
        for (let t = 0; t < 24; t++) {
            const label_time = t < 10 ? `0${t}h` : `${t}h`;
            t_labels.push(label_time);
            time_grouped.push({
                hour: label_time,
                value: a_time[t] || 0,
            });
        }

        setGroupedData({
            actions: action_grouped,
            robots: robot_grouped,
            robot_types: rtype_grouped,
            applications: application_grouped,
            groups: group_grouped,
            date: date_grouped,
            time: time_grouped,
            d_labels,
            t_labels,
        });

        setActionLogList(alist);
    }

    async function loadData() {
        setIsLoading(true);
        let query_filters = ``;
        if (options && options.application) {
            query_filters += `&application_id=${options.application}`;
        }
        if (options && options.robot) {
            query_filters += `&robot_id=${options.robot}`;
        }
        await api
            .get(
                `reports/autonomous_actions?from=${fromISO}&until=${untilISO}${query_filters}`
            )
            .then(response => {
                const { data } = response;
                filterRawActionData(data);
                setRawActionLogData(data.action_logs);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        if (mountedRef.current) {
            loadData();
        }
    }, [settings, fromISO, untilISO, locale]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    function CSVButton() {
        return (
            <ExportCSVButton
                data={actionLogList}
                headers={headers_file}
                filename={GetFileName(`REPORT-autonomous_actions`, 'csv')}
            />
        );
    }

    function buildDateChart(title, data, key = 'value') {
        return (
            <ReportDateChart
                title={title}
                color={colors.dashboard_graphs}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildHourChart(title, data, key = 'value') {
        return (
            <ReportTimeChart
                title={title}
                color={colors.dashboard_cards}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      color: colors.dashboard_graphs,
                      data: groupedData.groups,
                  },
              ]
            : []),
        {
            key: 'actions',
            title: 'per_action_type',
            color: colors.dashboard_cards,
            data: groupedData.actions,
        },
        {
            key: 'applications',
            title: 'per_application',
            color: colors.dashboard_graphs,
            data: groupedData.applications,
        },
        {
            key: 'robot_types',
            title: 'per_robot_type',
            color: colors.dashboard_cards,
            data: groupedData.robot_types,
        },
        {
            key: 'robots',
            title: 'per_robot',
            color: colors.dashboard_graphs,
            data: groupedData.robots,
        },
    ];

    return (
        <>
            <ReportHeader
                date_props={{
                    locale,
                }}
                period={{
                    minDate,
                    fromDate: new Date(fromISO),
                    untilDate: new Date(untilISO),
                }}
                handleFromDateChange={handleFromDateChange}
                handleUntilDateChange={handleUntilDateChange}
                reloadData={() => loadData()}
                exportButton={CSVButton()}
                shortcuts={shortcuts}
                handleShortcutClick={_id => handlePeriodShortcut(_id)}
            />
            <div className="sidecard-body mt-3">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {warnings.length > 0 ? (
                            <div className="col-12 mt-3 mb-3">
                                {warnings.map(w => {
                                    return (
                                        <p key={`label_${w}`}>
                                            <LocaleMessage msg={w} />
                                        </p>
                                    );
                                })}
                            </div>
                        ) : null}
                        <TotalContainer className="mt-3 mb-5">
                            <CounterCard
                                classes="col-md-4 col-12"
                                title={
                                    <LocaleMessage msg="page.reports.autonomous_actions.label.triggered" />
                                }
                                value={rawActionLogData.total || '0'}
                            />
                        </TotalContainer>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'value';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.autonomous_actions.label.${c.title}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                        customization={{
                                            stroke: dark_mode
                                                ? '#424242'
                                                : '#ddd',
                                        }}
                                    />
                                );
                            })}
                        </InfoContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.autonomous_actions.label.per_date" />,
                                groupedData.date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.autonomous_actions.label.per_hour" />,
                                groupedData.time
                            )}
                        </div>
                        <Divider />
                        <InfoContainer className="row mt-3 mb-3">
                            <TableArea className="col-12 mb-3 table-responsive-material">
                                <div className="mt-3 mb-3">
                                    <p className="card-title">
                                        <LocaleMessage msg="page.reports.autonomous_actions.label.history" />
                                    </p>
                                </div>
                                <DataTable
                                    maxHeight="100%"
                                    headerColumns={headers_actions}
                                    data={actionLogList || []}
                                    orderDirection="desc"
                                    orderColumn="timestamp"
                                    hasActions={false}
                                    handleTableRowClick={() => {}}
                                    defaultRowsPerPage={25}
                                    sortable
                                />
                            </TableArea>
                        </InfoContainer>
                    </>
                )}
            </div>
        </>
    );
}

AutonomousActionsReports.defaultProps = {
    language: '',
};

AutonomousActionsReports.propTypes = {
    settings: PropTypes.object.isRequired,
    headerSettings: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    counterData: PropTypes.object.isRequired,
    language: PropTypes.string,
};
