import styled from 'styled-components';

export const Container = styled.div`
    min-height: 40vh;
    width: 100%;
    /* background: rgba(0, 0, 0, 0.03); */

    .row {
        padding: 5px;
    }

    .item {
        padding: 5px;
        height: 15vh;
    }

    .card {
        height: 100%;

        .card-body {
            padding: 5px;
            width: 100%;
            height: 100%;
        }

        :hover {
            cursor: pointer;
        }
    }

    .card-title {
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
            padding: 0px;
        }

        .MuiIconButton-root {
            background: rgba(0, 0, 0, 0.42);

            svg {
                color: #fff;
            }
        }
    }
`;

export const Item = styled.div`
    background-color: #000;

    .audio-card {
        color: #eee;
        padding: 15px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .cover-card {
        color: #eee;
        height: 100%;
        padding: 15px 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .file-details {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px;

            svg {
                margin-right: 10px;
            }
        }
    }
`;

export const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: right;

    .delete-checkbox {
        color: ${props => (props.visible ? '#f1f3f4' : '#00000000')} !important;

        .MuiSvgIcon-root {
            background-color: ${props =>
                props.visible ? '#33333333' : '#00000000'} !important;
        }

        :hover {
            color: #f1f3f4 !important;
        }
    }
`;
