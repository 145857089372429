import styled from 'styled-components';

export const Container = styled.div`
    border-top: 5px solid ${props => props.theme.palette.primary.main};
    border-radius: 0px 0px 0.375em 0.375em !important;
    height: 100%;

    .jr-card {
        background: ${props => {
            return props.theme.dark_mode ? '#424242' : '#fff';
        }};
        color: ${props => {
            return props.theme.dark_mode ? '#fff' : '#000';
        }};
    }

    .half-img-title {
        max-width: 100%;
        min-height: 200px;
        max-height: 200px;
        object-fit: cover;
        object-position: center;
        ${props => {
            const color = props.theme.palette.primary.main;
            const use_color = props.imgBackground;

            return use_color ? `background-color: ${color}` : null;
        }};
    }

    .full-img-title {
        max-width: 100%;
        max-height: 250px;
        object-fit: cover;
        object-position: center;
        background: ${props => props.theme.palette.primary.main};
    }

    .img-placeholder {
        max-width: 100%;
        min-height: 200px;
        max-height: 250px;
        object-fit: cover;
        object-position: center;
        background-color: ${props => props.theme.palette.primary.main};
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card-title {
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            padding: 0px;
        }
    }

    .card-subtitle {
        color: ${props => {
            return props.theme.dark_mode ? '#eee' : '#495057';
        }};
    }

    .card-body {
        padding: 20px;
    }

    .card-bottom {
        position: absolute;
        padding: 15px;
        bottom: 0px;
        width: 100%;
        display: flex;
        align-items: center;

        span {
            font-size: 10px;
        }
    }

    .one-item {
        justify-content: flex-end;
    }

    .two-items {
        justify-content: space-between;
    }
`;
