export function switchView(page, view) {
    return {
        type: '@pages/SWITCH_PROP',
        payload: { page, key: 'view', value: view },
    };
}

export function switchProp(page, key, value) {
    return {
        type: '@pages/SWITCH_PROP',
        payload: { page, key, value },
    };
}
