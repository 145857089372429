/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import QnAExamples from '~/components/QnAExamples';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import { ParameterArea } from '../styles';

export default function ForbiddenSubjects({ dialogSettings, handleError }) {
    const { id, language: dialog_lng } = dialogSettings;

    const [forbiddenList, setForbiddenList] = useState([]);

    const [showEditDialog, setShowEditDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    async function loadAgentContents() {
        setIsLoading(true);
        await api
            .get(`dialogs/${id}/qna/forbidden`)
            .then(response => {
                const list = response.data;
                setForbiddenList(list);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadAgentContents();
    }, []);

    async function submitEdit() {
        setIsLoading(true);
        setShowEditDialog(false);
        const data = {
            list: forbiddenList,
        };

        await api
            .put(`dialogs/${id}/qna/forbidden`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });

        loadAgentContents();
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setShowEditDialog(false);
    }

    function renderEditDialog() {
        return (
            <SimpleDialog
                size="sm"
                open={showEditDialog}
                onClose={handleDialogClose}
                title={
                    <>
                        <LocaleMessage msg="page.qna.contents.list.subjects.edit" />
                    </>
                }
                content={<span>CONFIRM LIST UPDATE?</span>}
                actions={[
                    {
                        key: 'cancel',
                        onClick: e => handleDialogClose(e),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => submitEdit(),
                        label: <LocaleMessage msg="button.save" />,
                    },
                ]}
            />
        );
    }

    function renderForbiddenList() {
        return (
            <QnAExamples
                content={{}}
                contentId=""
                language={dialog_lng}
                examples={forbiddenList}
                customAddLabel="page.qna.contents.list.forbidden.add"
                handleError={e => handleError(e)}
                updateExamples={e => {
                    setForbiddenList(e);
                }}
            />
        );
    }

    return (
        <>
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.qna.contents.list.forbidden" />
                </h2>
            </div>
            <div
                className="mb-3"
                style={{
                    width: '100%',
                    padding: '0px 25px',
                }}
            >
                <LocaleMessage msg="page.qna.beta.title" />
            </div>

            <div className="sidecard-body">
                {showEditDialog ? renderEditDialog() : null}
                {isLoading ? (
                    <Splash
                        label={
                            <div className="mb-5">
                                <LocaleMessage msg="page.qna.content.training.loading" />
                            </div>
                        }
                        labelPosition="top"
                    />
                ) : (
                    <div className="col-12">
                        <ParameterArea className="mb-3">
                            {renderForbiddenList()}
                        </ParameterArea>
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={() => setShowEditDialog(true)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                )}
            </div>
        </>
    );
}

ForbiddenSubjects.defaultProps = {};

ForbiddenSubjects.propTypes = {
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
