import styled from 'styled-components';

export const Container = styled.div`
    .jr-card {
        background: ${props => {
            return props.theme.dark_mode ? '#252525' : '#fff';
        }};

        h4 {
            color: ${props => {
                return props.theme.dark_mode ? '#fff' : '#252525';
            }};
        }
    }
    h2 {
        color: ${props => {
            return props.theme.dark_mode ? '#fff' : '#252525';
        }};
    }
`;
