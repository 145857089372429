/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDone, MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core';

import CustomDialogTitle from '~/components/CustomDialogTitle';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

const centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export default function ZoneContact({
    zone,
    sections,
    requestError,
    setContactList,
}) {
    const zone_id = zone && zone.id ? zone.id : null;

    const [operation, setOperation] = useState('create');
    const [isLoading, setIsLoading] = useState(true);
    const [formOpen, setFormOpen] = useState(false);

    const [list_id, setList_id] = useState(true);

    const [body, setBody] = useState({
        zone_id,
        source: 'location_zone',
        name: zone.name,
        description: zone.description,
        create_contacts: false,
        contacts: [],
    });

    async function loadContacts(list) {
        return api
            .get(`contact_lists/${list}/contacts`)
            .then(response => {
                const { list: c_list, contacts } = response.data;
                setList_id(c_list.id);
                setBody({
                    name: c_list.name,
                    description: c_list.description,
                    source: c_list.source,
                });

                setContactList({
                    list: c_list,
                    contacts: contacts.map(c => {
                        return {
                            id: c.id,
                            section_id: c.section ? c.section.id : null,
                        };
                    }),
                });
            })
            .catch(error => requestError(error));
    }

    async function loadZoneContactList(_id) {
        setFormOpen(false);
        const filter = `?source=location_zone&zone=${_id}`;
        await api
            .get(`contact_lists${filter}`)
            .then(async response => {
                const list = response.data;
                const hasContact = !!(list && list[0] && list[0].id);

                setOperation(hasContact ? 'update' : 'create');
                if (!hasContact) {
                    return;
                }

                const c_list = list[0];
                const c_list_id = c_list && c_list.id ? c_list.id : null;

                await loadContacts(c_list_id);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function updateZone(data) {
        await api
            .put(`contact_lists/${list_id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.locations.zone.contact.update_success" />
                );
            })
            .catch(error => requestError(error));
    }

    async function handleSubmit(event) {
        event.preventDefault();

        const contacts = body.create_contacts
            ? sections.map(s => {
                  return {
                      id: s.id,
                      name: s.name,
                  };
              })
            : [];

        const data = { ...body, contacts };

        setIsLoading(true);

        if (operation === 'create') {
            await api
                .post(`contact_lists`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.locations.zone.contact.create_success" />
                    );
                    loadZoneContactList(zone_id);
                })
                .catch(error => requestError(error));
        } else {
            updateZone(data);
        }
        setIsLoading(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setFormOpen(false);
    }

    useEffect(() => {
        if (zone_id) {
            loadZoneContactList(zone_id);
        }
    }, [zone_id]);

    function renderCreateForm() {
        return (
            <>
                <FormSwitch
                    value={body.create_contacts || false}
                    onChange={event =>
                        setBody({
                            ...body,
                            create_contacts: event.target.checked,
                        })
                    }
                    label={
                        <LocaleMessage msg="page.locations.zone.contact.label.add_sections" />
                    }
                />
            </>
        );
    }

    function renderFormDialog() {
        return (
            <Dialog
                open={formOpen}
                onClose={handleDialogClose}
                maxWidth="sm"
                fullWidth
            >
                <CustomDialogTitle title="Lista de Contatos" />
                <DialogContent>
                    {operation === 'create' ? renderCreateForm() : null}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={e => handleSubmit(e)}
                        color="primary"
                        autoFocus
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <>
            {formOpen ? renderFormDialog() : null}
            {isLoading ? (
                <Splash />
            ) : (
                <>
                    <div>
                        <div className="row mb-3" style={centerStyle}>
                            <div className="col-1">
                                {operation === 'create' ? (
                                    <MdClose size={20} />
                                ) : (
                                    <MdDone size={20} />
                                )}
                            </div>
                            <div className="col-11">
                                <LocaleMessage
                                    msg={
                                        operation === 'create'
                                            ? 'page.locations.zone.contact.label.not_set'
                                            : 'page.locations.zone.contact.label.already_set'
                                    }
                                />
                            </div>
                        </div>
                        {operation === 'create' ? (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setFormOpen(true)}
                                fullWidth
                            >
                                <LocaleMessage
                                    msg={
                                        operation === 'create'
                                            ? 'page.locations.zone.contact.label.set_up'
                                            : 'page.locations.zone.contact.label.edit'
                                    }
                                />
                            </Button>
                        ) : null}
                    </div>
                </>
            )}
        </>
    );
}

ZoneContact.propTypes = {
    zone: PropTypes.object.isRequired,
    setContactList: PropTypes.func.isRequired,
    requestError: PropTypes.func.isRequired,
    sections: PropTypes.array,
};

ZoneContact.defaultProps = {
    sections: [],
};
