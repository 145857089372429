/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import getOrderedList from '~/util/OrderList';

export default function FormSelect({
    classes,
    label,
    value,
    onChange,
    options,
    optionKey,
    labelKey,
    orderKey,
    disabled,
    objOption,
    defaultValue,
    helper,
}) {
    const opt_value = value
        ? options.find(o => {
              if (objOption) {
                  return o[optionKey] && o[optionKey] === value;
              }
              return o === value;
          })
        : null;

    const ordered_list = orderKey
        ? getOrderedList(options, objOption, orderKey, 'asc')
        : options;

    return (
        <Autocomplete
            className={classes}
            options={ordered_list}
            getOptionLabel={u => (objOption ? u[labelKey] : u) || ''}
            onChange={(event, u) => {
                if (objOption) {
                    onChange(u ? u[optionKey] : defaultValue);
                } else {
                    onChange(u || defaultValue);
                }
            }}
            value={opt_value || ''}
            autoComplete={false}
            disabled={disabled}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                    helperText={helper || undefined}
                />
            )}
        />
    );
}

FormSelect.defaultProps = {
    classes: '',
    label: '',
    value: '',
    options: [],
    optionKey: 'id',
    labelKey: 'name',
    orderKey: '',
    disabled: false,
    objOption: true,
    defaultValue: '',
    helper: null,
};

FormSelect.propTypes = {
    classes: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array,
    optionKey: PropTypes.string,
    labelKey: PropTypes.string,
    orderKey: PropTypes.string,
    disabled: PropTypes.bool,
    objOption: PropTypes.bool,
    defaultValue: PropTypes.string,
    helper: PropTypes.element,
};
