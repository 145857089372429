/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { MdFactCheck, MdArticle, MdAdd, MdDelete } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    DialogContentText,
    TextField,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import FormCheckList from '~/components/Form/CheckList';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import {
    ParameterArea,
    TextParameter,
    EditableHeader,
    IconParameter,
} from './styles';

export default function PresentationContentForm(props) {
    const dispatch = useDispatch();

    const { match } = props;
    const { id } = match.params;

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);
    const [operation, setOperation] = useState('create');
    const [languages, setLanguages] = useState([]);
    const [currItem, setCurrItem] = useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const [body, setBody] = useState({
        name: '',
    });

    const theme = useTheme();

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadLanguages() {
        await api
            .get(`applications/voices`)
            .then(response => {
                const v = response.data;
                const lang_list = [];
                Object.keys(v).forEach(t => {
                    const t_voices = v[t];
                    t_voices.forEach(voice => {
                        lang_list.push({
                            type: t,
                            key: `${t}_${voice.value}`,
                            ...voice,
                        });
                    });
                });
                setLanguages(lang_list);
            })
            .catch(err => requestError(err));
    }

    async function loadContent(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`app_contents/${_id}`)
                .then(response => {
                    const c = response.data;
                    const content = c.content || {};
                    setBody({
                        name: c.name,
                        description: c.description,
                        content,
                    });
                })
                .catch(err => requestError(err));
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadLanguages();
        loadContent(id);
    }, []);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = {
            ...body,
            app_type: 'conversation',
            type: 'presentation',
        };

        if (operation === 'create') {
            await api
                .post(`app_contents`, data)
                .then(async () => {
                    toast.success(
                        <LocaleMessage msg="page.applications.conversation.content.message.created" />
                    );
                    history.push(`/tools/presentation_contents`);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`app_contents/${id}`, data)
                .then(async () => {
                    toast.success(
                        <LocaleMessage msg="page.applications.conversation.content.message.updated" />
                    );
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    function onDragEnd(result) {
        if (!result.destination) return;
        const { source, destination } = result;
        const content = body.content || {};
        const items = content.texts || [];
        const [removed] = items.splice(source.index, 1);
        items.splice(destination.index, 0, removed);
        setBody({
            ...body,
            content: {
                ...content,
                texts: items,
            },
        });
    }

    function removeMessage(idx) {
        setDeleteDialogOpen(false);
        const content = body.content || {};
        const items = content.texts || [];

        if (!items[idx]) {
            return;
        }
        items.splice(idx, 1);
        setBody({
            ...body,
            content: {
                ...content,
                texts: items,
            },
        });
    }

    function editMessage(idx, key, value) {
        const content = body.content || {};
        const items = content.texts || [];

        if (items[idx]) {
            items[idx] = {
                ...items[idx],
                [key]: value,
            };
        }
        setBody({
            ...body,
            content: {
                ...content,
                texts: items,
            },
        });
    }

    function handleNewMessage() {
        const content = body.content || {};
        const items = content.texts || [];

        const item_id = (+new Date()).toString(36);
        items.push({ item_id });
        setBody({
            ...body,
            content: {
                ...content,
                texts: items,
            },
        });
    }

    function renderText(item, idx) {
        const content = body.content || {};
        const c_languages =
            content && content.languages && content.languages.list
                ? content.languages.list
                : [];
        return (
            <Accordion
                className="mb-3"
                style={{
                    borderLeft: `5px solid ${
                        theme.palette.pluginspace
                            ? theme.palette.pluginspace.primary
                            : '#000'
                    }`,
                }}
            >
                <AccordionSummary>
                    <EditableHeader>
                        <div className="col-md-5 col-11">
                            <TextField
                                label={
                                    <LocaleMessage msg="page.tools.presentation_contents.form.messages.tag" />
                                }
                                size="small"
                                fullWidth
                                multiline
                                value={item.tag || ''}
                                onChange={e => {
                                    editMessage(idx, 'tag', e.target.value);
                                }}
                            />
                        </div>
                        <IconParameter className="col-1 mx-0">
                            <MdDelete
                                size={18}
                                onClick={() => {
                                    setCurrItem(idx);
                                    setDeleteDialogOpen(true);
                                }}
                            />
                        </IconParameter>
                    </EditableHeader>
                </AccordionSummary>
                <AccordionDetails>
                    <TextParameter className="text-content">
                        {c_languages.map(l => {
                            const l_label = `list.languages.${l}`;
                            return (
                                <TextField
                                    key={`${idx}_${l}`}
                                    className="mb-3"
                                    label={<LocaleMessage msg={l_label} />}
                                    size="small"
                                    fullWidth
                                    multiline
                                    value={item[l] || ''}
                                    onChange={e => {
                                        editMessage(idx, l, e.target.value);
                                    }}
                                />
                            );
                        })}
                    </TextParameter>
                </AccordionDetails>
            </Accordion>
        );
    }

    function renderMessagesArea() {
        const content = body.content || {};
        const items = content.texts || [];

        return (
            <>
                <div className="col-12 mb-3">
                    <div
                        className="col-12 mb-3"
                        style={{ textAlign: 'center' }}
                    >
                        <LocaleMessage msg="label.reorder" />
                    </div>
                    <ParameterArea className="row mt-1 mb-3">
                        <DragDropContext
                            onDragEnd={result => onDragEnd(result)}
                        >
                            <Droppable droppableId="condition-list">
                                {(drop_provided, drop_snapshot) => {
                                    return (
                                        <div
                                            className="p-3"
                                            {...drop_provided.droppableProps}
                                            ref={drop_provided.innerRef}
                                            style={{
                                                background: drop_snapshot.isDraggingOver
                                                    ? 'darkgrey'
                                                    : 'lightgrey',
                                                width: '100%',
                                            }}
                                        >
                                            {items.map((item, idx) => {
                                                return (
                                                    <Draggable
                                                        key={item.item_id}
                                                        draggableId={
                                                            item.item_id
                                                        }
                                                        index={idx}
                                                    >
                                                        {drag_provided => {
                                                            return (
                                                                <div
                                                                    ref={
                                                                        drag_provided.innerRef
                                                                    }
                                                                    {...drag_provided.draggableProps}
                                                                    {...drag_provided.dragHandleProps}
                                                                    style={{
                                                                        userSelect:
                                                                            'none',
                                                                        ...drag_provided
                                                                            .draggableProps
                                                                            .style,
                                                                    }}
                                                                >
                                                                    {renderText(
                                                                        item,
                                                                        idx
                                                                    )}
                                                                </div>
                                                            );
                                                        }}
                                                    </Draggable>
                                                );
                                            })}
                                            {drop_provided.placeholder}
                                        </div>
                                    );
                                }}
                            </Droppable>
                        </DragDropContext>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => handleNewMessage()}
                            startIcon={
                                <MdAdd
                                    style={{
                                        color: '#fff',
                                    }}
                                />
                            }
                        >
                            <LocaleMessage msg="page.tools.presentation_contents.form.messages.add" />
                        </Button>
                    </ParameterArea>
                </div>
            </>
        );
    }

    function renderLanguages() {
        const options = languages.map(l => {
            return {
                id: l.value,
                name: <LocaleMessage msg={`list.languages.${l.value}`} />,
            };
        });

        const content = body.content || {};

        return (
            <div>
                <LocaleMessage msg="page.tools.presentation_contents.form.language.list" />
                <br />
                <FormCheckList
                    settings={content.languages || {}}
                    options={options}
                    multiple
                    onChange={l => {
                        setBody({
                            ...body,
                            content: {
                                ...content,
                                languages: l,
                            },
                        });
                    }}
                />
            </div>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
                title={
                    <LocaleMessage msg="page.tools.presentation_contents.form.messages.delete.title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.need_save.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => removeMessage(currItem),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.tools.presentation_contents.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.tools.presentation_contents.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
                {
                    url: '/tools/presentation_contents',
                    title: (
                        <LocaleMessage msg="breadcrumbs.presentation_contents" />
                    ),
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div
                        className={`col-md-${
                            operation === 'create' ? '8' : '6'
                        } col-12 mb-5`}
                    >
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="breadcrumbs.presentation_contents" />
                            }
                            Icon={MdFactCheck}
                        >
                            <>
                                <div className="row">
                                    <div className="col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.tools.presentation_contents.form.label.name" />
                                            }
                                            value={body.name ? body.name : ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.tools.presentation_contents.form.label.description" />
                                            }
                                            value={
                                                body.description
                                                    ? body.description
                                                    : ''
                                            }
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    description:
                                                        event.target.value,
                                                })
                                            }
                                            fullWidth
                                        />
                                    </div>
                                    <div className="col-12 mb-5">
                                        {renderLanguages()}
                                    </div>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>

                    {operation === 'update' ? (
                        <div className="col-md-6 col-12 mb-5">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.tools.presentation_contents.form.messages.list" />
                                }
                                Icon={MdArticle}
                                hide
                            >
                                {renderMessagesArea()}
                            </CardSideBordered>
                        </div>
                    ) : null}

                    <div
                        className={`col-md-${
                            operation === 'create' ? '8' : '12'
                        } col-12 mb-5`}
                    >
                        <Button
                            className="p-3"
                            variant="contained"
                            color="primary"
                            onClick={event => handleSubmit(event)}
                            fullWidth
                            size="large"
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </form>
            </>
        </PageContent>
    );
}

PresentationContentForm.propTypes = {
    match: PropTypes.object.isRequired,
};
