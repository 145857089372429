/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

import {
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { ParameterArea } from '../styles';

export default function OnStartActionSetup({ settings, onChange, languages }) {
    const action_settings = settings.data || {};

    const exit_actions = {
        no_action: {
            label: (
                <LocaleMessage msg="page.applications.conversation.triggers.identifiers.actions.no_action" />
            ),
            defaults: {},
        },
        speak_message: {
            label: (
                <LocaleMessage msg="page.applications.conversation.triggers.identifiers.actions.speak_message" />
            ),
            defaults: {},
        },
    };

    function handleUpdateActionData(key, value) {
        onChange({
            ...settings,
            data: {
                ...action_settings,
                [key]: value,
            },
        });
    }

    function handleActionSelection(event) {
        const action = event.target.value;

        onChange({
            ...settings,
            action,
            data: action !== settings.action ? {} : settings.data,
        });
    }

    function buildLanguageInput(lang) {
        const messages =
            action_settings && action_settings.messages
                ? action_settings.messages
                : {};
        return (
            <div className="col-12 row mb-3" key={`starter_${lang}`}>
                <TextField
                    id={`${lang}_starter`}
                    label={<LocaleMessage msg={`list.languages.${lang}`} />}
                    fullWidth
                    value={messages[lang] || ''}
                    onChange={event =>
                        handleUpdateActionData('messages', {
                            ...messages,
                            [lang]: event.target.value,
                        })
                    }
                />
            </div>
        );
    }

    function renderSpeakMessageSettings() {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <ParameterArea className="col-12 mb-5 p-4">
                    {(languages.list || []).map(l => {
                        return buildLanguageInput(l);
                    })}
                </ParameterArea>
            </div>
        );
    }

    function renderActionSettings() {
        const action = settings && settings.action ? settings.action : '';
        const settings_renderer = {
            no_action: null,
            speak_message: renderSpeakMessageSettings(),
        };

        return settings_renderer[action] || null;
    }

    return (
        <div className="row">
            <ParameterArea className="col-12 pt-5 mt-3 mb-3">
                <div className="col-12">
                    <div className="col-md-4 col-12 mb-5">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.applications.conversation.triggers.identifiers.on_start.label" />
                            </InputLabel>
                            <Select
                                value={
                                    settings.action
                                        ? settings.action
                                        : 'no_action'
                                }
                                onChange={event => handleActionSelection(event)}
                            >
                                {Object.keys(exit_actions).map(m => {
                                    const action = exit_actions[m];
                                    return (
                                        <MenuItem value={m} key={m}>
                                            {action.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="col-12">{renderActionSettings()}</div>
            </ParameterArea>
        </div>
    );
}

OnStartActionSetup.defaultProps = {
    languages: {},
};

OnStartActionSetup.propTypes = {
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    languages: PropTypes.object,
};
