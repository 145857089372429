/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tab,
    Tabs,
} from '@material-ui/core';

import FormCheckList from '~/components/Form/CheckList';
import MultipleInputs from '~/components/Form/MultipleInputs';
import LocaleMessage from '~/components/LocaleMessage';

import { IconParameter, ParameterArea } from '../styles';
import QnATrigger from './QnATrigger';

const styleCenter = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
};

export default function QnANodeAction({
    config,
    saveConfig,
    answerLanguages,
    dialogSettings,
    handleError,
}) {
    const { language: dialogLanguage } = dialogSettings;

    const [show, setShow] = useState(true);
    const [currentTab, setCurrentTab] = useState('main');

    function setLangSettings(l, key, val) {
        const body_settings = config.settings || {};
        const lng_settings = body_settings[l] ? body_settings[l] : {};
        saveConfig({
            ...config,
            settings: {
                ...body_settings,
                [l]: {
                    ...lng_settings,
                    [key]: val,
                },
            },
        });
    }

    function handleLanguageCheckbox(langs, list) {
        const body_settings = config.settings || {};
        const new_settings = body_settings;

        const checked = {};
        list.forEach(l => {
            checked[l] = true;
        });

        langs.forEach(l => {
            const lng_settings = body_settings[l]
                ? body_settings[l]
                : {
                      ...(l !== dialogLanguage && {
                          auto_translate: false,
                      }),
                  };

            new_settings[l] = {
                ...lng_settings,
                auto_translate: !(checked[l] || false),
            };
        });

        saveConfig({
            ...config,
            settings: new_settings,
        });
    }

    function renderGenAIContent() {
        const body_settings = config.settings || {};

        return (
            <>
                <ParameterArea className="col-12 row mt-1 py-1">
                    <div
                        className="col-12 row"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <TextField
                            label={
                                <LocaleMessage msg="page.qna.nodes.form.answer.gen_ai.suggestion" />
                            }
                            className="col-12"
                            fullWidth
                            multiline
                            value={body_settings.content || ''}
                            onChange={event => {
                                saveConfig({
                                    ...config,
                                    settings: {
                                        ...body_settings,
                                        content: event.target.value,
                                    },
                                });
                            }}
                        />
                    </div>
                </ParameterArea>
            </>
        );
    }

    function renderLanguageTexts(language, texts) {
        const lang_label = `list.languages.${language}`;

        return (
            <div
                className="col-12 row px-0"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="col-12 mb-0">
                    <MultipleInputs
                        margin="mb-1"
                        language={language}
                        label={lang_label}
                        texts={texts}
                        open
                        onChange={t => {
                            setLangSettings(language, 'texts', t);
                        }}
                        // allowSuggestions={non_empty.length >= 1}
                        // suggestItems={() => {
                        //     requestSuggestions();
                        // }}
                    />
                </div>
            </div>
        );
    }

    function renderLanguage(body_settings, language) {
        const lng_settings = body_settings[language]
            ? body_settings[language]
            : {};
        const lng_texts = lng_settings.texts || [''];

        return renderLanguageTexts(language, lng_texts);
    }

    function renderOtherLanguageTexts(body_settings) {
        const other_langs = answerLanguages.filter(l => {
            return l !== dialogLanguage;
        });

        const langs = other_langs.map(l => {
            return {
                value: l,
                label: <LocaleMessage msg={`list.languages.${l}`} />,
            };
        });

        const lang_list = other_langs.filter(l => {
            const lng_settings = body_settings[l] ? body_settings[l] : {};
            return !lng_settings.auto_translate;
        });

        const show_texts = lang_list.length > 0;

        return (
            <div className="col-12 row px-0" style={styleCenter}>
                <div className="col-12 mt-3 text-center">
                    <LocaleMessage msg="page.qna.nodes.form.answer.translate.exception" />
                </div>
                <div
                    className="col-12 mt-3 px-0 text-center"
                    style={{
                        display: 'flex',
                        alignContent: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        className={`${
                            show_texts ? 'col-md-4' : 'col-md-5'
                        } col-12 mb-3 px-0`}
                        style={{ styleCenter }}
                    >
                        <FormCheckList
                            settings={{ list: lang_list }}
                            options={langs}
                            optionKey="value"
                            primaryLabelKey="label"
                            multiple
                            onChange={l => {
                                handleLanguageCheckbox(other_langs, l.list);
                            }}
                        />
                    </div>
                    {show_texts > 0 ? (
                        <div className="col-md-8 col-12 mb-3 px-0">
                            <div className="col-12 mt-3 text-center">
                                <LocaleMessage msg="page.qna.nodes.form.action.text" />
                            </div>
                            {lang_list.map(l => {
                                return (
                                    <div key={`lng_texts_${l}`}>
                                        {renderLanguage(body_settings, l)}
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </div>

                {/* <div className="col-md-4 col-12 mb-3">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.qna.nodes.form.answer.language" />
                            </InputLabel>
                            <Select
                                value={currentLanguage || false}
                                onChange={event =>
                                    setCurrentLanguage(event.target.value)
                                }
                            >
                                {other_langs.map(l => (
                                    <MenuItem value={l} key={`ans_lng_${l}`}>
                                        <LocaleMessage
                                            msg={`list.languages.${l}`}
                                        />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-8 col-12">
                        <FormSwitch
                            value={other_lng_settings.auto_translate || false}
                            onChange={event =>
                                setLangSettings(
                                    currentLanguage,
                                    'auto_translate',
                                    event.target.checked
                                )
                            }
                            label={
                                <LocaleMessage msg="page.qna.nodes.form.answer.translate" />
                            }
                        />
                    </div>
                </div>
                {!other_lng_settings.auto_translate
                    ? renderLanguageTexts(currentLanguage, lang_texts)
                    : null} */}
            </div>
        );
    }

    function renderMainLanguage(body_settings) {
        return (
            <div className="text-center mt-3">
                <strong
                    className="col-12 mt-3 mb-3"
                    style={{ textAlign: 'center' }}
                >
                    <LocaleMessage msg="page.qna.nodes.form.answer.text.random" />
                </strong>
                {renderLanguage(body_settings, dialogLanguage)}
            </div>
        );
    }

    function renderTextAnswer() {
        const body_settings = config.settings || {};
        return (
            <>
                <div className="col-12 row mt-1 py-1 px-1">
                    <ParameterArea className="col-12 pb-1">
                        <Tabs
                            indicatorColor="primary"
                            orientation="horizontal"
                            value={currentTab || 'main'}
                            onChange={(e, v) => {
                                setCurrentTab(v);
                            }}
                            centered
                        >
                            <Tab
                                value="main"
                                label={
                                    <LocaleMessage msg="page.qna.nodes.form.answer.language.main" />
                                }
                            />
                            <Tab
                                value="langs"
                                label={
                                    <LocaleMessage msg="page.qna.nodes.form.answer.language.other" />
                                }
                            />
                        </Tabs>

                        <div className="pt-3" style={{ flex: 1 }}>
                            {currentTab === 'main'
                                ? renderMainLanguage(body_settings)
                                : renderOtherLanguageTexts(body_settings)}
                        </div>
                    </ParameterArea>
                </div>
            </>
        );
    }

    function renderActionTrigger() {
        return (
            <QnATrigger
                dialogSettings={dialogSettings}
                config={config.trigger || {}}
                onChange={t =>
                    saveConfig({
                        ...config,
                        trigger: t,
                    })
                }
                handleError={e => handleError(e)}
            />
        );
    }

    return (
        <>
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <IconParameter className="col-1">
                    {show ? (
                        <MdExpandLess
                            size={22}
                            onClick={() => setShow(false)}
                        />
                    ) : (
                        <MdExpandMore size={22} onClick={() => setShow(true)} />
                    )}
                </IconParameter>
                {!show ? (
                    <strong>
                        <LocaleMessage msg="page.qna.nodes.form.action" />
                    </strong>
                ) : (
                    <div className="col-md-4 col-12">
                        <FormControl fullWidth className="mb-3">
                            <InputLabel>
                                <LocaleMessage msg="page.qna.nodes.form.action" />
                            </InputLabel>
                            <Select
                                value={config.type || 'text'}
                                onChange={event =>
                                    saveConfig({
                                        ...config,
                                        type: event.target.value,
                                    })
                                }
                            >
                                {config.condition_type === 'decision' ? (
                                    <MenuItem value="search_answer">
                                        <LocaleMessage msg="page.qna.nodes.form.action.search_answer" />
                                    </MenuItem>
                                ) : null}
                                <MenuItem value="generative_ai">
                                    <LocaleMessage msg="page.qna.nodes.form.action.generative_ai" />
                                </MenuItem>
                                <MenuItem value="text">
                                    <LocaleMessage msg="page.qna.nodes.form.action.text" />
                                </MenuItem>
                                <MenuItem value="action">
                                    <LocaleMessage msg="page.qna.nodes.form.action.trigger" />
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                )}
                <IconParameter className="col-1" />
            </div>
            {show ? (
                <div className="col-12" style={styleCenter}>
                    {!config.type || config.type === 'generative_ai'
                        ? renderGenAIContent()
                        : null}

                    {config.type === 'text' ? renderTextAnswer() : null}

                    {config.type === 'action' ? renderActionTrigger() : null}
                </div>
            ) : null}
        </>
    );
}

QnANodeAction.defaultProps = {
    config: {},
    answerLanguages: [],
};

QnANodeAction.propTypes = {
    config: PropTypes.object,
    saveConfig: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
    answerLanguages: PropTypes.array,
    handleError: PropTypes.func.isRequired,
};
