/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportDateChart from '~/components/Reports/ReportDateChart';
import ReportHeader from '~/components/Reports/ReportHeader';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import ReportTimeChart from '~/components/Reports/ReportTimeChart';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetFileName from '~/util/GetFileName';
import GetOperationTime from '~/util/GetOperationTime';
import GetPeriodDates from '~/util/GetPeriodDates';

import { TotalContainer, ChartsContainer, RowArea } from '../style';

export default function InviteReports({
    settings,
    headerSettings,
    requestError,
    counterData,
    options,
}) {
    const {
        fromISO,
        untilISO,
        minDate,
        shortcuts,
        handleFromDateChange,
        handleUntilDateChange,
        handlePeriodShortcut,
    } = headerSettings;

    const { active, colors, format, locale, dateOptions, dark_mode } = settings;

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(false);
    const [rawData, setRawData] = useState({});
    const [list, setList] = useState([]);
    const [groupedData, setGroupedData] = useState({});
    const [subTables, setSubTables] = useState({});

    const headers_table = [
        {
            id: 'created',
            order_by: 'created_timestamp',
            label: <LocaleMessage msg="table.headers.created" />,
        },
        {
            id: 'created_name',
            label: <LocaleMessage msg="table.headers.invited_by" />,
        },
        {
            id: 'robot_name',
            label: <LocaleMessage msg="table.headers.robot" />,
        },
        {
            id: 'application',
            label: <LocaleMessage msg="table.headers.application" />,
        },
        {
            id: 'access_period',
            order_by: 'start_timestamp',
            label: <LocaleMessage msg="table.headers.available_period" />,
        },
        {
            id: 'destinations',
            label: <LocaleMessage msg="table.headers.sent_to" />,
        },
        {
            id: 'views_length',
            label: <LocaleMessage msg="table.headers.accesses" />,
        },
        {
            id: 'revoked_name',
            label: <LocaleMessage msg="table.headers.revoked_by" />,
        },
        {
            id: 'revoked',
            order_by: 'revoked_timestamp',
            label: <LocaleMessage msg="table.headers.revoked" />,
        },
    ];

    const headers_file = {
        main: [
            { key: 'group', label: 'Group' },
            { key: 'robot_name', label: 'Robot' },
            { key: 'type', label: 'Robot Type' },
            { key: 'application', label: 'Application' },
            {
                key: 'created_date',
                label: 'Created Date',
            },
            {
                key: 'created_time',
                label: 'Created Time',
            },
            {
                key: 'created_name',
                label: 'Created By',
            },
            {
                key: 'start_date',
                label: 'Start Date',
            },
            {
                key: 'start_time',
                label: 'Start Time',
            },
            {
                key: 'duration',
                label: 'Duration',
            },
            {
                key: 'revoked_date',
                label: 'Revoked Date',
            },
            {
                key: 'revoked_time',
                label: 'Revoked Time',
            },
            {
                key: 'revoked_name',
                label: 'Revoked By',
            },
            {
                key: 'destinations',
                label: 'Sent To',
            },
            { key: 'views_length', label: 'Accesses' },
        ],
        sub: [
            { key: 'start_date', label: 'Accessed Date' },
            { key: 'start_time', label: 'Accessed Time' },
            { key: 'identification_type', label: 'Identification Type' },
            { key: 'identification', label: 'Identification' },
        ],
    };

    const headers_sub = [
        {
            id: 'start',
            order_by: 'start_timestamp',
            label: <LocaleMessage msg="table.headers.start_date" />,
        },
        {
            id: 'identification_type',
            label: <LocaleMessage msg="table.headers.identification_type" />,
        },
        {
            id: 'identification',
            label: <LocaleMessage msg="table.headers.identification" />,
        },
    ];

    function filterData(data) {
        const {
            robot_types,
            robots,
            groups,
            users,
            applications,
        } = counterData;

        const { period, invites } = data;

        const i_robots = [];
        const i_r_types = [];
        const i_users = [];
        const i_groups = [];
        const i_applications = [];
        const i_c_date = [];
        const i_c_time = [];
        const i_r_date = [];
        const i_r_time = [];
        const i_s_date = [];
        const i_s_time = [];
        let duration_sum = 0;

        const ilist = invites.list.map(i => {
            duration_sum += i.duration;

            const c_datetime = GetDateTimeLabel(new Date(i.created), {
                format,
            });
            const r_datetime = GetDateTimeLabel(new Date(i.revoked_at), {
                format,
            });
            const s_datetime = GetDateTimeLabel(new Date(i.start), { format });
            const e_datetime = GetDateTimeLabel(new Date(i.end), { format });

            const duration_obj = GetOperationTime(i.duration * 60);

            const robot = robots[i.robot_id];
            const type = robot_types[i.robottype_id];
            const group = groups[i.group_id];
            const application = applications[i.application_id];

            if (!robots[i.robot_id]) {
                robots[i.robot_id] = { code: i.robot_code || '---', name: '' };
            }

            i_robots[i.robot_id] = i_robots[i.robot_id]
                ? i_robots[i.robot_id] + 1
                : 1;

            i_r_types[i.robottype_id] = i_r_types[i.robottype_id]
                ? i_r_types[i.robottype_id] + 1
                : 1;

            i_groups[i.group_id] = i_groups[i.group_id]
                ? i_groups[i.group_id] + 1
                : 1;

            i_users[i.created_by.id] = i_users[i.created_by.id]
                ? i_users[i.created_by.id] + 1
                : 1;

            i_applications[i.application_id] = i_applications[i.application_id]
                ? i_applications[i.application_id] + 1
                : 1;

            i_c_date[c_datetime.date] = i_c_date[c_datetime.date]
                ? i_c_date[c_datetime.date] + 1
                : 1;
            i_c_time[c_datetime.hour] = i_c_time[c_datetime.hour]
                ? i_c_time[c_datetime.hour] + 1
                : 1;

            if (!i.active) {
                i_r_date[r_datetime.date] = i_r_date[r_datetime.date]
                    ? i_r_date[r_datetime.date] + 1
                    : 1;
                i_r_time[r_datetime.hour] = i_r_time[r_datetime.hour]
                    ? i_r_time[r_datetime.hour] + 1
                    : 1;
            }

            i_s_date[s_datetime.date] = i_s_date[s_datetime.date]
                ? i_s_date[s_datetime.date] + 1
                : 1;
            i_s_time[s_datetime.hour] = i_s_time[s_datetime.hour]
                ? i_s_time[s_datetime.hour] + 1
                : 1;

            const identification = i.identification ? i.identification : {};

            const ident_formatter_name = identification.formatter_name
                ? identification.formatter_name.toUpperCase()
                : '---';

            const views = i.views
                ? i.views.map(v => {
                      const v_datetime = GetDateTimeLabel(new Date(v.start), {
                          format,
                      });
                      const v_identification = v.identification || '';

                      return {
                          id: v_datetime.timestamp,
                          start: v_datetime.string,
                          start_date: v_datetime.date,
                          start_time: v_datetime.time,
                          identification_type: ident_formatter_name,
                          identification: v_identification,
                      };
                  })
                : [];

            const sent_to = i.sent_to ? i.sent_to : {};
            const destinations = Object.keys(sent_to).map(c => {
                return sent_to[c];
            });
            const destinations_str = destinations.join(', ');

            const start_date = s_datetime.date;
            const start_time = s_datetime.time;
            const end_date = e_datetime.date;
            const end_time = e_datetime.time;
            const access_period =
                start_date === end_date
                    ? `${start_date}: ${start_time} - ${end_time}`
                    : `${start_date}, ${start_time} - ${end_date},${end_time})`;

            return {
                ...i,
                group: group ? group.name : '---',
                robot_name: robot
                    ? `[${robot.code}] ${robot.name}`
                    : `[${i.robot_code || '---'}]`,
                type: type ? type.name : '---',
                application: application ? application.name : '---',
                created_date: c_datetime.date,
                created_time: c_datetime.time,
                created: c_datetime.string,
                created_name: i.created_by ? i.created_by.name : '---',
                created_timestamp: c_datetime.timestamp,
                revoked_date: !i.active ? r_datetime.date : '',
                revoked_time: !i.active ? r_datetime.time : '',
                revoked: !i.active ? r_datetime.string : '',
                revoked_name:
                    !i.active && i.revoked_by ? i.revoked_by.name : '',
                revoked_timestamp: r_datetime.timestamp,
                start_date: s_datetime.date,
                start_time: s_datetime.time,
                start: s_datetime.string,
                start_timestamp: s_datetime.timestamp,
                duration: duration_obj.label,
                access_period,
                views_length: views.length,
                views,
                destinations: destinations_str,
            };
        });

        const robot_grouped = Object.keys(i_robots).map(r => {
            const val = i_robots[r];
            const obj = robots[r];

            return {
                name: obj ? `[${obj.code}] ${obj.name || ''}` : `---`,
                value: val,
            };
        });

        const type_grouped = Object.keys(i_r_types).map(t => {
            const val = i_r_types[t];
            const obj = robot_types[t];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const user_grouped = Object.keys(i_users).map(u => {
            const val = i_users[u];
            const obj = users[u];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const group_grouped = Object.keys(i_groups).map(g => {
            const val = i_groups[g];
            const obj = groups[g];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const application_grouped = Object.keys(i_applications).map(a => {
            const val = i_applications[a];
            const obj = applications[a];

            return {
                name: obj ? obj.name : '---',
                value: val,
            };
        });

        const period_dates = GetPeriodDates(period);

        const d_labels = [];
        const c_date_grouped = [];
        const r_date_grouped = [];
        const s_date_grouped = [];

        period_dates.forEach(p => {
            const formatted_date = new Date(p).toLocaleDateString(
                format.format,
                dateOptions
            );
            const label_date = new Date(p).toLocaleDateString(format.format, {
                month: '2-digit',
                day: '2-digit',
            });
            d_labels.push(label_date);

            c_date_grouped.push({
                date: label_date,
                value: i_c_date[formatted_date] || 0,
            });

            r_date_grouped.push({
                date: label_date,
                value: i_r_date[formatted_date] || 0,
            });

            s_date_grouped.push({
                date: label_date,
                value: i_s_date[formatted_date] || 0,
            });
        });

        const t_labels = [];
        const c_time_grouped = [];
        const r_time_grouped = [];
        const s_time_grouped = [];

        for (let t = 0; t < 24; t++) {
            const label_time = `${t}h`;
            t_labels.push(label_time);

            c_time_grouped.push({
                hour: label_time,
                value: i_c_time[t] || 0,
            });

            r_time_grouped.push({
                hour: label_time,
                value: i_r_time[t] || 0,
            });

            s_time_grouped.push({
                hour: label_time,
                value: i_s_time[t] || 0,
            });
        }

        const mean_duration = (
            Math.round((duration_sum / ilist.length) * 10) / 10 || 0
        ).toFixed(1);

        setGroupedData({
            mean_duration,
            robots: robot_grouped,
            robot_types: type_grouped,
            groups: group_grouped,
            users: user_grouped,
            applications: application_grouped,
            created_date: c_date_grouped,
            created_time: c_time_grouped,
            revoked_date: r_date_grouped,
            revoked_time: r_time_grouped,
            start_date: s_date_grouped,
            start_time: s_time_grouped,
            d_labels,
            t_labels,
        });

        setList(ilist);
    }

    async function loadData() {
        setIsLoading(true);
        let query_filters = ``;
        if (options && options.application) {
            query_filters += `&application_id=${options.application}`;
        }
        if (options && options.robot) {
            query_filters += `&robot_id=${options.robot}`;
        }
        await api
            .get(
                `reports/invites?from=${fromISO}&until=${untilISO}${query_filters}`
            )
            .then(response => {
                const { data } = response;
                filterData(data);
                setRawData(data.invites);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    function onRowClick(event, _id) {
        const invite = list.find(i => {
            return i.id === _id;
        });

        if (invite) {
            const sub = subTables[_id]
                ? false
                : {
                      id: _id,
                      title: <LocaleMessage msg="table.headers.accesses" />,
                      headers: headers_sub,
                      rows: invite.views,
                  };

            setSubTables({
                ...subTables,
                [_id]: sub,
            });
        }
    }

    function buildListView() {
        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headers_table}
                    data={list || []}
                    orderDirection="desc"
                    orderColumn="created_timestamp"
                    handleTableRowClick={(event, _id) => {
                        onRowClick(event, _id);
                    }}
                    hasActions={false}
                    sortable
                    hasFilter
                    subTables={subTables}
                />
            </div>
        );
    }

    function buildDateChart(title, data, key = 'value') {
        return (
            <ReportDateChart
                title={title}
                color={colors.dashboard_graphs}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildHourChart(title, data, key = 'value') {
        return (
            <ReportTimeChart
                title={title}
                color={colors.dashboard_cards}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function getSubValues(data, subKey) {
        const full_list = [];

        const sub_headers = headers_file.sub || [];
        const sub_headers_file = sub_headers.map(h => {
            return {
                ...h,
                key: `${subKey}_${h.key}`,
            };
        });

        data.forEach(r => {
            full_list.push(r);

            const views = r.views || [];
            views.forEach(v => {
                const sub_obj = {};
                sub_headers.forEach((k, i) => {
                    const sub_h = sub_headers[i] || {};
                    const file_h = sub_headers_file[i] || {};
                    sub_obj[file_h.key] = v[sub_h.key];
                });
                full_list.push(sub_obj);
            });
        });

        return {
            headers: [...headers_file.main, ...sub_headers_file],
            list: full_list,
        };
    }

    function CSVButton() {
        const full_list = getSubValues(list, 'view');
        return (
            <ExportCSVButton
                data={full_list.list}
                headers={full_list.headers}
                filename={GetFileName(`REPORTS-robot_invites`, 'csv')}
            />
        );
    }

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      color: colors.dashboard_cards,
                      data: groupedData.groups,
                  },
              ]
            : []),
        {
            key: 'users',
            title: 'per_user',
            color: colors.dashboard_graphs,
            data: groupedData.users,
        },
        {
            key: 'applications',
            title: 'per_application',
            color: colors.dashboard_cards,
            data: groupedData.applications,
        },
        {
            key: 'robots',
            title: 'per_robot',
            color: colors.dashboard_graphs,
            data: groupedData.robots,
        },
        {
            key: 'robot_types',
            title: 'per_robot_type',
            color: colors.dashboard_cards,
            data: groupedData.robot_types,
        },
    ];

    useEffect(() => {
        loadData();
    }, [active, fromISO, untilISO, locale]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <>
            <ReportHeader
                date_props={{
                    locale,
                }}
                period={{
                    minDate,
                    fromDate: new Date(fromISO),
                    untilDate: new Date(untilISO),
                }}
                handleFromDateChange={handleFromDateChange}
                handleUntilDateChange={handleUntilDateChange}
                reloadData={() => loadData()}
                exportButton={CSVButton()}
                shortcuts={shortcuts}
                handleShortcutClick={_id => handlePeriodShortcut(_id)}
            />
            <div className="sidecard-body mt-3">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <TotalContainer className="mt-3 mb-5">
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.invites.label.total_invites" />
                                }
                                value={rawData.total}
                            />
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.invites.label.mean_duration" />
                                }
                                value={groupedData.mean_duration}
                            />
                        </TotalContainer>
                        <Divider />
                        <ChartsContainer className="row mt-3 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'value';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.invites.label.${c.title}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                        customization={{
                                            stroke: dark_mode
                                                ? '#424242'
                                                : '#ddd',
                                        }}
                                    />
                                );
                            })}
                        </ChartsContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.invites.label.per_c_date" />,
                                groupedData.created_date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.invites.label.per_c_hour" />,
                                groupedData.created_time
                            )}
                        </div>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.invites.label.per_r_date" />,
                                groupedData.revoked_date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.invites.label.per_r_hour" />,
                                groupedData.revoked_time
                            )}
                        </div>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.invites.label.per_s_date" />,
                                groupedData.start_date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.invites.label.per_s_hour" />,
                                groupedData.start_time
                            )}
                        </div>
                        <Divider color="primary" />
                        <RowArea className="mt-3 mb-5">
                            <div>
                                <p className="card-title">
                                    <LocaleMessage msg="page.reports.invites.label.history" />
                                </p>
                            </div>
                            {buildListView()}
                        </RowArea>
                    </>
                )}
            </div>
        </>
    );
}

InviteReports.defaultProps = {
    options: {},
};

InviteReports.propTypes = {
    settings: PropTypes.object.isRequired,
    headerSettings: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    counterData: PropTypes.object.isRequired,
    CustomTimeTooltip: PropTypes.func.isRequired,
    options: PropTypes.object,
};
