import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;

    .app-footer {
        color: ${props => {
            return props.theme.dark_mode ? '#808080' : '#808080';
        }};
        background: ${props => {
            const color = props.theme.palette.primary.main;
            return props.theme.dark_mode ? darken(0.35, color) : '#fff';
        }};
    }
`;
