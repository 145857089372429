/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import PropTypes from 'prop-types';

// import { TextField } from '@material-ui/core';

import MultipleInputs from '~/components/Form/MultipleInputs';
import LocaleMessage from '~/components/LocaleMessage';

import { ParameterArea } from '../styles';

const styleCenter = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
};

export default function QnANodeRequirements({
    config,
    saveConfig,
    dialogSettings,
}) {
    const settings = config.settings || {};
    const requirements = settings.requirements || {};
    const complement_attempts = requirements.attempts || {};
    const { language: dialogLanguage } = dialogSettings;

    function updateRequirements(r) {
        saveConfig({
            ...config,
            settings: {
                ...settings,
                requirements: r,
            },
        });
    }

    function renderLanguageTexts(texts, onUpdate) {
        return (
            <div
                className="col-12 row px-0"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="col-12 mb-0">
                    <MultipleInputs
                        margin="mb-1"
                        language={dialogLanguage}
                        label="Ask information"
                        texts={texts}
                        open
                        onChange={t => onUpdate(t)}
                    />
                </div>
            </div>
        );
    }

    function renderAttemptAnswer() {
        const retry_texts = complement_attempts.retry || [''];

        return (
            <>
                <div className="col-12 pb-1 text-center mt-3">
                    <span
                        className="col-12 mt-3 mb-3"
                        style={{ textAlign: 'center' }}
                    >
                        <LocaleMessage msg="page.qna.nodes.form.requirements.complement.texts.retry" />
                    </span>
                    {renderLanguageTexts(retry_texts, t => {
                        updateRequirements({
                            ...requirements,
                            attempts: {
                                ...complement_attempts,
                                retry: t,
                            },
                        });
                    })}
                </div>
            </>
        );
    }

    function renderGiveupAnswer() {
        const giveup_texts = complement_attempts.giveup || [''];

        return (
            <>
                <div className="col-12 pb-1 text-center mt-3">
                    <span
                        className="col-12 mt-3 mb-3"
                        style={{ textAlign: 'center' }}
                    >
                        <LocaleMessage msg="page.qna.nodes.form.requirements.complement.texts.giveup" />
                    </span>
                    {renderLanguageTexts(giveup_texts, t => {
                        updateRequirements({
                            ...requirements,
                            attempts: {
                                ...complement_attempts,
                                giveup: t,
                            },
                        });
                    })}
                </div>
            </>
        );
    }

    return (
        <div
            className="col-12 row mt-1 py-1 px-1 text-center"
            style={styleCenter}
        >
            <ParameterArea className="col-12" style={styleCenter}>
                {/* <strong
                    className="col-12 mt-3 mb-3"
                    style={{ textAlign: 'center' }}
                >
                    <LocaleMessage msg="page.qna.nodes.form.requirements.complement.attempts" />
                </strong>
                <div className="col-md-4 col-12 mt-3 mb-3" style={styleCenter}>
                    <TextField
                        fullWidth
                        value={complement_attempts.retries || 0}
                        onChange={event => {
                            updateRequirements({
                                ...requirements,
                                attempts: {
                                    ...complement_attempts,
                                    retries: event.target.value,
                                },
                            });
                        }}
                        type="number"
                        inputProps={{
                            min: '0',
                            step: '1',
                            style: { textAlign: 'center' },
                        }}
                    />
                </div>
                {complement_attempts.retries > 0 ? renderAttemptAnswer() : null} */}
                {renderAttemptAnswer()}
                {renderGiveupAnswer()}
            </ParameterArea>
        </div>
    );
}

QnANodeRequirements.defaultProps = {
    config: {},
};

QnANodeRequirements.propTypes = {
    config: PropTypes.object,
    saveConfig: PropTypes.func.isRequired,
};
