import React from 'react';

import { getYear } from 'date-fns';

import { Container } from './styles';

export default function Footer() {
    const date = new Date();
    const year = getYear(date);

    return (
        <Container>
            <footer
                className="app-footer"
                style={{ justifyContent: 'flex-end' }}
            >
                <span className="d-inline-block">
                    v{process.env.REACT_APP_ENV_VERSION_NUMBER} - Pluginbot
                    &copy;
                    {year}
                </span>
            </footer>
        </Container>
    );
}
