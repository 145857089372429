import styled from 'styled-components';

export const Container = styled.div`
    h2 {
        color: ${props => {
            const color = props.theme.palette.pluginspace
                ? props.theme.palette.pluginspace.primary
                : props.theme.palette.primary.main;
            return props.theme.dark_mode ? '#fff' : color;
        }};
    }
`;
