import styled from 'styled-components';

export const Container = styled.div`
    .MuiDialogTitle-root {
        h2 {
            font-weight: bold;
            color: ${props => {
                return props.theme.dark_mode ? '#fff' : '#000';
            }} !important;
        }
    }

    .title {
        color: ${props => {
            return props.theme.dark_mode ? '#fff' : '#000';
        }} !important;
    }
`;
