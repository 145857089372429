/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';

import PropTypes from 'prop-types';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import api from '~/services/pluginbot-api';

export default function MapPointDestinations({
    handleError,
    robotType,
    settings,
    setSettings,
    appSettings,
}) {
    const map_settings = appSettings.map_navigation || {};
    const r_key = robotType.slug ? robotType.slug : 'all';
    const r_map = map_settings[r_key] || {};

    const [isLoading, setIsLoading] = useState(false);
    const [destinations, setDestinations] = useState([]);

    async function loadMapPoints(_id) {
        setIsLoading(true);
        if (_id) {
            await api
                .get(`map_points?map_layer=${_id}`)
                .then(response => {
                    const data = response.data.map(z => ({
                        id: z.id,
                        name: `${z.name} [${z.reference}]`,
                    }));
                    setDestinations(data);
                })
                .catch(error => handleError(error));
        } else {
            setDestinations([]);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (r_map && r_map.map_layer_id) {
            loadMapPoints(r_map.map_layer_id);
        }
    }, [r_map]);

    function saveBody(key, value) {
        setSettings({
            ...settings,
            [key]: value,
        });
    }

    return isLoading ? (
        <ReactLoading type="bars" color="#c8c8c8" height={50} width={50} />
    ) : (
        <div
            className="row col-12"
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div className="col-md-5 col-12">
                <FormControl className="mb-5" fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.applications.conversation.triggers.identifiers.map_point" />
                    </InputLabel>
                    <Select
                        value={
                            settings.map_point_id ? settings.map_point_id : ''
                        }
                        onChange={event =>
                            saveBody('map_point_id', event.target.value)
                        }
                    >
                        {destinations.map(p => {
                            return (
                                <MenuItem value={p.id} key={p.id}>
                                    {p.name}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        </div>
    );
}

MapPointDestinations.defaultProps = {};

MapPointDestinations.propTypes = {
    settings: PropTypes.object.isRequired,
    setSettings: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    robotType: PropTypes.object.isRequired,
    appSettings: PropTypes.object.isRequired,
};
