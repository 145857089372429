/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { MdBuild, MdAdd, MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import CustomDialogTitle from '~/components/CustomDialogTitle';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

export default function PluginspaceTools({
    requestError,
    pluginspace_id,
    tool_list,
    renderAddonButton,
}) {
    const [toolOperation, setToolOperation] = useState('create');

    const [pluginspaceTools, setPluginspaceTools] = useState([]);

    const [currToolItem, setCurrToolItem] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const [tool, setTool] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    async function loadTools(_id) {
        setIsLoading(true);
        await api
            .get(`admin/pluginspaces/${_id}/addons?type=tool`)
            .then(response => {
                const t = response.data;
                setPluginspaceTools(t);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadTools(pluginspace_id);
    }, [pluginspace_id]);

    async function addTool(data) {
        setFormOpen(false);
        setIsLoading(true);

        const body = {
            type: 'tool',
            ...data,
        };
        await api
            .post(`admin/pluginspaces/${pluginspace_id}/addons`, body)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.su_pluginspace.form.tool.added" />
                );
            })
            .catch(error => requestError(error));
        await loadTools(pluginspace_id);
        setIsLoading(false);
    }

    async function removeTool() {
        setIsLoading(true);
        setDeleteOpen(false);
        await api
            .delete(
                `admin/pluginspaces/${pluginspace_id}/addons/${currToolItem}`
            )
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.su_pluginspace.form.tool.removed" />
                );
            })
            .catch(error => requestError(error));

        await loadTools(pluginspace_id);
        setIsLoading(false);
    }

    async function handleToolSubmit(event) {
        event.preventDefault();

        const data = { addon: toolOperation === 'create' ? tool : 'all' };
        await addTool(data);
    }

    // async function handleFreeTools(event) {
    //     event.preventDefault();

    //     const data = { addon: 'all' };
    //     await addTool(data);
    // }

    function handleNewTool() {
        setToolOperation('create');
        setCurrToolItem(null);
        setFormOpen(true);
    }

    function handledeleteOpen(event, _id) {
        setCurrToolItem(_id);
        event.preventDefault();
        setDeleteOpen(true);
    }

    function handleToolDialogClose(event) {
        event.preventDefault();
        setDeleteOpen(false);
    }

    function handleToolFormClose(event) {
        event.preventDefault();
        setFormOpen(false);
    }

    function buildToolDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteOpen}
                onClose={handleToolDialogClose}
                title={
                    <LocaleMessage msg="page.su_pluginspace.form.tool.delete" />
                }
                content={<></>}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => removeTool(currToolItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildToolFormDialog() {
        const filterTools = tool_list.filter(a => {
            return !pluginspaceTools.find(t => {
                return t.id === a.id;
            });
        });

        return (
            <Dialog
                open={formOpen}
                onClose={handleToolFormClose}
                maxWidth="sm"
                fullWidth
            >
                <CustomDialogTitle
                    title={
                        toolOperation === 'create' ? (
                            <LocaleMessage msg="page.su_pluginspace.form.tool.add" />
                        ) : (
                            <LocaleMessage msg="page.su_pluginspace.form.tools" />
                        )
                    }
                />
                <DialogContent>
                    <FormControl fullWidth className="mb-5">
                        <InputLabel>
                            <LocaleMessage msg="page.su_pluginspace.form.label.tool" />
                        </InputLabel>
                        <Select
                            value={tool || ''}
                            onChange={event => setTool(event.target.value)}
                            disabled={toolOperation === 'update'}
                        >
                            {}
                            {filterTools.length > 0 ? (
                                filterTools.map(t => (
                                    <MenuItem value={t.id} key={t.id}>
                                        {t.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="">
                                    <LocaleMessage msg="page.su_pluginspace.form.label.no_available_tools" />
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={event => handleToolFormClose(event)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={event => handleToolSubmit(event)}
                        color="primary"
                    >
                        {toolOperation === 'create' ? (
                            <LocaleMessage msg="button.add" />
                        ) : (
                            <LocaleMessage msg="button.update" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildToolListView() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handledeleteOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={pluginspaceTools}
                    orderColumn="name"
                    rowActions={rowActions}
                />
            </div>
        );
    }

    return (
        <>
            {deleteOpen ? buildToolDeleteDialog() : null}
            {formOpen ? buildToolFormDialog() : null}
            <CardSideBordered
                title={
                    <LocaleMessage msg="page.su_pluginspace.form.label.tool_list" />
                }
                hide
                Icon={MdBuild}
            >
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {/* <div className="body-top-controls">
                            <Button
                                className="col-12"
                                onClick={event => handleFreeTools(event)}
                                variant="contained"
                                color="primary"
                                startIcon={<MdMoneyOff />}
                                style={{
                                    whiteSpace: 'nowrap',
                                    padding: '5px 20px',
                                }}
                            >
                                <LocaleMessage msg="page.su_pluginspace.form.tool.free" />
                            </Button>
                        </div> */}
                        {renderAddonButton({
                            label: (
                                <LocaleMessage msg="page.su_pluginspace.form.tool.add" />
                            ),
                            icon: <MdAdd />,
                            onClick: () => handleNewTool(),
                        })}

                        {buildToolListView()}
                    </>
                )}
            </CardSideBordered>
        </>
    );
}

PluginspaceTools.defaultProps = {
    tool_list: [],
};

PluginspaceTools.propTypes = {
    pluginspace_id: PropTypes.string.isRequired,
    tool_list: PropTypes.array,
    requestError: PropTypes.func.isRequired,
    renderAddonButton: PropTypes.func.isRequired,
};
