/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';

import PropTypes from 'prop-types';

import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import { IconParameter } from '../styles';

const after_actions = {
    intent: ['return_start', 'wait_decision'],
    command: ['return_start', 'wait_decision'],
    question: ['return_start', 'wait_decision'],
    decision: ['return_start', 'retry_decision'],
    all: ['return_start'],
};

export default function QnANodeNext({ config, saveConfig }) {
    const condition_type = config.condition_type || 'all';
    const available_actions = after_actions[condition_type]
        ? after_actions[condition_type]
        : after_actions.all;

    const [show, setShow] = useState(false);

    function renderNextActionSelector() {
        return (
            <div className="col-md-4 col-12">
                <FormControl fullWidth className="mb-4">
                    <InputLabel>
                        <LocaleMessage msg="page.qna.nodes.form.after" />
                    </InputLabel>
                    <Select
                        value={config.after_response || 'return_start'}
                        onChange={event =>
                            saveConfig({
                                ...config,
                                after_response: event.target.value,
                            })
                        }
                    >
                        {available_actions.map(a => {
                            return (
                                <MenuItem value={a} key={`after_action_${a}`}>
                                    <LocaleMessage
                                        msg={`page.qna.nodes.form.after.${a}`}
                                    />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        );
    }

    return (
        <>
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <IconParameter className="col-1">
                    {show ? (
                        <MdExpandLess
                            size={22}
                            onClick={() => setShow(false)}
                        />
                    ) : (
                        <MdExpandMore size={22} onClick={() => setShow(true)} />
                    )}
                </IconParameter>
                {!show ? (
                    <strong>
                        <LocaleMessage msg="page.qna.nodes.form.after" />
                    </strong>
                ) : (
                    renderNextActionSelector()
                )}
                <IconParameter className="col-1" />
            </div>
        </>
    );
}

QnANodeNext.defaultProps = {
    config: {},
};

QnANodeNext.propTypes = {
    config: PropTypes.object,
    saveConfig: PropTypes.func.isRequired,
};
