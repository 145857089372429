import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    .jr-card {
        background: ${props => {
            return props.theme.dark_mode ? '#252525' : '#fff';
        }};
    }
    h1 {
        color: ${props => {
            const color = props.theme.palette.pluginspace
                ? props.theme.palette.pluginspace.dashboard_cards
                : props.theme.palette.primary.main;
            return props.theme.dark_mode ? '#fff' : color;
        }};
    }
`;
