/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdSettings, MdDelete, MdCheck, MdClose } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import {
    DialogContentText,
    Menu,
    MenuItem,
    TextField,
} from '@material-ui/core';

import CardItem from '~/components/CardItem';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';
import ViewSwitcher from '~/components/ViewSwitcher';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import { loadGroupsRequest } from '~/store/modules/user/actions';
import getDateLocale from '~/util/GetDateLocale';
import lng_labels from '~/util/LangMessages';

const delete_key = 'message.delete_confirm';

const PAGE_KEY = 'groups';
const PAGE_VIEW_DEF = 'grid';

export default function Groups() {
    const dispatch = useDispatch();

    const user = useSelector(state => state.user);
    const _settings = useSelector(state => state.settings || null);
    const page_settings = useSelector(state => state.pages || null);
    const curr_page_settings =
        page_settings[PAGE_KEY] && typeof page_settings[PAGE_KEY] === 'object'
            ? page_settings[PAGE_KEY]
            : {};

    const date_loc = getDateLocale(_settings);

    const lng_all = lng_labels[date_loc.lang];
    const delete_string = lng_all[delete_key];

    const { permission } = user.profile;
    const adminMode = ['master', 'super_admin', 'admin'].includes(permission);

    if (!adminMode) {
        history.push('/groups');
    }

    const active_group = _settings.active;

    const [view, setView] = useState(
        curr_page_settings && curr_page_settings.view
            ? curr_page_settings.view
            : PAGE_VIEW_DEF
    );
    const [isLoading, setIsLoading] = useState(true);

    const [allowRC3, setAllowRC3] = useState(false);

    const [items, setItems] = useState([]);

    const [deleteConfirm, setDeleteConfirm] = useState('');
    const [currItem, setCurrItem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const open = Boolean(anchorEl);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function verifyRC3() {
        await api
            .get(`rc3/pluginspaces/verify`)
            .then(response => {
                const { data } = response;
                setAllowRC3(data.active);
            })
            .catch(error => requestError(error));
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`groups`)
            .then(response => {
                const data = response.data.map(g => {
                    const updated = new Date(g.updated);
                    return {
                        ...g,
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                        rc3_allowed: !!g.principal_id,
                        rc3_icon: g.principal_id ? (
                            <MdCheck size={20} />
                        ) : (
                            <MdClose size={20} />
                        ),
                    };
                });
                setItems(data);

                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        verifyRC3();
    }, []);

    useEffect(() => {
        loadItems();
    }, [date_loc]);

    function handleClick(event, _id) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setCurrItem(_id);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/pluginspace/groups/${id}`);
    }

    function goToSettings(event, _id) {
        event.preventDefault();
        setAnchorEl(null);
        history.push(`/pluginspace/groups/${_id}`);
    }

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDialogOpen(true);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
    }

    async function deleteItem() {
        setIsLoading(true);
        setDialogOpen(false);
        const currActive =
            active_group && active_group.id ? active_group.id : '*';

        if (currActive !== currItem) {
            await api
                .delete(`groups/${currItem}`)
                .then(async () => {
                    toast.success('Group deleted');
                    dispatch(loadGroupsRequest());
                    await loadItems();
                })
                .catch(error => requestError(error));
        } else {
            toast.error(<LocaleMessage msg="errors.exit_group" />);
        }
        setIsLoading(false);
    }

    function itemCard(item) {
        const badge =
            allowRC3 && item.rc3_allowed ? (
                <LocaleMessage msg="label.rc3_licensed" />
            ) : null;
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={item.id}>
                <Link to={`/pluginspace/groups/${item.id}`}>
                    <CardItem
                        title={item.name}
                        optionsClick={event => handleClick(event, item.id)}
                        updated={item.updated}
                        img={{
                            url:
                                item.file && item.file.url
                                    ? item.file.url
                                    : null,
                            name: item.name,
                        }}
                        position="center"
                        fit="contain"
                        colorBackground
                        group={badge}
                    >
                        <>
                            <p className="card-text">
                                <span>{`${item.user_count || '0'} `}</span>
                                <LocaleMessage msg="table.headers.user_count" />
                            </p>
                            <small>
                                <p className="card-text">{item.description}</p>
                            </small>
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                <div
                    className="row"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    {items.map(dialog => itemCard(dialog))}
                </div>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleMenuClose}
                >
                    {adminMode ? (
                        <MenuItem onClick={e => goToSettings(e, currItem)}>
                            <LocaleMessage msg="button.settings" />
                        </MenuItem>
                    ) : null}
                    <MenuItem onClick={e => handleClickOpen(e, currItem)}>
                        <LocaleMessage msg="page.groups.list.delete" />
                    </MenuItem>
                </Menu>
            </>
        );
    }

    function buildListView() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'description',
                label: <LocaleMessage msg="table.headers.description" />,
            },
            {
                id: 'user_count',
                label: <LocaleMessage msg="table.headers.user_count" />,
            },
            ...(allowRC3
                ? [
                      {
                          id: 'rc3_icon',
                          label: <LocaleMessage msg="label.rc3_licensed" />,
                          order_by: 'rc3_allowed',
                      },
                  ]
                : []),
            // {
            //     id: 'updated',
            //     label: <LocaleMessage msg="table.headers.updated" />,
            //     order_by: 'updated_timestamp',
            // },
        ];

        const rowActions = [
            {
                id: 'settings',
                label: <LocaleMessage msg="button.settings" />,
                icon: <MdSettings />,
                action: goToSettings,
            },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleClickOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={items}
                    pageKey={`${PAGE_KEY}_list`}
                    orderColumn="name"
                    orderDirection="asc"
                    setCurrDialog={_id => setCurrItem(_id)}
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function buildConfirmDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={handleDialogClose}
                title={<LocaleMessage msg="page.groups.list.delete_title" />}
                content={
                    <>
                        <DialogContentText>
                            <LocaleMessage msg="page.groups.list.delete_content" />
                            <LocaleMessage msg="message.undone.content" />
                        </DialogContentText>

                        <div className="col-12 mb-5">
                            <TextField
                                label={
                                    <>
                                        <LocaleMessage msg="message.delete.confirm" />
                                        <span>{delete_string}</span>
                                    </>
                                }
                                fullWidth
                                onChange={event =>
                                    setDeleteConfirm(event.target.value)
                                }
                                value={deleteConfirm}
                            />
                        </div>
                    </>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => {
                            setDeleteConfirm('');
                            setDialogOpen(false);
                        },
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => {
                            deleteItem(currItem);
                            setDeleteConfirm('');
                        },
                        disabled: deleteConfirm !== delete_string,
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="breadcrumbs.groups" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? buildConfirmDialog() : null}
                <div className="body-top-controls">
                    <div className="col-3" style={{ padding: '0px' }}>
                        <NewItemButton
                            disabled={!adminMode}
                            link="/pluginspace/groups/new"
                            text={<LocaleMessage msg="page.groups.list.add" />}
                        />
                    </div>

                    <ViewSwitcher
                        size="col-3"
                        view={view}
                        setView={v => setView(v)}
                        pageKey={PAGE_KEY}
                    />
                </div>
                {view === 'grid' ? buildGridView() : buildListView()}
            </>
        </PageContent>
    );
}
