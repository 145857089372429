/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdCollectionsBookmark,
    MdFormatListBulleted,
    MdCancel,
    MdAdd,
    MdDeleteSweep,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Tab,
    Tabs,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CardSideBordered from '~/components/CardSideBordered';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import lists from '~/config/Lists';
import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';

import { ParameterArea } from '../../styles';

const status = ['unavailable', 'development', 'testing', 'available'];

const types = [
    {
        value: 'intent',
        label: 'page.qna.content.intents',
    },
    {
        value: 'subject',
        label: 'page.qna.content.subjects',
    },
];

const useStyles = makeStyles(theme => {
    return {
        root: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        tabs: {
            marginTop: '10px',
        },
    };
});

export default function QnATemplateForm({ match }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const locale = getDateLocale(settings);

    const template_id = match.params.id;
    const { qna_languages } = lists;

    const [operation, setOperation] = useState('create');
    const [body, setBody] = useState({
        identifier: '',
        status: 'development',
    });
    const [bindingBody, setBindingBody] = useState({});

    const [availableContents, setAvailableContents] = useState([]);
    const [templateSettings, setTemplateSettings] = useState({});
    const [templateContents, setTemplateContents] = useState([]);
    const [typeContentList, setTypeContentList] = useState([]);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    const [currType, setCurrType] = useState(types[0].value);
    const [currItem, setCurrItem] = useState(null);

    const [selectedRows, setSelectedRows] = useState([]);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status: resp_status } = error.response;
            if (resp_status === 401) {
                dispatch(expireSession());
            } else if (resp_status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadTemplate(_id) {
        if (_id === 'new') {
            setOperation('create');
        } else {
            setOperation('update');
            await api
                .get(`/qna/admin/templates/${_id}`)
                .then(response => {
                    const t = response.data;
                    const t_settings = t.settings || {};
                    const formBody = {
                        identifier: t.identifier,
                        status: t.status,
                    };
                    setTemplateSettings(t_settings);
                    setBody(formBody);
                })
                .catch(error => requestError(error));
        }
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function loadTemplateContents(_id) {
        if (_id !== 'new') {
            await api
                .get(`/qna/admin/templates/${_id}/contents`)
                .then(response => {
                    const c_list = response.data;
                    const contents = c_list.map(c => {
                        const pkg_content = c.content || {};
                        const created = new Date(c.created);
                        const updated = new Date(c.updated);

                        return {
                            id: pkg_content.id,
                            type: pkg_content.type,
                            identifier: pkg_content.identifier,
                            added: formatDistance(created, new Date(), {
                                addSuffix: true,
                                locale,
                            }),
                            added_timestamp: updated.toISOString(),
                            updated: formatDistance(updated, new Date(), {
                                addSuffix: true,
                                locale,
                            }),
                            updated_timestamp: updated.toISOString(),
                        };
                    });
                    setTemplateContents(contents);
                })
                .catch(error => requestError(error));
        }
    }

    async function loadAvailableContents() {
        await api
            .get(`/qna/admin/contents`)
            .then(response => {
                const c_list = response.data;
                const contents = c_list.map(c => {
                    return {
                        id: c.id,
                        type: c.type,
                        identifier: c.identifier,
                    };
                });
                setAvailableContents(contents);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadAvailableContents();
    }, []);

    useEffect(() => {
        const template_content = templateContents.filter(c => {
            return c.type === currType;
        });
        setTypeContentList(template_content);
    }, [currType, templateContents]);

    useEffect(() => {
        loadTemplate(template_id);
        loadTemplateContents(template_id);
    }, [template_id]);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = { ...body, settings: templateSettings };

        if (operation === 'create') {
            await api
                .post(`/qna/admin/templates`, data)
                .then(t => {
                    toast.success(
                        <LocaleMessage msg="page.qna.template.create_success" />
                    );
                    const created = t.data;
                    setIsLoading(false);
                    history.push(`/pluginbot_qna/templates/${created.id}`);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`/qna/admin/templates/${template_id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.qna.template.update_success" />
                    );
                    loadTemplate(template_id);
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    async function bindContacts() {
        return api
            .post(`/qna/admin/templates/${template_id}/contents`, bindingBody)
            .then(() => {
                return true;
            })
            .catch(error => {
                requestError(error);
                return false;
            });
    }

    async function unbindContacts() {
        const delete_list = JSON.stringify(selectedRows);
        return api
            .delete(
                `/qna/admin/templates/${template_id}/contents?list=${delete_list}`
            )
            .then(() => {
                return true;
            })
            .catch(error => {
                requestError(error);
                return false;
            });
    }

    async function submitBinding() {
        setIsLoading(true);
        setDialogOpen(false);

        let success = true;
        if (currItem === 'new') {
            success = await bindContacts();
        } else {
            success = await unbindContacts();
        }

        if (success) {
            toast.success(
                <LocaleMessage msg="page.qna.content.update_success" />
            );
        }
        setBindingBody({});
        setCurrItem(null);
        setSelectedRows([]);
        loadTemplateContents(template_id);
        setIsLoading(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setCurrItem('');
        setDialogOpen(false);
    }

    function handleDialogOpen(event, _id) {
        event.preventDefault();
        setCurrItem(_id);
        setSelectedRows([_id]);
        setDialogOpen(true);
    }

    function handleDeleteMultiOpen(event) {
        setCurrItem(null);
        event.preventDefault();
        setCurrItem('multi');
        setDialogOpen(true);
    }

    function renderBindingForm() {
        const content_ids = typeContentList.map(c => {
            return c.id;
        });

        const content_filtered = availableContents.filter(c => {
            return c.type === currType && !content_ids.includes(c.id);
        });

        const headCells = [
            {
                id: 'identifier',
                label: <LocaleMessage msg="page.qna.content.all" />,
            },
        ];

        return (
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="col-12">
                    <div
                        style={{
                            minHeight: '150px',
                            width: '100%',
                            padding: '0px 15px',
                        }}
                    >
                        <DataTable
                            headerColumns={headCells}
                            data={content_filtered}
                            orderDirection="asc"
                            orderColumn="identifier"
                            handleTableRowClick={() => {}}
                            hasActions={false}
                            hasFilter={false}
                            sortable
                            selectable
                            selectedActions={[]}
                            selectedRows={bindingBody.contents || []}
                            setSelectedRows={s => {
                                setBindingBody({
                                    ...bindingBody,
                                    type: currType,
                                    contents: s,
                                });
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    function renderContents() {
        const headCells = [
            {
                id: 'identifier',
                label: <LocaleMessage msg="table.headers.name" />,
            },
            {
                id: 'added',
                label: <LocaleMessage msg="table.headers.created" />,
                order_by: 'added_timestamp',
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdCancel />,
                action: handleDialogOpen,
            },
        ];

        const selAction = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDeleteSweep />,
                action: handleDeleteMultiOpen,
            },
        ];

        return (
            <>
                <div
                    style={{
                        minHeight: '150px',
                        width: '100%',
                        padding: '15px',
                    }}
                >
                    <Button
                        fullWidth
                        className="mb-3"
                        variant="contained"
                        color="primary"
                        startIcon={<MdAdd />}
                        style={{
                            whiteSpace: 'nowrap',
                            padding: '5px 20px',
                        }}
                        onClick={() => {
                            setCurrItem('new');
                            setSelectedRows([]);
                            setDialogOpen(true);
                        }}
                    >
                        <LocaleMessage msg="page.qna.template.form.content.add" />
                    </Button>
                    <DataTable
                        headerColumns={headCells}
                        data={typeContentList}
                        orderColumn="name"
                        rowActions={rowActions}
                        selectable
                        selectedActions={selAction}
                        selectedRows={selectedRows}
                        setSelectedRows={s => {
                            setSelectedRows(s);
                        }}
                    />
                </div>
            </>
        );
    }

    function renderContentTypes() {
        return (
            <>
                <Tabs
                    indicatorColor="primary"
                    value={currType}
                    onChange={(e, v) => {
                        setCurrType(v);
                        setBindingBody({});
                    }}
                    className={classes.tabs}
                    centered
                >
                    {types.map(t => {
                        return (
                            <Tab
                                key={t.value}
                                label={<LocaleMessage msg={t.label} />}
                                value={t.value}
                            />
                        );
                    })}
                </Tabs>

                <div>{renderContents()}</div>
            </>
        );
    }

    function renderDialog() {
        return (
            <SimpleDialog
                open={dialogOpen}
                onClose={event => handleDialogClose(event)}
                title={
                    <LocaleMessage
                        msg={`page.qna.template.form.content.${
                            currItem === 'new' ? 'bind' : 'unbind'
                        }.title`}
                    />
                }
                content={currItem === 'new' ? renderBindingForm() : null}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => submitBinding(),
                        label: (
                            <LocaleMessage
                                msg={
                                    currItem === 'new'
                                        ? 'button.save'
                                        : 'button.delete'
                                }
                            />
                        ),
                    },
                ]}
            />
        );
    }

    function renderLanguageSettings() {
        return (
            <>
                <span>
                    <LocaleMessage msg="page.qna.content.display_name" />
                </span>
                <ParameterArea className="mt-3">
                    {qna_languages.map(l => {
                        const lng_settings = templateSettings[l.value] || {};
                        return (
                            <div
                                className="col-12"
                                key={`display_name_${l.value}`}
                            >
                                <TextField
                                    fullWidth
                                    className="mb-3"
                                    label={<LocaleMessage msg={l.label} />}
                                    value={lng_settings.display_name || ''}
                                    onChange={event =>
                                        setTemplateSettings({
                                            ...templateSettings,
                                            [l.value]: {
                                                ...lng_settings,
                                                display_name:
                                                    event.target.value,
                                            },
                                        })
                                    }
                                />
                            </div>
                        );
                    })}
                </ParameterArea>
            </>
        );
    }

    function renderTemplateForm() {
        return (
            <>
                <div className="col-12 row">
                    <div className="col-md-6 col-12">
                        <TextField
                            className="mb-5"
                            label={
                                <LocaleMessage msg="page.qna.template.form.identifier" />
                            }
                            fullWidth
                            value={body.identifier || ''}
                            onChange={event =>
                                setBody({
                                    ...body,
                                    identifier: event.target.value,
                                })
                            }
                        />
                    </div>
                    <div className="col-md-6 col-12 mb-5">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="label.status" />
                            </InputLabel>
                            <Select
                                value={body.status || 'development'}
                                onChange={event =>
                                    setBody({
                                        ...body,
                                        status: event.target.value,
                                    })
                                }
                                disabled={operation === 'create'}
                            >
                                {status.map(s => {
                                    return (
                                        <MenuItem value={s} key={`status_${s}`}>
                                            <LocaleMessage
                                                msg={`list.status.${s}`}
                                            />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="col-12 mb-5" style={{ padding: '20px 15px' }}>
                    {renderLanguageSettings()}
                </div>
            </>
        );
    }

    return (
        <PageContent
            title={
                <LocaleMessage msg={`page.qna.template.${operation}.title`} />
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/pluginbot_qna',
                    title: <LocaleMessage msg="page.qna.title" />,
                },
                {
                    url: '/pluginbot_qna/templates',
                    title: <LocaleMessage msg="page.qna.templates.title" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {dialogOpen ? renderDialog() : null}
                <div className="row full-body mt-5">
                    <div
                        className={`${
                            operation === 'update' ? 'col-md-6' : 'col-md-8'
                        } col-12 mb-5`}
                    >
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.qna.templates.title" />
                            }
                            Icon={MdCollectionsBookmark}
                        >
                            <>
                                {renderTemplateForm()}
                                <div className="col-12">
                                    <Button
                                        className="p-3 mb-3"
                                        variant="contained"
                                        color="primary"
                                        onClick={event => handleSubmit(event)}
                                        fullWidth
                                        size="large"
                                    >
                                        <LocaleMessage msg="button.save" />
                                    </Button>
                                </div>
                            </>
                        </CardSideBordered>
                    </div>
                    {operation === 'update' ? (
                        <div className="col-md-6 col-12 mb-5">
                            <CardSideBordered
                                title={
                                    <LocaleMessage msg="page.qna.content.all" />
                                }
                                hide
                                Icon={MdFormatListBulleted}
                            >
                                <>{renderContentTypes()}</>
                            </CardSideBordered>
                        </div>
                    ) : null}
                </div>
            </>
        </PageContent>
    );
}

QnATemplateForm.propTypes = {
    match: PropTypes.object.isRequired,
};
