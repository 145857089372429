/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdAccountBox,
    MdDeleteForever,
    MdExitToApp,
    MdSatellite,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import CustomDialogTitle from '~/components/CustomDialogTitle';
import DataTable from '~/components/DataTable';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import RC3UserSettings from '~/components/RC3Settings/User';

import api from '~/services/pluginbot-api';
import { signOut, expireSession } from '~/store/modules/auth/actions';
import { switchTheme } from '~/store/modules/settings/actions';
import {
    updateProfileRequest,
    updateProfileSuccess,
    updateProfileFailure,
} from '~/store/modules/user/actions';
import getDateLocale from '~/util/GetDateLocale';

import AvatarInput from './AvatarInput';
import { Container, Option } from './styles';

const delete_string = 'DELETE';

export default function Profile() {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings || null);
    const { dark_mode } = settings;
    const date_loc = getDateLocale(settings);

    const profile = useSelector(state => state.user.profile);
    const { permission } = profile;

    const [avatar, setAvatar] = useState(profile.avatar);
    const [body, setBody] = useState({
        name: profile.name,
        avatar_id: avatar && avatar.id ? avatar.id : null,
    });

    const [allowRC3, setAllowRC3] = useState(false);
    const [changePassword, setChangePassword] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const [currItem, setCurrItem] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
    const [accDialogOpen, setAccDialogOpen] = useState(false);

    const [deleteConfirm, setDeleteConfirm] = useState('');

    const user_id = profile.id;

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function verifyRC3() {
        await api
            .get(`rc3/pluginspaces/verify`)
            .then(response => {
                const { data } = response;
                setAllowRC3(data.active);
            })
            .catch(error => requestError(error));
    }

    async function loadSessions() {
        setIsLoading(true);
        await api
            .get(`auth/sessions`)
            .then(response => {
                const data = response.data.map(d => {
                    const ua = d.user_agent || {};
                    const { browser, version, os } = ua;
                    const source = `${browser} ${version} - ${os}`;
                    const created = new Date(d.created);
                    return {
                        ...d,
                        source,
                        created: formatDistance(created, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        created_timestamp: created.toISOString(),
                    };
                });
                setSessionList(data);

                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        verifyRC3();
    }, []);

    useEffect(() => {
        loadSessions();
    }, [date_loc]);

    useEffect(() => {
        setDeleteConfirm('');
    }, [accDialogOpen]);

    function onChangeTheme(dark) {
        dispatch(switchTheme(dark));
    }

    function handleDialogsClose() {
        setDialogOpen(false);
        setLeaveDialogOpen(false);
        setAccDialogOpen(false);
        return true;
    }

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDialogOpen(true);
    }

    function cancelChangePassword() {
        setChangePassword(false);
        setBody({
            avatar_id: body.avatar_id,
            name: body.name,
        });
    }

    async function updateProfile(data) {
        setIsLoading(true);

        dispatch(updateProfileRequest());

        await api
            .put(`/profile`, data)
            .then(response => {
                dispatch(updateProfileSuccess(response.data));
                toast.success(
                    <LocaleMessage msg="page.profile.form.update_success" />
                );
                cancelChangePassword();
                setIsLoading(false);
            })
            .catch(error => {
                requestError(error);
                dispatch(updateProfileFailure());
            });
    }

    async function revokeSession() {
        setIsLoading(true);
        handleDialogsClose();
        await api
            .delete(`auth/sessions/${currItem}`)
            .then(async response => {
                const { data } = response;
                if (data && data.sign_out) {
                    dispatch(signOut());
                } else {
                    toast.success(
                        <LocaleMessage msg="page.profile.session.delete_success" />
                    );
                    await loadSessions();
                }
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function leavePluginspace() {
        handleDialogsClose();

        setIsLoading(true);
        await api
            .delete(`/profile/leave`)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.profile.message.leave.success" />
                );
                setTimeout(() => {
                    dispatch(signOut());
                }, 500);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function deleteAccount() {
        handleDialogsClose();

        setIsLoading(true);
        await api
            .delete(`/profile/delete`)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.profile.message.account.delete.success" />
                );
                setTimeout(() => {
                    dispatch(signOut());
                }, 500);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        updateProfile({ ...body, avatar_id: avatar.id });
    }

    function changePasswordForm() {
        return (
            <>
                <div className="col-md-8 col-12 mb-5 centered-line">
                    <Button
                        color="primary"
                        onClick={() => cancelChangePassword()}
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                </div>
                <div className="col-md-8 col-12 mb-5">
                    <TextField
                        id="current-password"
                        label={
                            <LocaleMessage msg="page.profile.form.label.current_password" />
                        }
                        fullWidth
                        onChange={event =>
                            setBody({
                                ...body,
                                oldPassword: event.target.value,
                            })
                        }
                        type="password"
                    />
                </div>

                <div className="col-md-8 col-12 mb-5">
                    <TextField
                        id="new-password"
                        label={
                            <LocaleMessage msg="page.profile.form.label.new_password" />
                        }
                        fullWidth
                        onChange={event =>
                            setBody({
                                ...body,
                                password: event.target.value,
                            })
                        }
                        type="password"
                    />
                </div>

                <div className="col-md-8 col-12 mb-5">
                    <TextField
                        id="confirm-password"
                        label={
                            <LocaleMessage msg="page.profile.form.label.confirm_password" />
                        }
                        fullWidth
                        onChange={event =>
                            setBody({
                                ...body,
                                confirmPassword: event.target.value,
                            })
                        }
                        type="password"
                    />
                </div>
            </>
        );
    }

    async function onFileUpload(e) {
        const data = new FormData();
        data.append('file', e.target.files[0]);
        setIsLoading(true);
        const response = await api.post('profile/avatar', data);
        const { id, url } = response.data;
        setAvatar({ id, url });

        const updated = { ...body, avatar_id: id };
        updateProfile(updated);
    }

    function removeAvatar() {
        setAvatar({ id: null, url: null });
        updateProfile({ ...body, avatar_id: null });
    }

    function buildSessionList() {
        const headCells = [
            {
                id: 'source',
                label: <LocaleMessage msg="table.headers.source" />,
            },
            {
                id: 'created',
                label: <LocaleMessage msg="table.headers.created" />,
                order_by: 'created_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdExitToApp />,
                action: handleClickOpen,
            },
        ];

        return (
            <div className="col-md-8 col-12 mb-5">
                <CardSideBordered
                    title={<LocaleMessage msg="page.profile.session_list" />}
                    hide
                    Icon={MdAccountBox}
                >
                    <>
                        <DataTable
                            headerColumns={headCells}
                            data={sessionList}
                            orderColumn="created_timestamp"
                            orderDirection="desc"
                            handleTableRowClick={() => {}}
                            rowActions={rowActions}
                            hasHeader
                            headerDirection="row-reverse"
                            header={
                                <div
                                    className="col-md-6 col-12"
                                    style={{ padding: '0px' }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        onClick={event => {
                                            handleClickOpen(event, 'all');
                                        }}
                                    >
                                        <LocaleMessage msg="page.profile.session.delete_all" />
                                    </Button>
                                </div>
                            }
                        />
                    </>
                </CardSideBordered>
            </div>
        );
    }

    function renderDialog(open, title, content, actions) {
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handleDialogsClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <CustomDialogTitle title={title} />
                    <DialogContent>{content}</DialogContent>
                    <DialogActions>{actions}</DialogActions>
                </Dialog>
            </div>
        );
    }

    function renderSessionDialog() {
        const title = (
            <LocaleMessage
                msg={`page.profile.session.delete_title.${
                    currItem === 'all' ? 'all' : 'one'
                }`}
            />
        );

        const content = (
            <DialogContentText>
                <LocaleMessage msg="message.undone.content" />
            </DialogContentText>
        );

        const actions = (
            <>
                <Button onClick={() => handleDialogsClose()} color="primary">
                    <LocaleMessage msg="button.cancel" />
                </Button>
                <Button
                    onClick={() => revokeSession()}
                    color="primary"
                    autoFocus
                >
                    <LocaleMessage msg="button.delete" />
                </Button>
            </>
        );

        return renderDialog(dialogOpen, title, content, actions);
    }

    function renderLeaveDialog() {
        const title = (
            <LocaleMessage msg="page.profile.form.label.leave_pluginspace" />
        );

        const content = (
            <>
                <DialogContentText>
                    <LocaleMessage msg="page.profile.form.label.leave.content" />
                </DialogContentText>
                <DialogContentText>
                    <LocaleMessage msg="message.undone.content" />
                </DialogContentText>
            </>
        );

        const actions = (
            <>
                <Button onClick={() => handleDialogsClose()} color="primary">
                    <LocaleMessage msg="button.cancel" />
                </Button>
                <Button
                    onClick={() => leavePluginspace()}
                    color="primary"
                    autoFocus
                >
                    <LocaleMessage msg="button.leave" />
                </Button>
            </>
        );

        return renderDialog(leaveDialogOpen, title, content, actions);
    }

    function renderDeleteAccDialog() {
        const title = (
            <LocaleMessage msg="page.profile.form.label.delete_account" />
        );

        const content = (
            <>
                <DialogContentText>
                    <LocaleMessage msg="page.profile.form.label.delete.content" />
                </DialogContentText>
                <DialogContentText>
                    <LocaleMessage msg="message.undone.content" />
                </DialogContentText>
                <div className="col-12 mb-5">
                    <TextField
                        label={
                            <>
                                <LocaleMessage msg="page.profile.form.label.delete.confirm" />
                                <span>{delete_string}</span>
                            </>
                        }
                        fullWidth
                        onChange={event => setDeleteConfirm(event.target.value)}
                        value={deleteConfirm}
                    />
                </div>
            </>
        );

        const actions = (
            <>
                <Button onClick={() => handleDialogsClose()} color="primary">
                    <LocaleMessage msg="button.cancel" />
                </Button>
                <Button
                    onClick={() => deleteAccount()}
                    color="primary"
                    autoFocus
                    disabled={deleteConfirm !== delete_string}
                >
                    <LocaleMessage msg="button.delete" />
                </Button>
            </>
        );

        return renderDialog(accDialogOpen, title, content, actions);
    }

    function renderRC3User() {
        return (
            <div className="col-md-8 col-12 mb-5">
                <CardSideBordered
                    title={<LocaleMessage msg="page.users.form.rc3.settings" />}
                    hide
                    Icon={MdSatellite}
                >
                    <RC3UserSettings user={user_id} level="profile" />
                </CardSideBordered>
            </div>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.profile.form.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {dialogOpen ? renderSessionDialog() : null}
                {leaveDialogOpen ? renderLeaveDialog() : null}
                {accDialogOpen ? renderDeleteAccDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.profile.form.profile" />
                            }
                            hide
                            Icon={MdAccountBox}
                        >
                            <Container>
                                <div className="row d-flex justify-content-center">
                                    <div className="col-md-8 col-12 avatar-area">
                                        <AvatarInput
                                            name={body.name}
                                            defaultValue={avatar}
                                            onFileUpload={id =>
                                                onFileUpload(id)
                                            }
                                        />
                                        {avatar.id ? (
                                            <Option
                                                onClick={() => removeAvatar()}
                                            >
                                                <MdDeleteForever size={18} />
                                                <LocaleMessage msg="page.profile.form.label.remove_avatar" />
                                            </Option>
                                        ) : null}
                                    </div>

                                    <div className="col-md-8 col-12 mb-5">
                                        <TextField
                                            id="user-name"
                                            label={
                                                <LocaleMessage msg="page.profile.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    {permission === 'master' ? (
                                        <div className="col-md-8 col-12 mb-5 centered-line">
                                            <FormSwitch
                                                value={dark_mode || false}
                                                onChange={event =>
                                                    onChangeTheme(
                                                        event.target.checked
                                                    )
                                                }
                                                label={
                                                    <LocaleMessage msg="page.profile.form.label.theme.dark" />
                                                }
                                            />
                                        </div>
                                    ) : null}

                                    {changePassword ? (
                                        changePasswordForm()
                                    ) : (
                                        <div className="col-md-8 col-12 mb-5 centered-line">
                                            <Button
                                                color="primary"
                                                onClick={() =>
                                                    setChangePassword(true)
                                                }
                                            >
                                                <LocaleMessage msg="page.profile.form.label.change_password" />
                                            </Button>
                                        </div>
                                    )}

                                    <div className="col-12 mb-3">
                                        <Button
                                            className="p-3"
                                            variant="contained"
                                            color="primary"
                                            onClick={event =>
                                                handleSubmit(event)
                                            }
                                            fullWidth
                                            size="large"
                                        >
                                            <LocaleMessage msg="button.save" />
                                        </Button>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <Button
                                            className="p-3"
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                setLeaveDialogOpen(true);
                                            }}
                                            fullWidth
                                            size="large"
                                        >
                                            <LocaleMessage msg="page.profile.form.label.leave_pluginspace" />
                                        </Button>
                                    </div>

                                    <div className="col-12 mb-3">
                                        <Button
                                            className="p-3"
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                setAccDialogOpen(true);
                                            }}
                                            fullWidth
                                            size="large"
                                        >
                                            <LocaleMessage msg="page.profile.form.label.delete_account" />
                                        </Button>
                                    </div>
                                </div>
                            </Container>
                        </CardSideBordered>
                    </div>
                    {allowRC3 ? renderRC3User() : null}
                    {buildSessionList()}
                </form>
            </>
        </PageContent>
    );
}
