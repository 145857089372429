import styled from 'styled-components';

export const Container = styled.div`
    h4 {
        color: ${props => {
            const color = props.theme.palette.primary.main;
            return props.theme.dark_mode ? '#fff' : color;
        }};
    }
`;
