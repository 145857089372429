/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { MdWidgets, MdAdd, MdDelete, MdSettings } from 'react-icons/md';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import CustomDialogTitle from '~/components/CustomDialogTitle';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import RC3SettingsForm from './RC3';

export default function PluginspaceModules({
    pluginspace_id,
    module_list,
    requestError,
    renderAddonButton,
}) {
    const [operation, setOperation] = useState('create');

    const [pluginspaceModules, setPluginspaceModules] = useState([]);

    const [currItem, setCurrItem] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);

    const [module, setModule] = useState(null);

    const [isLoading, setIsLoading] = useState(true);

    const [currModule, setCurrModule] = useState('');
    const [settingsOpen, setSettingsOpen] = useState(false);

    async function loadModules(_id) {
        setIsLoading(true);
        await api
            .get(`admin/pluginspaces/${_id}/addons?type=module`)
            .then(response => {
                const t = response.data;
                setPluginspaceModules(t);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadModules(pluginspace_id);
    }, [pluginspace_id]);

    async function addModule(data) {
        setIsLoading(true);
        const body = {
            type: 'module',
            ...data,
        };
        await api
            .post(`admin/pluginspaces/${pluginspace_id}/addons`, body)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.su_pluginspace.form.module.added" />
                );
            })
            .catch(error => requestError(error));
        await loadModules(pluginspace_id);
        setIsLoading(false);
    }

    async function removeModule() {
        setIsLoading(true);
        setDeleteOpen(false);
        await api
            .delete(`admin/pluginspaces/${pluginspace_id}/addons/${currItem}`)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.su_pluginspace.form.tool.removed" />
                );
            })
            .catch(error => requestError(error));

        await loadModules(pluginspace_id);
        setIsLoading(false);
    }

    async function handleSubmit(event) {
        event.preventDefault();

        setFormOpen(false);
        setIsLoading(true);

        const data = { addon: operation === 'create' ? module : 'all' };
        await addModule(data);
    }

    // async function handleFreeModules(event) {
    //     event.preventDefault();

    //     const data = { addon: 'all' };
    //     await addModule(data);
    // }

    function handleSettingsOpen(event, _id) {
        const m_settings = pluginspaceModules.find(m => {
            return m.id === _id;
        });
        event.preventDefault();
        if (m_settings) {
            setCurrModule(m_settings);
            setSettingsOpen(true);
        }
    }

    function handleNewModule() {
        setOperation('create');
        setCurrItem(null);
        setFormOpen(true);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setDeleteOpen(true);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDeleteOpen(false);
    }

    function handleFormClose(event) {
        event.preventDefault();
        setFormOpen(false);
    }

    function renderLoading() {
        return (
            <div
                className="row"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <ReactLoading
                    type="bars"
                    color="#c8c8c8"
                    height={50}
                    width={50}
                />
            </div>
        );
    }

    function buildModuleDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.su_pluginspace.form.module.delete" />
                }
                content={<></>}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => removeModule(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildModuleSettingsDialog() {
        const contents = {
            rc3: (
                <RC3SettingsForm
                    pluginspace_id={pluginspace_id}
                    requestError={e => requestError(e)}
                    showLoading={() => renderLoading()}
                />
            ),
        };

        const curr_content = currModule ? contents[currModule.slug] : null;

        return (
            <SimpleDialog
                open={settingsOpen}
                onClose={() => setSettingsOpen(false)}
                title={currModule && currModule.name ? currModule.name : ''}
                content={
                    curr_content || (
                        <>
                            <LocaleMessage msg="page.su_pluginspace.form.label.module_list" />
                        </>
                    )
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setSettingsOpen(false),
                        label: <LocaleMessage msg="button.close" />,
                    },
                ]}
            />
        );
    }

    function buildFormDialog() {
        const filtered = module_list.filter(a => {
            return !pluginspaceModules.find(t => {
                return t.id === a.id;
            });
        });

        return (
            <Dialog
                open={formOpen}
                onClose={handleFormClose}
                maxWidth="sm"
                fullWidth
            >
                <CustomDialogTitle
                    title={
                        operation === 'create' ? (
                            <LocaleMessage msg="page.su_pluginspace.form.module.add" />
                        ) : (
                            <LocaleMessage msg="page.su_pluginspace.form.modules" />
                        )
                    }
                />
                <DialogContent>
                    <FormControl fullWidth className="mb-5">
                        <InputLabel>
                            <LocaleMessage msg="page.su_pluginspace.form.label.module" />
                        </InputLabel>
                        <Select
                            value={module || ''}
                            onChange={event => setModule(event.target.value)}
                            disabled={operation === 'update'}
                        >
                            {filtered.length > 0 ? (
                                filtered.map(t => (
                                    <MenuItem value={t.id} key={t.id}>
                                        {t.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="">
                                    <LocaleMessage msg="page.su_pluginspace.form.label.no_available_modules" />
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={event => handleFormClose(event)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={event => handleSubmit(event)}
                        color="primary"
                    >
                        {operation === 'create' ? (
                            <LocaleMessage msg="button.add" />
                        ) : (
                            <LocaleMessage msg="button.update" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildListView() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
        ];

        const rowActions = [
            {
                id: 'settings',
                label: <LocaleMessage msg="button.settings" />,
                icon: <MdSettings />,
                action: handleSettingsOpen,
            },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={pluginspaceModules}
                    orderColumn="name"
                    rowActions={rowActions}
                />
            </div>
        );
    }

    // const rc3_available = pluginspaceModules.find(m => {
    //     return m.slug === 'rc3';
    // });

    return (
        <>
            {deleteOpen ? buildModuleDeleteDialog() : null}
            {settingsOpen ? buildModuleSettingsDialog() : null}
            {formOpen ? buildFormDialog() : null}
            <CardSideBordered
                title={
                    <LocaleMessage msg="page.su_pluginspace.form.label.module_list" />
                }
                hide
                Icon={MdWidgets}
            >
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {/* <div className="body-top-controls">
                            <Button
                                className="col-12"
                                onClick={event => handleFreeModules(event)}
                                variant="contained"
                                color="primary"
                                startIcon={<MdMoneyOff />}
                                style={{
                                    whiteSpace: 'nowrap',
                                    padding: '5px 20px',
                                }}
                            >
                                <LocaleMessage msg="page.su_pluginspace.form.module.free" />
                            </Button>
                        </div> */}
                        {renderAddonButton({
                            label: (
                                <LocaleMessage msg="page.su_pluginspace.form.module.add" />
                            ),
                            icon: <MdAdd />,
                            onClick: () => handleNewModule(),
                        })}
                        {buildListView()}
                    </>
                )}
            </CardSideBordered>
        </>
    );
}

PluginspaceModules.defaultProps = {
    module_list: [],
};

PluginspaceModules.propTypes = {
    pluginspace_id: PropTypes.string.isRequired,
    module_list: PropTypes.array,
    requestError: PropTypes.func.isRequired,
    renderAddonButton: PropTypes.func.isRequired,
};
