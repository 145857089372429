/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { MdOutlineHelp } from 'react-icons/md';
import * as MaterialDesign from 'react-icons/md';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { Button, ButtonGroup } from '@material-ui/core';

import { switchProp } from '~/store/modules/pages/actions';

const iconSize = 20;

export default function ViewSwitcher({
    view,
    setView,
    pageKey,
    size,
    options,
}) {
    const dispatch = useDispatch();

    function renderOption(option) {
        const { key, icon } = option;
        const mdIcon = MaterialDesign[icon];

        return (
            <Button
                key={key}
                onClick={() => {
                    if (pageKey) {
                        dispatch(switchProp(pageKey, 'view', key));
                    }
                    setView(key);
                }}
                className={view === key ? 'active' : ''}
            >
                {mdIcon ? (
                    React.createElement(mdIcon, {
                        size: iconSize,
                    })
                ) : (
                    <MdOutlineHelp size={iconSize} />
                )}
            </Button>
        );
    }

    return (
        <div className={`${size} view-control`}>
            <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"
            >
                {options.map(o => {
                    return renderOption(o);
                })}
            </ButtonGroup>
        </div>
    );
}

ViewSwitcher.propTypes = {
    setView: PropTypes.func.isRequired,
    view: PropTypes.string,
    size: PropTypes.string,
    options: PropTypes.array,
    pageKey: PropTypes.string,
};

ViewSwitcher.defaultProps = {
    view: 'list',
    size: 'col-3',
    pageKey: '',
    options: [
        {
            key: 'list',
            icon: 'MdFormatListBulleted',
        },
        {
            key: 'grid',
            icon: 'MdViewModule',
        },
    ],
};
