/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import hexToRgba from 'hex-to-rgba';
import PropTypes from 'prop-types';
import {
    BarChart,
    Bar,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid,
} from 'recharts';

import { Divider } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import CounterCard from '~/components/Reports/CounterCard';
import ExportCSVButton from '~/components/Reports/CSVButton';
import ReportDateChart from '~/components/Reports/ReportDateChart';
import ReportHeader from '~/components/Reports/ReportHeader';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import ReportTimeChart from '~/components/Reports/ReportTimeChart';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetDateTimeLabel from '~/util/GetDateTimeLabel';
import GetFileName from '~/util/GetFileName';
import GetOperationTime from '~/util/GetOperationTime';
import GetPeriodDates from '~/util/GetPeriodDates';
import lng_labels from '~/util/LangMessages';

import { TotalContainer, ChartsContainer, ChartCard, RowArea } from '../style';

const operation_mode_labels = {
    delivery_normal: 'list.operations.mode.delivery_normal',
    delivery_direct: 'list.operations.mode.delivery_direct',
    delivery_special: 'list.operations.mode.delivery_special',
    undefined: 'list.operations.mode.undefined',
};

export default function DeliveryReports({
    settings,
    headerSettings,
    requestError,
    counterData,
    CustomTimeTooltip,
    options,
}) {
    const {
        fromISO,
        untilISO,
        minDate,
        shortcuts,
        handleFromDateChange,
        handleUntilDateChange,
        handlePeriodShortcut,
    } = headerSettings;

    const { active, colors, format, locale, dateOptions, dark_mode } = settings;

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [rawData, setRawData] = useState({});
    const [list, setList] = useState([]);
    const [groupedData, setGroupedData] = useState({});
    const [, setCurrItem] = useState(null);

    const headers_table = [
        {
            id: 'start',
            order_by: 'start_timestamp',
            label: <LocaleMessage msg="table.headers.start_date" />,
        },
        {
            id: 'robot_name',
            label: <LocaleMessage msg="table.headers.robot" />,
        },
        {
            id: 'map_name',
            label: <LocaleMessage msg="table.headers.map" />,
        },
        {
            id: 'map_point_name',
            label: <LocaleMessage msg="table.headers.map_point" />,
        },
        {
            id: 'mode_str',
            label: <LocaleMessage msg="table.headers.mode" />,
        },
        {
            id: 'end',
            order_by: 'end_timestamp',
            label: <LocaleMessage msg="table.headers.end_date" />,
        },
        {
            id: 'duration_label',
            order_by: 'duration',
            label: <LocaleMessage msg="table.headers.duration" />,
        },
    ];

    const headers_file = [
        { key: 'group_name', label: 'Group' },
        { key: 'robot_name', label: 'Robot' },
        { key: 'application_name', label: 'Application' },
        { key: 'map_name', label: 'Map' },
        { key: 'map_point_name', label: 'Map Point' },
        { key: 'point_reference', label: 'Point Reference' },
        { key: 'mode_str', label: 'Mode' },
        {
            key: 'start_date',
            label: 'Start Date',
        },
        {
            key: 'start_time',
            label: 'Start Time',
        },
        {
            key: 'end_date',
            label: 'End Date',
        },
        {
            key: 'end_time',
            label: 'End Time',
        },
        {
            key: 'duration_label',
            label: 'Duration',
        },
    ];

    function filterData(data) {
        const lang_str = lng_labels[format ? format.code : 'pt_BR'];
        const { robots, groups, applications, maps, map_points } = counterData;

        const { deliveries, period } = data;
        if (!deliveries) return;
        const deliveries_list = deliveries ? deliveries.list : [];

        const d_robots = [];
        const d_modes = [];
        const d_maps = [];
        const d_map_points = [];
        const d_groups = [];
        const d_applications = [];
        const d_e_date = [];
        const d_e_time = [];
        const d_s_date = [];
        const d_s_time = [];
        const d_m_time = [];

        let duration_sum = 0;
        // let has_time = 0;
        let longest = 0;
        const dlist = deliveries_list.map(d => {
            const d_time = d.time && d.time > 0 ? d.time : 0;
            // if (d_time) {
            //     has_time++;
            // }
            duration_sum += d_time;

            const start = GetDateTimeLabel(new Date(d.start), { format });
            const end = d.end
                ? GetDateTimeLabel(new Date(d.end), { format })
                : null;

            const robot = robots[d.robot_id];
            if (!robots[d.robot_id] || robots[d.robot_id].code === '---') {
                robots[d.robot_id] = { code: d.robot_code || '---', name: '' };
            }

            const group = groups[d.group_id];

            const application = applications[d.application_id];

            const r_map = maps[d.map_id];

            const map_point = map_points[d.map_point_id];

            const m_time = d_time / 60;
            const m_time_label = Math.floor(m_time);
            if (m_time_label > longest) {
                longest = m_time_label;
            }

            d_robots[d.robot_id] = {
                total: d_robots[d.robot_id]
                    ? d_robots[d.robot_id].total + 1
                    : 1,
                time: d_robots[d.robot_id]
                    ? d_robots[d.robot_id].time + d_time
                    : d_time,
            };

            d_groups[d.group_id] = {
                total: d_groups[d.group_id]
                    ? d_groups[d.group_id].total + 1
                    : 1,
                time: d_groups[d.group_id]
                    ? d_groups[d.group_id].time + d_time
                    : d_time,
            };

            d_applications[d.application_id] = {
                total: d_applications[d.application_id]
                    ? d_applications[d.application_id].total + 1
                    : 1,
                time: d_applications[d.application_id]
                    ? d_applications[d.application_id].time + d_time
                    : d_time,
            };

            d_maps[d.map_id] = {
                total: d_maps[d.map_id] ? d_maps[d.map_id].total + 1 : 1,
                time: d_maps[d.map_id]
                    ? d_maps[d.map_id].time + d_time
                    : d_time,
            };

            d_map_points[d.map_point_id] = {
                total: d_map_points[d.map_point_id]
                    ? d_map_points[d.map_point_id].total + 1
                    : 1,
                time: d_map_points[d.map_point_id]
                    ? d_map_points[d.map_point_id].time + d_time
                    : d_time,
            };

            d_modes[d.mode || '---'] = {
                total: d_modes[d.mode || '---']
                    ? d_modes[d.mode || '---'].total + 1
                    : 1,
                time: d_modes[d.mode || '---']
                    ? d_modes[d.mode || '---'].time + d_time
                    : d_time,
            };

            if (end) {
                d_e_date[end.date] = d_e_date[end.date]
                    ? d_e_date[end.date] + 1
                    : 1;
                d_e_time[end.hour] = d_e_time[end.hour]
                    ? d_e_time[end.hour] + 1
                    : 1;
            }

            d_s_date[start.date] = d_s_date[start.date]
                ? d_s_date[start.date] + 1
                : 1;
            d_s_time[start.hour] = d_s_time[start.hour]
                ? d_s_time[start.hour] + 1
                : 1;

            const duration = GetOperationTime(d.time);

            const mode_key = operation_mode_labels[d.mode || 'undefined'];

            return {
                ...d,
                group_name: group ? group.name : '---',
                robot_name: robot
                    ? `[${robot.code}] ${robot.name}`
                    : `[${d.robot_code || '---'}]`,
                application_name: application ? application.name : '---',
                mode: d.mode || '---',
                mode_str: lang_str[mode_key],
                map_name: r_map ? r_map.name : '---',
                map_point_name: map_point
                    ? map_point.name
                    : `--- [${d.point_reference}]`,
                start_date: start.date,
                start_time: start.time,
                start: start.string,
                start_timestamp: start.timestamp,
                end_date: end ? end.date : '',
                end_time: end ? end.time : '',
                end: end ? end.string : '',
                end_timestamp: end ? end.timestamp : '',
                duration: d_time || 0,
                duration_label: end && d_time ? duration.label : '---',
            };
        });

        let extra_time = 0;
        const limit_time = longest > 0 && longest <= 60 ? longest + 10 : 60;
        const duration_spacer = limit_time > 20 ? 5 : 1;

        dlist.forEach(d => {
            const time = Math.floor(d.duration / 60);
            const time_label =
                Math.floor(time / duration_spacer) * duration_spacer || 0;

            if (time_label > limit_time) {
                extra_time++;
                return;
            }
            d_m_time[time_label] = d_m_time[time_label]
                ? d_m_time[time_label] + 1
                : 1;
        });

        const robot_grouped = Object.keys(d_robots).map(r => {
            const val = d_robots[r];
            const obj = robots[r];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);

            return {
                name: obj ? `[${obj.code}] ${obj.name || ''}` : `---`,
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const group_grouped = Object.keys(d_groups).map(g => {
            const val = d_groups[g];
            const obj = groups[g];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);

            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const application_grouped = Object.keys(d_applications).map(a => {
            const val = d_applications[a];
            const obj = applications[a];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);

            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const mode_grouped = Object.keys(d_modes).map(m => {
            const val = d_modes[m];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);
            return {
                name: m.toUpperCase(),
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const map_grouped = Object.keys(d_maps).map(m => {
            const val = d_maps[m];
            const obj = maps[m];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);

            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const point_grouped = Object.keys(d_map_points).map(p => {
            const val = d_map_points[p];
            const obj = map_points[p];
            const time = val ? val.time : 0;
            const parsed_time = GetOperationTime(time);

            return {
                name: obj ? obj.name : '---',
                total: val ? val.total : 0,
                time,
                time_label: parsed_time.label,
            };
        });

        const period_dates = GetPeriodDates(period);

        const d_labels = [];
        const s_date_grouped = [];
        const e_date_grouped = [];

        period_dates.forEach(p => {
            const formatted_date = new Date(p).toLocaleDateString(
                format.format,
                dateOptions
            );
            const label_date = new Date(p).toLocaleDateString(format.format, {
                month: '2-digit',
                day: '2-digit',
            });

            d_labels.push(label_date);

            s_date_grouped.push({
                date: label_date,
                value: d_s_date[formatted_date] || 0,
            });

            e_date_grouped.push({
                date: label_date,
                value: d_e_date[formatted_date] || 0,
            });
        });

        const t_labels = [];
        const s_time_grouped = [];
        const e_time_grouped = [];
        const m_time_grouped = [];

        for (let t = 0; t < 24; t++) {
            const label_time = `${t}h`;
            t_labels.push(label_time);

            s_time_grouped.push({
                hour: label_time,
                value: d_s_time[t] || 0,
            });

            e_time_grouped.push({
                hour: label_time,
                value: d_e_time[t] || 0,
            });
        }

        let end_label = '';
        for (let t = 0; t <= limit_time; t += duration_spacer) {
            end_label = t + duration_spacer;
            const label_time = `${t} - ${end_label} min`;

            m_time_grouped.push({
                time: label_time,
                value: d_m_time[t] || 0,
            });
        }
        if (extra_time) {
            m_time_grouped.push({
                time: `> ${end_label} min`,
                value: extra_time || 0,
            });
        }

        const mean_duration = (
            Math.round((duration_sum / dlist.length) * 10 || 0) / 10
        ).toFixed(1);

        const duration_time = GetOperationTime(duration_sum);
        const mean_time = GetOperationTime(mean_duration);

        // Seconds to Minutes
        const mean_time_txt = `${Number(mean_time.hours) * 60 +
            Number(mean_time.minutes)}:${mean_time.seconds}`;

        setGroupedData({
            duration: duration_time,
            mean_duration: mean_time_txt,
            robots: robot_grouped,
            groups: group_grouped,
            applications: application_grouped,
            start_date: s_date_grouped,
            start_time: s_time_grouped,
            end_date: e_date_grouped,
            end_time: e_time_grouped,
            op_time: m_time_grouped,
            modes: mode_grouped,
            maps: map_grouped,
            map_points: point_grouped,
            d_labels,
            t_labels,
        });

        setList(dlist);
    }

    async function loadData() {
        setIsLoading(true);
        let query_filters = ``;
        if (options && options.application) {
            query_filters += `&application_id=${options.application}`;
        }
        if (options && options.robot) {
            query_filters += `&robot_id=${options.robot}`;
        }
        await api
            .get(
                `reports/deliveries?from=${fromISO}&until=${untilISO}${query_filters}`
            )
            .then(response => {
                const { data } = response;

                setRawData(data);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function buildListView() {
        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headers_table}
                    data={list || []}
                    orderDirection="desc"
                    orderColumn="start_timestamp"
                    hasActions={false}
                    sortable
                    hasFilter
                    handleTableRowClick={(e, _id) => {
                        setCurrItem(_id);
                    }}
                />
            </div>
        );
    }

    function buildDateChart(title, data, key = 'value') {
        return (
            <ReportDateChart
                title={title}
                color={colors.dashboard_graphs}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildHourChart(title, data, key = 'value') {
        return (
            <ReportTimeChart
                title={title}
                color={colors.dashboard_cards}
                data={data}
                labels={groupedData.d_labels}
                dataKey={key}
            />
        );
    }

    function buildDurationChart(title, data) {
        return (
            <ChartCard className="col-md-8 col-12 area-chart-container">
                <div>
                    <p className="card-title">{title}</p>
                </div>
                <ResponsiveContainer
                    className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-5"
                    width="100%"
                    height={300}
                >
                    <BarChart data={data || []}>
                        <CartesianGrid />
                        <XAxis dataKey="time" />
                        <YAxis allowDecimals={false} />
                        <Tooltip />
                        <Bar
                            dataKey="value"
                            fill={`${hexToRgba(colors.dashboard_cards, 0.7)}`}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </ChartCard>
        );
    }

    function CSVButton() {
        return (
            <ExportCSVButton
                data={list}
                headers={headers_file}
                filename={GetFileName(`REPORTS-delivery`, 'csv')}
            />
        );
    }

    useEffect(() => {
        filterData(rawData);
    }, [rawData, locale]);

    useEffect(() => {
        loadData();
    }, [active, fromISO, untilISO]);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const deliveries =
        rawData && rawData.deliveries ? rawData.deliveries.total : 0;

    const show_group_card = active && active.id === '*';
    const def_class = `col-md-4 col-12 mb-5`;

    const pie_charts = [
        ...(show_group_card
            ? [
                  {
                      key: 'groups',
                      title: 'per_group',
                      color: colors.dashboard_cards,
                      data: groupedData.groups,
                  },
              ]
            : []),
        {
            key: 'applications',
            title: 'per_application',
            color: colors.dashboard_graphs,
            data: groupedData.applications,
        },
        {
            key: 'robots',
            title: 'per_robot',
            color: colors.dashboard_cards,
            data: groupedData.robots,
        },
        {
            key: 'modes',
            title: 'per_mode',
            color: colors.dashboard_graphs,
            data: groupedData.modes,
        },
        {
            key: 'maps',
            title: 'per_map',
            color: colors.dashboard_cards,
            data: groupedData.maps,
        },
        {
            key: 'map_points',
            title: 'per_map_point',
            color: colors.dashboard_graphs,
            data: groupedData.map_points,
        },
    ];

    return (
        <>
            <>
                <ReportHeader
                    date_props={{
                        locale,
                    }}
                    period={{
                        minDate,
                        fromDate: new Date(fromISO),
                        untilDate: new Date(untilISO),
                    }}
                    handleFromDateChange={handleFromDateChange}
                    handleUntilDateChange={handleUntilDateChange}
                    reloadData={() => loadData()}
                    exportButton={CSVButton()}
                    shortcuts={shortcuts}
                    handleShortcutClick={_id => handlePeriodShortcut(_id)}
                />
            </>
            <div className="sidecard-body mt-3">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        <TotalContainer className="mt-3 mb-5">
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.deliveries.label.total_deliveries" />
                                }
                                value={deliveries}
                            />
                        </TotalContainer>
                        <Divider />
                        <ChartsContainer className="row mt-5 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'total';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.deliveries.label.${c.title}.${datakey}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                        customization={{
                                            stroke: dark_mode
                                                ? '#424242'
                                                : '#ddd',
                                        }}
                                    />
                                );
                            })}
                        </ChartsContainer>
                        <Divider />
                        <ChartsContainer className="row mt-5 mb-3">
                            {pie_charts.map(c => {
                                const datakey = 'time';
                                return (
                                    <ReportPieChart
                                        key={`${datakey}_${c.key}`}
                                        classes={def_class}
                                        title={
                                            <LocaleMessage
                                                msg={`page.reports.deliveries.label.${c.title}.${datakey}`}
                                            />
                                        }
                                        color={c.color}
                                        data={c.data}
                                        dataKey={datakey}
                                        customization={{
                                            tooltip: CustomTimeTooltip,
                                            stroke: dark_mode
                                                ? '#424242'
                                                : '#ddd',
                                        }}
                                    />
                                );
                            })}
                        </ChartsContainer>
                        <Divider />
                        <div className="row mt-3 mb-3">
                            {buildDateChart(
                                <LocaleMessage msg="page.reports.deliveries.label.per_s_date" />,
                                groupedData.start_date
                            )}
                            {buildHourChart(
                                <LocaleMessage msg="page.reports.deliveries.label.per_s_hour" />,
                                groupedData.start_time
                            )}
                        </div>
                        <Divider />
                        <TotalContainer
                            className="mt-3 mb-3"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <CounterCard
                                title={
                                    <LocaleMessage msg="page.reports.deliveries.label.mean_duration" />
                                }
                                value={
                                    groupedData.mean_duration
                                        ? groupedData.mean_duration
                                        : '00:00'
                                }
                                footer={
                                    <LocaleMessage msg="label.time.minutes_seconds" />
                                }
                            />
                            {buildDurationChart(
                                <LocaleMessage msg="page.reports.deliveries.label.per_duration" />,
                                groupedData.op_time
                            )}
                        </TotalContainer>
                        <Divider />
                        <RowArea className="mt-3 mb-5">
                            <div>
                                <p className="card-title">
                                    <LocaleMessage msg="page.reports.deliveries.label.history" />
                                </p>
                            </div>
                            {buildListView()}
                        </RowArea>
                    </>
                )}
            </div>
        </>
    );
}

DeliveryReports.defaultProps = {
    options: {},
};

DeliveryReports.propTypes = {
    settings: PropTypes.object.isRequired,
    headerSettings: PropTypes.object.isRequired,
    requestError: PropTypes.func.isRequired,
    counterData: PropTypes.object.isRequired,
    CustomTimeTooltip: PropTypes.func.isRequired,
    options: PropTypes.object,
};
