import styled from 'styled-components';

export const Container = styled.div`
    padding: 20px 20px 0px 20px;

    .breadcrumb {
        background: none !important;
        padding: 20px 0px;
        margin-bottom: 0px;
        span {
            color: ${props => {
                return props.theme.dark_mode ? '#eee' : '#6c757d';
            }};
        }
        a {
            color: ${props =>
                props.theme.palette.pluginspace.primary} !important;
            :hover {
                font-weight: bold;
            }
        }
    }

    h1 {
        color: ${props => {
            return props.theme.dark_mode ? '#fff' : '#252525';
        }};
        font-weight: bold;
    }
`;
