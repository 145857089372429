import { darken } from 'polished';
import styled from 'styled-components';

export const Splash = styled.div`
    display: flex;
    padding: 0px;
    margin: 0px;
    background: ${props => props.theme.palette.primary.main};
    width: 100%;
    height: 100vh;
    z-index: 3000;
    position: absolute;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const Container = styled.div`
    padding-top: 70px;
    height: 100vh;
    color: ${props => {
        return props.theme.dark_mode ? '#fff' : '#252525';
    }};
    background: ${props => {
        const color = props.theme.palette.primary.main;
        return props.theme.dark_mode
            ? `linear-gradient(-45deg, ${darken(0.4, '#000')}, ${darken(
                  0.5,
                  color
              )})`
            : '#f4f4f7';
    }};

    .MuiTypography-h6 {
        color: ${props => {
            return props.theme.dark_mode ? '#fff' : '#252525';
        }} !important;
    }
`;

export const DarkBody = styled.div`
    background: ${props => {
        const color = props.theme.palette.primary.main;
        const { bg } = props;

        return `linear-gradient(45deg, ${darken(0.3, color)}, ${color}), ${
            bg ? ` url(${bg})` : ''
        }`;
    }};
    background-size: cover;
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    padding: 0px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 70px;
`;

export const Body = styled.div`
    padding: 0px;
    max-width: 1400px;
    margin: auto;
    min-height: 100vh;
`;

export const Logo = styled.div`
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
`;

export const NullDiv = styled.div``;
