import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;

    .counter-card {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: ${props => {
            const color = `5px solid ${props.theme.palette.primary.main}`;
            return props.bordered ? color : 'none';
        }};
        background: ${props => {
            return props.theme.dark_mode ? '#424242' : '#fff';
        }};

        .card-heading {
            color: ${props => {
                const theme = props.theme || {};
                const color = theme.palette.primary.main;
                return theme.dark_mode ? '#fff' : color;
            }};
        }

        .value {
            font-weight: bold;
            font-size: 42px;
            color: ${props => {
                const color = props.theme.palette.primary.main;
                return props.theme.dark_mode ? '#fff' : color;
            }};
        }
    }
`;
