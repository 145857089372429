/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { addDays } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core';

import CustomDialogTitle from '~/components/CustomDialogTitle';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import getFormats from '~/util/GetFormats';

export default function RecycleBinList({
    page,
    requestError,
    filter,
    headers,
    apiURL,
    title,
    successMessage,
    confirmationMessage,
    contentMessage,
}) {
    const settings = useSelector(state => state.settings || null);
    const formats = getFormats(settings);

    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [filteredItems, setFilteredItems] = useState([]);
    const [currItem, setCurrItem] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    const headCells = [
        ...headers.map(h => {
            return {
                id: h.id,
                label: <LocaleMessage msg={h.label} />,
            };
        }),
        {
            id: 'removed_by',
            order_by: 'removed_by',
            label: <LocaleMessage msg="table.headers.removed_by" />,
        },
        {
            id: 'removed_datetime',
            order_by: 'removed_timestamp',
            label: <LocaleMessage msg="table.headers.removed" />,
        },
    ];

    function filterData(raw_data) {
        const data = raw_data.map(d => {
            const updated = new Date(d.updated);
            const u_date = updated.toLocaleTimeString(
                formats.format.format,
                formats.date
            );

            const limit = addDays(updated, 30);
            const l_date = limit.toLocaleTimeString(
                formats.format.format,
                formats.date
            );

            return {
                ...d,
                group_name: d.group && d.group.name ? d.group.name : '---',
                removed_datetime: u_date,
                removed_timestamp: updated.toISOString(),
                removed_by:
                    d.updated_by && d.updated_by.name ? d.updated_by.name : '',
                limit_date: l_date,
                limit_timestamp: limit.toISOString(),
            };
        });
        setFilteredItems(data);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(apiURL)
            .then(response => {
                const { data } = response;
                setItems(data);
            })
            .catch(error => requestError(error));
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    async function updateItem(event) {
        event.preventDefault();
        setDialogOpen(false);
        setCurrItem(null);

        if (!currItem) {
            return toast.error(
                <LocaleMessage msg="errors.content_not_found" />
            );
        }
        setIsLoading(true);

        await api
            .put(`${apiURL}/${currItem.id}`, {})
            .then(() => {
                toast.success(<LocaleMessage msg={successMessage} />);
            })
            .catch(error => requestError(error));
        loadItems();
        return setIsLoading(false);
    }

    useEffect(() => {
        loadItems();
    }, [page, apiURL]);

    useEffect(() => {
        filterData(items);
    }, [filter, items]);

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen(false);
        setCurrItem(null);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        const application = items.find(a => {
            return a.id === id;
        });
        setCurrItem(application);
        setDialogOpen(true);
    }

    function buildListView() {
        const rowActions = [];

        return (
            <div style={{ minHeight: '150px', width: '100%', padding: '15px' }}>
                <DataTable
                    headerColumns={headCells}
                    data={filteredItems}
                    orderColumn="removed_datetime"
                    rowActions={rowActions}
                    handleTableRowClick={(event, id) =>
                        handleTableRowClick(event, id)
                    }
                />
            </div>
        );
    }

    function buildConfirmDialog() {
        return (
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={() => {
                        setDialogOpen(false);
                    }}
                    maxWidth="sm"
                    fullWidth
                >
                    <CustomDialogTitle
                        title={<LocaleMessage msg={confirmationMessage} />}
                    />
                    <DialogContent>
                        {contentMessage ? (
                            <LocaleMessage msg={contentMessage} />
                        ) : (
                            <div className="col-12 mb-3" />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <>
                            <Button onClick={handleDialogClose} color="primary">
                                <LocaleMessage msg="button.cancel" />
                            </Button>
                            <Button
                                onClick={e => updateItem(e)}
                                color="primary"
                                autoFocus
                            >
                                <LocaleMessage msg="button.recover" />
                            </Button>
                        </>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    return isLoading ? (
        <Splash />
    ) : (
        <>
            {dialogOpen ? buildConfirmDialog() : null}
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg={title} />
                </h2>
            </div>
            <>{buildListView()}</>
        </>
    );
}

RecycleBinList.defaultProps = {
    filter: '',
    headers: [
        {
            id: 'group_name',
            label: 'table.headers.group',
        },
        {
            id: 'name',
            label: 'table.headers.name',
        },
    ],
    title: '',
    successMessage: '',
    confirmationMessage: '',
    contentMessage: '',
};

RecycleBinList.propTypes = {
    page: PropTypes.string.isRequired,
    filter: PropTypes.string,
    requestError: PropTypes.func.isRequired,
    headers: PropTypes.array,
    apiURL: PropTypes.string.isRequired,
    title: PropTypes.string,
    successMessage: PropTypes.string,
    confirmationMessage: PropTypes.string,
    contentMessage: PropTypes.string,
};
