/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import ReactLoading from 'react-loading';

import PropTypes from 'prop-types';

import {
    Button,
    Card,
    TextField,
    FormControl,
    InputLabel,
    Select,
    Menu,
    MenuItem,
} from '@material-ui/core';

import ColorInput from '~/components/ColorInput';
import FormSwitch from '~/components/Form/Switch';
import GalleryView from '~/components/GalleryView';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';

import lists from '~/config/Lists';
import api from '~/services/pluginbot-api';

import { ParameterArea, MediaArea } from '../../styles';

export default function VisualSetup({
    stepType,
    file,
    colors,
    templates,
    galleries,
    settings,
    onChange,
    handleError,
}) {
    const defColor = colors && colors.primary ? colors.primary : '#000';
    const image_settings = settings && settings.image ? settings.image : {};

    const mediatype_list = [
        {
            value: 'no-media',
            label: <LocaleMessage msg="label.form.no_media" />,
        },
        {
            value: 'gallery',
            label: (
                <LocaleMessage msg="page.survey_steps.form.visual.label.media.gallery" />
            ),
        },
        {
            value: 'url',
            label: (
                <LocaleMessage msg="page.survey_steps.form.visual.label.media.url" />
            ),
        },
        {
            value: 'body',
            label: (
                <LocaleMessage msg="page.survey_steps.form.visual.label.media.body" />
            ),
        },
    ];

    const allow_image = {
        step_default: false,
        step_side_by_side: true,
        display_default: true,
        image_default: false,
        end_default: true,
        end_side_by_side: true,
    };

    const template = file && file.name ? file.name : '';
    const template_image =
        template && allow_image[template] ? allow_image[template] : false;

    const [colorPicker, setColorPicker] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const [currGallery, setCurrGallery] = useState('');
    const [currGalleryImg, setCurrGalleryImg] = useState({});
    const [fileList, setFileList] = useState([]);
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);

    async function loadFiles(_id) {
        if (!_id) {
            return setFileList([]);
        }
        setIsLoading(true);
        await api
            .get(`albums/${_id}/files`)
            .then(response => {
                const files = response.data;
                setFileList(files);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
        return true;
    }

    async function onFileUpload(e) {
        const { files } = e.target;
        const data = new FormData();

        setIsLoading(true);

        Array.from(files).forEach(f => {
            data.append('files', f);
        });
        await api
            .post(`albums/${currGallery}/files`, data)
            .then(async () => {
                await loadFiles(currGallery);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    function handleImgChange(key, value) {
        const image =
            key !== 'source'
                ? {
                      ...image_settings,
                      [key]: value,
                  }
                : {
                      active: image_settings.active,
                      [key]: value,
                  };
        onChange({ ...settings, image });
    }

    function handleColorClick(event) {
        setAnchorEl(event.currentTarget);

        setColorPicker(true);
    }

    function handleColorClose() {
        setColorPicker(false);
    }

    function handleColorChange(c) {
        const sel_color = c.hex;
        onChange({
            ...settings,
            color: sel_color,
        });
    }

    function handleMediaDialogClose() {
        setMediaDialogOpen(false);
    }

    function handleItemClick(event, index) {
        const g_file = fileList[index] || {};
        setCurrGalleryImg({
            id: g_file.id,
            name: g_file.id,
            url: g_file.url,
        });
        onChange({
            ...settings,
            image: {
                ...settings.image,
                file_id: g_file.id,
                file_url: g_file.url,
                url: '',
            },
        });
        setMediaDialogOpen(false);
    }

    useEffect(() => {
        if (image_settings.file_id) {
            setCurrGalleryImg({
                id: image_settings.file_id || null,
                name: image_settings.file_id || null,
                url: image_settings.file_url || null,
            });
        }
    }, [image_settings]);

    useEffect(() => {
        loadFiles(currGallery);
    }, [currGallery, galleries]);

    function renderGallery() {
        const currGalleryObj = galleries.find(g => {
            return g.id === currGallery;
        });
        return (
            <>
                <FormControl fullWidth>
                    <InputLabel>
                        <LocaleMessage msg="page.applications.telepresence.form.gallery" />
                    </InputLabel>
                    <Select
                        value={currGallery || ''}
                        onChange={event => setCurrGallery(event.target.value)}
                    >
                        {galleries.map(g => (
                            <MenuItem key={g.id} value={g.id}>
                                {/* {!g.editable ? (
                                    <Chip
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        label={
                                            <LocaleMessage msg="message.open_all.short" />
                                        }
                                        style={{ marginRight: '10px' }}
                                    />
                                ) : null} */}
                                {g.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="sidecard-body" style={{ height: '45vh' }}>
                    {isLoading ? (
                        <div
                            className="mt-5"
                            style={{
                                flex: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ReactLoading
                                type="bars"
                                color="#c8c8c8"
                                height={50}
                                width={50}
                            />
                        </div>
                    ) : (
                        <>
                            {currGalleryObj ? (
                                <GalleryView
                                    media={fileList}
                                    onClick={(e, _id) =>
                                        handleItemClick(e, _id)
                                    }
                                    allowNew={currGalleryObj.editable}
                                    fileUpload={e => onFileUpload(e)}
                                />
                            ) : null}
                        </>
                    )}
                </div>
            </>
        );
    }

    function renderMediaDialog() {
        return (
            <SimpleDialog
                open={mediaDialogOpen}
                onClose={() => handleMediaDialogClose()}
                title={
                    <LocaleMessage msg="page.applications.telepresence.form.media" />
                }
                content={renderGallery('image')}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setMediaDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                ]}
            />
        );
    }

    function renderImageOptions() {
        const img_previews = {
            url:
                settings.image && settings.image
                    ? settings.image && settings.image
                    : {},
            gallery: currGalleryImg || {},
        };
        const img_settings = settings && settings.image ? settings.image : {};
        const img_source = img_settings.source || 'no-media';
        const img_show = img_previews[img_source] || {};

        return template_image &&
            settings &&
            settings.image &&
            settings.image.active ? (
            <ParameterArea className="col-12 row mb-3 p-3">
                <div className="col-md-7 col-12 pl-0 pt-3">
                    {settings.image && settings.image.active ? (
                        <div className="col-md-8 col-12">
                            <FormControl className="mb-5" fullWidth>
                                <InputLabel>
                                    <LocaleMessage msg="page.survey_steps.form.visual.label.media.source" />
                                </InputLabel>
                                <Select
                                    id="mediatype"
                                    value={
                                        settings.image && settings.image.source
                                            ? settings.image.source
                                            : 'no-media'
                                    }
                                    onChange={event =>
                                        handleImgChange(
                                            'source',
                                            event.target.value
                                        )
                                    }
                                >
                                    {mediatype_list.map(m => (
                                        <MenuItem key={m.value} value={m.value}>
                                            {m.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    ) : null}
                    {img_source !== 'no-media' ? (
                        <div className="col-12">
                            <p>
                                <LocaleMessage msg="page.survey_steps.form.visual.label.image.warning" />
                            </p>
                        </div>
                    ) : null}
                    {img_source === 'url' ? (
                        <div className="col-12">
                            <TextField
                                label={
                                    <LocaleMessage msg="page.survey_steps.form.visual.label.image.url" />
                                }
                                fullWidth
                                value={img_settings.url || ''}
                                onChange={event =>
                                    handleImgChange('url', event.target.value)
                                }
                            />
                        </div>
                    ) : null}
                    {img_source === 'body' ? (
                        <div className="col-12">
                            <TextField
                                label={
                                    <LocaleMessage msg="page.survey_steps.form.visual.label.image.var_name" />
                                }
                                fullWidth
                                value={img_settings.var_name || ''}
                                onChange={event =>
                                    handleImgChange(
                                        'var_name',
                                        event.target.value
                                    )
                                }
                            />
                        </div>
                    ) : null}
                    {img_source === 'gallery' ? (
                        <div className="col-md-8 col-12">
                            <Button
                                variant="contained"
                                size="large"
                                fullWidth
                                onClick={() => setMediaDialogOpen(true)}
                                color="primary"
                            >
                                <LocaleMessage msg="label.form.change_image" />
                            </Button>
                        </div>
                    ) : null}
                </div>
                <MediaArea className="col-md-5 col-12">
                    <Card
                        className="img-card"
                        style={{
                            border: '1px solid #999',
                            backgroundColor: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                        }}
                    >
                        {img_show && img_show.url ? (
                            <img
                                src={img_show ? img_show.url : null}
                                alt={img_show ? img_show.name : null}
                            />
                        ) : (
                            <LocaleMessage msg="page.survey_steps.form.visual.label.image.no_preview" />
                        )}
                    </Card>
                </MediaArea>
            </ParameterArea>
        ) : null;
    }

    function renderDisplayExtras() {
        return (
            <FormControl className="mb-5" fullWidth>
                <InputLabel>
                    <LocaleMessage msg="page.survey_steps.form.visual.label.text_size" />
                </InputLabel>
                <Select
                    value={
                        settings && settings.text_size ? settings.text_size : ''
                    }
                    onChange={event =>
                        onChange({
                            ...settings,
                            text_size: event.target.value,
                        })
                    }
                >
                    <MenuItem value="small">
                        <LocaleMessage msg="list.size.small" />
                    </MenuItem>
                    <MenuItem value="medium">
                        <LocaleMessage msg="list.size.medium" />
                    </MenuItem>
                    <MenuItem value="large">
                        <LocaleMessage msg="list.size.large" />
                    </MenuItem>
                </Select>
            </FormControl>
        );
    }

    function renderVisualSettings() {
        return (
            <div
                className="col-12"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <FormControl fullWidth className="mb-5">
                    <InputLabel>
                        <LocaleMessage msg="page.survey_steps.form.visual.label.step_template" />
                    </InputLabel>
                    <Select
                        id="template-id"
                        value={settings.template_id ? settings.template_id : ''}
                        onChange={event => {
                            onChange({
                                ...settings,
                                template_id: event.target.value,
                            });
                        }}
                    >
                        {templates.map(t => {
                            return (
                                <MenuItem key={t.id} value={t.id}>
                                    <LocaleMessage
                                        msg={`list.visual_templates.${t.slug}`}
                                    />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                {settings.template_id ? (
                    <>
                        {file ? (
                            <ColorInput
                                margin="mb-5"
                                title={
                                    <LocaleMessage msg="page.survey_steps.form.visual.label.color" />
                                }
                                color={
                                    settings.color ? settings.color : defColor
                                }
                                handleColorClick={event =>
                                    handleColorClick(event)
                                }
                            />
                        ) : null}

                        {stepType === 'display' ? renderDisplayExtras() : null}

                        {template_image ? (
                            <FormSwitch
                                className="mb-3"
                                value={
                                    template_image && settings.image
                                        ? settings.image.active
                                        : false
                                }
                                onChange={event =>
                                    handleImgChange(
                                        'active',
                                        event.target.checked
                                    )
                                }
                                disabled={!template_image}
                                label={
                                    <LocaleMessage msg="page.survey_steps.form.visual.label.image.use" />
                                }
                            />
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    }

    function renderColorPicker() {
        return colorPicker && anchorEl ? (
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={colorPicker}
                onClose={() => handleColorClose()}
            >
                <SketchPicker
                    color={settings.color ? settings.color : defColor}
                    onChange={color => handleColorChange(color)}
                    onChangeComplete={color => {
                        handleColorChange(color);
                    }}
                    disableAlpha
                    presetColors={
                        colors
                            ? [
                                  colors.primary,
                                  colors.secondary,
                                  ...lists.default_colors,
                              ]
                            : null
                    }
                />
            </Menu>
        ) : null;
    }

    function renderPreview() {
        return (
            <MediaArea className="col-md-5 col-12">
                <Card
                    className="img-card mb-3"
                    style={{
                        backgroundColor: settings.color
                            ? settings.color
                            : defColor,
                    }}
                >
                    <img
                        src={file && file.url ? file.url : ''}
                        alt={file && file.slug ? file.slug : ''}
                    />
                </Card>
                <p>
                    <LocaleMessage msg="page.survey_steps.form.visual.label.step_template.example" />
                </p>
            </MediaArea>
        );
    }

    return (
        <div
            className="row mt-3"
            style={{
                display: 'flex',
                alignItems: 'top',
                justifyContent: 'space-around',
            }}
        >
            <div
                className="col-md-6 col-12"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {mediaDialogOpen ? renderMediaDialog() : null}
                {renderColorPicker()}
                {renderVisualSettings()}
            </div>
            {file ? renderPreview() : null}
            <div className="col-12 mb-5">{renderImageOptions()}</div>
        </div>
    );
}

VisualSetup.defaultProps = {
    file: null,
    colors: {},
    templates: [],
    galleries: [],
};

VisualSetup.propTypes = {
    stepType: PropTypes.string.isRequired,
    file: PropTypes.object,
    colors: PropTypes.object,
    templates: PropTypes.array,
    galleries: PropTypes.array,
    settings: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
};
