/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    MdAdd,
    MdDelete,
    MdEdit,
    MdClose,
    MdContentCopy,
    MdDownload,
} from 'react-icons/md';
import { useSelector } from 'react-redux';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CustomDialogTitle from '~/components/CustomDialogTitle';
import DataTable from '~/components/DataTable';
import FileInput from '~/components/FileInput';
import FormSwitch from '~/components/Form/Switch';
import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import generateDownloadableFile from '~/util/GenerateDownloadableFile';
import getDateLocale from '~/util/GetDateLocale';
import lngLabels from '~/util/LangMessages';

import DialogConditionConfig from './DialogConditionConfig';

const useStyles = makeStyles(() => {
    return {
        row: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
});

const label_conditions = {
    robot_state: 'page.applications.conversation.content.condition_type.state',
    conversation:
        'page.applications.conversation.content.condition_type.conversation',
    dialog_node:
        'page.applications.conversation.content.condition_type.dialog_node',
    context: 'page.applications.conversation.content.condition_type.context',
    integration_resp:
        'page.applications.conversation.content.condition_type.integration_response',
};

const label_medias = {
    'no-media': 'page.applications.conversation.content.media_type.no_media',
    different: 'page.applications.conversation.content.media_type.different',
    face: 'page.applications.conversation.content.media_type.face',
    image: 'page.applications.conversation.content.media_type.image',
    buttons: 'page.applications.conversation.content.media_type.buttons',
    template: 'page.applications.conversation.content.media_type.template',
};

const label_states = {
    standby: 'page.applications.conversation.content.state.standby',
    wake_up: 'page.applications.conversation.content.state.wakeup',
    listening: 'page.applications.conversation.content.state.listening',
    thinking: 'page.applications.conversation.content.state.thinking',
};

export default function DialogContentConfig({
    setToast,
    handleError,
    appSettings,
    updateObject,
    robotTypes,
    currType,
    setCurrType,
}) {
    const settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(settings);
    const loc = settings.locale;
    const lang = loc && loc.code ? loc.code : 'pt_BR';

    const labels = lngLabels[lang];

    const { application_content, group } = appSettings;
    const group_id = group ? group.id : '*';

    const def_content = {
        application_id: appSettings.id,
        app_type: 'conversation',
        type: 'conversation',
    };

    const mountedRef = React.useRef(true);
    const [isLoading, setIsLoading] = useState(true);
    const [copyOpen, setCopyOpen] = useState(false);

    const [dialogList, setDialogList] = useState([]);
    const [contentList, setContentList] = useState([]);
    const [conditionList, setConditionList] = useState([]);

    const [imageGalleriesList, setImageGalleriesList] = useState([]);
    const [robotGalleries, setRobotGalleries] = useState({});

    const [contentOperation, setContentOperation] = useState('create');
    const [formOpen, setFormOpen] = useState(false);

    const [currContent, setCurrContent] = useState(application_content || {});
    const [currContentItem, setCurrContentItem] = useState(
        application_content.id
    );
    const [contentObj, setContentObj] = useState(def_content);
    const [deleteContentOpen, setDeleteContentOpen] = useState(false);

    const [currCondition, setCurrCondition] = useState('new');
    const [deleteConditionOpen, setDeleteConditionOpen] = useState(false);

    const [conditionOpen, setConditionOpen] = useState(false);
    const [changeContent, setChangeContent] = useState(!application_content.id);

    const [availableLanguages, setAvailableLanguages] = useState([]);
    const [templateContent, setTemplateContent] = useState({});

    const classes = useStyles();

    function getConditionStr(condition_obj) {
        const type = condition_obj.type || '';
        const condition = condition_obj.condition || {};

        const c_key = label_conditions[type];

        let condition_str = `${labels[c_key]}: `;

        switch (type) {
            case 'robot_state': {
                const s_key = label_states[condition.state];
                const s_name = (labels[s_key] || '').toUpperCase();
                condition_str += s_name;
                break;
            }
            case 'conversation': {
                const subject = `${
                    condition.subject && condition.subject !== '--any--'
                        ? `${condition.subject.toUpperCase()}`
                        : ``
                }`;
                const intent = condition.intent || condition.question;

                const final_subject = subject.replace(/:\*/g, '');
                const final_intent = (intent && intent !== '--any--'
                    ? intent
                    : ''
                ).toUpperCase();

                condition_str += [
                    ...(final_intent ? [`#${final_intent}`] : []),
                    ...(final_subject ? [`#${final_subject}`] : []),
                ].join(', ');
                break;
            }
            case 'dialog_node':
                condition_str = labels[c_key];
                break;
            default:
                break;
        }

        return condition_str;
    }

    function getMediaStr(media) {
        const { different_robots } = media;
        const r_type = different_robots && currType ? currType.slug : 'all';
        const m_all = media.all || {};
        const m_r_type = media[r_type] || m_all;
        const { type } = m_r_type;

        const m_key = different_robots
            ? label_medias.different
            : label_medias[type || 'no-media'];

        const media_str = `${labels[m_key]}`;

        return media_str;
    }

    function requestError(error) {
        handleError(error);
        setIsLoading(false);
    }

    async function loadLanguages() {
        await api
            .get(`languages/conversation`)
            .then(response => {
                const langs = response.data;
                setAvailableLanguages(langs);
            })
            .catch(err => requestError(err));
    }

    async function loadDialogs() {
        await api
            .get(`dialogs`)
            .then(async response => {
                const { data } = response;
                setDialogList(data);
            })
            .catch(error => requestError(error));
    }

    async function loadGalleryList() {
        await api
            .get(`albums?type=images`)
            .then(async response => {
                const filtered = response.data.filter(g => {
                    return (
                        g.group &&
                        (g.group.id === '*' || g.group.id === group_id)
                    );
                });
                setImageGalleriesList(filtered);
            })
            .catch(error => requestError(error));
    }

    async function loadRobotDefaults() {
        let filters = '';
        robotTypes.forEach((t, idx) => {
            filters += `${idx !== 0 ? '&' : ''}types[]=${t.id}`;
        });

        await api
            .get(`types/all_robots/defaults?${filters}`)
            .then(response => {
                const defaults = response.data;

                const default_galleries = {};
                defaults.forEach(g => {
                    const gallery_type = g.type;
                    const robot_type = g.robot_type ? g.robot_type.slug : 'all';

                    const g_body = { ...g };
                    delete g_body.robot_type;

                    const r_gallery = default_galleries[robot_type] || {};
                    if (r_gallery[gallery_type]) {
                        r_gallery[gallery_type].push(g_body);
                    }
                    r_gallery[gallery_type] = [g_body];

                    default_galleries[robot_type] = r_gallery;
                });
                setRobotGalleries(default_galleries);
            })
            .catch(error => requestError(error));
    }

    async function loadContents() {
        await api
            .get(`conversation_contents`)
            .then(async response => {
                const filtered = response.data.filter(c => {
                    return c.group && c.group.id === group_id;
                });
                const data = filtered.map(c => {
                    const updated = new Date(c.updated);
                    return {
                        ...c,
                        fullname: `${c.name} (${c.dialog.name}) `,
                        dialog_id: c.dialog.id,
                        dialog_name: `[${c.dialog.provider}] ${c.dialog.name}`,
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setContentList(data);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function loadConditions(_id) {
        if (_id) {
            setIsLoading(true);
            await api
                .get(`conversation_contents/${_id}/conditions`)
                .then(response => {
                    const data = response.data.map(c => {
                        const condition = c.condition || {};
                        const media = c.media || {};

                        const updated = new Date(c.updated);

                        return {
                            ...c,
                            title: c.description || getConditionStr(condition),
                            media_str: getMediaStr(media),
                            updated: formatDistance(updated, new Date(), {
                                addSuffix: true,
                                locale: date_loc,
                            }),
                            updated_timestamp: updated.toISOString(),
                        };
                    });
                    setConditionList(data);
                })
                .catch(error => requestError(error));
            setIsLoading(false);
        }
    }

    async function submitConfig() {
        updateObject({ application_content_id: currContentItem });
    }

    async function submitContent(event) {
        event.preventDefault();
        setIsLoading(true);
        setFormOpen(false);

        if (contentOperation === 'create') {
            const data = {
                ...contentObj,
                ...(contentObj.use_template && {
                    template: templateContent,
                }),
            };

            await api
                .post(`conversation_contents`, data)
                .then(async response => {
                    setToast({
                        color: 'success',
                        body: (
                            <LocaleMessage msg="page.applications.conversation.content.message.created" />
                        ),
                    });
                    updateObject({
                        application_content_id: response.data.id,
                    });
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`conversation_contents/${currContentItem}`, contentObj)
                .then(async () => {
                    setToast({
                        color: 'success',
                        body: (
                            <LocaleMessage msg="page.applications.conversation.content.message.updated" />
                        ),
                    });
                })
                .catch(error => requestError(error));
        }

        setContentObj({});
        await loadContents();
    }

    async function deleteContent() {
        setIsLoading(true);
        setDeleteContentOpen(false);

        await api
            .delete(`conversation_contents/${currContentItem}`)
            .then(async () => {
                setToast({
                    color: 'success',
                    body: (
                        <LocaleMessage msg="page.applications.conversation.content.message.deleted" />
                    ),
                });
                await loadContents();
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function deleteCondition() {
        setIsLoading(true);
        setDeleteConditionOpen(false);

        const content_id = currContentItem;

        await api
            .delete(
                `conversation_contents/${content_id}/conditions/${currCondition}`
            )
            .then(async () => {
                setToast({
                    color: 'success',
                    body: (
                        <LocaleMessage msg="page.applications.conversation.content.movement.deleted" />
                    ),
                });
                await loadConditions(content_id);
                setIsLoading(false);
            })
            .catch(error => requestError(error));
    }

    async function copyCondition(item) {
        setIsLoading(true);
        setCopyOpen(false);
        await api
            .post(
                `conversation_contents/${currContentItem}/conditions/${item}/clone`
            )
            .then(async () => {
                setToast({
                    color: 'success',
                    body: (
                        <LocaleMessage msg="page.applications.conversation.condition.created" />
                    ),
                });
                await loadConditions(currContentItem);
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    useEffect(() => {
        loadLanguages();
        loadDialogs();
        loadContents();
        loadGalleryList();
        loadRobotDefaults();
        loadConditions(currContent.id);
        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        loadContents();
        loadConditions(currContent.id);
    }, [date_loc]);

    function downloadData(event) {
        // Prepare the file
        const timestamp = new Date().toISOString();
        const filename = `CONVERSATION-${currContent.name}-${timestamp}.json`;
        const filedata = {
            application_content: currContent,
            conditions: conditionList,
        };
        generateDownloadableFile(event, {
            filename,
            data: filedata,
        });
    }

    const onFileUpload = async event => {
        const fileObj = event.target.files[0];
        const reader = new FileReader();

        let fileloaded = e => {
            const fileContents = e.target.result;
            const template = JSON.parse(fileContents);

            setTemplateContent({
                file: {
                    id: fileObj.name,
                    name: fileObj.name,
                },
                application_content: template.application_content || {},
                conditions: template.conditions || [],
            });
        };

        // Mainline of the method
        fileloaded = fileloaded.bind(this);
        reader.onload = fileloaded;
        reader.readAsText(fileObj);
    };

    function hideChangeContent() {
        setChangeContent(false);
    }

    function toggleChangeContent() {
        setChangeContent(!changeContent);
    }

    function closeConditionPage() {
        setConditionOpen(false);
        loadConditions(currContentItem);
    }

    function handleContentSelection(event, id) {
        event.preventDefault();
        setCurrContentItem(id);
        const newCurrContent = contentList.find(c => {
            return c.id === id;
        });
        if (newCurrContent) {
            setCurrContent(newCurrContent);
            loadConditions(id);
        }
        setChangeContent(false);

        const data = { application_content_id: id };
        updateObject(data);
    }

    function handleConditionSelection(event, _id) {
        setCurrCondition(_id);
        setConditionOpen(true);
    }

    function handleCopyItem(event, _id) {
        setCurrCondition(_id);
        event.preventDefault();
        setCopyOpen(true);
    }

    function handleNewContent(event) {
        event.stopPropagation();
        setFormOpen(true);
        setContentOperation('create');
        setContentObj(def_content);
        setTemplateContent({});
    }

    function handleEditContent(event, _id) {
        event.stopPropagation();
        event.preventDefault();
        const content = contentList.find(b => {
            return b.id === _id;
        });
        if (!content) {
            setToast({
                color: 'error',
                body: (
                    <LocaleMessage msg="page.applications.conversation.map_navigation.error" />
                ),
            });
            loadContents();
            return;
        }
        setContentObj({
            apptype: 'conversation',
            name: content.name,
            dialog_id: content.dialog_id,
        });
        setCurrContentItem(_id);
        setContentOperation('update');
        setFormOpen(true);
    }

    function handleDeleteContent(event, _id) {
        event.stopPropagation();
        event.preventDefault();
        setCurrContentItem(_id);
        setDeleteContentOpen(true);
    }

    function handleDeleteCondition(event, _id) {
        setCurrCondition(_id);
        event.preventDefault();
        setDeleteConditionOpen(true);
    }

    function handleFormClose(event) {
        event.preventDefault();
        setFormOpen(false);
    }

    function handleDeleteClose(event) {
        event.preventDefault();
        setDeleteContentOpen(false);
        setDeleteConditionOpen(false);
    }

    function handleCopyClose(event) {
        event.preventDefault();
        setCopyOpen(false);
    }

    function buildFormDialog() {
        return (
            <Dialog
                open={formOpen}
                onClose={handleFormClose}
                maxWidth="sm"
                fullWidth
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0px 15px 0px 0px',
                    }}
                >
                    <CustomDialogTitle
                        title={
                            <LocaleMessage msg="page.applications.conversation.content.message.new" />
                        }
                    />
                    <IconButton
                        aria-label="close"
                        onClick={handleFormClose}
                        style={{ padding: '5px' }}
                    >
                        <MdClose size={20} />
                    </IconButton>
                </div>
                <DialogContent>
                    <TextField
                        className="mb-3"
                        label={
                            <LocaleMessage msg="page.applications.conversation.content.label.name" />
                        }
                        fullWidth
                        value={contentObj.name ? contentObj.name : ''}
                        onChange={event =>
                            setContentObj({
                                ...contentObj,
                                name: event.target.value,
                            })
                        }
                    />
                    <FormControl className="mb-3" fullWidth>
                        <InputLabel>
                            <LocaleMessage msg="page.applications.conversation.content.label.dialog" />
                        </InputLabel>
                        <Select
                            id="dialog"
                            value={
                                contentObj && contentObj.dialog_id
                                    ? contentObj.dialog_id
                                    : ''
                            }
                            onChange={event =>
                                setContentObj({
                                    ...contentObj,
                                    dialog_id: event.target.value,
                                })
                            }
                            disabled={contentOperation === 'update'}
                        >
                            {dialogList.map(d => (
                                <MenuItem key={d.id} value={d.id}>
                                    {`[${d.provider}] - ${d.name}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {contentOperation === 'create' ? (
                        <>
                            <div className="col-12 mt-3 mb-3 row">
                                <FormSwitch
                                    value={contentObj.use_template || false}
                                    onChange={event =>
                                        setContentObj({
                                            ...contentObj,
                                            use_template: event.target.checked,
                                        })
                                    }
                                    label={
                                        <LocaleMessage msg="page.applications.conversation.content.template.use" />
                                    }
                                />
                            </div>
                            {contentObj.use_template ? (
                                <div
                                    className="col-12 mb-1"
                                    style={{
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div className="mb-3">
                                        <LocaleMessage msg="page.applications.conversation.content.template.warning" />
                                    </div>
                                    <FileInput
                                        defaultValue={
                                            templateContent &&
                                            templateContent.file
                                                ? templateContent.file
                                                : {}
                                        }
                                        onFileUpload={onFileUpload}
                                        multiple={false}
                                        text={
                                            <LocaleMessage msg="page.applications.conversation.content.template.upload" />
                                        }
                                        type="json"
                                        showFooter={false}
                                    />
                                </div>
                            ) : null}
                        </>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={event => handleFormClose(event)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={event => submitContent(event)}
                        color="primary"
                    >
                        {contentOperation === 'create' ? (
                            <LocaleMessage msg="button.create" />
                        ) : (
                            <LocaleMessage msg="button.update" />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function buildDeleteContentDialog() {
        return (
            <SimpleDialog
                open={deleteContentOpen}
                onClose={handleDeleteClose}
                title={
                    <LocaleMessage msg="page.applications.conversation.content.delete.title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteContentOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteContent(currContentItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function buildDeleteConditionDialog() {
        return (
            <SimpleDialog
                open={deleteConditionOpen}
                onClose={handleDeleteClose}
                title={
                    <LocaleMessage msg="page.applications.conversation.content.delete.title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteConditionOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteCondition(currCondition),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    function renderCopyDialog() {
        return (
            <SimpleDialog
                open={copyOpen}
                onClose={handleCopyClose}
                title={
                    <LocaleMessage msg="page.applications.conversation.condition.copy.title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="page.applications.conversation.condition.copy.text" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setCopyOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'copy',
                        onClick: () => copyCondition(currCondition),
                        label: <LocaleMessage msg="button.copy" />,
                    },
                ]}
            />
        );
    }

    function renderConditionList() {
        const headCells = [
            {
                id: 'title',
                label: <LocaleMessage msg="table.headers.description" />,
            },
            {
                id: 'media_str',
                label: <LocaleMessage msg="table.headers.media" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'copy',
                label: <LocaleMessage msg="button.copy" />,
                icon: <MdContentCopy />,
                action: handleCopyItem,
            },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteCondition,
            },
        ];

        return (
            <div className="mt-5" style={{ minHeight: '150px', width: '100%' }}>
                <DataTable
                    data={conditionList}
                    orderColumn="content"
                    headerColumns={headCells}
                    rowActions={rowActions}
                    handleTableRowClick={(event, _id) =>
                        handleConditionSelection(event, _id)
                    }
                    sortable
                    hasHeader
                    headerDirection="row"
                    header={
                        <>
                            <div className="col-4" style={{ padding: '0px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={event =>
                                        handleConditionSelection(event, 'new')
                                    }
                                >
                                    <MdAdd
                                        size={20}
                                        style={{
                                            color: '#fff',
                                        }}
                                    />
                                    <LocaleMessage msg="page.applications.conversation.content.add_condition" />
                                </Button>
                            </div>
                            <div className="col-4" style={{ padding: '0px' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    fullWidth
                                    onClick={event => downloadData(event)}
                                >
                                    <MdDownload
                                        size={20}
                                        style={{
                                            color: '#fff',
                                        }}
                                    />
                                    <LocaleMessage msg="page.applications.conversation.content.export" />
                                </Button>
                            </div>
                        </>
                    }
                />
            </div>
        );
    }

    function renderCurrentContent() {
        return (
            <>
                <div className={`row mb-5 ${classes.row}`}>
                    <div className="col-3">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => toggleChangeContent()}
                            fullWidth
                        >
                            <LocaleMessage msg="button.change" />
                        </Button>
                    </div>
                    <div className="col-6">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.applications.conversation.content.current" />
                            </InputLabel>
                            <Select value={currContentItem || ''} disabled>
                                {contentList.map(b => (
                                    <MenuItem value={b.id} key={b.id}>
                                        {b.fullname}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-3">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => submitConfig()}
                            fullWidth
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </div>
                </div>
                <div className="col-12">{renderConditionList()}</div>
            </>
        );
    }

    function renderContentList() {
        const headCells = [
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'dialog_name',
                label: <LocaleMessage msg="table.headers.dialog" />,
            },
            {
                id: 'conditions',
                label: <LocaleMessage msg="table.headers.conditions" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'edit',
                label: <LocaleMessage msg="button.edit" />,
                icon: <MdEdit />,
                action: handleEditContent,
            },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteContent,
            },
        ];

        return (
            <div className="mt-5" style={{ minHeight: '150px', width: '100%' }}>
                <DataTable
                    headerColumns={headCells}
                    data={contentList}
                    orderColumn="name"
                    handleTableRowClick={(event, _id) =>
                        handleContentSelection(event, _id)
                    }
                    rowActions={rowActions}
                />
            </div>
        );
    }

    function renderContentSelector() {
        return (
            <>
                <div
                    className={`col-12 row mb-1 ${classes.row}`}
                    style={{ justifyContent: 'space-between' }}
                >
                    {currContent ? (
                        <div className="col-4">
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth
                                onClick={() => hideChangeContent()}
                            >
                                <LocaleMessage msg="button.cancel" />
                            </Button>
                        </div>
                    ) : null}
                    <div className="col-4">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={event => handleNewContent(event)}
                        >
                            <MdAdd
                                size={20}
                                style={{
                                    color: '#fff',
                                }}
                            />
                            <LocaleMessage msg="page.applications.conversation.content.add" />
                        </Button>
                    </div>
                </div>
                <div className={`row mb-5 ${classes.row}`}>
                    <div className="col-12">{renderContentList()}</div>
                </div>
            </>
        );
    }

    function renderContentSettings() {
        return (
            <>
                {deleteContentOpen ? buildDeleteContentDialog() : null}
                {deleteConditionOpen ? buildDeleteConditionDialog() : null}
                {formOpen ? buildFormDialog() : null}
                {copyOpen ? renderCopyDialog() : null}
                {changeContent
                    ? renderContentSelector()
                    : renderCurrentContent()}
            </>
        );
    }

    function renderConditionSettings() {
        const cond_settings = {
            id: currCondition,
            application_content: currContent,
            group_id: group_id || null,
        };
        const app_settings =
            appSettings && appSettings.settings ? appSettings.settings : {};

        const app_language =
            app_settings && app_settings.language
                ? app_settings.language
                : { language: 'pt_BR' };

        const languages = app_language.list || ['pt_BR'];
        const filtered = languages.filter(app_lang => {
            return availableLanguages.find(l => {
                return l.value === app_lang;
            });
        });

        return (
            <div className="col-12">
                <DialogConditionConfig
                    languages={filtered}
                    settings={cond_settings}
                    robotTypes={robotTypes}
                    currType={currType}
                    setCurrType={t => setCurrType(t)}
                    setToast={msg => setToast(msg)}
                    handleError={err => requestError(err)}
                    closePage={() => closeConditionPage()}
                    robotGalleries={robotGalleries}
                    imageGalleriesList={imageGalleriesList}
                />
            </div>
        );
    }

    return (
        <div className="col-12 mb-3 row">
            <div className="sidecard-header mb-3">
                <h2>
                    <LocaleMessage msg="page.applications.conversation.content.title" />
                </h2>
            </div>
            <div className="sidecard-body col-12">
                {isLoading ? (
                    <Splash />
                ) : (
                    <>
                        {conditionOpen
                            ? renderConditionSettings()
                            : renderContentSettings()}
                    </>
                )}
            </div>
        </div>
    );
}

DialogContentConfig.propTypes = {
    updateObject: PropTypes.func.isRequired,
    setToast: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    appSettings: PropTypes.object.isRequired,
    robotTypes: PropTypes.array.isRequired,
    currType: PropTypes.object.isRequired,
    setCurrType: PropTypes.func.isRequired,
};

DialogContentConfig.defaultProps = {};
