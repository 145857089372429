import styled from 'styled-components';

export const Container = styled.div`
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9em !important;

    .fc-col-header-cell-cushion {
        color: ${props => {
            return props.theme.dark_mode ? '#fff' : '#495057';
        }} !important;
    }

    .fc-scrollgrid {
        border-top: 5px solid ${props => props.theme.palette.primary.main};

        tr {
            line-height: 2.5em;
        }

        .fc-timegrid-col-events {
            margin: 0px;
        }

        .fc-v-event {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-color: ${props => props.theme.palette.primary.main};
            background-color: ${props =>
                `${props.theme.palette.primary.main}99`};

            :hover {
                cursor: pointer;
            }
        }

        .fc-event-time {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-weight: bold;
        }

        .fc-event-title-container {
            height: 0px;
        }

        .fc-event {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    .fc-timegrid-cols {
        .fc-day-today {
            background-color: #dddddd99;
        }
    }
`;
