/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';

import { differenceInSeconds } from 'date-fns';
import PropTypes from 'prop-types';

import { Box, Button } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import GetOperationTime from '~/util/GetOperationTime';

import { Card } from './styles';

const h100 = { height: '100%' };

const allowed_actions = ['external_app'];

export default function CurrentActionCard({
    options,
    now,
    width,
    permission,
    robot,
    sendAction,
    renderLoading,
}) {
    const status = robot.status || {};
    const { operation } = status;
    const op_action = operation && operation.action ? operation.action : null;
    const op_since =
        op_action && op_action.since ? new Date(op_action.since) : null;
    const op_time_s = op_since
        ? differenceInSeconds(now.datetime, op_since)
        : null;
    const op_time =
        op_time_s && op_time_s > 0 ? GetOperationTime(op_time_s) : null;
    // const show_action = op_action && allowed_actions.includes(op_action.type);
    const show_action = !!op_action;
    const op_settings =
        op_action && op_action.settings ? op_action.settings : null;

    const allow_permission = permission !== 'viewer';

    const [isLoading, setIsLoading] = useState(false);

    function onExitClick() {
        // setIsLoading(true);
        // const onclick = options.onClick || {};
        // const onclick_params = onclick.params || {};
        // sendAction(onclick_params);
        // setIsLoading(false);
    }

    return status.status === 'occupied' && show_action ? (
        <div className={width} style={h100}>
            <Box style={h100}>
                <Card>
                    {isLoading ? (
                        renderLoading()
                    ) : (
                        <>
                            <div className="content mb-3">
                                <span className="mid-value mb-3">
                                    {op_settings && op_settings.label ? (
                                        <span>{op_settings.label}</span>
                                    ) : (
                                        <>
                                            {op_action ? (
                                                <LocaleMessage
                                                    msg={`rc3.robot.data.actions.${op_action.type}`}
                                                />
                                            ) : (
                                                <LocaleMessage msg="rc3.robot.data.actions.in_use" />
                                            )}
                                        </>
                                    )}
                                </span>
                                <span className="jr-fs-md title mb-3">
                                    <LocaleMessage msg="rc3.robot.data.actions.running_time" />
                                </span>
                                <div className="info">
                                    <span className="mid-value">
                                        {op_time ? op_time.label : '--:--:--'}
                                    </span>
                                </div>
                            </div>
                            <Button
                                fullWidth
                                className="mb-1"
                                variant="contained"
                                color="primary"
                                disabled={!allow_permission}
                                onClick={() => onExitClick()}
                            >
                                <LocaleMessage msg="rc3.robot.data.actions.finish" />
                            </Button>
                        </>
                    )}
                </Card>
            </Box>
        </div>
    ) : null;
}

CurrentActionCard.propTypes = {
    now: PropTypes.object,
    width: PropTypes.string,
    permission: PropTypes.string,
    options: PropTypes.object,
    robot: PropTypes.object,
    sendAction: PropTypes.func.isRequired,
    renderLoading: PropTypes.func.isRequired,
};

CurrentActionCard.defaultProps = {
    width: 'col-12',
    permission: '',
    robot: {},
    options: {},
    now: {},
};
