/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { MdAdd } from 'react-icons/md';

import DateFnsUtils from '@date-io/date-fns';
import { set, format } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormGroup,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
} from '@material-ui/pickers';

import CustomDialogTitle from '~/components/CustomDialogTitle';
import LocaleMessage from '~/components/LocaleMessage';
import WeekView from '~/components/WeekView';

import { BorderedArea } from './styles';

const sel_days = [
    {
        value: '0',
        label_key: 'label.day.sunday.short',
        included: { all: true, mon_fri: false, weekend: true },
    },
    {
        value: '1',
        label_key: 'label.day.monday.short',
        included: { all: true, mon_fri: true, weekend: false },
    },
    {
        value: '2',
        label_key: 'label.day.tuesday.short',
        included: { all: true, mon_fri: true, weekend: false },
    },
    {
        value: '3',
        label_key: 'label.day.wednesday.short',
        included: { all: true, mon_fri: true, weekend: false },
    },
    {
        value: '4',
        label_key: 'label.day.thursday.short',
        included: { all: true, mon_fri: true, weekend: false },
    },
    {
        value: '5',
        label_key: 'label.day.friday.short',
        included: { all: true, mon_fri: true, weekend: false },
    },
    {
        value: '6',
        label_key: 'label.day.saturday.short',
        included: { all: true, mon_fri: false, weekend: true },
    },
];

const time_format = {
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
};

export default function TimeslotForm({
    locale,
    timeslots,
    updateTimeslots,
    allowEdit,
}) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [operation, setOperation] = useState('create');
    const [currIndex, setCurrIndex] = useState('');
    const [currSlot, setCurrSlot] = useState(null);
    const [oldSlot, setOldSlot] = useState(null);

    const parsed_times = {
        0: {
            day: 'sunday',
            slots: [],
        },
        1: {
            day: 'monday',
            slots: [],
        },
        2: {
            day: 'tuesday',
            slots: [],
        },
        3: {
            day: 'wednesday',
            slots: [],
        },
        4: {
            day: 'thursday',
            slots: [],
        },
        5: {
            day: 'friday',
            slots: [],
        },
        6: {
            day: 'saturday',
            slots: [],
        },
    };

    function formatTime(dt) {
        return format(new Date(dt), 'HH:mm');
    }

    timeslots.forEach(t => {
        const { item_id, start, end, days } = t;
        const s_body = {
            id: item_id,
            start: formatTime(start),
            end: formatTime(end),
        };
        Object.keys(days).forEach(d => {
            if (days[d]) {
                const day_list = parsed_times[d];
                const day_slots = day_list.slots;
                day_slots.push(s_body);
                parsed_times[d] = {
                    ...day_list,
                    slots: day_slots,
                };
            }
        });
    });

    function updateSlots(s) {
        if (operation === 'create') {
            updateTimeslots([...timeslots, ...(s ? [s] : [])]);
        } else {
            const new_slots = [
                ...timeslots.slice(0, currIndex),
                ...(s ? [s] : []),
                ...timeslots.slice(currIndex + 1),
            ];
            updateTimeslots(new_slots);
        }
        setDialogOpen(false);
        return true;
    }

    function removeSlot() {
        if (currIndex !== 'new') {
            const new_slots = [
                ...timeslots.slice(0, currIndex),
                ...timeslots.slice(currIndex + 1),
            ];
            updateTimeslots(new_slots);
        }
        setDialogOpen(false);
        return true;
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setCurrSlot(null);
        setOldSlot(null);
        setOperation('');
        setDialogOpen(false);
    }

    function cancel(event) {
        event.preventDefault();
        updateSlots(oldSlot);
        setOperation('');
        setDialogOpen(false);
    }

    function handleSlotClick(_id) {
        const idx = timeslots.findIndex(i => {
            return i.item_id === _id;
        });
        const slot = idx !== -1 ? timeslots[idx] : null;

        setCurrIndex(idx !== -1 ? idx : 'new');
        setOldSlot(slot);
        setCurrSlot(slot);
        setOperation('update');
        setDialogOpen(true);
        return true;
    }

    function handleNewSlot() {
        const item_id = (+new Date()).toString(36);
        const def_start = set(new Date(), { ...time_format, hours: 8 });
        const def_end = set(new Date(), { ...time_format, hours: 18 });

        const new_slot = {
            item_id,
            start: def_start,
            end: def_end,
            days: {},
        };

        setCurrIndex('new');
        setCurrSlot(new_slot);

        setOperation('create');
        setDialogOpen(true);
    }

    function handleDayClick(d, e) {
        const value = e.target.checked;
        const days = { ...currSlot.days };
        days[d] = value;
        setCurrSlot({
            ...currSlot,
            days,
        });
    }

    function renderTimePicker(key, label) {
        const dt_value = currSlot[key];

        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                <KeyboardTimePicker
                    fullWidth
                    value={dt_value || ''}
                    label={<LocaleMessage msg={label} />}
                    variant="inline"
                    format="HH:mm"
                    onChange={v => {
                        setCurrSlot({
                            ...currSlot,
                            [key]: v,
                        });
                    }}
                    ampm={false}
                    minutesStep={5}
                />
            </MuiPickersUtilsProvider>
        );
    }

    function renderEditSlot() {
        const days = currSlot && currSlot.days ? currSlot.days : {};
        return currSlot ? (
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-6 col-12 mb-3">
                            {renderTimePicker('start', 'label.time.start')}
                        </div>
                        <div className="col-md-6 col-12 mb-3">
                            {renderTimePicker('end', 'label.time.end')}
                        </div>
                    </div>
                </div>
                <BorderedArea className="col-12">
                    <LocaleMessage msg="page.tools.contacts.form.label.availability.days" />
                    <FormGroup row>
                        {sel_days.map(d => {
                            return (
                                <FormControlLabel
                                    key={`day_${d.value}`}
                                    value={d.value}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            onChange={e =>
                                                handleDayClick(d.value, e)
                                            }
                                            checked={days[d.value] || false}
                                        />
                                    }
                                    label={<LocaleMessage msg={d.label_key} />}
                                    labelPlacement="bottom"
                                />
                            );
                        })}
                    </FormGroup>
                </BorderedArea>
            </div>
        ) : null;
    }

    function renderRemoveDialog() {
        return (
            <>
                <CustomDialogTitle
                    title={
                        <LocaleMessage msg="page.tools.contacts.form.label.availability.remove" />
                    }
                />
                <DialogContent />
                <DialogActions>
                    <Button
                        onClick={event => handleDialogClose(event)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={() => {
                            removeSlot();
                        }}
                        color="primary"
                        autoFocus
                        disabled={!allowEdit}
                    >
                        <LocaleMessage msg="button.delete" />
                    </Button>
                </DialogActions>
            </>
        );
    }

    function renderEditDialog() {
        const days = currSlot && currSlot.days ? currSlot.days : {};
        const allowSave = Object.keys(days).find(d => {
            return days[d];
        });
        return (
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={event => handleDialogClose(event)}
                    maxWidth="sm"
                    fullWidth
                >
                    <CustomDialogTitle
                        title={
                            <LocaleMessage
                                msg={
                                    currIndex === 'new'
                                        ? 'page.tools.contacts.form.label.availability.add'
                                        : 'page.tools.contacts.form.label.availability.edit'
                                }
                            />
                        }
                    />
                    <DialogContent>
                        <>{renderEditSlot()}</>
                    </DialogContent>
                    <DialogActions>
                        {currIndex !== 'new' ? (
                            <Button
                                onClick={() => setOperation('delete')}
                                color="primary"
                                disabled={!allowEdit}
                            >
                                <LocaleMessage msg="button.delete" />
                            </Button>
                        ) : null}
                        <Button
                            onClick={event => cancel(event)}
                            color="primary"
                        >
                            <LocaleMessage msg="button.cancel" />
                        </Button>
                        <Button
                            onClick={() => {
                                updateSlots(currSlot);
                            }}
                            color="primary"
                            autoFocus
                            disabled={!allowSave || !allowEdit}
                        >
                            <LocaleMessage msg="button.save" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    function renderDialog() {
        return (
            <div>
                <Dialog
                    open={dialogOpen}
                    onClose={event => handleDialogClose(event)}
                    maxWidth="sm"
                    fullWidth
                >
                    {operation === 'delete'
                        ? renderRemoveDialog()
                        : renderEditDialog()}
                </Dialog>
            </div>
        );
    }

    return (
        <div className="col-12 mb-5">
            {dialogOpen ? renderDialog() : null}
            <div className="body-top-controls">
                <LocaleMessage msg="page.tools.contacts.form.label.availability" />
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<MdAdd />}
                    style={{
                        whiteSpace: 'nowrap',
                        padding: '5px 20px',
                    }}
                    disabled={!allowEdit}
                    onClick={event => handleNewSlot(event)}
                >
                    <LocaleMessage msg="page.tools.contacts.form.label.availability.add" />
                </Button>
            </div>
            <WeekView
                timeslots={parsed_times}
                editable={false}
                onSlotClick={e => handleSlotClick(e)}
            />
        </div>
    );
}

TimeslotForm.defaultProps = {
    timeslots: [],
    allowEdit: true,
};

TimeslotForm.propTypes = {
    locale: PropTypes.object.isRequired,
    timeslots: PropTypes.array,
    updateTimeslots: PropTypes.func.isRequired,
    allowEdit: PropTypes.bool,
};
