import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    display: flex;
    border-radius: 0.375rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    margin-bottom: 20px;
    padding: 15px;
    flex-direction: column;
    background: ${props => {
        return props.theme.dark_mode ? '#252525' : '#fff';
    }};

    .sidecard {
        width: 100%;
        height: 100%;
        padding: 10px;
    }

    .sidecard-header {
        padding: 20px 25px;
        display: flex;
        align-items: center;

        svg {
            margin-right: 15px;
            color: ${props => props.theme.palette.pluginspace.primary};
        }

        h2 {
            color: ${props => props.theme.palette.pluginspace.primary};
            margin: 0px;
        }
    }

    .sidecard-body {
        width: 100%;
        height: 100%;
        padding: 10px;
    }
`;
