/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Card } from 'reactstrap';

import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';

import { Container } from './styles';

export default function MultiInfoCard({ title, data, color, onClick }) {
    return (
        <Container>
            <Card
                className="jr-card jr-card-widget"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '100%',
                    height: '100%',
                    padding: '0px',
                }}
                onClick={onClick}
            >
                <div
                    style={{
                        width: '100%',
                        textAlign: 'center',
                        padding: '10px',
                        color,
                    }}
                >
                    <Typography variant="button">
                        <strong>{title}</strong>
                    </Typography>
                </div>
                <div
                    className="row"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        margin: '5px',
                    }}
                >
                    {data.map(d => {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                    height: '100%',
                                }}
                                key={d.key}
                            >
                                <div
                                    className="mb-3"
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    <Typography variant="h4">
                                        <strong>{d.value}</strong>
                                    </Typography>
                                </div>
                                <p className="mb-3 text-grey">{d.subTitle}</p>
                            </div>
                        );
                    })}
                </div>
            </Card>
        </Container>
    );
}

MultiInfoCard.defaultProps = {
    title: '',
    color: '#3f51b5',
    data: [],
    onClick: () => {},
};

MultiInfoCard.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    color: PropTypes.string,
    data: PropTypes.array,
    onClick: PropTypes.func,
};
