/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { MdApps } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

import PropTypes from 'prop-types';

import { IconButton } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';

import menuApps from '~/config/Apps';
import history from '~/services/history';
import { changeAppRequest } from '~/store/modules/application/actions';

import { Container, Item } from './styles';

export default function AppSwitcher({ open, toggle, hideMenu, appList }) {
    const dispatch = useDispatch();

    const settings = useSelector(state => state.application || null);
    const main_app = menuApps.getMainApp();
    const apps = menuApps.createAppMenu(appList);

    const curr_app =
        settings && settings.app_type ? settings.app_type : 'platform';

    function handleAppClick(a) {
        const sel_app = a && a.app ? a.app : 'platform';
        if (sel_app !== curr_app) {
            dispatch(changeAppRequest(a));
        } else {
            history.push(a.home || '/');
        }
        hideMenu();
    }

    function renderItem(a) {
        return (
            <Item
                className="jr-list-item"
                onClick={() => handleAppClick(a)}
                key={a.app}
            >
                <div className="item">
                    <i className={`zmdi zmdi-hc-fw ${a.icon} mb-3`} />
                    <span>
                        <LocaleMessage msg={a.key} />
                    </span>
                </div>
            </Item>
        );
    }

    function renderApps() {
        return (
            <>
                <div style={{ padding: '20px' }}>
                    <ul className="jr-list jr-list-half">
                        {renderItem(main_app)}
                        {apps.map(a => {
                            return renderItem(a);
                        })}
                    </ul>
                </div>
            </>
        );
    }

    return apps && apps.length > 0 ? (
        <Container>
            <Dropdown
                className="quick-menu"
                isOpen={open}
                toggle={() => toggle()}
            >
                <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown"
                >
                    <IconButton className="icon-btn">
                        <MdApps size={24} />
                    </IconButton>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu" right>
                    {renderApps()}
                </DropdownMenu>
            </Dropdown>
        </Container>
    ) : null;
}

AppSwitcher.defaultProps = {
    appList: [],
};

AppSwitcher.propTypes = {
    open: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    hideMenu: PropTypes.func.isRequired,
    appList: PropTypes.array,
};
