/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    MdAdd,
    MdDelete,
    MdVpnKey,
    MdDeleteSweep,
    MdClose,
    MdContentCopy,
} from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import PropTypes from 'prop-types';

import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    TextField,
    IconButton,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import CustomDialogTitle from '~/components/CustomDialogTitle';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';

import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';

import { DialogContainer } from './styles';

export default function APIkeys(props) {
    const { match } = props;
    const { id } = match.params;

    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const [isLoading, setIsLoading] = useState(true);
    const [app, setApp] = useState({});
    const [body, setBody] = useState({});
    const [items, setItems] = useState([]);
    const [tempKey, setTempKey] = useState(null);
    const [currItem, setCurrItem] = useState(null);
    const [deleteOpen, setDeleteOpen] = useState('');
    const [selectedRows, setSelectedRows] = useState([]);
    const [formOpen, setFormOpen] = useState(false);
    const [configOperation, setConfigOperation] = useState('create');

    const headCells = [
        {
            id: 'name',
            label: <LocaleMessage msg="table.headers.name" />,
        },
        {
            id: 'value',
            label: 'Key',
            width: '40%',
        },
        {
            id: 'created_by_name',
            label: <LocaleMessage msg="table.headers.created_by" />,
        },
        {
            id: 'created',
            label: <LocaleMessage msg="table.headers.created" />,
            order_by: 'created_timestamp',
        },
    ];

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadApplication(_id) {
        if (_id) {
            await api
                .get(`applications/${_id}`)
                .then(async response => {
                    const a = response.data;
                    setApp({
                        name: a.name,
                        description: a.description,
                        apptype_id: a.app_type.id,
                    });
                })
                .catch(error => requestError(error));
        }
    }

    async function loadApplicationKeys(_id) {
        if (_id) {
            await api
                .get(`applications/${_id}/keys`)
                .then(async response => {
                    const list = response.data;
                    const key_list = list.map(k => {
                        const created = new Date(k.created);
                        return {
                            ...k,
                            created: formatDistance(created, new Date(), {
                                addSuffix: true,
                                locale: date_loc,
                            }),
                            created_timestamp: created.toISOString(),
                            created_by_name: k.created_by
                                ? k.created_by.name
                                : ``,
                        };
                    });
                    setItems(key_list);
                })
                .catch(error => requestError(error));
        }

        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadApplication(id);
        loadApplicationKeys(id);
    }, [id]);

    async function deleteItemMulti() {
        const delete_list = JSON.stringify(selectedRows);
        await api
            .delete(`applications/${id}/keys?list=${delete_list}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.applications.apidetails.key.removed" />
                );
            })
            .catch(error => requestError(error));
    }

    async function deleteItem(item_id) {
        await api
            .delete(`applications/${id}/keys/${item_id}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.applications.apidetails.key.removed" />
                );
            })
            .catch(error => requestError(error));
    }

    async function removeItems() {
        setIsLoading(true);
        if (deleteOpen === 'single') {
            await deleteItem(currItem);
        } else {
            await deleteItemMulti();
        }
        await loadApplicationKeys(id);
        setDeleteOpen('');
        setSelectedRows([]);
        setIsLoading(false);
    }

    async function handleConfigSubmit(event) {
        event.preventDefault();
        setFormOpen(false);
        setIsLoading(true);
        const data = { ...body };

        if (configOperation === 'create') {
            await api
                .post(`applications/${id}/keys`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.applications.apidetails.key.created" />
                    );
                    loadApplicationKeys(id);
                    setTempKey(response.data);
                })
                .catch(error => requestError(error));
        }
        setIsLoading(false);
    }

    function handleNewItem() {
        setBody({});
        setSelectedRows([]);
        setConfigOperation('create');
        setFormOpen(true);
    }

    function handleFormClose(event) {
        event.preventDefault();
        setFormOpen(false);
    }

    function handleDialogsClose(event) {
        event.preventDefault();
        setDeleteOpen('');
        setSelectedRows([]);
    }

    function handleDeleteOpen(event, item_id) {
        setCurrItem(item_id);
        event.preventDefault();
        setSelectedRows([]);
        setDeleteOpen('single');
    }

    function handleDeleteMultiOpen(event) {
        setCurrItem(null);
        event.preventDefault();
        setDeleteOpen('multi');
    }

    function handleCopy() {
        toast.info(
            <LocaleMessage msg="page.applications.apidetails.key.created.copied" />
        );
    }

    function showKey() {
        return (
            <Dialog open onClose={() => {}} maxWidth="md" fullWidth>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '0px 15px 0px 0px',
                    }}
                >
                    <CustomDialogTitle
                        title={
                            <LocaleMessage msg="page.applications.apidetails.key.created" />
                        }
                    />
                    <IconButton
                        aria-label="close"
                        onClick={() => {
                            setTempKey(null);
                        }}
                        style={{ padding: '5px' }}
                    >
                        <MdClose size={20} />
                    </IconButton>
                </div>
                <DialogContent className="mb-5">
                    <div className="col-12 mb-4">
                        <p>
                            <LocaleMessage msg="page.applications.apidetails.key.created.warning_1" />
                        </p>
                        <p>
                            <LocaleMessage msg="page.applications.apidetails.key.created.warning_2" />
                        </p>
                        <p>
                            <LocaleMessage msg="page.applications.apidetails.key.created.warning_3" />
                        </p>
                    </div>
                    <div className="col-12 mt-3">
                        <Card>
                            <CardContent
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '20px',
                                    fontSize: '14px',
                                }}
                            >
                                <p style={{ margin: '0px' }}>{tempKey.value}</p>
                                <CopyToClipboard
                                    text={tempKey.value}
                                    onCopy={() => handleCopy()}
                                >
                                    <Button
                                        variant="outlined"
                                        startIcon={<MdContentCopy />}
                                    >
                                        <LocaleMessage msg="button.copy" />
                                    </Button>
                                </CopyToClipboard>
                            </CardContent>
                        </Card>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={!!deleteOpen}
                onClose={handleDialogsClose}
                title={
                    <LocaleMessage msg="page.applications.apidetails.key.remove.title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="page.applications.apidetails.key.remove.confirmation" />
                        <br />
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: e => handleDialogsClose(e),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => removeItems(),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function buildFormDialog() {
        return (
            <DialogContainer>
                <Dialog
                    open={formOpen}
                    onClose={handleFormClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <CustomDialogTitle
                        title={
                            <LocaleMessage msg="page.applications.apidetails.key.create.title" />
                        }
                    />
                    <DialogContent>
                        <div className="row">
                            <div className="col-md-12 col-12 mb-3">
                                <TextField
                                    label={
                                        <LocaleMessage msg="page.applications.apidetails.key.name" />
                                    }
                                    fullWidth
                                    value={body && body.name ? body.name : ''}
                                    onChange={event =>
                                        setBody({
                                            ...body,
                                            name: event.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={event => handleFormClose(event)}
                            color="primary"
                        >
                            <LocaleMessage msg="button.cancel" />
                        </Button>
                        <Button
                            onClick={event => handleConfigSubmit(event)}
                            color="primary"
                        >
                            {configOperation === 'create' ? (
                                <LocaleMessage msg="button.add" />
                            ) : (
                                <LocaleMessage msg="button.update" />
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            </DialogContainer>
        );
    }

    function buildListView() {
        const rowActions = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        const selAction = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDeleteSweep />,
                action: handleDeleteMultiOpen,
            },
        ];

        return (
            <div
                className="col-12 mb-3"
                style={{
                    minHeight: '150px',
                }}
            >
                <DataTable
                    headerColumns={headCells}
                    data={items || []}
                    orderColumn="order"
                    orderDirection="asc"
                    rowActions={rowActions}
                    hasHeader
                    header={
                        <>
                            <div className="col-4" style={{ padding: '0px' }}>
                                <Button
                                    onClick={() => handleNewItem()}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<MdAdd />}
                                    style={{
                                        whiteSpace: 'nowrap',
                                        padding: '5px 20px',
                                    }}
                                >
                                    <LocaleMessage msg="page.applications.apidetails.add_key" />
                                </Button>
                            </div>
                        </>
                    }
                    selectable
                    selectedActions={selAction}
                    selectedRows={selectedRows}
                    setSelectedRows={s => {
                        setSelectedRows(s);
                    }}
                />
            </div>
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.applications.apidetails.title" />}
            currentPage={
                <LocaleMessage msg="page.applications.apidetails.title" />
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/applications',
                    title: <LocaleMessage msg="breadcrumbs.applications" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {deleteOpen ? buildDeleteDialog() : null}
                {formOpen ? buildFormDialog() : null}
                {tempKey ? showKey() : null}
                <div className="col-md-10 col-12 mb-5">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.applications.apidetails.title" />
                        }
                        Icon={MdVpnKey}
                    >
                        <>
                            <div style={{ padding: '0px 15px' }}>
                                <div className="mb-5">
                                    <h4>
                                        <strong>
                                            <LocaleMessage msg="page.applications.apidetails.app_name" />
                                        </strong>
                                    </h4>
                                    <p>{app.name || '---'}</p>
                                </div>

                                {/* <div className="mb-3">
                                    <h4>
                                        <strong>
                                            <LocaleMessage msg="page.applications.apidetails.app_url" />
                                        </strong>
                                    </h4>
                                    <p>{`${process.env.REACT_APP_API_URL}/tools/apps/${id}`}</p>
                                </div> */}

                                {/* <span>
                                    <strong>
                                        <LocaleMessage msg="page.applications.apidetails.api_key" />
                                    </strong>
                                </span>
                                <p>
                                    {app.apikey || (
                                        <LocaleMessage msg="page.applications.apidetails.unavailable" />
                                    )}
                                </p> */}

                                {/* <span>
                                    <a>See API Reference</a>
                                </span> */}
                            </div>
                        </>
                    </CardSideBordered>
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.applications.apidetails.keys" />
                        }
                        Icon={MdVpnKey}
                        hide
                    >
                        {buildListView()}
                    </CardSideBordered>
                </div>
            </>
        </PageContent>
    );
}

APIkeys.propTypes = {
    match: PropTypes.object.isRequired,
};
