/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdCloudUpload, MdDownload, MdSync } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Button, Divider, Tooltip } from '@material-ui/core';

import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import QnAExamples from '~/components/QnAExamples';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import lists from '~/config/Lists';
import api from '~/services/pluginbot-api';
import GetFileName from '~/util/GetFileName';
import lng_labels from '~/util/LangMessages';
import QnALoadFile from '~/util/QnALoadFile';

export default function QnADefaults({ dialogSettings, handleError, settings }) {
    const loc = settings.locale;

    const lang = loc && loc.code ? loc.code : 'pt_BR';
    const lng_all = lng_labels[lang];

    const { id, language: dialog_lng } = dialogSettings;

    const [isLoading, setIsLoading] = useState(true);

    const [, setRawList] = useState([]);
    const [contentList, setContentList] = useState([]);
    const [currItemId, setCurrItemId] = useState(null);
    const [currItemObj, setCurrItemObj] = useState(null);
    const [currItemExamples, setCurrItemExamples] = useState([]);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const [syncOptions, setSyncOptions] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [syncLoading, setSyncLoading] = useState(false);
    const [showSyncDialog, setShowSyncDialog] = useState(false);

    function compareSyncList(list) {
        const current_contents = {};

        contentList.forEach(c => {
            current_contents[c.identifier] = c;
        });

        const available_contents = list.map(c => {
            return {
                id: c.id,
                identifier: c.identifier,
                display_name: c.display_name,
                examples: c.examples || [],
                type: c.type,
            };
        });

        const syncObjs = {};
        available_contents.forEach(c => {
            const c_identifier = c.identifier;
            const c_examples = c.examples || [];
            if (c.type === 'reference') return;
            if (!current_contents[c_identifier]) {
                // const example_display = c_examples ? c_examples.join(', ') : '';
                syncObjs[c.id] = {
                    operation: 'bind',
                    operation_label: 'page.qna.content.defaults.operation.new',
                    id: c.id,
                    identifier: c_identifier,
                    display_name: c.display_name,
                    examples: c_examples,
                    // example_list: example_display,
                };
            } else {
                const curr_settings = current_contents[c_identifier];
                const curr_examples = curr_settings.examples;
                const diff_examples = c_examples.filter(e => {
                    return !curr_examples.includes(e);
                });
                if (diff_examples.length > 0) {
                    // const example_display = diff_examples.join(', ');
                    syncObjs[curr_settings.id] = {
                        operation: 'update',
                        operation_label:
                            'page.qna.content.defaults.operation.update',
                        id: curr_settings.id,
                        identifier: c_identifier,
                        display_name: c.display_name,
                        examples: diff_examples,
                        // example_list: example_display,
                    };
                }
            }
        });

        const syncList = Object.keys(syncObjs).map(s => {
            return syncObjs[s];
        });
        setSyncOptions(syncList);
    }

    async function loadAgentContents() {
        setIsLoading(true);
        await api
            .get(
                `dialogs/${id}/qna/contents?type=defaults&language=${dialog_lng}`
            )
            .then(response => {
                const list = response.data;
                const contents = list.map(c => {
                    const example_display = c.examples
                        ? c.examples.join(', ')
                        : '';
                    const label_key = `page.qna.content.${c.type}`;
                    const label = lng_all[label_key] || '';

                    return {
                        id: c.id,
                        identifier: c.identifier,
                        display_name: c.display_name,
                        examples: c.examples,
                        example_list: example_display,
                        type: c.type,
                        type_label: label.toUpperCase(),
                    };
                });
                const content_list = contents.filter(c => {
                    return c.type !== 'reference';
                });
                setRawList(contents);
                setContentList(content_list);
            })
            .catch(error => handleError(error));

        setIsLoading(false);
    }

    async function loadDefaultContents() {
        setSyncLoading(true);
        await api
            .get(`qna/contents?language=${dialog_lng}`)
            .then(response => {
                const list = response.data;
                compareSyncList(list);
            })
            .catch(error => handleError(error));
        setSyncLoading(false);
    }

    useEffect(() => {
        loadAgentContents();
    }, []);

    useEffect(() => {
        if (contentList) {
            const item = contentList.find(i => {
                return i.id === currItemId;
            });
            setCurrItemObj(item || null);
            setCurrItemExamples(item ? item.examples : []);
        } else {
            setCurrItemObj(null);
            setCurrItemExamples([]);
        }
    }, [currItemId, contentList]);

    const onFileUpload = async event => {
        const fileObj = event.target.files[0];
        const reader = new FileReader();

        let fileloaded = e => {
            const updatedSettings = QnALoadFile(
                {
                    [dialog_lng]: {
                        training: currItemExamples,
                    },
                },
                e
            );
            const examples = updatedSettings[dialog_lng]
                ? updatedSettings[dialog_lng].training
                : [];

            // Use spread operator to update variable
            setCurrItemExamples([...examples]);
        };

        // Mainline of the method
        fileloaded = fileloaded.bind(this);
        try {
            reader.readAsText(fileObj, 'UTF-8');
            reader.onload = fileloaded;
        } catch (error) {
            toast.error(
                <LocaleMessage msg="page.qna.content.training.form.examples.error" />
            );
        }
    };

    async function bindContents() {
        const optionObjects = {};
        syncOptions.forEach(s => {
            optionObjects[s.id] = s;
        });

        const optionList = selectedRows.map(s => {
            return optionObjects[s];
        });

        const data = {
            type: 'defaults',
            source: 'default_list',
            contents: optionList,
        };

        setIsLoading(true);
        setShowSyncDialog(false);

        await api
            .post(`dialogs/${id}/qna/contents`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
            })
            .catch(error => {
                handleError(error);
                return false;
            });

        setIsLoading(false);
        setSelectedRows([]);
        loadAgentContents();
    }

    async function submitEdit() {
        setShowEditDialog(false);
        const data = {
            display_name:
                currItemObj && currItemObj.display_name
                    ? currItemObj.display_name
                    : '',
            examples: currItemExamples,
        };
        setIsLoading(true);
        await api
            .put(`dialogs/${id}/qna/contents/${currItemId}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });
        setCurrItemId(null);
        loadAgentContents();
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setShowEditDialog(false);
    }

    function handleItemClick(event, _id) {
        setCurrItemId(_id);
        setShowEditDialog(true);
    }

    function renderEditContentForm() {
        const examples =
            currItemExamples && currItemExamples.length > 0
                ? currItemExamples
                : [''];
        const { qna_languages } = lists;
        const file_lng = qna_languages.find(l => {
            return l.value === dialog_lng;
        });
        const headers_file = [
            {
                key: dialog_lng,
                label: file_lng ? file_lng.key.toUpperCase() : dialog_lng,
            },
        ];
        const examples_file = examples.map(e => {
            return {
                [dialog_lng]: e,
            };
        });
        const non_empty = examples.filter(e => {
            return !!e;
        });
        const allow_suggestion = non_empty.length > 3;

        return (
            <>
                <Divider />
                <div
                    className="mt-3 mb-4 col-12"
                    style={{
                        display: 'flex',
                        textAlign: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Tooltip
                        title={
                            <LocaleMessage msg="page.qna.content.training.form.examples.upload" />
                        }
                        placement="top"
                    >
                        <Button
                            component="label"
                            variant="outlined"
                            color="primary"
                            onClick={() => {}}
                        >
                            <MdCloudUpload size={20} />
                            <input
                                hidden
                                accept="text/csv"
                                type="file"
                                onChange={onFileUpload}
                            />
                        </Button>
                    </Tooltip>

                    <LocaleMessage msg="page.qna.content.examples" />
                    <Tooltip
                        title={
                            <LocaleMessage msg="page.qna.content.training.form.examples.export" />
                        }
                        placement="top"
                    >
                        <CSVLink
                            data={examples_file}
                            headers={headers_file}
                            filename={GetFileName(
                                `QNA-DEFAULTS-${
                                    currItemObj ? currItemObj.identifier : ''
                                }`,
                                'csv'
                            )}
                            onClick={() => {
                                toast.info(
                                    <LocaleMessage msg="message.generating_file" />
                                );
                            }}
                        >
                            <Button variant="outlined" color="primary">
                                <MdDownload size={20} />
                            </Button>
                        </CSVLink>
                    </Tooltip>
                </div>

                <QnAExamples
                    contentId={currItemId}
                    language={dialog_lng}
                    examples={examples}
                    handleError={e => handleError(e)}
                    updateExamples={e => setCurrItemExamples(e)}
                    allowSuggestions={allow_suggestion}
                />
                <Divider />
            </>
        );
    }

    function renderEditDialog() {
        return (
            <SimpleDialog
                size="md"
                open={showEditDialog}
                onClose={handleDialogClose}
                title={
                    <>
                        <LocaleMessage msg="page.qna.contents.list.edit" />
                        <span>
                            {` - ${
                                currItemObj ? currItemObj.display_name : ''
                            }`}
                        </span>
                    </>
                }
                content={renderEditContentForm()}
                actions={[
                    {
                        key: 'cancel',
                        onClick: e => handleDialogClose(e),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => submitEdit(),
                        label: <LocaleMessage msg="button.save" />,
                    },
                ]}
            />
        );
    }

    function renderContentSyncForm() {
        const content_filtered = syncOptions.map(c => {
            return {
                ...c,
                example_list: c.examples ? c.examples.join(', ') : '',
                operation_name: <LocaleMessage msg={c.operation_label} />,
            };
        });

        const headCells = [
            {
                id: 'operation_name',
                label: <LocaleMessage msg="table.headers.operation" />,
            },
            {
                id: 'display_name',
                label: <LocaleMessage msg="page.dialogs.info.content" />,
            },
            {
                id: 'example_list',
                label: <LocaleMessage msg="page.dialogs.info.examples" />,
            },
        ];

        return (
            <div
                className="col-12"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <>
                    {syncLoading ? (
                        <Splash />
                    ) : (
                        <div
                            className="col-12 row mb-3"
                            style={{
                                minHeight: '150px',
                                width: '100%',
                                padding: '0px 15px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {content_filtered.length > 0 ? (
                                <DataTable
                                    headerColumns={headCells}
                                    data={content_filtered}
                                    orderDirection="asc"
                                    orderColumn="value"
                                    handleTableRowClick={() => {}}
                                    hasActions={false}
                                    hasFilter={false}
                                    sortable
                                    selectable
                                    selectedActions={[]}
                                    selectedRows={selectedRows}
                                    setSelectedRows={s => {
                                        setSelectedRows(s);
                                    }}
                                />
                            ) : (
                                <LocaleMessage msg="page.qna.content.defaults.operation.no_updates" />
                            )}
                        </div>
                    )}
                </>
            </div>
        );
    }

    function renderSyncDialog() {
        return (
            <SimpleDialog
                size="md"
                open={showSyncDialog}
                onClose={() => {
                    setShowSyncDialog(false);
                }}
                title={<LocaleMessage msg="page.qna.content.defaults.sync" />}
                content={renderContentSyncForm()}
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => {
                            setShowSyncDialog(false);
                        },
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => bindContents(),
                        label: <LocaleMessage msg="button.save" />,
                    },
                ]}
            />
        );
    }

    function renderContentList() {
        // const filtered_list = contentList.filter(i => {
        //     return i.type !== 'question' && i.type !== 'reference';
        // });

        const filtered_list = contentList;

        const headCells = [
            {
                id: 'type_label',
                label: <LocaleMessage msg="table.headers.type" />,
            },
            {
                id: 'display_name',
                label: <LocaleMessage msg="page.dialogs.info.content" />,
                width: '20%',
            },
            {
                id: 'example_list',
                label: <LocaleMessage msg="page.dialogs.info.examples" />,
            },
        ];

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headCells}
                    data={filtered_list}
                    orderDirection="asc"
                    orderColumn="type_label"
                    handleTableRowClick={handleItemClick}
                    hasActions={false}
                    rowActions={[]}
                    hasFilter
                    sortable
                    hasHeader
                    header={
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MdSync />}
                            style={{
                                whiteSpace: 'nowrap',
                                padding: '5px 20px',
                            }}
                            onClick={() => {
                                loadDefaultContents();
                                // setOperation('add');
                                // setCurrItemId('new');
                                setShowSyncDialog(true);
                                setSelectedRows([]);
                            }}
                        >
                            <LocaleMessage msg="page.qna.content.defaults.sync" />
                        </Button>
                    }
                />
            </div>
        );
    }

    return (
        <>
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.qna.contents.list.defaults" />
                </h2>
            </div>

            <div className="sidecard-body">
                {showEditDialog ? renderEditDialog() : null}
                {showSyncDialog ? renderSyncDialog() : null}
                {isLoading ? (
                    <Splash
                        label={
                            <div className="mb-5">
                                <LocaleMessage msg="page.qna.content.training.loading" />
                            </div>
                        }
                        labelPosition="top"
                    />
                ) : (
                    <>{renderContentList()}</>
                )}
            </div>
        </>
    );
}

QnADefaults.defaultProps = {
    settings: {},
};

QnADefaults.propTypes = {
    settings: PropTypes.object,
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
