/* eslint-disable react/forbid-prop-types */
import React from 'react';

import PropTypes from 'prop-types';

import { DialogTitle } from '@material-ui/core';

import { Container } from './styles';

export default function CustomDialogTitle({ title }) {
    return (
        <Container>
            {title ? (
                <DialogTitle className="title">{title}</DialogTitle>
            ) : null}
        </Container>
    );
}

CustomDialogTitle.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

CustomDialogTitle.defaultProps = {};
