/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdAdd, MdCancel, MdCloudUpload, MdDownload } from 'react-icons/md';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    DialogContentText,
    Tooltip,
} from '@material-ui/core';

import DataTable from '~/components/DataTable';
import FormSelect from '~/components/Form/Select';
import LocaleMessage from '~/components/LocaleMessage';
import QnAExamples from '~/components/QnAExamples';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import lists from '~/config/Lists';
import api from '~/services/pluginbot-api';
import GetFileName from '~/util/GetFileName';
import QnALoadFile from '~/util/QnALoadFile';

import { ParameterArea } from '../styles';

const content_sources = [
    {
        value: 'templates',
        label: 'page.qna.contents.list.sources.templates',
    },
    // {
    //     value: 'pluginspace_contents',
    //     label: 'Pluginspace Contents',
    // },
    {
        value: 'new_content',
        label: 'page.qna.contents.list.sources.new_intent',
    },
];

export default function Intents({ dialogSettings, handleError }) {
    const { id, language: dialog_lng } = dialogSettings;

    const [currItemId, setCurrItemId] = useState(null);
    const [currItemObj, setCurrItemObj] = useState(null);
    const [currItemExamples, setCurrItemExamples] = useState([]);

    const [rawList, setRawList] = useState([]);
    const [contentList, setContentList] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [availableTemplates, setAvailableTemplates] = useState([]);
    const [availableContents, setAvailableContents] = useState([]);
    const [currTemplate, setCurrTemplate] = useState(null);

    const [operation, setOperation] = useState('add');
    const [bindingBody, setBindingBody] = useState({
        source: 'templates',
    });
    const [bindingRows, setBindingRows] = useState([]);

    const [showBindingDialog, setShowBindingDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [internalLoading, setInternalLoading] = useState(false);

    async function loadAgentContents() {
        setIsLoading(true);
        await api
            .get(
                `dialogs/${id}/qna/contents?type=intent&language=${dialog_lng}`
            )
            .then(response => {
                const list = response.data;
                const contents = list.map(c => {
                    const example_display = c.examples
                        ? c.examples.join(', ')
                        : '';

                    const identifier = c.identifier.toUpperCase();

                    return {
                        id: c.id,
                        identifier,
                        default: c.default,
                        display_name: (
                            c.display_name || identifier
                        ).toUpperCase(),
                        examples: c.examples,
                        example_list: example_display,
                    };
                });
                const removable_contents = contents.filter(c => {
                    return !c.default;
                });
                setRawList(contents);
                setContentList(removable_contents);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    async function loadAvailableTemplates() {
        await api
            .get(`qna/templates?language=${dialog_lng}`)
            .then(response => {
                const list = response.data;
                const templates = list.map(t => {
                    const t_label = `${t.display_name}`;
                    return {
                        ...t,
                        label: t_label.toUpperCase(),
                    };
                });
                setAvailableTemplates(templates);
            })
            .catch(error => handleError(error));
    }

    async function loadTemplateContents(t_id) {
        if (!t_id) {
            setAvailableContents([]);
            return;
        }
        setInternalLoading(true);
        await api
            .get(
                `qna/templates/${t_id}/contents?type=intent&language=${dialog_lng}`
            )
            .then(response => {
                const list = response.data;
                const contents = list.map(c => {
                    const c_label = `${c.display_name} (${c.examples.length ||
                        0})`;
                    const example_list = `${c.examples.join(', ')}`;

                    return {
                        ...c,
                        label: c_label.toUpperCase(),
                        example_list,
                    };
                });
                setAvailableContents(contents);
            })
            .catch(error => handleError(error));
        setInternalLoading(false);
    }

    useEffect(() => {
        loadAvailableTemplates();
        loadAgentContents();
    }, []);

    useEffect(() => {
        setCurrTemplate(null);
        if (bindingBody.source === 'new_content') {
            setCurrItemExamples([]);
        }
    }, [bindingBody.source]);

    useEffect(() => {
        loadTemplateContents(currTemplate);
    }, [currTemplate]);

    useEffect(() => {
        if (contentList) {
            const item = contentList.find(i => {
                return i.id === currItemId;
            });
            setCurrItemObj(item || null);
            setCurrItemExamples(item ? item.examples : []);
        } else {
            setCurrItemObj(null);
            setCurrItemExamples([]);
        }
    }, [currItemId, contentList]);

    const onFileUpload = async event => {
        setInternalLoading(true);
        const fileObj = event.target.files[0];
        const reader = new FileReader();

        let fileloaded = e => {
            const updatedSettings = QnALoadFile(
                {
                    [dialog_lng]: {
                        training: currItemExamples,
                    },
                },
                e
            );
            const examples = updatedSettings[dialog_lng]
                ? updatedSettings[dialog_lng].training
                : [];

            // Use spread operator to update variable
            setCurrItemExamples([...examples]);
        };

        // Mainline of the method
        fileloaded = fileloaded.bind(this);
        try {
            reader.readAsText(fileObj, 'UTF-8');
            reader.onload = fileloaded;
        } catch (error) {
            toast.error(
                <LocaleMessage msg="page.qna.content.training.form.examples.error" />
            );
            console.error(error);
        }
        setInternalLoading(false);
    };

    async function bindContents() {
        const data = {
            type: 'intent',
            ...bindingBody,
            ...(bindingBody.source === 'new_content'
                ? {
                      content: {
                          ...bindingBody.content,
                          examples: currItemExamples,
                      },
                  }
                : {
                      contents: bindingRows,
                  }),
        };

        return api
            .post(`dialogs/${id}/qna/contents`, data)
            .then(() => {
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });
    }

    async function unbindContents() {
        const list = JSON.stringify(selectedRows);
        return api
            .delete(`dialogs/${id}/qna/contents?list=${list}`)
            .then(() => {
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });
    }

    async function submitBinding() {
        setIsLoading(true);
        setShowBindingDialog(false);
        let success = true;
        if (currItemId === 'new') {
            success = await bindContents();
        } else {
            success = await unbindContents();
        }
        if (success) {
            toast.success(
                <LocaleMessage msg="page.qna.content.update_success" />
            );
        }
        setCurrItemId(null);
        setBindingRows([]);
        setSelectedRows([]);
        loadAgentContents();
        setIsLoading(false);
    }

    async function submitEdit() {
        setIsLoading(true);
        setInternalLoading(true);
        setShowEditDialog(false);
        const data = {
            display_name:
                currItemObj && currItemObj.display_name
                    ? currItemObj.display_name
                    : '',
            examples: currItemExamples,
        };

        await api
            .put(`dialogs/${id}/qna/contents/${currItemId}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });

        setCurrItemId(null);
        setBindingRows([]);
        setSelectedRows([]);
        loadAgentContents();
        setInternalLoading(false);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setShowEditDialog(false);
        setShowBindingDialog(false);
        setBindingBody({});
    }

    function handleItemClick(event, _id) {
        setOperation('edit');
        setCurrItemId(_id);
        setSelectedRows([]);
        setShowEditDialog(true);
    }

    function renderEditContentForm() {
        const examples =
            currItemExamples && currItemExamples.length > 0
                ? currItemExamples
                : [''];
        const { qna_languages } = lists;
        const file_lng = qna_languages.find(l => {
            return l.value === dialog_lng;
        });
        const headers_file = [
            {
                key: dialog_lng,
                label: file_lng ? file_lng.key.toUpperCase() : dialog_lng,
            },
        ];
        const examples_file = examples.map(e => {
            return {
                [dialog_lng]: e,
            };
        });
        const non_empty = examples.filter(e => {
            return !!e;
        });
        const allow_suggestion = non_empty.length >= 3;

        return (
            <>
                <ParameterArea>
                    <div
                        className="mt-3 mb-4 col-12"
                        style={{
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Tooltip
                            title={
                                <LocaleMessage msg="page.qna.content.training.form.examples.upload" />
                            }
                            placement="top"
                        >
                            <Button
                                component="label"
                                variant="outlined"
                                color="primary"
                                onClick={() => {}}
                            >
                                <MdCloudUpload size={20} />
                                <input
                                    hidden
                                    accept="text/csv"
                                    type="file"
                                    onChange={onFileUpload}
                                />
                            </Button>
                        </Tooltip>

                        <LocaleMessage msg="page.qna.content.examples" />
                        <Tooltip
                            title={
                                <LocaleMessage msg="page.qna.content.training.form.examples.export" />
                            }
                            placement="top"
                        >
                            <CSVLink
                                data={examples_file}
                                headers={headers_file}
                                filename={GetFileName(
                                    `QNA-INTENTS-${
                                        currItemObj
                                            ? currItemObj.identifier
                                            : ''
                                    }`,
                                    'csv'
                                )}
                                onClick={() => {
                                    toast.info(
                                        <LocaleMessage msg="message.generating_file" />
                                    );
                                }}
                            >
                                <Button variant="outlined" color="primary">
                                    <MdDownload size={20} />
                                </Button>
                            </CSVLink>
                        </Tooltip>
                    </div>
                    <QnAExamples
                        content={currItemObj}
                        contentId={currItemId}
                        language={dialog_lng}
                        examples={examples}
                        allowSuggestions={allow_suggestion}
                        handleError={e => handleError(e)}
                        updateExamples={e => setCurrItemExamples(e)}
                    />
                </ParameterArea>
            </>
        );
    }

    function renderEditDialog() {
        return (
            <SimpleDialog
                size="md"
                open={showEditDialog}
                onClose={handleDialogClose}
                title={
                    <>
                        <LocaleMessage msg="page.qna.contents.list.intents.edit" />
                        <span>
                            {` - ${
                                currItemObj ? currItemObj.display_name : ''
                            }`}
                        </span>
                    </>
                }
                content={renderEditContentForm()}
                actions={[
                    {
                        key: 'cancel',
                        onClick: e => handleDialogClose(e),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => submitEdit(),
                        label: <LocaleMessage msg="button.save" />,
                    },
                ]}
            />
        );
    }

    function renderNewContentForm() {
        const binding_new = bindingBody.content || {};

        return (
            <div
                className="col-12 row"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="col-md-6 col-12">
                    <TextField
                        className="mb-5"
                        label={
                            <LocaleMessage msg="page.qna.content.training.form.identifier" />
                        }
                        fullWidth
                        value={binding_new.name || ''}
                        onChange={event =>
                            setBindingBody({
                                ...bindingBody,
                                content: {
                                    ...binding_new,
                                    name: event.target.value,
                                },
                            })
                        }
                    />
                </div>
                {renderEditContentForm()}
            </div>
        );
    }

    function renderContentSearchForm() {
        const content_identifiers = rawList.map(c => {
            return (c.identifier || '').toUpperCase();
        });

        const content_filtered = availableContents.filter(c => {
            return !content_identifiers.includes(c.identifier.toUpperCase());
        });

        const headCells = [
            {
                id: 'display_name',
                label: <LocaleMessage msg="page.dialogs.info.intents" />,
                width: '20%',
            },
            {
                id: 'example_list',
                label: <LocaleMessage msg="page.dialogs.info.examples" />,
            },
        ];

        return (
            <>
                <div className="col-md-6 col-12">
                    <FormSelect
                        classes="mb-5"
                        options={availableTemplates}
                        labelKey="label"
                        label={
                            <LocaleMessage msg="page.qna.contents.list.templates.choose" />
                        }
                        onChange={value => {
                            setCurrTemplate(value);
                            setBindingRows([]);
                        }}
                        value={currTemplate || ''}
                    />
                </div>
                {internalLoading ? (
                    <Splash />
                ) : (
                    <div className="col-12 row mb-3">
                        {currTemplate ? (
                            <div
                                style={{
                                    minHeight: '150px',
                                    width: '100%',
                                    padding: '0px 15px',
                                }}
                            >
                                <DataTable
                                    headerColumns={headCells}
                                    data={content_filtered}
                                    orderDirection="asc"
                                    orderColumn="value"
                                    handleTableRowClick={() => {}}
                                    hasActions={false}
                                    hasFilter={false}
                                    sortable
                                    selectable
                                    selectedActions={[]}
                                    selectedRows={bindingRows}
                                    setSelectedRows={s => {
                                        setBindingRows(s);
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                )}
            </>
        );
    }

    function renderContentForm() {
        const source_forms = {
            new_content: renderNewContentForm(),
            templates: renderContentSearchForm(),
            pluginspace_contents: renderContentSearchForm(),
        };

        return (
            <div
                className="col-12"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className="col-md-4 col-12">
                    <FormControl fullWidth className="mb-5">
                        <InputLabel>
                            <LocaleMessage msg="page.qna.contents.list.intents.source" />
                        </InputLabel>
                        <Select
                            value={bindingBody.source || 'other'}
                            onChange={event => {
                                setBindingBody({
                                    source: event.target.value,
                                });
                                setBindingRows([]);
                            }}
                        >
                            {content_sources.map(s => (
                                <MenuItem value={s.value} key={s.value}>
                                    <LocaleMessage msg={s.label} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                {source_forms[bindingBody.source] || null}
            </div>
        );
    }

    function renderBindingDialog() {
        const allow_save =
            (operation === 'add' &&
                bindingBody &&
                bindingBody.source &&
                (bindingBody.content || bindingRows.length > 0)) ||
            (operation === 'remove' && selectedRows.length > 0);

        return (
            <SimpleDialog
                size="md"
                open={showBindingDialog}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage
                        msg={`page.qna.contents.list.intents.${
                            operation === 'add' ? 'bind' : 'unbind'
                        }`}
                    />
                }
                content={
                    operation === 'add' ? (
                        renderContentForm()
                    ) : (
                        <DialogContentText>
                            <LocaleMessage msg="page.qna.contents.list.remove" />
                        </DialogContentText>
                    )
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: e => handleDialogClose(e),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => submitBinding(),
                        disabled: !allow_save,
                        label: (
                            <LocaleMessage
                                msg={
                                    currItemId === 'new'
                                        ? 'button.save'
                                        : 'button.remove'
                                }
                            />
                        ),
                    },
                ]}
            />
        );
    }

    function renderContentList() {
        const headCells = [
            {
                id: 'display_name',
                label: <LocaleMessage msg="page.dialogs.info.intents" />,
                width: '20%',
            },
            {
                id: 'example_list',
                label: <LocaleMessage msg="page.dialogs.info.examples" />,
            },
        ];

        const rowActions = [
            {
                id: 'remove',
                label: <LocaleMessage msg="button.remove" />,
                icon: <MdCancel />,
                action: (e, _id) => {
                    setOperation('remove');
                    setSelectedRows([_id]);
                    setShowBindingDialog(true);
                },
            },
        ];

        const headerActions = [
            {
                id: 'remove',
                label: <LocaleMessage msg="button.remove" />,
                icon: <MdCancel />,
                action: () => {
                    setOperation('remove');
                    setShowBindingDialog(true);
                },
            },
        ];

        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <DataTable
                    headerColumns={headCells}
                    data={contentList}
                    orderDirection="asc"
                    orderColumn="identifier"
                    handleTableRowClick={handleItemClick}
                    hasActions
                    rowActions={rowActions}
                    hasFilter
                    sortable
                    hasHeader
                    header={
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MdAdd />}
                            style={{
                                whiteSpace: 'nowrap',
                                padding: '5px 20px',
                            }}
                            onClick={() => {
                                setOperation('add');
                                setCurrItemId('new');
                                setShowBindingDialog(true);
                                setSelectedRows([]);
                            }}
                        >
                            <LocaleMessage msg="page.qna.content.intent.add" />
                        </Button>
                    }
                    selectable
                    selectedActions={headerActions}
                    selectedRows={selectedRows}
                    setSelectedRows={s => {
                        setSelectedRows(s);
                    }}
                />
            </div>
        );
    }

    return (
        <>
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.qna.contents.list.intents" />
                </h2>
            </div>

            <div className="sidecard-body">
                {showEditDialog ? renderEditDialog() : null}
                {showBindingDialog ? renderBindingDialog() : null}
                {isLoading ? (
                    <Splash
                        label={
                            <div className="mb-5">
                                <LocaleMessage msg="page.qna.content.training.loading" />
                            </div>
                        }
                        labelPosition="top"
                    />
                ) : (
                    <>{renderContentList()}</>
                )}
            </div>
        </>
    );
}

Intents.defaultProps = {};

Intents.propTypes = {
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
