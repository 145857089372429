/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useRef } from 'react';
import Tree from 'react-d3-tree';
import {
    MdAdd,
    MdPause,
    MdCancel,
    MdOutlineReplayCircleFilled,
    MdAccountTree,
} from 'react-icons/md';

import PropTypes from 'prop-types';

import { Tooltip } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';

import lng_labels from '~/util/LangMessages';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import { TreeArea } from '../../styles';

const nodeW = 200;
const nodeH = 50;

export default function TreeView({
    data,
    selected,
    onNodeAction,
    openBranches,
    labelLanguage,
}) {
    const theme = GetPluginspaceTheme(useTheme());

    const lng_all = lng_labels[labelLanguage];

    const def_color = theme && theme.primary ? theme.primary : '#000';

    const targetRef = useRef();
    const [treeData, setTreeData] = useState({});
    const [translate, setTranslate] = useState({ x: 0, y: 0 });

    function renderNodeIcon({
        Icon,
        onClick,
        tooltip,
        x,
        rx,
        rs,
        color,
        selected: isSelected,
        after,
    }) {
        const icon_size = 20;
        const r_size = rs;
        const r = r_size / 2.0 || 0;

        const background = theme.dark ? '#424242' : '#fff';
        const text_color = theme.dark ? '#eee' : '#666';

        return (
            <Tooltip title={tooltip || ''} placement="top" arrow>
                <g
                    onClick={e => {
                        e.stopPropagation();
                        onClick();
                    }}
                >
                    <rect
                        rx={r}
                        width={r}
                        height={r}
                        y={-r / 2.0}
                        x={rx || x + r / 2.0}
                        fill={isSelected ? def_color : background}
                        stroke={color || '#000'}
                        strokeWidth={3}
                    />
                    {Icon ? (
                        <Icon
                            size={icon_size}
                            y={-icon_size / 2.0}
                            x={after ? x + icon_size / 4.0 : x}
                            color={isSelected ? '#fff' : text_color}
                        />
                    ) : null}
                </g>
            </Tooltip>
        );
    }

    function renderNode(node) {
        const background = theme.dark ? '#424242' : '#fff';
        const text_color = theme.dark ? '#fff' : '#000';

        return (
            <Tooltip title={node.tooltip || ''} placement="top" arrow>
                <g
                    onClick={() => {
                        if (node.onClick) {
                            onNodeAction(node.onClick, node);
                        }
                    }}
                >
                    <rect
                        width={nodeW}
                        height={nodeH}
                        y={-nodeH / 2.0}
                        x={-nodeW / 2.0}
                        rx="5"
                        fill={node.selected ? def_color : background}
                        stroke={def_color}
                        strokeWidth={3}
                    />
                    {node.remove
                        ? renderNodeIcon({
                              Icon: MdCancel,
                              onClick: () => {
                                  onNodeAction('delete', node);
                              },
                              tooltip: (
                                  <LocaleMessage msg="page.qna.nodes.tree.remove" />
                              ),
                              x: -110,
                          })
                        : null}
                    {node.expand
                        ? renderNodeIcon({
                              Icon: null,
                              onClick: () => {
                                  onNodeAction('expand', node);
                              },
                              x: 85,
                              rs: 30,
                              color: def_color,
                          })
                        : null}
                    {node.new_search
                        ? renderNodeIcon({
                              Icon: MdOutlineReplayCircleFilled,
                              onClick: () => {},
                              tooltip: (
                                  <LocaleMessage msg="page.qna.nodes.form.action.search_answer" />
                              ),
                              x: 85,
                              rs: 30,
                              after: true,
                          })
                        : null}
                    {node.wait && node.children && node.children.length > 0
                        ? renderNodeIcon({
                              Icon: MdPause,
                              onClick: () => {},
                              tooltip: (
                                  <LocaleMessage msg="page.qna.nodes.tree.wait" />
                              ),
                              x: 140,
                              rx: 140,
                              rs: 40,
                              color: def_color,
                              selected: node.selected,
                          })
                        : null}
                    {node.expanded && node.children_selection
                        ? renderNodeIcon({
                              Icon: MdAccountTree,
                              onClick: () => {
                                  onNodeAction('edit_children', node);
                              },
                              x: 140,
                              rx: 135,
                              rs: 60,
                              color: def_color,
                              selected: node.selected,
                          })
                        : null}
                    <text
                        dominantBaseline="middle"
                        textAnchor="middle"
                        fill={node.selected ? 'white' : text_color}
                        style={{ stroke: 'none', fontWeight: 'bold' }}
                    >
                        {node.name}
                    </text>
                </g>
            </Tooltip>
        );
    }

    function renderAddNode(node) {
        return (
            <Tooltip
                title={<LocaleMessage msg="page.qna.nodes.list.add" />}
                placement="top"
                arrow
            >
                <g
                    onClick={() => {
                        onNodeAction('add', node);
                    }}
                >
                    <rect
                        width="40"
                        height="40"
                        y="-20"
                        x="-20"
                        rx="20"
                        fill="white"
                        stroke="#666"
                    />
                    <MdAdd size={30} x="-15" y="-15" color="#666" />
                </g>
            </Tooltip>
        );
    }

    function renderTreeNode({ nodeDatum }) {
        const type = nodeDatum.type || 'node';
        const renders = {
            add: renderAddNode,
            node: renderNode,
        };

        const render_function = renders[type] || renderNode;

        return render_function ? render_function(nodeDatum) : null;
    }

    function parseTree() {
        if (!data) return;

        const n_types = {};

        const parsed = {};
        const children = {};
        const node_list = data.nodes || {};
        const content_list = data.contents || {};
        const subject_list = content_list.subject || [];

        const subject_keys = {};
        const subject_groupings = {};
        subject_list.forEach(s => {
            subject_keys[s.id] = s;
            const { grouping } = s;
            if (grouping) {
                const g_key = grouping.replace(' ', '');
                if (!subject_groupings[g_key]) {
                    subject_groupings[g_key] = {
                        name: grouping,
                        children: [],
                    };
                }
            }
        });

        Object.keys(node_list).forEach(n => {
            const node = node_list[n];
            const type = node.condition_type || 'node';
            if (!n_types[type]) n_types[type] = [];
            n_types[type].push(node);

            const c_node = {
                id: n,
                label: node.name,
                type,
                children: [],
            };
            parsed[n] = c_node;
        });

        const intents = n_types.intent || [];
        const subjects = n_types.subject || [];
        const commands = n_types.command || [];
        const questions = n_types.question || [];
        const decisions = n_types.decision || [];
        const else_node = n_types.else ? n_types.else[0] : {};

        const intent_nodes = [];
        const command_nodes = [];
        const subject_nodes = [];
        const grouping_nodes = [];

        decisions.forEach(c => {
            const d_node = {
                id: c.id,
                name: c.name,
                type: 'decision',
                onClick: 'edit',
                tooltip: <LocaleMessage msg="page.qna.nodes.tree.edit" />,
                expand: false,
                new_search: c.type === 'search_answer',
                remove: c.removable,
                children: [],
                selected: selected.includes(c.id),
            };
            parsed[c.id] = d_node;
            if (c.root) {
                const root_id = c.root;
                if (!children[root_id]) children[root_id] = [];
                children[root_id].push(d_node);
            }
        });

        intents.forEach(c => {
            let i_children = [];
            const wait_decision = c.after_response === 'wait_decision';

            if (wait_decision) {
                i_children = [...(children[c.id] || [])];
                if (i_children.length !== 3) {
                    const add_decision = {
                        id: `add_decision_${c.id}`,
                        type: 'add',
                        onClick: 'add',
                        root: c.id,
                        children_type: ['decision'],
                    };
                    i_children.unshift(add_decision);
                }
            }

            const i_node = {
                id: c.id,
                name: c.name,
                type: 'intent',
                onClick: 'edit',
                tooltip: <LocaleMessage msg="page.qna.nodes.tree.edit" />,
                expand: wait_decision,
                wait: wait_decision,
                children: wait_decision ? i_children : [],
                selected: selected.includes(c.id),
            };

            parsed[c.id] = i_node;
            if (c.root) {
                i_node.remove = true;
                if (!children[c.root]) children[c.root] = [];
                children[c.root].push(i_node);
            } else {
                intent_nodes.push(i_node);
            }
        });

        commands.forEach(c => {
            let c_children = [];
            const wait_decision = c.after_response === 'wait_decision';

            if (wait_decision) {
                c_children = [...(children[c.id] || [])];
                if (c_children.length !== 3) {
                    const add_decision = {
                        id: `add_decision_${c.id}`,
                        type: 'add',
                        onClick: 'add',
                        root: c.id,
                        children_type: ['decision'],
                    };
                    c_children.unshift(add_decision);
                }
            }

            const c_node = {
                id: c.id,
                name: c.name,
                type: 'command',
                onClick: 'edit',
                tooltip: <LocaleMessage msg="page.qna.nodes.tree.edit" />,
                expand: wait_decision,
                wait: wait_decision,
                children: wait_decision ? c_children : [],
                selected: selected.includes(c.id),
            };

            parsed[c.id] = c_node;
            if (c.root) {
                c_node.remove = true;
                if (!children[c.root]) children[c.root] = [];
                children[c.root].push(c_node);
            } else {
                command_nodes.push(c_node);
            }
        });

        questions.forEach(c => {
            let q_children = [];
            const wait_decision = c.after_response === 'wait_decision';

            if (wait_decision) {
                q_children = [...(children[c.id] || [])];
                if (q_children.length !== 3) {
                    const add_decision = {
                        id: `add_decision_${c.id}`,
                        type: 'add',
                        onClick: 'add',
                        children_type: ['decision'],
                        root: c.id,
                    };
                    q_children.unshift(add_decision);
                }
            }

            const q_node = {
                id: c.id,
                name: c.removable
                    ? c.name
                    : lng_all['page.qna.nodes.list.else'],
                type: 'question',
                onClick: 'edit',
                tooltip: <LocaleMessage msg="page.qna.nodes.tree.edit" />,
                remove: c.removable,
                expand: wait_decision,
                wait: wait_decision,
                children: wait_decision ? q_children : [],
                selected: selected.includes(c.id),
            };
            parsed[c.id] = q_node;
            if (c.root) {
                if (!children[c.root]) children[c.root] = [];
                children[c.root].push(q_node);

                const parent = node_list[c.root];
                if (!c.removable && parent && !parent.allow_children) {
                    q_node.name = (
                        lng_all['page.qna.nodes.form.any_question'] || ''
                    ).toUpperCase();
                }
            }
        });

        // topics.forEach(c => {
        //     const add_question = {
        //         id: `add_question_${c.id}`,
        //         type: 'add',
        //         onClick: 'add',
        //         children_type: ['question', 'intent'],
        //         root: c.id,
        //     };

        //     const t_children = [
        //         ...(c.allow_children ? [add_question] : []),
        //         ...(children[c.id] || []),
        //     ];

        //     const expanded = openBranches[c.id] || t_children.length === 1;
        //     // const expanded = openBranches[c.id];

        //     const t_node = {
        //         id: c.id,
        //         name: c.removable
        //             ? c.name
        //             : lng_all['page.qna.nodes.list.else'],
        //         type: 'topic',
        //         onClick: 'expand',
        //         expand: true,
        //         remove: c.removable,
        //         expanded,
        //         children_selection: true,
        //         children: expanded ? t_children : [],
        //         selected: selected.includes(c.id),
        //     };
        //     parsed[c.id] = t_node;
        //     if (c.root) {
        //         if (!children[c.root]) children[c.root] = [];
        //         children[c.root].push(t_node);

        //         const parent = node_list[c.root];
        //         if (!c.removable && parent && !parent.allow_children) {
        //             t_node.name = (
        //                 lng_all['page.qna.nodes.form.any_topic'] || ''
        //             ).toUpperCase();
        //         }
        //     }
        // });

        subjects.forEach(c => {
            // const add_topic = {
            //     id: `add_topic_${c.id}`,
            //     type: 'add',
            //     onClick: 'add',
            //     children_type: ['topic'],
            //     root: c.id,
            // };

            // const s_children = [
            //     // ...(c.allow_children ? [add_topic] : []),
            //     ...(children[c.id] || []),
            // ];

            // const expanded = openBranches[c.id] || s_children.length === 1;
            // const expanded = openBranches[c.id];

            const s_node = {
                id: c.id,
                name: c.name,
                type: 'subject',
                // onClick: 'expand',
                // expand: true,
                // expanded,
                // children_selection: true,
                // children: expanded ? s_children : [],
                onClick: 'edit',
                tooltip: <LocaleMessage msg="page.qna.nodes.tree.edit" />,
                expand: false,
                selected: selected.includes(c.id),
            };

            const { subject } = c;
            const subject_obj = subject_keys[subject] || {};
            const { grouping } = subject_obj;
            if (grouping) {
                const g_key = grouping.replace(' ', '');
                if (subject_groupings[g_key]) {
                    const g_children = [
                        ...(subject_groupings[g_key].children || []),
                        s_node,
                    ];
                    subject_groupings[g_key] = {
                        ...subject_groupings[g_key],
                        children: g_children,
                    };
                    s_node.root = g_key;
                    return;
                }
            }
            subject_nodes.push(s_node);
        });

        Object.keys(subject_groupings).forEach(k => {
            const g_obj = subject_groupings[k];
            const g_children = g_obj.children || [];

            const g_node = {
                id: k,
                name: g_obj.name,
                type: 'subject_group',
                onClick: 'expand',
                tooltip: (
                    <LocaleMessage
                        msg={`page.qna.nodes.tree.${
                            openBranches[k] ? 'collapse' : 'expand'
                        }`}
                    />
                ),
                expand: true,
                children: openBranches[k] ? g_children : [],
                selected: selected.includes(k),
            };

            grouping_nodes.push(g_node);
        });

        // const intent_root = {
        //     id: 'intents',
        //     name: lng_all['page.qna.nodes.list.intent'],
        //     onClick: 'expand',
        //     expand: true,
        //     children: openBranches.intents ? intent_nodes : [],
        //     selected: selected.includes('intents'),
        // };

        const command_root = {
            id: 'commands',
            name: lng_all['page.qna.nodes.list.command'],
            onClick: 'expand',
            tooltip: (
                <LocaleMessage
                    msg={`page.qna.nodes.tree.${
                        openBranches.commands ? 'collapse' : 'expand'
                    }`}
                />
            ),
            expand: true,
            children: openBranches.commands ? command_nodes : [],
            selected: selected.includes('commands'),
        };

        const subject_root = {
            id: 'subjects',
            name: lng_all['page.qna.nodes.list.subject'],
            onClick: 'expand',
            tooltip: (
                <LocaleMessage
                    msg={`page.qna.nodes.tree.${
                        openBranches.subjects ? 'collapse' : 'expand'
                    }`}
                />
            ),
            expand: true,
            children: openBranches.subjects
                ? [...grouping_nodes, ...subject_nodes]
                : [],
            selected: selected.includes('subjects'),
        };

        const else_tree_node = {
            id: else_node ? else_node.id : 'else',
            name: lng_all['page.qna.nodes.list.else'],
            onClick: 'edit',
            tooltip: <LocaleMessage msg="page.qna.nodes.tree.edit" />,
            expand: false,
            children: [],
            selected: selected.includes(else_node.id),
        };

        const tree_root = {
            id: 'start',
            name: lng_all['page.qna.nodes.list.start'],
            onClick: 'expand',
            special: true,
            expand: true,
            remove: false,
            selected: selected.includes('start'),
            children: [
                // intent_root,
                ...intent_nodes,
                command_root,
                subject_root,
                else_tree_node,
            ],
        };

        setTreeData(tree_root);
    }

    useEffect(() => {
        if (targetRef.current) {
            const dimensions = targetRef.current.getBoundingClientRect();
            setTranslate({ x: dimensions.width / 3, y: dimensions.height / 2 });
        }
    }, []);

    useEffect(() => {
        parseTree();
    }, [data, selected, openBranches]);

    return treeData ? (
        <TreeArea
            id="treeWrapper"
            style={{ width: '100%', height: '100%', minHeight: '70vh' }}
            ref={targetRef}
        >
            <Tree
                zoom={1}
                data={treeData}
                pathFunc="step"
                orientation="horizontal"
                translate={translate}
                nodeSize={{ x: nodeW * 1.5, y: nodeH * 1.5 }}
                renderCustomNodeElement={renderTreeNode}
            />
        </TreeArea>
    ) : null;
}

TreeView.defaultProps = {
    data: {},
    openBranches: {},
    selected: [],
    labelLanguage: 'en_GB',
};

TreeView.propTypes = {
    data: PropTypes.object,
    onNodeAction: PropTypes.func.isRequired,
    openBranches: PropTypes.object,
    labelLanguage: PropTypes.string,
    selected: PropTypes.array,
};
