/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { MdSync, MdSend, MdClose } from 'react-icons/md';

import PropTypes from 'prop-types';

import {
    IconButton,
    MenuItem,
    Select,
    Tooltip,
    TextField,
    InputAdornment,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    LinearProgress,
    Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

import {
    TestArea,
    TestHeader,
    TestBody,
    TestFooter,
    ConversationStateArea,
    MessageArea,
    AnswerArea,
} from '../styles';

const def_flags = {
    en: 'gb',
    pt: 'br',
    es: 'gs',
};

const update_time = 30 * 1000;

export default function QnATest({
    dialog,
    languages,
    nodes,
    closeArea,
    showAnswerFlow,
    handleError,
}) {
    const theme = GetPluginspaceTheme(useTheme());

    // const messagesEndRef = useRef(null);
    const def_color = theme && theme.primary ? theme.primary : '#000';

    const [currIdx, setCurrIdx] = useState(-1);
    const [currSession, setCurrSession] = useState('');
    const [currLanguage, setCurrLanguage] = useState('pt_BR');
    const [currMessage, setCurrMessage] = useState('');
    const [currAnswer, setCurrAnswer] = useState(null);
    const [messageHistory, setMessageHistory] = useState([]);

    const [internalLoading, setInternalLoading] = useState(false);
    const [isTraining, setIsTraining] = useState(false);

    async function getTrainingOperation() {
        await api
            .get(`/dialogs/${dialog}/qna/training`)
            .then(response => {
                const { data } = response;
                setIsTraining(!data.success || data.is_training);
            })
            .catch(error => {
                handleError(error);
                return false;
            });
    }

    async function createSession() {
        setMessageHistory([]);
        showAnswerFlow([]);
        setCurrAnswer(null);
        setCurrMessage('');
        await api
            .post(`qna/test/${dialog}/session`, {})
            .then(response => {
                const { data } = response;
                setCurrSession(data.session || '');
            })
            .catch(error => {
                handleError(error);
                setCurrSession('');
                return false;
            });
    }

    async function sendMessage(msg) {
        if (currMessage && currMessage !== '') {
            setInternalLoading(true);
            setMessageHistory([msg, ...messageHistory]);
            setCurrMessage('');
            setCurrIdx(-1);

            const data = {
                session: currSession,
                message: {
                    language: currLanguage,
                    text: msg,
                },
            };

            await api
                .post(`qna/test/${dialog}/message`, data)
                .then(response => {
                    const ans = response.data;
                    const node_flow = ans.nodes || [];
                    showAnswerFlow(node_flow);
                    setCurrAnswer(ans);
                })
                .catch(error => {
                    handleError(error);
                    setCurrAnswer(null);
                    return false;
                });
            setInternalLoading(false);
        }
    }

    useEffect(() => {
        createSession();
        getTrainingOperation();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            getTrainingOperation();
        }, update_time);
        return () => clearInterval(interval);
    }, [dialog]);

    function onArrow(event) {
        const key = event && event.key ? event.key : '';
        switch (key) {
            case 'ArrowUp': {
                event.preventDefault();
                event.stopPropagation();
                const idx = currIdx + 1;
                const msg_idx = messageHistory[idx];
                if (msg_idx) {
                    setCurrMessage(msg_idx);
                    setCurrIdx(idx);
                }
                break;
            }

            case 'ArrowDown': {
                event.preventDefault();
                event.stopPropagation();
                const idx = currIdx - 1;
                const msg_idx = messageHistory[idx];
                if (msg_idx) {
                    setCurrMessage(msg_idx);
                    setCurrIdx(idx);
                } else {
                    setCurrMessage('');
                    setCurrIdx(-1);
                }
                break;
            }
            default:
                break;
        }
    }

    function onKeyPress(event) {
        const key = event && event.key ? event.key : '';
        switch (key) {
            case 'Enter': {
                event.preventDefault();
                event.stopPropagation();
                sendMessage(currMessage);
                break;
            }
            default:
                break;
        }
    }

    function onTextEdit(event) {
        setCurrMessage(event.target.value);
    }

    function renderConversationState() {
        const rows = ['intent', 'subject'];

        const conversation_state =
            currAnswer && currAnswer.state ? currAnswer.state : {};

        const previous_state = conversation_state.previous || {};
        const current_state = conversation_state.current || {};
        const origin =
            previous_state.origin && nodes[previous_state.origin]
                ? nodes[previous_state.origin]
                : null;
        return (
            <ConversationStateArea>
                <span className="mb-3">
                    <LocaleMessage msg="page.qna.nodes.test.state.previous" />
                </span>
                <TableContainer component={Paper} className="mb-3">
                    <Table>
                        <TableBody>
                            <TableRow
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    <LocaleMessage msg="page.qna.nodes.test.state.origin" />
                                </TableCell>
                                <TableCell align="right">
                                    {origin ? (
                                        origin.name
                                    ) : (
                                        <LocaleMessage msg="page.qna.nodes.list.start" />
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} className="mb-3">
                    <Table>
                        <TableBody>
                            {rows.map(t => (
                                <TableRow
                                    key={`previous_state_${t}`}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <LocaleMessage
                                            msg={`page.qna.content.${t}`}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        {typeof previous_state[t] ===
                                            'object' &&
                                        previous_state[t] &&
                                        previous_state[t].name
                                            ? previous_state[
                                                  t
                                              ].name.toUpperCase()
                                            : '---'}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <span className="mb-3">
                    <LocaleMessage msg="page.qna.nodes.test.state.current" />
                </span>
                <TableContainer component={Paper} className="mb-3">
                    <Table>
                        <TableBody>
                            {rows.map(t => (
                                <TableRow
                                    key={`current_state_${t}`}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <LocaleMessage
                                            msg={`page.qna.content.${t}`}
                                        />
                                    </TableCell>
                                    <TableCell align="right">
                                        {typeof current_state[t] === 'object' &&
                                        current_state[t] &&
                                        current_state[t].name
                                            ? current_state[
                                                  t
                                              ].name.toUpperCase()
                                            : '---'}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} className="mb-3">
                    <Table>
                        <TableBody>
                            <TableRow
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    <LocaleMessage msg="page.qna.nodes.test.state.next" />
                                </TableCell>
                                <TableCell align="right">
                                    {current_state.next ? (
                                        <LocaleMessage
                                            msg={`page.qna.nodes.form.after.${current_state.next}`}
                                        />
                                    ) : (
                                        <LocaleMessage msg="page.qna.nodes.list.start" />
                                    )}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </ConversationStateArea>
        );
    }

    function renderAnswer() {
        return currAnswer ? (
            <>
                <MessageArea>
                    {currAnswer.input ? (
                        <>
                            <p>
                                <span className="main-input">
                                    {currAnswer.input.original || ''}
                                </span>
                            </p>
                            {currAnswer.input.text &&
                            currAnswer.input.text !==
                                currAnswer.input.original ? (
                                <span className="mt-3">
                                    ({currAnswer.input.text || ''})
                                </span>
                            ) : null}
                        </>
                    ) : null}
                </MessageArea>
                <AnswerArea className="mb-3">
                    {currAnswer.response && currAnswer.response.text
                        ? currAnswer.response.text
                        : ''}
                </AnswerArea>
                {renderConversationState()}
                {/* <div ref={messagesEndRef} /> */}
            </>
        ) : null;
    }

    function renderFooter() {
        return (
            <TextField
                className="col-12"
                color="primary"
                variant="outlined"
                value={currMessage}
                onKeyUp={event => {
                    onArrow(event);
                }}
                onKeyPress={event => {
                    onKeyPress(event);
                }}
                onChange={event => {
                    onTextEdit(event);
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Select
                                value={currLanguage}
                                onChange={event =>
                                    setCurrLanguage(event.target.value)
                                }
                            >
                                {languages.map(l => {
                                    const l_split = l.split('_');
                                    const l_country = l_split[1]
                                        ? l_split[1].toLowerCase()
                                        : def_flags[l];
                                    return l_country ? (
                                        <MenuItem key={`lang_${l}`} value={l}>
                                            <IconButton className="icon-btn">
                                                <i
                                                    className={`flag flag-24 flag-${l_country}`}
                                                />
                                            </IconButton>
                                        </MenuItem>
                                    ) : null;
                                })}
                            </Select>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            onClick={() => {
                                sendMessage(currMessage);
                            }}
                        >
                            <MdSend size={20} color={def_color} />
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    return (
        <TestArea>
            <TestHeader>
                <div className="col-9">
                    <Typography variant="button" component="h2">
                        <LocaleMessage msg="page.qna.nodes.test.title" />
                    </Typography>
                </div>
                <div className="col-3 p-0 text-right">
                    <Tooltip
                        title={
                            <LocaleMessage msg="page.qna.nodes.test.reset" />
                        }
                        placement="top"
                    >
                        <IconButton
                            onClick={() => {
                                createSession();
                            }}
                            style={{ padding: '5px' }}
                        >
                            <MdSync size={20} color="white" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={
                            <LocaleMessage msg="page.qna.nodes.test.close" />
                        }
                        placement="top"
                    >
                        <IconButton
                            onClick={() => {
                                closeArea();
                            }}
                            style={{ padding: '5px' }}
                        >
                            <MdClose size={20} color="white" />
                        </IconButton>
                    </Tooltip>
                </div>
            </TestHeader>
            {isTraining ? (
                <Box
                    className="text-center"
                    sx={{ width: '100%' }}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: '#fff',
                    }}
                >
                    <span className="py-1">
                        <LocaleMessage msg="page.qna.nodes.test.training" />
                    </span>
                    <LinearProgress color="primary" />
                </Box>
            ) : null}

            <TestBody>
                <Scrollbars autoHide>
                    {internalLoading ? <Splash /> : renderAnswer()}
                </Scrollbars>
            </TestBody>
            <TestFooter>{renderFooter()}</TestFooter>
        </TestArea>
    );
}

QnATest.defaultProps = {
    languages: [],
    nodes: {},
    dialog: '',
    // version: '',
};

QnATest.propTypes = {
    // version: PropTypes.string,
    dialog: PropTypes.string,
    languages: PropTypes.array,
    nodes: PropTypes.object,
    closeArea: PropTypes.func.isRequired,
    showAnswerFlow: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
};
