/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { MdAdd, MdMoreVert, MdRefresh } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    Menu,
    MenuItem,
} from '@material-ui/core';

import CustomDialogTitle from '~/components/CustomDialogTitle';
import LocaleMessage from '~/components/LocaleMessage';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { changeGroupRequest } from '~/store/modules/settings/actions';
import { loadGroupsRequest } from '~/store/modules/user/actions';

import checkPermission from '../../util/CheckPermission';
import { CardRow, Card, Container } from './styles';

export default function GroupSelector() {
    const user = useSelector(state => state.user);
    const groups = user ? user.groups : [];
    const settings = useSelector(state => state.settings || null);

    const { active } = settings;
    const { permission } = user.profile;
    const adminMode = ['master', 'super_admin', 'admin'].includes(permission);

    const [currItem, setCurrItem] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const open = Boolean(anchorEl);

    const badges = {
        admin: 'primary',
        editor: 'primary',
        viewer: 'secondary',
        operator: 'secondary',
    };

    const dispatch = useDispatch();

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
    }

    function loadGroups() {
        dispatch(loadGroupsRequest());
    }

    async function leaveGroup() {
        await api
            .post(`groups/${currItem}/exit`)
            .then(() => {
                loadGroups();
            })
            .catch(error => requestError(error));
        setDialogOpen(false);
    }

    function changeGroup(event, g) {
        if (g) {
            dispatch(changeGroupRequest(g));
            history.push(`/`);
        }
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleClick(event, _id) {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setCurrItem(_id);
    }

    async function handleLeaveGroup(event, _id) {
        setCurrItem(_id);
        event.stopPropagation();
        setAnchorEl(null);
        setDialogOpen(true);
    }

    function handleManageGroup(event, _id) {
        setCurrItem(_id);
        event.stopPropagation();
        history.push(`/pluginspace/groups/${_id}`);
    }

    function handleDialogClose(event) {
        event.stopPropagation();
        setDialogOpen(false);
    }

    function buildConfirmDialog() {
        return (
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                maxWidth="sm"
                fullWidth
            >
                <CustomDialogTitle
                    title={<LocaleMessage msg="message.leave_group.title" />}
                />
                <DialogContent>
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialogOpen(false)}
                        color="primary"
                    >
                        <LocaleMessage msg="button.cancel" />
                    </Button>
                    <Button
                        onClick={() => leaveGroup()}
                        color="primary"
                        autoFocus
                    >
                        <LocaleMessage msg="button.leave_group" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    function itemCard(item) {
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={item.id}>
                <Card
                    className="jr-card jr-card-widget jr-card-full text-center card group-card"
                    style={{ height: '100%' }}
                >
                    <div className="pt-3 px-2">
                        <div className="action-row">
                            <h3>
                                <strong>{item.name}</strong>
                            </h3>
                            <IconButton
                                onClick={event => handleClick(event, item.id)}
                            >
                                <MdMoreVert />
                            </IconButton>
                        </div>
                    </div>
                    <div
                        className="body"
                        style={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <div className="mb-5">
                            <Chip
                                variant="outlined"
                                size="small"
                                label={item.permission.toUpperCase()}
                                color={badges[item.permission]}
                            />
                        </div>

                        {active && active.id === item.id ? (
                            <Button variant="contained" color="primary">
                                <LocaleMessage msg="button.signed" />
                            </Button>
                        ) : (
                            <Button
                                className="select"
                                variant="outlined"
                                color="primary"
                                onClick={event => changeGroup(event, item)}
                            >
                                <LocaleMessage msg="button.sign_in" />
                            </Button>
                        )}
                    </div>
                </Card>
            </div>
        );
    }

    function buildEmpty() {
        return (
            <>
                <div className="col-md-8 col-12 mb-5">
                    <Card className="jr-card jr-card-widget jr-card-full text-center card">
                        <div className="body">
                            <div className="jr-separator bg-primary mt-3" />
                            <h2 className="mb-3 text-primary">
                                <LocaleMessage msg="message.no_group.content" />
                            </h2>
                            <div className="mb-3 jr-separator bg-primary" />
                        </div>
                    </Card>
                </div>
                {checkPermission(permission) ? (
                    <div className="col-md-8 col-12 mb-5">
                        <Link to="/pluginspace/groups/new">
                            <div
                                className="jr-card jr-card-widget jr-card-full text-center card new-card"
                                style={{ height: '100%' }}
                            >
                                <div
                                    className="card-body text-center"
                                    style={{
                                        background: 'rgba(0,0,0,0.03)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: '#999',
                                    }}
                                >
                                    <LocaleMessage msg="message.create_group.title" />
                                    <MdAdd
                                        size={48}
                                        style={{ margin: '20px' }}
                                    />
                                </div>
                            </div>
                        </Link>
                    </div>
                ) : (
                    <div className="col-md-8 col-12 mb-5">
                        <Card className="jr-card jr-card-widget jr-card-full text-center card">
                            <div className="body">
                                <div className="jr-separator bg-primary mt-3" />
                                <h2 className="mb-3 text-primary">
                                    <LocaleMessage msg="message.wait_group.content" />
                                </h2>
                                <div className="mb-3 jr-separator bg-primary" />
                            </div>
                        </Card>
                    </div>
                )}
            </>
        );
    }

    function buildCardMenu() {
        const group = groups.find(g => {
            return g.id === currItem;
        });

        const g_permission =
            active && active.id === currItem && group.permission === 'admin';

        return (
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleMenuClose}
            >
                {adminMode || g_permission ? (
                    <MenuItem onClick={e => handleManageGroup(e, currItem)}>
                        <LocaleMessage msg="message.manage_group.short" />
                    </MenuItem>
                ) : null}
                <MenuItem onClick={e => handleLeaveGroup(e, currItem)}>
                    <LocaleMessage msg="message.leave_group.short" />
                </MenuItem>
            </Menu>
        );
    }

    function buildGridView() {
        return groups.length === 0 ? (
            buildEmpty()
        ) : (
            <>
                <CardRow className="row">
                    {groups.map(group => itemCard(group))}
                </CardRow>
                {open ? buildCardMenu() : null}
            </>
        );
    }

    return (
        <div className="app-wrapper">
            <Container>
                {dialogOpen ? buildConfirmDialog() : null}
                <Button
                    variant="outlined"
                    className="mt-1 mb-5"
                    startIcon={<MdRefresh />}
                    onClick={() => loadGroups()}
                    style={{ color: '#fff' }}
                >
                    <LocaleMessage msg="button.update" />
                </Button>
                {buildGridView()}
            </Container>
        </div>
    );
}
