/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
// import { useSelector } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import hexToRgba from 'hex-to-rgba';
import PropTypes from 'prop-types';
import {
    Area,
    AreaChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
    CartesianGrid,
} from 'recharts';

import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import ChartCard from '~/components/ChartCard';
import LocaleMessage from '~/components/LocaleMessage';
import ReportPieChart from '~/components/Reports/ReportPieChart';
import Splash from '~/components/Splash/Inside';

import { ChatCardContainer } from '../styles';

export default function DataCard({
    loading,
    card,
    data,
    filter,
    handleDateFilterChange,
    customTooltip,
}) {
    const settings = useSelector(state => state.settings || null);
    const { dark_mode } = settings;

    function buildPieChart(title, color, chartData) {
        return (
            <>
                {title ? (
                    <div>
                        <p className="card-title">{title}</p>
                    </div>
                ) : null}
                <ReportPieChart
                    title={null}
                    color={color}
                    data={chartData}
                    dataKey="value"
                    customization={{
                        innerRadius: 40,
                        outerRadius: 70,
                        stroke: dark_mode ? '#424242' : '#ddd',
                        ...(customTooltip && {
                            tooltip: customTooltip,
                        }),
                    }}
                />
            </>
        );
    }

    function buildCardFilters(available_filters, current_filter) {
        return (
            <div className="up-component">
                <FormControl
                    fullWidth
                    style={{
                        marginBottom: '10px',
                    }}
                >
                    <InputLabel id="demo-simple-select-label">
                        <LocaleMessage msg="page.dashboard.group_by" />
                    </InputLabel>
                    <Select
                        value={current_filter}
                        onChange={event =>
                            handleDateFilterChange(event.target.value)
                        }
                    >
                        {available_filters.map(l => {
                            return (
                                <MenuItem value={l.value} key={l.value}>
                                    <LocaleMessage msg={l.label} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
        );
    }

    function buildViewMore({ link }) {
        return (
            <Link to={link}>
                <Button variant="contained" color="primary" fullWidth>
                    <LocaleMessage msg="page.dashboard.view_more" />
                </Button>
            </Link>
        );
    }

    function buildTimeChart(list, labels, color, dataKey) {
        return (
            <ResponsiveContainer
                className="card-img-bottom overflow-hidden animated slideInFromLeft animation-duration-5"
                width="100%"
                height={300}
            >
                <AreaChart
                    data={list}
                    labels={labels}
                    margin={{
                        top: 30,
                        right: 0,
                        left: 10,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid vertical={false} />
                    <XAxis dataKey="date" />
                    <YAxis allowDecimals={false} />
                    <Tooltip />
                    <Area
                        dataKey={dataKey}
                        stroke={color}
                        fill={`${hexToRgba(color, 0.7)}`}
                    />
                </AreaChart>
            </ResponsiveContainer>
        );
    }

    return (
        <div className="row card-row">
            <div className="col-12 mb-5 dash-card">
                {loading ? (
                    <Splash />
                ) : (
                    <ChatCardContainer>
                        <ChartCard
                            className="animated slideInFromLeft animation-duration-3"
                            title={card.title}
                            desc={card.subtitle}
                            value={card.value}
                        >
                            <div className="row">
                                <div className="col-md-8 col-12 mb-3 area-chart-container">
                                    {buildTimeChart(
                                        data.data,
                                        data.labels,
                                        card.color,
                                        data.key
                                    )}
                                </div>
                                <div className="col-md-4 col-12 mb-3">
                                    {buildCardFilters(
                                        filter.available,
                                        filter.current
                                    )}
                                    {buildPieChart(
                                        null,
                                        card.color,
                                        filter.grouped
                                    )}
                                </div>
                            </div>
                            {buildViewMore({ link: card.link })}
                        </ChartCard>
                    </ChatCardContainer>
                )}
            </div>
        </div>
    );
}

DataCard.defaultProps = {
    loading: true,
    card: {},
    data: {},
    filter: {},
    customTooltip: null,
};

DataCard.propTypes = {
    loading: PropTypes.bool,
    card: PropTypes.object,
    data: PropTypes.object,
    filter: PropTypes.object,
    customTooltip: PropTypes.func,
    handleDateFilterChange: PropTypes.func.isRequired,
};
