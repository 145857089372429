/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete, MdCompareArrows } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import { lighten } from 'polished';

import {
    Chip,
    DialogContentText,
    Menu,
    MenuItem,
    Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import CardItem from '~/components/CardItem';
import DataFilter from '~/components/DataFilter';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import NewItemCard from '~/components/NewItem/Card';
import PageContent from '~/components/PageContent';
import PreFilter from '~/components/PreFilter';
import SimpleDialog from '~/components/SimpleDialog';
import ViewSwitcher from '~/components/ViewSwitcher';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import checkPermission from '~/util/CheckPermission';
import getDateLocale from '~/util/GetDateLocale';
import GetPluginspaceTheme from '~/util/PluginspaceTheme';

const PAGE_KEY = 'robots';
const PAGE_VIEW_DEF = 'grid';

export default function Robots() {
    const dispatch = useDispatch();

    const { profile } = useSelector(state => state.user || null);
    const settings = useSelector(state => state.settings || null);
    const page_settings = useSelector(state => state.pages || null);
    const curr_page_settings =
        page_settings[PAGE_KEY] && typeof page_settings[PAGE_KEY] === 'object'
            ? page_settings[PAGE_KEY]
            : {};

    const date_loc = getDateLocale(settings);
    const date_format = settings.locale;

    const [view, setView] = useState(
        curr_page_settings && curr_page_settings.view
            ? curr_page_settings.view
            : PAGE_VIEW_DEF
    );

    const [isLoading, setIsLoading] = useState(true);
    const [currItem, setCurrItem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const open = Boolean(anchorEl);
    const colors = GetPluginspaceTheme(useTheme());

    const { active } = settings;
    const g_admin = checkPermission(profile) || active.permission === 'admin';
    const allowNew = !(active && active.id === '*');

    const [rawData, setRawData] = useState([]);
    const [filteredData, setFilteredData] = useState(rawData);
    const [typeFilters, setTypeFilters] = useState([]);
    const [prefilter, setPrefilter] = useState(null);
    const [transfers, setTransfers] = useState([]);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadRobots() {
        const type_filters = {};
        setIsLoading(true);
        await api
            .get(`robots`)
            .then(response => {
                const data = response.data.map(r => {
                    const r_settings = r.settings;
                    const applications = r_settings.applications || {};
                    const active_apps = Object.keys(applications).filter(a => {
                        return applications[a];
                    });
                    const { transfer } = r;
                    const until =
                        transfer && transfer.until
                            ? new Date(transfer.until)
                            : null;

                    const r_type = r.type.slug;
                    if (!type_filters[r_type]) {
                        type_filters[r_type] = {
                            value: r_type,
                            label: r.type.name,
                        };
                    }
                    const updated = new Date(r.updated);

                    return {
                        ...r,
                        type_slug: r_type,
                        type_name: r.type.name,
                        apps: active_apps.length,
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                        transfered_until: until
                            ? until.toLocaleString(date_format.format)
                            : '---',
                    };
                });
                setRawData(data);

                const filter_array = Object.keys(type_filters).map(f => {
                    return type_filters[f];
                });

                setTypeFilters(filter_array);

                setTimeout(() => {
                    setIsLoading(false);
                }, 100);
            })
            .catch(error => requestError(error));
    }

    async function loadTransfers() {
        setIsLoading(true);
        await api
            .get(`robots/transfers`)
            .then(response => {
                const _transfers = response.data.filter(t => {
                    return t.type === 'temporary' || t.status === 'waiting';
                });

                const data = _transfers.map(t => {
                    const updated = new Date(t.updated);

                    return {
                        ...t,
                        robot_name: `${t.robot.code} - ${t.robot.name}`,
                        origin_group: t.origin.group,
                        destination_group: t.destination.group,
                        until: t.until
                            ? new Date(t.until).toLocaleString(
                                  date_format.format
                              )
                            : '',
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                    };
                });
                setTransfers(data);
            })
            .catch(error => requestError(error));
    }

    useEffect(() => {
        loadRobots();
        loadTransfers();
    }, [active, date_loc]);

    function handleClick(event, _id) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setCurrItem(_id);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/robots/${id}`);
    }

    function handleTransferRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/robots/transfer/${id}`);
    }

    function goToSettings(event, _id) {
        event.preventDefault();
        setAnchorEl(null);
        history.push(`/robots/${_id}`);
    }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    }

    function handleTransferOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        history.push(`/robots/transfer/new/${_id}`);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDeleteDialogOpen(false);
    }

    async function deleteItem() {
        setIsLoading(true);
        setDeleteDialogOpen(false);

        await api
            .delete(`robots/${currItem}`)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.robots.list.delete_success" />
                );

                loadRobots();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function robotCard(r) {
        const { transfer } = r;
        const in_transfer =
            transfer && transfer.id && transfer.type === 'temporary';

        const is_owner =
            active && (active.id === '*' || r.group_id === active.id);

        const badge = in_transfer ? (
            <LocaleMessage msg="page.robots.list.label.in_transfer" />
        ) : (
            r.group
        );

        return (
            <div className="col-md-3 col-sm-6 mb-5" key={r.id}>
                <Link
                    to={`${
                        is_owner
                            ? `/robots/${r.id}`
                            : `/robots/transfer/${r.transfer.id}`
                    }`}
                >
                    <CardItem
                        title={`${r.code} - ${r.name}`}
                        hasOptions={!in_transfer}
                        optionsClick={event => handleClick(event, r.id)}
                        group={badge}
                        updated={r.updated}
                        img={{ url: r.type.file.url, name: r.type_name }}
                        fit="contain"
                        background={
                            colors.primary && r.status === 'active'
                                ? `linear-gradient(0deg, ${lighten(
                                      0.25,
                                      colors.primary
                                  )}, ${colors.primary})`
                                : `linear-gradient(0deg, ${lighten(
                                      0.25,
                                      '#999'
                                  )}, #999)`
                        }
                    >
                        <>
                            <small className="card-text">
                                {in_transfer ? (
                                    <div
                                        className="mb-3"
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Chip
                                            icon={<MdCompareArrows />}
                                            label={r.transfered_until}
                                            variant="outlined"
                                            color="primary"
                                            size="small"
                                        />
                                    </div>
                                ) : null}
                                {r.status === 'active' ? (
                                    <>
                                        {r.type_name}
                                        <br />
                                        <LocaleMessage msg="page.robots.list.label.active_apps" />
                                        {r.apps || '0'}
                                    </>
                                ) : (
                                    <div
                                        className="mb-3"
                                        style={{ textAlign: 'center' }}
                                    >
                                        <Chip
                                            label={
                                                <LocaleMessage
                                                    msg={`robot.status.${r.status}`}
                                                />
                                            }
                                            variant="outlined"
                                            size="medium"
                                        />
                                    </div>
                                )}
                            </small>
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function transferCard(item) {
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={item.id}>
                <Link to={`/robots/transfer/${item.id}`}>
                    <CardItem
                        title={item.robot_name}
                        hasOptions={false}
                        group={
                            <LocaleMessage
                                msg={`list.transfers.status.${item.status ||
                                    'open'}`}
                            />
                        }
                    >
                        <>
                            <p className="card-text">
                                <LocaleMessage msg="label.form.from" />
                                {' : '}
                                {item.origin_group}
                            </p>
                            <p className="card-text">
                                <LocaleMessage msg="page.robots.list.label.to_group" />{' '}
                                {item.destination_group}
                            </p>
                            <p className="card-text">
                                {item.type === 'temporary' ? (
                                    <span>
                                        <LocaleMessage msg="label.form.until" />
                                        {` : ${item.until}`}
                                    </span>
                                ) : (
                                    <LocaleMessage msg="page.robots.form.label.definitive" />
                                )}
                            </p>
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={e => goToSettings(e, currItem)}>
                        <LocaleMessage msg="page.robots.list.label.settings" />
                    </MenuItem>
                    {g_admin ? (
                        <MenuItem
                            onClick={e => handleTransferOpen(e, currItem)}
                        >
                            <LocaleMessage msg="page.robots.list.label.transfer" />
                        </MenuItem>
                    ) : null}
                    {/* {g_admin ? (
                        <MenuItem onClick={e => handleDeleteOpen(e, currItem)}>
                            <LocaleMessage msg="page.robots.list.delete" />
                        </MenuItem>
                    ) : null} */}
                </Menu>
                {allowNew ? (
                    <Typography variant="h6" className="mb-3">
                        <LocaleMessage msg="page.robots.list.label.available" />
                    </Typography>
                ) : null}
                <div
                    className="row mb-5"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    <NewItemCard
                        disabled={!allowNew}
                        link="/robots/new"
                        text={<LocaleMessage msg="page.robots.list.add" />}
                    />
                    {filteredData.map(r => robotCard(r))}
                </div>
                {transfers.length > 0 ? (
                    <>
                        <Typography variant="h6" className="mb-3">
                            <LocaleMessage msg="page.robots.list.label.unavailable" />
                        </Typography>
                        <div
                            className="row"
                            style={{ minHeight: '150px', paddingTop: '15px' }}
                        >
                            {transfers.map(t => transferCard(t))}
                        </div>
                    </>
                ) : null}
            </>
        );
    }

    function buildListView() {
        const headCells = [
            {
                id: 'group',
                label: <LocaleMessage msg="table.headers.group" />,
            },
            {
                id: 'name',
                label: <LocaleMessage msg="table.headers.name" />,
            },
            {
                id: 'code',
                label: <LocaleMessage msg="table.headers.code" />,
            },
            {
                id: 'apps',
                label: <LocaleMessage msg="table.headers.active_apps" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'transfer',
                label: <LocaleMessage msg="page.robots.list.label.transfer" />,
                icon: <MdCompareArrows />,
                action: handleTransferOpen,
            },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        const transferCells = [
            {
                id: 'robot_name',
                label: <LocaleMessage msg="table.headers.robot" />,
            },
            {
                id: 'origin_group',
                label: <LocaleMessage msg="table.headers.from_group" />,
            },
            {
                id: 'destination_group',
                label: <LocaleMessage msg="table.headers.to_group" />,
            },
            { id: 'until', label: <LocaleMessage msg="table.headers.until" /> },
        ];

        return (
            <>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                        justifyContent: 'space-between',
                        padding: '0px 15px',
                    }}
                    className="mb-3"
                >
                    <Typography variant="h6" style={{ padding: '0px 5px' }}>
                        <LocaleMessage msg="page.robots.list.label.available" />
                    </Typography>

                    <div className="col-3" style={{ padding: '0px' }}>
                        <NewItemButton
                            disabled={!allowNew}
                            link="/robots/new"
                            text={<LocaleMessage msg="page.robots.list.add" />}
                        />
                    </div>
                </div>
                <div
                    className="mb-5"
                    style={{
                        minHeight: '150px',
                        width: '100%',
                        padding: '15px',
                    }}
                >
                    <DataTable
                        headerColumns={headCells}
                        data={filteredData}
                        pageKey={`${PAGE_KEY}_list`}
                        orderColumn="name"
                        orderDirection="asc"
                        setCurrDialog={_id => setCurrItem(_id)}
                        handleTableRowClick={(event, id) =>
                            handleTableRowClick(event, id)
                        }
                        rowActions={g_admin ? rowActions : []}
                    />
                </div>
                {allowNew && transfers.length > 0 ? (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                width: '100%',
                                justifyContent: 'space-between',
                                padding: '0px 15px',
                            }}
                            className="mb-3"
                        >
                            <Typography
                                variant="h6"
                                style={{ padding: '0px 5px' }}
                            >
                                <LocaleMessage msg="page.robots.list.label.unavailable" />
                            </Typography>
                        </div>
                        <div
                            className="mb-5"
                            style={{
                                minHeight: '150px',
                                width: '100%',
                                padding: '15px',
                            }}
                        >
                            <DataTable
                                headerColumns={transferCells}
                                data={transfers}
                                pageKey={`${PAGE_KEY}_transfer_list`}
                                orderDirection="asc"
                                orderColumn="robot_name"
                                setCurrDialog={_id => {}}
                                handleTableRowClick={(event, id) =>
                                    handleTransferRowClick(event, id)
                                }
                                rowActions={[]}
                            />
                        </div>
                    </>
                ) : null}
            </>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteDialogOpen}
                onClose={handleDialogClose}
                title={<LocaleMessage msg="page.robots.list.delete_title" />}
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.undone.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'delete',
                        onClick: () => deleteItem(currItem),
                        label: <LocaleMessage msg="button.delete" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.robots.list.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                <PreFilter
                    filterKey="type_slug"
                    options={typeFilters}
                    onFilterChange={f => setPrefilter(f)}
                />
                <div className="body-top-controls">
                    <div className="col-3 pl-0">
                        <DataFilter
                            rawData={rawData}
                            prefilter={prefilter}
                            headerColumns={[
                                'group',
                                'name',
                                'code',
                                'status',
                                'type_name',
                            ]}
                            returnFilteredData={f_data =>
                                setFilteredData(f_data)
                            }
                        />
                    </div>
                    <ViewSwitcher
                        size="col-3"
                        view={view}
                        setView={v => setView(v)}
                        pageKey={PAGE_KEY}
                    />
                </div>
                {view === 'grid' ? buildGridView() : buildListView()}
            </>
        </PageContent>
    );
}
