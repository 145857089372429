import styled from 'styled-components';

export const Item = styled.li`
    :hover {
        background: rgba(0, 0, 0, 0.05);
        cursor: pointer;
    }

    .item-name {
        color: ${props => {
            return props.theme.dark_mode ? '#fff' : '#252525';
        }} !important;
    }
`;
