/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { Button, TextField, DialogContentText } from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';
// import lng_labels from '~/util/LangMessages';

import { ParameterArea } from '../styles';

export default function QnAPersona({ dialogSettings, handleError }) {
    // const loc = settings.locale;
    // const lang = loc && loc.code ? loc.code : 'pt_BR';
    // const lng_all = lng_labels[lang];

    const { id } = dialogSettings;

    const [isLoading, setIsLoading] = useState(true);
    const [body, setBody] = useState({});

    const [showEditDialog, setShowEditDialog] = useState(false);

    async function loadAgent() {
        setIsLoading(true);
        await api
            .get(`dialogs/${id}/qna/persona`)
            .then(response => {
                const { data } = response;
                setBody(data);
            })
            .catch(error => handleError(error));

        setIsLoading(false);
    }

    useEffect(() => {
        loadAgent();
    }, []);

    async function handleSubmit() {
        setShowEditDialog(false);
        setIsLoading(true);
        await api
            .put(`dialogs/${id}/qna/persona`, body)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });
        loadAgent();
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setShowEditDialog(false);
    }

    function renderEditDialog() {
        return (
            <SimpleDialog
                size="sm"
                open={showEditDialog}
                onClose={handleDialogClose}
                title={
                    <>
                        <LocaleMessage msg="page.qna.contents.list.edit" />
                    </>
                }
                content={
                    <>
                        <DialogContentText>
                            <LocaleMessage msg="page.qna.persona.confirmation" />
                        </DialogContentText>
                    </>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: e => handleDialogClose(e),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => handleSubmit(),
                        label: <LocaleMessage msg="button.save" />,
                    },
                ]}
            />
        );
    }

    function renderTextField(key, label) {
        return (
            <TextField
                label={<LocaleMessage msg={label} />}
                fullWidth
                value={body[key] || ''}
                variant="outlined"
                onChange={event => {
                    setBody({
                        ...body,
                        [key]: event.target.value,
                    });
                }}
            />
        );
    }

    function renderContent() {
        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <ParameterArea className="pt-4 pb-4 mb-3 row">
                    <div className="col-12 mt-3 row">
                        <div className="col-md-5 col-12 mb-3">
                            {renderTextField('name', 'page.qna.persona.name')}
                        </div>
                    </div>
                    <div className="col-12 row">
                        <div className="col-md-5 col-12 mb-3">
                            {renderTextField(
                                'company_name',
                                'page.qna.persona.company_name'
                            )}
                        </div>
                        <div className="col-md-7 col-12 mb-3">
                            {renderTextField(
                                'company_description',
                                'page.qna.persona.company_description'
                            )}
                        </div>
                    </div>
                    <div className="col-12 row">
                        <div className="col-12 mb-3">
                            {renderTextField(
                                'objective',
                                'page.qna.persona.objective'
                            )}
                        </div>
                    </div>
                </ParameterArea>
                <Button
                    className="p-3"
                    variant="contained"
                    color="primary"
                    onClick={() => setShowEditDialog(true)}
                    fullWidth
                    size="large"
                >
                    <LocaleMessage msg="button.save" />
                </Button>
            </div>
        );
    }

    return (
        <>
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.qna.contents.list.persona" />
                </h2>
            </div>
            <div
                className="mb-3"
                style={{
                    width: '100%',
                    padding: '0px 25px',
                }}
            >
                <LocaleMessage msg="page.qna.beta.title" />
            </div>

            <div className="sidecard-body">
                {showEditDialog ? renderEditDialog() : null}
                {isLoading ? <Splash /> : <>{renderContent()}</>}
            </div>
        </>
    );
}

QnAPersona.defaultProps = {
    // settings: {},
};

QnAPersona.propTypes = {
    // settings: PropTypes.object,
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
