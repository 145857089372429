/* eslint-disable consistent-return */
import produce from 'immer';

const INITIAL_STATE = {};

export default function pages(state = INITIAL_STATE, action) {
    return produce(state, draft => {
        switch (action.type) {
            case '@pages/SWITCH_VIEW': {
                const { page, view } = action.payload;
                const curr_state = draft[page] || {};
                const new_state = {};
                if (typeof curr_state === 'object') {
                    Object.keys(curr_state).forEach(k => {
                        new_state[k] = curr_state[k];
                    });
                }
                new_state.view = view;
                draft[page] = new_state;
                break;
            }
            case '@pages/SWITCH_PROP': {
                const { page, key, value } = action.payload;
                const curr_state = draft[page] || {};
                const new_state = {};
                if (typeof curr_state === 'object') {
                    Object.keys(curr_state).forEach(k => {
                        new_state[k] = curr_state[k];
                    });
                }
                new_state[key] = value;
                draft[page] = new_state;
                break;
            }
            default:
                return state;
        }
    });
}
