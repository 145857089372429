/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    // TextField,
    DialogContentText,
    Slider,
} from '@material-ui/core';

import LocaleMessage from '~/components/LocaleMessage';
import SimpleDialog from '~/components/SimpleDialog';
import Splash from '~/components/Splash/Inside';

import api from '~/services/pluginbot-api';

import { ParameterArea } from '../styles';

export default function QnASettings({
    dialogSettings,
    reloadSettings,
    handleError,
}) {
    const { id, settings } = dialogSettings;

    const [isLoading, setIsLoading] = useState(false);
    const [genAIConfig, setGenAIConfig] = useState(settings.genAI || {});
    const [showEditDialog, setShowEditDialog] = useState(false);

    async function handleSubmit() {
        setShowEditDialog(false);
        setIsLoading(true);
        await api
            .put(`dialogs/${id}/qna/settings/genAI`, genAIConfig)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.qna.content.update_success" />
                );
                return true;
            })
            .catch(error => {
                handleError(error);
                return false;
            });
        reloadSettings();
        setIsLoading(false);
    }

    function editGenAISource(source, key, value) {
        const source_config = genAIConfig[source] || {};
        setGenAIConfig({
            ...genAIConfig,
            [source]: {
                ...source_config,
                [key]: value,
            },
        });
    }

    // function editGenAIOption(key, value) {
    //     setGenAIConfig({
    //         ...genAIConfig,
    //     });
    // }

    function handleDialogClose(event) {
        event.preventDefault();
        setShowEditDialog(false);
    }

    function renderEditDialog() {
        return (
            <SimpleDialog
                size="sm"
                open={showEditDialog}
                onClose={handleDialogClose}
                title={
                    <>
                        <LocaleMessage msg="page.qna.settings.submit" />
                    </>
                }
                content={
                    <>
                        <DialogContentText>
                            <LocaleMessage msg="page.qna.settings.submit.confirmation" />
                        </DialogContentText>
                    </>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: e => handleDialogClose(e),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'submit',
                        onClick: () => handleSubmit(),
                        label: <LocaleMessage msg="button.save" />,
                    },
                ]}
            />
        );
    }

    // function renderTextField(key, label) {
    //     // return (
    //     //     <TextField
    //     //         label={<LocaleMessage msg={label} />}
    //     //         fullWidth
    //     //         value={body[key] || ''}
    //     //         variant="outlined"
    //     //         onChange={event => {
    //     //             setBody({
    //     //                 ...body,
    //     //                 [key]: event.target.value,
    //     //             });
    //     //         }}
    //     //     />
    //     // );
    //     return null;
    // }

    function renderProbSlider(source) {
        const source_config = genAIConfig[source] || {};

        return (
            <div
                className="col-12"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <h5 className="mb-3">
                    <LocaleMessage
                        msg={`list.dialogs.answer_sources.${source}`}
                    />
                </h5>
                <Slider
                    className="col-md-8 col-12"
                    value={source_config.prob || 0}
                    onChange={(event, value) => {
                        editGenAISource(source, 'prob', value);
                    }}
                    step={0.05}
                    marks={[
                        {
                            value: 0,
                            label: (
                                <LocaleMessage msg="page.qna.settings.gen_ai.prob.low" />
                            ),
                        },
                        {
                            value: 1,
                            label: (
                                <LocaleMessage msg="page.qna.settings.gen_ai.prob.high" />
                            ),
                        },
                    ]}
                    min={0}
                    max={1}
                    size="medium"
                />
            </div>
        );
    }

    function renderGenAISettings() {
        return (
            <div
                style={{
                    minHeight: '150px',
                    width: '100%',
                    padding: '0px 15px',
                }}
            >
                <ParameterArea className="p-4 mb-3 row">
                    <div className="col-12 mt-3 mb-3 text-center">
                        <h3 className="">
                            <LocaleMessage msg="page.qna.settings.gen_ai.title" />
                        </h3>
                        <span>
                            <LocaleMessage msg="page.qna.settings.gen_ai.description" />
                        </span>
                    </div>
                    <ParameterArea className="p-4 mb-3 row">
                        <div className="col-12 row">
                            {renderProbSlider('dialog_content')}
                        </div>
                        <div className="col-12 row">
                            {renderProbSlider('vertex_search')}
                        </div>
                        <div className="col-12 row">
                            {renderProbSlider('google_search')}
                        </div>
                    </ParameterArea>
                </ParameterArea>
                <Button
                    className="p-3"
                    variant="contained"
                    color="primary"
                    onClick={() => setShowEditDialog(true)}
                    fullWidth
                    size="large"
                >
                    <LocaleMessage msg="button.save" />
                </Button>
            </div>
        );
    }

    return (
        <>
            <div className="sidecard-header">
                <h2>
                    <LocaleMessage msg="page.qna.settings.title" />
                </h2>
            </div>

            <div className="sidecard-body">
                {showEditDialog ? renderEditDialog() : null}
                {isLoading ? <Splash /> : <>{renderGenAISettings()}</>}
            </div>
        </>
    );
}

QnASettings.defaultProps = {
    // settings: {},
};

QnASettings.propTypes = {
    // settings: PropTypes.object,
    handleError: PropTypes.func.isRequired,
    reloadSettings: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
