import styled from 'styled-components';

export const SpecialCard = styled.div`
    height: 100%;
    border-radius: 0px 0px 0.375em 0.375em !important;
    border-top: 5px solid
        ${props => {
            const color = props.theme.palette.primary.main;
            return props.theme.dark_mode ? '#fff' : color;
        }};
    background: ${props => {
        const color = props.theme.palette.primary.main;
        return props.theme.dark_mode ? color : '#fff';
    }};

    .plugin-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 16px;
        color: ${props => {
            const color = props.theme.palette.primary.main;
            return props.theme.dark_mode ? '#fff' : color;
        }};
    }
`;

export const NewCard = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.5vh solid
        ${props => {
            const color = props.theme.palette.primary.main;
            return color;
        }};
    background: ${props => {
        return props.theme.dark_mode ? '#424242' : '#fff';
    }};
    color: ${props => {
        const color = props.theme.palette.primary.main;
        return props.theme.dark_mode ? '#fff' : color;
    }};
    :hover {
        cursor: pointer;
    }

    .disabled {
        pointer-events: none;
        .card,
        .card-body {
            border: none;
            color: #999;
            background: #ddd;
        }
    }
`;
